import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportBadminton = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M11.036 13.807l-1.89-1.892-2.842 2.842.715.713 4.017-1.663zM4.55 13.01l.691.689 2.844-2.844L6.22 8.989l-1.669 4.02zm2.11 4.23l-3.9-3.9S7.13 2.567 8.29 0c.16-.01 2.45.5 2.45.5l.45 2.4 2.42.47.53 2.49 2.5.54s.44 2.2.43 2.38c.18-.01 2.39.44 2.39.44l.54 2.49-13.34 5.53zM.808 19.193c-1.078-1.078-1.078-2.825 0-3.904l.912-.911 3.903 3.904-.91.91c-1.079 1.079-2.826 1.079-3.905 0z" />
  </svg>
);

IconSportBadminton.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportBadminton.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
