import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportTaekwondo = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M15.287 1.642L13.251.62 9.451 12h4.8l.37-1.46 1.375 3.492c.093.232.343.36.586.299l3.038-.758c.287-.073.45-.378.35-.657L16.272 2.75c-.174-.481-.527-.878-.986-1.108zM11.231 13.5l1.39 2.08c.23.35.14.81-.2 1.04-.13.09-.28.13-.42.13-.24 0-.48-.12-.62-.33l-1.38-2.07-1.38 2.07c-.23.34-.69.43-1.04.2-.34-.23-.43-.69-.2-1.04l1.39-2.08H5.86l-1.678 5.863c-.09.319.15.637.481.637h10.674c.332 0 .572-.318.48-.637L14.141 13.5h-2.91zm.65-13.5h-3.76l1.88 5.62L11.881 0zM3.417 14.327l-3.038-.76c-.288-.072-.45-.377-.349-.656L3.728 2.75c.175-.481.528-.878.987-1.108L6.751.62 9.211 8l-1.34 4h-2.12l-.37-1.46-1.378 3.487c-.093.232-.343.36-.586.3z" />
  </svg>
);

IconSportTaekwondo.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportTaekwondo.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
