import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import Button from 'reactstrap/lib/Button';

import { SkipServerRender } from 'components/skip-server-render/skip-server-render';
import { Portal } from 'components/portal/portal';
import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { formatAmountWithCurrencySymbol } from 'helpers/currency';

import './cashout-confirm.scss';

const PortalWithBackdrop = ({ children, onClick }) => (
  <Portal>
    <div
      role="button"
      tabIndex="0"
      onClick={onClick}
      onKeyPress={onClick}
      className="cashout-confirm-backdrop position-fixed d-flex align-items-center justify-content-center px-2"
    >
      {children}
    </div>
  </Portal>
);

PortalWithBackdrop.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export class CashoutConfirm extends Component {
  static propTypes = {
    currency: PropTypes.string.isRequired,
    isPortal: PropTypes.bool,
    xPosition: PropTypes.number,
    yPosition: PropTypes.number,
    cashoutAmount: PropTypes.number,
    cancelCashout: PropTypes.func.isRequired,
    makeCashout: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isPortal: false,
    xPosition: null,
    yPosition: null,
    cashoutAmount: null,
  };

  state = {
    isAmountChanged: false,
    isCashoutDisabled: false,
  };

  componentDidMount() {
    const { isPortal } = this.props;

    if (!isPortal) {
      document.body.classList.add('scroll-disabled');
    }

    document.addEventListener('click', this.checkOutsideClick);
  }

  componentDidUpdate(prevProps) {
    const { cashoutAmount } = this.props;
    const { cashoutAmount: prevCashoutAmount } = prevProps;

    if (prevCashoutAmount !== cashoutAmount) {
      if (cashoutAmount) {
        this.setIsAmountChanged(true);
        this.setIsCashoutDisabled(false);
      } else {
        this.setIsAmountChanged(false);
        this.setIsCashoutDisabled(true);
      }
    }
  }

  componentWillUnmount() {
    const { isPortal } = this.props;

    if (!isPortal) {
      document.body.classList.remove('scroll-disabled');
    }

    document.removeEventListener('click', this.checkOutsideClick);
  }

  setIsAmountChanged = isAmountChanged => this.setState({ isAmountChanged });

  setIsCashoutDisabled = isCashoutDisabled => this.setState({ isCashoutDisabled });

  checkOutsideClick = (e) => {
    const { cancelCashout } = this.props;
    const path = e.path || (e.composedPath && e.composedPath());

    const isClickOnCashout = R.compose(
      R.contains(true),
      R.map(DOMTokenList => DOMTokenList.contains('cashout-confirm')),
      R.reject(DOMTokenList => !DOMTokenList),
      R.pluck('classList')
    )(path);

    if (!isClickOnCashout) {
      cancelCashout(e);
    }
  };

  onClick = (e) => {
    e.stopPropagation();
  };

  render() {
    const {
      currency,
      isPortal,
      xPosition,
      yPosition,
      cashoutAmount,
      cancelCashout,
      makeCashout,
    } = this.props;
    const { isAmountChanged, isCashoutDisabled } = this.state;
    const Wrapper = isPortal ? Portal : PortalWithBackdrop;
    const wrapperProps = isPortal
      ? {}
      : { onClick: cancelCashout };
    let intlDescriptionId = 'cashout.confirm-description';

    if (isAmountChanged) {
      intlDescriptionId = 'cashout.amount-changed';
    } else if (isCashoutDisabled) {
      intlDescriptionId = 'cashout.disabled';
    }

    return (
      <SkipServerRender skip>
        <Wrapper {...wrapperProps}>
          <div
            style={isPortal ? { left: xPosition, top: yPosition } : {}}
            className={classNames('cashout-confirm p-2_5 rounded bg-main-5', {
              'is-portal position-fixed': isPortal,
            })}
            role="button"
            tabIndex="0"
            onClick={this.onClick}
            onKeyPress={this.onClick}
          >
            {!isCashoutDisabled && !!cashoutAmount && (
              <FormattedTag
                id="cashout.confirm-amount"
                tag="div"
                values={{ amount: `${formatAmountWithCurrencySymbol(cashoutAmount, currency)}` }}
                className="mb-0_5 font-weight-bold"
              />
            )}

            <FormattedTag id={intlDescriptionId} tag="div" className="text-small mb-2_5" />

            <div className="d-flex justify-content-end">
              <FormattedTag
                id={`general.${isCashoutDisabled ? 'close' : 'cancel'}`}
                tag={Button}
                size="sm"
                outline
                onClick={cancelCashout}
              />

              {!isCashoutDisabled && (
                <FormattedTag
                  id="cashout.confirm"
                  tag={Button}
                  size="sm"
                  className="ml-1_5"
                  color="primary"
                  onClick={makeCashout}
                />
              )}
            </div>
          </div>
        </Wrapper>
      </SkipServerRender>
    );
  }
}
