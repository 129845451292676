import React from 'react';
import PropTypes from 'prop-types';

export const IconExpand = ({ className }) => (
  <svg className={className} width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 4.5L4 2L6.5 4.5" stroke="white" strokeOpacity="0.3" />
    <path d="M1.5 8L4 10.5L6.5 8" stroke="white" strokeOpacity="0.3" />
  </svg>

);

IconExpand.propTypes = {
  className: PropTypes.string,
};

IconExpand.defaultProps = {
  className: null,
};
