import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportAussieRules = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M11.743 13.553c-.14.15-.33.22-.53.22-.19 0-.38-.07-.53-.22-.29-.29-.29-.77 0-1.06l1.81-1.81c.29-.29.77-.29 1.06 0 .29.3.29.77 0 1.06l-1.81 1.81zm-2.64-1.9c-.19 0-.39-.07-.53-.22-.3-.29-.3-.76 0-1.06l1.8-1.8c.3-.3.77-.3 1.06 0 .3.29.3.76 0 1.06l-1.8 1.8c-.15.15-.34.22-.53.22zm-2.13-2.12c-.19 0-.38-.07-.53-.22-.29-.29-.29-.77 0-1.06l1.81-1.81c.29-.29.77-.29 1.06 0 .29.3.29.77 0 1.06l-1.81 1.81c-.14.15-.33.22-.53.22zm7.27 3.96c.19 0 .38.07.53.22l4.69 4.69c1.26-2.54.49-9.28-4.04-13.82-4.54-4.53-11.28-5.3-13.82-4.04l4.68 4.68c.3.3.3.77 0 1.06-.14.15-.34.22-.53.22s-.38-.07-.53-.22l-4.68-4.68c-1.26 2.54-.49 9.28 4.04 13.82 4.54 4.53 11.28 5.3 13.82 4.04l-4.69-4.69c-.29-.29-.29-.77 0-1.06.15-.15.34-.22.53-.22z" />
  </svg>
);

IconSportAussieRules.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportAussieRules.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
