import {
  withFreebets, withBetSlip, withUser, withBetSlipActions
} from '@sportsbook-digitain/core/lib/hocs';
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  BET_SLIP_STORE_FIELDS,
  USER_STORE_FIELDS,
  BET_SLIP_STATE,
  BET_SLIP_TABS,
} from 'core/constants';

import { IconArrowRight } from 'components/icons/icon-arrow-right/icon-arrow-right';
import { Tabs, Tab } from 'components/tabs/tabs';
import { IconFreebet } from 'components/icons/icon-freebet/icon-freebet';
import { GA } from 'helpers/ga';
import { BetSlipTab } from './bet-slip-tab/bet-slip-tab';
import { MyBetsTab } from './my-bets-tab/my-bets-tab';
import { UnsupportedCurrencyState } from './unsupported-currency-state/unsupported-currency-state';

import './ticket.scss';

const StakesAmount = ({ amount }) => !!amount && (
  <div className="stakes-amount d-flex align-items-center justify-content-center rounded-circle order-last ml-1">
    {amount}
  </div>
);

StakesAmount.propTypes = {
  amount: PropTypes.number,
};

StakesAmount.defaultProps = {
  amount: null,
};

class TicketUI extends Component {
  static propTypes = {
    betSlipStakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isBetSlipOpened: PropTypes.bool.isRequired,
    isCurrencySupported: PropTypes.bool.isRequired,
    setIsOpened: PropTypes.func.isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    clearBetSlip: PropTypes.func.isRequired,
    setBetSlipTab: PropTypes.func.isRequired,
    betSlipTab: PropTypes.string.isRequired,
    freebets: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    freebets: null,
  }

  ticketRef = createRef();

  componentDidUpdate(prevProps) {
    const {
      betSlipStakes,
      betSlipTab,
      isBetSlipOpened,
      setIsOpened,
    } = this.props;
    const { betSlipStakes: prevBetSlipStakes, betSlipTab: prevBetSlipTab } = prevProps;

    if (prevBetSlipStakes.length !== betSlipStakes.length || betSlipTab !== prevBetSlipTab) {
      if (!prevBetSlipStakes.length && !isBetSlipOpened) {
        setIsOpened(true);
      }

      this.scrollTicketToBottom();
    }
  }

  componentWillUnmount() {
    const { isBetSlipOpened, setIsOpened } = this.props;

    if (isBetSlipOpened) {
      setIsOpened(false);
    }
  }

  toggleTab = (activeTab) => {
    const {
      isBetSlipOpened,
      setIsOpened,
      betSlipState,
      clearBetSlip,
      setBetSlipTab,
    } = this.props;
    let label;
    setBetSlipTab(activeTab);

    if (!isBetSlipOpened) {
      setIsOpened(true);
    } else if (betSlipState === BET_SLIP_STATE.SUCCESS) {
      clearBetSlip({ withClosing: false, betSlipTab: activeTab });
    }

    if (activeTab === BET_SLIP_TABS.BET_SLIP) {
      label = 'open';
    } else if (activeTab === BET_SLIP_TABS.MY_BETS) {
      label = 'my-bets-click';
    }

    if (label) {
      GA.event({
        category: 'bet-slip',
        label,
      });
    }
  };

  toggleIsOpen = () => {
    const { isBetSlipOpened, setIsOpened } = this.props;
    setIsOpened(!isBetSlipOpened);
  };

  scrollTicketToBottom = () => {
    const ticket = this.ticketRef.current;

    if (ticket) {
      const tabPane = ticket.querySelector('.tab-pane');

      if (tabPane) {
        tabPane.scrollTop = tabPane.scrollHeight;
      }
    }
  };

  render() {
    const {
      betSlipStakes,
      isBetSlipOpened,
      isCurrencySupported,
      betSlipTab,
      freebets: { availableFreebetId },
    } = this.props;

    return (
      <div
        ref={this.ticketRef}
        className={classNames('ticket position-fixed d-none d-lg-block bg-main-2', {
          'is-open': isBetSlipOpened,
        })}
      >
        <div>
          <Tabs activeTab={betSlipTab} toggleTab={this.toggleTab} classNameLabel="pr-5">
            <Tab
              tabId={BET_SLIP_TABS.BET_SLIP}
              label="ticket.tab1"
              external={<StakesAmount amount={betSlipStakes.length} />}
            >
              {isCurrencySupported && betSlipTab === BET_SLIP_TABS.BET_SLIP && (
                <BetSlipTab scrollTicketToBottom={this.scrollTicketToBottom} />
              )}
            </Tab>
            <Tab tabId={BET_SLIP_TABS.MY_BETS} label="ticket.tab2" external={availableFreebetId && <IconFreebet className="icon-freebet order-last ml-1" />}>
              {isCurrencySupported && betSlipTab === BET_SLIP_TABS.MY_BETS && <MyBetsTab />}
            </Tab>
          </Tabs>

          <div
            role="button"
            tabIndex="0"
            onClick={this.toggleIsOpen}
            onKeyPress={this.toggleIsOpen}
            className="ticket-toggle-button position-absolute d-flex align-items-center justify-content-center"
          >
            <IconArrowRight />
          </div>
        </div>

        {!isCurrencySupported && <UnsupportedCurrencyState />}
      </div>
    );
  }
}

export const Ticket = withBetSlipActions(withUser(
  withFreebets(withBetSlip(TicketUI, [
    BET_SLIP_STORE_FIELDS.STAKES,
    BET_SLIP_STORE_FIELDS.IS_OPENED,
    BET_SLIP_STORE_FIELDS.BET_SLIP_STATE,
    BET_SLIP_STORE_FIELDS.BET_SLIP_TAB,
  ])),
  [USER_STORE_FIELDS.IS_CURRENCY_SUPPORTED],
));
