import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { prepareBetsForRepeat, mathRound, convertStringifiedBoolean } from 'core/helpers';
import { BET_TYPE, BET_STAKE_STATUS, AI_OUTCOME_SECTION } from 'core/constants';
import { handicapStakeTypesIds, restOfTheMatchStakeTypeIds } from 'core/mapping-stake-types';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { RouteLink } from 'components/route-link/route-link';
import { CashoutButton } from 'components/cashout-button/cashout-button';
import { CashoutConfirm } from 'components/cashout-confirm/cashout-confirm';
import { IconRepeat } from 'components/icons/icon-repeat/icon-repeat';
import { prepareStakeTypeName } from 'helpers/stake';
import { toShortFormat } from 'helpers/date';
import { getBetStatusIcon, isBetWithRepeat } from 'helpers/bets';
import { formatAmountWithCurrencySymbol } from 'helpers/currency';
import { prepareBetsScore } from 'helpers/score';
import { GA } from 'helpers/ga';
import FreebetTitle from 'components/freebet-title/freebet-title';

export class BetItemOrdinar extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    stakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    betAmount: PropTypes.number.isRequired,
    betFactor: PropTypes.number.isRequired,
    maxWinAmount: PropTypes.number.isRequired,
    makeCashout: PropTypes.func.isRequired,
    order: PropTypes.PropTypes.shape().isRequired,
    orderId: PropTypes.string.isRequired,
    isCashoutInProgress: PropTypes.bool,
    repeatBet: PropTypes.func.isRequired,
    betType: PropTypes.oneOf([
      BET_TYPE.ORDINAR,
      BET_TYPE.EXPRESS,
      BET_TYPE.SYSTEM,
    ]).isRequired,
    systemIndex: PropTypes.number,
    onCashoutClick: PropTypes.func.isRequired,
    closeCashout: PropTypes.func.isRequired,
    isCashoutOpen: PropTypes.bool.isRequired,
    cashoutX: PropTypes.number,
    cashoutY: PropTypes.number,
    sendEventClickFeedback: PropTypes.func.isRequired,
    freeBetId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    isCashoutInProgress: false,
    systemIndex: null,
    cashoutX: null,
    cashoutY: null,
  };

  onCashoutClick = (e) => {
    const { onCashoutClick, orderId } = this.props;
    onCashoutClick(e, orderId);
  };

  cancelCashout = () => {
    const { closeCashout } = this.props;
    closeCashout();
    GA.event({
      category: 'my-bets',
      label: 'cancel-cashout',
    });
  };

  makeCashout = () => {
    const {
      orderId,
      order: {
        cashoutAmount,
      },
      makeCashout,
      closeCashout,
    } = this.props;

    closeCashout();
    makeCashout({ orderId, cashoutAmount });
    GA.event({
      category: 'my-bets',
      label: 'confirm-cashout',
    });
  };

  onEventClick = (e) => {
    const { dataset: { eventId, isLive } } = e.currentTarget;
    const { sendEventClickFeedback } = this.props;
    GA.event({
      category: 'go-to-event-page',
      label: 'my-bets-event-click',
    });
    sendEventClickFeedback(eventId, convertStringifiedBoolean(isLive));
  };

  repeatBet = () => {
    const {
      repeatBet,
      betAmount,
      betType,
      stakes,
      systemIndex,
    } = this.props;
    repeatBet({
      betAmount,
      betType,
      stakes: prepareBetsForRepeat(stakes),
      systemIndex,
    });
    GA.event({
      category: 'bet-slip',
      label: 'repeat-last-bet-click',
    });
  };

  render() {
    const {
      locale,
      currency,
      betAmount,
      betFactor,
      stakes,
      maxWinAmount,
      order: {
        cashoutAmount,
      },
      isCashoutInProgress,
      isCashoutOpen,
      cashoutX,
      cashoutY,
      freeBetId,
    } = this.props;
    const {
      teamA,
      teamB,
      eventDateTimeStamp,
      stakeName,
      stakeCode,
      stakeTypeId,
      stakeTypeName,
      stakeArgument,
      isOutright,
      periodName,
      sportId,
      eventNumber,
      order: {
        statusId,
        currentScore,
      }
    } = stakes[0];
    const StatusIcon = getBetStatusIcon(statusId);
    const score = prepareBetsScore(currentScore, sportId);
    const formattedStakeTypeName = prepareStakeTypeName(periodName, stakeTypeName);
    let formattedStakeName = `${stakeName}${stakeArgument === null ? '' : ` ${stakeArgument}`}`;
    const isActive = statusId === BET_STAKE_STATUS.NEW;
    const LinkComponent = isActive ? RouteLink : 'span';
    const linkProps = isActive
      ? {
        locale,
        to: `/event/${eventNumber}?isLive=${!!score}&sectionBeforeEventPage=${AI_OUTCOME_SECTION.MY_BETS}`,
        onClick: this.onEventClick,
        'data-event-id': eventNumber,
        'data-is-live': !!score,
      }
      : {};

    if (handicapStakeTypesIds.includes(stakeTypeId)) {
      formattedStakeName = `${stakeCode === 1 ? teamA : teamB} ${stakeArgument > 0 ? `+${stakeArgument}` : stakeArgument}`;
    } else if (restOfTheMatchStakeTypeIds.includes(stakeTypeId)) {
      formattedStakeName = `${stakeName} (${stakeArgument.toFixed(1).replace('.', ':')})`;
    }

    return (
      <div className="bet-item-ordinar p-2 bg-main-4">
        <div className="d-flex justify-content-between mb-0_25">
          <span title={formattedStakeName} className="font-weight-bold text-truncate mr-1">{formattedStakeName}</span>
          {!!betFactor && <span className="font-weight-bold">{mathRound(betFactor)}</span>}
        </div>

        <div title={formattedStakeTypeName} className="text-extra-2 caption mb-0_25">{formattedStakeTypeName}</div>

        <div className="d-flex align-items-center justify-content-between mb-1">
          <div className="d-flex overflow-hidden caption">
            <LinkComponent
              {...linkProps}
              title={isOutright ? teamA : `${teamA} - ${teamB}`}
              className={classNames('text-truncate', { 'bet-item-event-link': isActive, 'text-extra-2': !isActive })}
            >
              {isOutright ? teamA : `${teamA} - ${teamB}`}
            </LinkComponent>

            <span className="mx-0_5 text-extra-3">&middot;</span>

            {score
              ? <span className="text-nowrap font-weight-bold text-success">{score[0]} - {score[1]}</span>
              : <span className="text-extra-2 text-nowrap">{toShortFormat(eventDateTimeStamp, locale)}</span>}
          </div>

          {isBetWithRepeat(stakes) && (
            <div
              role="button"
              tabIndex="0"
              onClick={this.repeatBet}
              onKeyPress={this.repeatBet}
              className="d-flex align-items-center flex-shrink-0 ml-1"
            >
              <IconRepeat className="flex-shrink-0 mr-1" />
              <FormattedTag id="bet-slip.repeat-bet" className="caption font-weight-bold text-extra-2" />
            </div>
          )}
        </div>

        <div className="d-flex align-items-center flex-wrap">
          <div className={classNames('bet-item-info d-flex flex-fill', {
            'without-cashout': !cashoutAmount,
          })}
          >
            <div className="bet-item-stake pr-0_25 mr-0_5">
              <FormattedTag id="bet-slip.stake" tag="div" className="caption text-extra-2" />
              {!!betAmount && <div className="text-small font-weight-bold text-break">{formatAmountWithCurrencySymbol(betAmount, currency)}</div>}
            </div>
            <div className="bet-item-return pl-0_25 ml-0_5">
              <FormattedTag id="bet-slip.return" tag="div" className="caption text-extra-2" />
              <div className="d-flex align-items-center">
                <StatusIcon className="bet-status-icon mr-1 flex-shrink-0" />
                {!!maxWinAmount && <div className="text-small font-weight-bold text-break">{formatAmountWithCurrencySymbol(maxWinAmount, currency)}</div>}
                {!!freeBetId && <FreebetTitle className="ml-auto self-end" />}
              </div>
            </div>
          </div>

          {!!cashoutAmount && !freeBetId && (
            <CashoutButton
              currency={currency}
              cashoutAmount={cashoutAmount}
              onClick={this.onCashoutClick}
              isLoading={isCashoutInProgress}
            />
          )}

          {isCashoutOpen && (
            <CashoutConfirm
              currency={currency}
              isPortal
              xPosition={cashoutX}
              yPosition={cashoutY}
              cashoutAmount={cashoutAmount}
              cancelCashout={this.cancelCashout}
              makeCashout={this.makeCashout}
            />
          )}
        </div>
      </div>
    );
  }
}
