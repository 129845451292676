import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { withEvent } from 'core/hocs';
import { EVENT_STORE_FIELDS, INPLAY_GAMES_TYPES } from 'core/constants';

import { LeagueGames } from './league-games/league-games';
import { InplayGames } from './inplay-games/inplay-games';
import { GamesPreloader } from './games-preloader/games-preloader';

import './games.scss';

const GamesUI = ({
  params,
  eventData,
  isEventInProgress,
  toggleNavigation,
  setActiveCountries,
  setActiveLeagueId,
  sendEventClickFeedback,
}) => {
  if (R.isNil(eventData)) {
    return isEventInProgress ? <GamesPreloader /> : null;
  }

  return eventData.isLive || eventData.eventStatus !== null
    ? (
      <InplayGames
        params={params}
        toggleNavigation={toggleNavigation}
        coreProps={{ type: INPLAY_GAMES_TYPES.EVENT }}
        sendEventClickFeedback={sendEventClickFeedback}
      />
    )
    : (
      <LeagueGames
        params={params}
        toggleNavigation={toggleNavigation}
        setActiveCountries={setActiveCountries}
        setActiveLeagueId={setActiveLeagueId}
        sendEventClickFeedback={sendEventClickFeedback}
      />
    );
};

GamesUI.propTypes = {
  params: PropTypes.shape().isRequired,
  eventData: PropTypes.shape(),
  isEventInProgress: PropTypes.bool.isRequired,
  toggleNavigation: PropTypes.func,
  setActiveCountries: PropTypes.func,
  setActiveLeagueId: PropTypes.func,
  sendEventClickFeedback: PropTypes.func.isRequired,
};

GamesUI.defaultProps = {
  eventData: null,
  toggleNavigation: () => {},
  setActiveCountries: () => {},
  setActiveLeagueId: () => {},
};

export const Games = withEvent(GamesUI, [
  EVENT_STORE_FIELDS.EVENT_DATA,
  EVENT_STORE_FIELDS.IS_IN_PROGRESS,
]);
