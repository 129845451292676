import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportSnooker = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M16.363 10.09c0-1.505-1.22-2.726-2.727-2.726-1.506 0-2.727 1.22-2.727 2.727 0 1.506 1.221 2.727 2.727 2.727 1.506 0 2.727-1.221 2.727-2.727M5.454 16.455c0-1.507-1.22-2.728-2.727-2.728S0 14.947 0 16.455c0 1.506 1.22 2.727 2.727 2.727 1.506 0 2.727-1.221 2.727-2.727m14.546 0c0-1.507-1.22-2.728-2.728-2.728-1.506 0-2.726 1.22-2.726 2.728 0 1.506 1.22 2.727 2.726 2.727 1.507 0 2.728-1.221 2.728-2.727m-7.273 0c0-1.507-1.22-2.728-2.727-2.728-1.506 0-2.728 1.22-2.728 2.728 0 1.506 1.222 2.727 2.728 2.727 1.506 0 2.727-1.221 2.727-2.727M9.091 10.09c0-1.506-1.22-2.727-2.728-2.727-1.506 0-2.727 1.22-2.727 2.727 0 1.506 1.221 2.727 2.727 2.727 1.507 0 2.728-1.221 2.728-2.727m.91-3.636c-1.507 0-2.729-1.221-2.729-2.728C7.272 2.22 8.494 1 10 1c1.506 0 2.727 1.22 2.727 2.727S11.507 6.455 10 6.455" />
  </svg>
);

IconSportSnooker.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportSnooker.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
