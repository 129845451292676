import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportSumo = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M18.79 7.617c0 .217-.029.43-.072.638-.168-.03-.339-.052-.515-.052h-.997C16.546 6.823 15.146 6 13.516 6h-1.172c-.856 0-1.666.262-2.344.741C9.322 6.261 8.512 6 7.656 6H6.484c-1.63 0-3.029.822-3.69 2.203h-.997c-.176 0-.347.022-.515.052-.043-.209-.07-.42-.07-.638 0-1.886 1.823-4.18 5.821-4.947.006-.057.008-.115.018-.17h-1.3c-.415 0-.75-.336-.75-.75S5.335 1 5.75 1h2.027C8.328.39 9.115 0 10 0s1.672.39 2.222 1h2.028c.414 0 .75.336.75.75s-.336.75-.75.75h-1.3c.01.055.011.114.017.171 3.994.77 5.822 3.061 5.822 4.946zM2.11 9.48c-.07.31-.11.62-.11.95V13h-.2C.83 13 0 12.21 0 11.24s.83-1.76 1.8-1.76h.31zM20 11.24c0 .97-.83 1.76-1.8 1.76H18v-2.57c0-.33-.04-.64-.11-.95h.31c.97 0 1.8.79 1.8 1.76zm-7.4 4.21c-.93 1.24-2.33 1.3-2.6 1.3-1.55 0-2.44-1.08-2.6-1.3-.25-.33-.18-.8.15-1.05.34-.25.8-.18 1.05.15.02.03.53.7 1.4.7.09 0 .89-.02 1.4-.7.25-.33.72-.4 1.05-.15.33.25.4.72.15 1.05zm.95-7.95h-1.19c-.97 0-1.82.47-2.36 1.19-.54-.72-1.39-1.19-2.36-1.19H6.45c-1.62 0-2.95 1.31-2.95 2.93v3.05C3.5 17.03 6.42 20 10 20s6.5-2.97 6.5-6.52v-3.05c0-1.62-1.33-2.93-2.95-2.93z" />
  </svg>
);

IconSportSumo.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportSumo.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
