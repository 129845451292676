import * as R from 'ramda';

import { toDateFormat, toGamesFormat } from './date';
import { TODAY, TOMORROW } from '../constants';

export const sortGames = R.sort((a, b) => {
  const { isLive: aIsLive, eventStatus: aEventStatus, timestamp: aTimestamp } = a;
  const { isLive: bIsLive, eventStatus: bEventStatus, timestamp: bTimestamp } = b;

  const isAInLive = aIsLive || aEventStatus !== null;
  const isBInLive = bIsLive || bEventStatus !== null;

  if (isAInLive && !isBInLive) {
    return -1;
  }

  if (!isAInLive && isBInLive) {
    return 1;
  }

  return aTimestamp - bTimestamp;
});

export const groupByDate = R.compose(
  R.groupBy(({ timestamp }) => {
    const formattedDate = toDateFormat(timestamp);
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    if (toDateFormat(today) === formattedDate) {
      return TODAY;
    }

    if (toDateFormat(tomorrow) === formattedDate) {
      return TOMORROW;
    }

    return formattedDate;
  }),
  sortGames,
);

export const groupUpcomingGamesByDate = (games, locale) => R.groupBy(({ timestamp }) => {
  const formattedDate = toGamesFormat(timestamp, locale);
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  if (toGamesFormat(today, locale) === formattedDate) {
    return TODAY;
  }

  if (toGamesFormat(tomorrow, locale) === formattedDate) {
    return TOMORROW;
  }

  return formattedDate;
}, games);

export const filterByLiveStream = R.filter(R.prop('hasLiveStream'));

export const filterGamesWithLiveStream = R.compose(
  R.reject(R.compose(R.isEmpty, R.prop('games'))),
  R.map(item => ({
    ...item,
    games: filterByLiveStream(item.games),
  }))
);

export const groupGamesByLeague = R.compose(
  R.map(games => ({
    sportId: games[0].sportId,
    leagueId: games[0].leagueId,
    leagueName: games[0].leagueName,
    games,
  })),
  Object.values,
  R.groupBy(R.prop('leagueId')),
);
