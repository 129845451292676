import * as R from 'ramda';
import {
  isOneOfValues,
  prepareStakes,
  prepareHandicap,
  prepareTotal
} from 'core/helpers';
import {
  handicapStakeTypesIds,
  totalStakeTypesIds,
  resultWithDrawStakeTypeIds,
  resultWithoutDrawStakeTypeIds,
  doubleChanceStakeTypeIds,
  drawNoBetStakeTypeIds
} from 'core/mapping-stake-types';

export const mapStakeTypes = (
  {
    stakeTypes,
    isLive,
    eventStatus,
  },
  betSlipStakes,
  betSlipState,
  favoriteStakeTypes,
) => R.compose(
  R.sort((a, b) => {
    if (a.isFavorite && !b.isFavorite) {
      return -1;
    }

    return 0;
  }),
  R.sort((a, b) => {
    if (a.isBig) {
      return -1;
    }

    if (b.isBig) {
      return 1;
    }

    return 0;
  }),
  R.map(({ stakeTypeId, stakeTypeName, stakes }) => {
    const isEventInLive = isLive || eventStatus !== null;
    const isFavorite = !!R.find(R.propEq('stakeTypeId', stakeTypeId), favoriteStakeTypes || []);
    const isHandicap = handicapStakeTypesIds.includes(stakeTypeId);
    const isTotal = totalStakeTypesIds.includes(stakeTypeId);
    const isBig = stakeTypeId === 1 || stakeTypeId === 702 && favoriteStakeTypes
    && favoriteStakeTypes.length && !isFavorite;
    let preparedStakes = prepareStakes(stakes, isEventInLive, betSlipStakes, betSlipState, stakeTypeName);

    if (isHandicap) {
      preparedStakes = prepareHandicap(preparedStakes);
    } else if (isTotal) {
      preparedStakes = prepareTotal(preparedStakes);
    }

    return {
      stakeTypeId,
      stakeTypeName,
      stakes: preparedStakes,
      isFavorite,
      isHandicap,
      isTotal,
      isBig,
    };
  }),
)(stakeTypes);

export const getXsLabel = (stakeTypeId, stakeCode) => {
  if (isOneOfValues(resultWithDrawStakeTypeIds)(stakeTypeId)) {
    if (stakeCode === 1) {
      return '1';
    }

    if (stakeCode === 2) {
      return 'X';
    }

    if (stakeCode === 3) {
      return '2';
    }
  }

  if (isOneOfValues(resultWithoutDrawStakeTypeIds)(stakeTypeId) || isOneOfValues(drawNoBetStakeTypeIds)(stakeTypeId)) {
    if (stakeCode === 1) {
      return '1';
    }

    if (stakeCode === 2) {
      return '2';
    }
  }

  if (isOneOfValues(doubleChanceStakeTypeIds)(stakeTypeId)) {
    if (stakeCode === 1) {
      return '1X';
    }

    if (stakeCode === 2) {
      return '12';
    }

    if (stakeCode === 3) {
      return 'X2';
    }
  }

  return null;
};

export const filterSubEvents = (eventData, activeTab) => {
  if (activeTab === 0) {
    return eventData;
  }

  return R.find(R.propEq('periodName', activeTab), eventData.subEvents);
};
