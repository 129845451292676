import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportSkiing = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M17.848 16.983l1.15 2.02-2.019-1.151-.27.271c-.2.2-.52.2-.71 0-.2-.191-.2-.51 0-.71l.18-.17-5.12-5.12 1.06-1.061 5.12 5.12.17-.179c.2-.201.52-.201.71 0 .2.19.2.509 0 .709l-.27.271zm1.118-8.729c-.079.226-.343.521-.692.649-.369.136-.793.064-1.004-.072-.43-.279-.655-1.457-.09-3.05 1.344.885 1.977 1.931 1.786 2.473zM2.73 8.833c-.21.139-.64.21-1.01.07-.35-.131-.61-.42-.69-.65-.19-.541.44-1.59 1.79-2.47.56 1.59.34 2.769-.09 3.05zM17.382 4.74l1.472-1.472c.192-.191.192-.503 0-.695l-1.427-1.426c-.19-.192-.503-.192-.694 0l-2.84 2.841c-.192.191-.192.503 0 .694l.182.183-4.078 4.077-4.079-4.079.191-.181c.19-.189.19-.5 0-.699L3.27 1.142c-.2-.189-.51-.189-.7 0L1.14 2.573c-.19.189-.19.5 0 .699l1.48 1.471C.72 5.863-.32 7.422.09 8.583c.19.549.7 1.04 1.29 1.259.26.09.52.13.76.13.45 0 .86-.13 1.13-.3.87-.559 1.09-1.979.67-3.61l.04.051c.2.189.51.189.7 0l.18-.191 4.08 4.08-6.178 6.177-.177-.176c-.195-.196-.51-.196-.707 0-.195.195-.195.511 0 .707l.274.273-1.153 2.021 2.02-1.154.273.274c.196.195.512.195.707 0 .196-.196.196-.512 0-.707l-.176-.177L15.135 5.925l.184.184c.191.191.503.191.694 0l.046-.046c-.422 1.627-.196 3.051.668 3.608.273.176.681.305 1.127.305.248 0 .508-.04.764-.134.592-.216 1.1-.71 1.292-1.255.41-1.166-.63-2.723-2.528-3.847z" />
  </svg>
);

IconSportSkiing.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportSkiing.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
