import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportEsport = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M16.504 9.833c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1m-2.5-1.5c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1m0 3c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1m-7.5-1.25h-.75v.75c0 .41-.34.75-.75.75s-.75-.34-.75-.75v-.75h-.75c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h.75v-.75c0-.41.34-.75.75-.75s.75.34.75.75v.75h.75c.41 0 .75.34.75.75s-.34.75-.75.75m11.68-3.74c-2.64-4.34-6.61-1.32-6.61-1.32-.26.19-.73.35-1.06.35h-1.06c-.32.01-.8-.16-1.05-.35 0 0-3.98-3.02-6.62 1.32-2.64 4.33-1.57 9.05-1.57 9.05.18 1.15.8 1.92 1.95 1.83 1.16-.1 3.67-3.11 3.67-3.11.2-.25.64-.46.96-.46h6.38c.32 0 .76.21.96.46 0 0 2.51 3.01 3.67 3.11 1.16.09 1.77-.68 1.96-1.83 0 0 1.06-4.72-1.58-9.05" />
  </svg>
);

IconSportEsport.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportEsport.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
