import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportRollerHockey = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M11.75 1.501h7.5c.414 0 .75-.336.75-.751 0-.414-.336-.75-.75-.75h-7.5c-.414 0-.75.336-.75.75 0 .415.336.751.75.751M17 17c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1M5 17c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1m-5-3.497h20C20 14.33 19.33 15 18.503 15h-.279c.477.531.776 1.228.776 2 0 1.66-1.34 3-3 3s-3-1.34-3-3c0-.772.299-1.469.776-2H6.224c.477.531.776 1.228.776 2 0 1.66-1.34 3-3 3s-3-1.34-3-3c0-.772.299-1.469.776-2h-.279C.67 15 0 14.33 0 13.503M20 4v8H0s0-4 5-4c1.76 0 3.03-.62 3.93-1.43l2.13 1.42c.13.08.27.12.41.12.24 0 .48-.11.63-.33.23-.35.13-.81-.21-1.04L9.93 5.43c.25-.38.45-.75.61-1.08l2.04 1.36c.13.08.28.13.42.13.24 0 .48-.12.62-.34.23-.34.14-.81-.2-1.04L11.23 3H19c.55 0 1 .45 1 1" />
  </svg>
);

IconSportRollerHockey.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportRollerHockey.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
