import React from 'react';
import PropTypes from 'prop-types';

export const IconLivestreamSmall = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12">
    <path fill="#FFF" fillOpacity=".2" fillRule="evenodd" d="M14 0c1.105 0 2 .895 2 2v8c0 1.105-.895 2-2 2H2c-1.105 0-2-.895-2-2V2C0 .895.895 0 2 0h12zm0 1H2c-.513 0-.936.386-.993.883L1 2v8c0 .513.386.936.883.993L2 11h12c.513 0 .936-.386.993-.883L15 10V2c0-.513-.386-.936-.883-.993L14 1zM7 3.618c.155 0 .308.036.447.106l2.764 1.382c.494.247.694.847.447 1.341-.096.194-.253.35-.447.447L7.447 8.276c-.494.247-1.094.047-1.341-.447C6.036 7.69 6 7.537 6 7.382V4.618c0-.552.448-1 1-1z" />
  </svg>

);

IconLivestreamSmall.propTypes = {
  className: PropTypes.string,
};

IconLivestreamSmall.defaultProps = {
  className: null,
};
