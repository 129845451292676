import * as R from 'ramda';

export const getStakeByStakeCode = (stakes, stakeCode) => R.find(R.propEq('stakeCode', stakeCode), stakes);

export const prepareStakeTypeName = (periodName, stakeTypeName) =>
  periodName ? `${periodName}. ${stakeTypeName}` : stakeTypeName;

export const setEmptyStakes = (marketStake, is3way) => {
  if (R.isNil(marketStake)) {
    return null;
  }

  if (is3way && marketStake.length !== 3) {
    return [
      getStakeByStakeCode(marketStake, 1) || null,
      getStakeByStakeCode(marketStake, 2) || null,
      getStakeByStakeCode(marketStake, 3) || null
    ];
  }

  if (!is3way && marketStake.length !== 2) {
    return [
      getStakeByStakeCode(marketStake, 1) || null,
      getStakeByStakeCode(marketStake, 2) || null
    ];
  }

  return marketStake;
};
