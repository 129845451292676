import React from 'react';
import PropTypes from 'prop-types';

import colors from '../../../customizations/js/color-variables';

export const IconLock = ({ className }) => (
  <svg className={className} width="12px" height="16px" viewBox="0 0 12 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.200000003">
      <g transform="translate(-46.000000, -18.000000)" fill={colors.cpExtra1} fillRule="nonzero">
        <g>
          <g transform="translate(46.000000, 18.000000)">
            <path d="M6,0 C3.50714288,0 1.5,2.00714288 1.5,4.5 L1.5,5.25 C0.675,5.25 0,5.925 0,6.75 L0,14.25 C0,15.075 0.675,15.75 1.5,15.75 L10.5,15.75 C11.325,15.75 12,15.075 12,14.25 L12,6.75 C12,5.925 11.325,5.25 10.5,5.25 L10.5,4.5 C10.5,2.00714288 8.4928575,0 6,0 Z M6,1.5 C7.7071425,1.5 9,2.79285712 9,4.5 L9,5.25 L3,5.25 L3,4.5 C3,2.79285712 4.29285712,1.5 6,1.5 Z M6,9 C6.825,9 7.5,9.675 7.5,10.5 C7.5,11.325 6.825,12 6,12 C5.175,12 4.5,11.325 4.5,10.5 C4.5,9.675 5.175,9 6,9 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconLock.propTypes = {
  className: PropTypes.string,
};

IconLock.defaultProps = {
  className: null,
};
