import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import { FormattedMessage } from 'react-intl';
import Collapse from 'reactstrap/lib/Collapse';
import { prepareBetsForRepeat, mathRound, convertStringifiedBoolean } from 'core/helpers';
import {
  BET_STATUS,
  BET_TYPE,
  BONUS_TYPES,
  BET_STAKE_STATUS,
  AI_OUTCOME_SECTION,
} from 'core/constants';
import { handicapStakeTypesIds, restOfTheMatchStakeTypeIds } from 'core/mapping-stake-types';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { RouteLink } from 'components/route-link/route-link';
import { CashoutConfirm } from 'components/cashout-confirm/cashout-confirm';
import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';
import { IconArrowBottom } from 'components/icons/icon-arrow-bottom/icon-arrow-bottom';
import { IconLiveStatus } from 'components/icons/icon-live-status/icon-live-status';
import { IconBonus } from 'components/icons/icon-bonus/icon-bonus';
import { IconRepeat } from 'components/icons/icon-repeat/icon-repeat';
import { prepareStakeTypeName } from 'helpers/stake';
import { toShortFormat } from 'helpers/date';
import { prepareCurrency, formatAmountWithCurrencySymbol } from 'helpers/currency';
import { findLostStake, getMultipleBetStatusIcon, isBetWithRepeat } from 'helpers/bets';
import { prepareBetsScore } from 'helpers/score';
import { GA } from 'helpers/ga';
import { BetStatus } from '../bet-status/bet-status';
import { MODAL_IDS } from '../../../../../../constants';

export class BetItemMultiple extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    stakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    betAmount: PropTypes.number.isRequired,
    betFactor: PropTypes.number,
    maxWinAmount: PropTypes.number.isRequired,
    makeCashout: PropTypes.func,
    order: PropTypes.PropTypes.shape().isRequired,
    orderId: PropTypes.string.isRequired,
    digitainOrderId: PropTypes.string.isRequired,
    isCashouted: PropTypes.bool.isRequired,
    systemIndex: PropTypes.number,
    betType: PropTypes.oneOf([
      BET_TYPE.ORDINAR,
      BET_TYPE.EXPRESS,
      BET_TYPE.SYSTEM,
    ]).isRequired,
    bettingBonusType: PropTypes.oneOf([
      BONUS_TYPES.EXPRESS_PERCENT,
      BONUS_TYPES.EXPRESS_CASHBACK,
    ]),
    bettingBonusValue: PropTypes.number,
    accumulatorId: PropTypes.number,
    isCashoutInProgress: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    repeatBet: PropTypes.func.isRequired,
    onCashoutClick: PropTypes.func.isRequired,
    closeCashout: PropTypes.func.isRequired,
    isCashoutOpen: PropTypes.bool.isRequired,
    cashoutX: PropTypes.number,
    cashoutY: PropTypes.number,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    betFactor: null,
    makeCashout: null,
    systemIndex: null,
    bettingBonusType: null,
    bettingBonusValue: null,
    accumulatorId: null,
    cashoutX: null,
    cashoutY: null,
  };

  state = {
    isOpen: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !R.equals(this.props, nextProps) || !R.equals(this.state, nextState);
  }

  toggleIsOpen = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }));

  onCashoutClick = (e) => {
    const { onCashoutClick, orderId } = this.props;
    onCashoutClick(e, orderId);
  };

  cancelCashout = (e) => {
    const { closeCashout } = this.props;
    e.stopPropagation();
    closeCashout();
    GA.event({
      category: 'bets-history',
      label: 'cancel-cashout',
    });
  };

  makeCashout = (e) => {
    e.stopPropagation();
    const {
      orderId,
      order: {
        cashoutAmount,
      },
      makeCashout,
      closeCashout,
    } = this.props;

    closeCashout();
    makeCashout({ orderId, cashoutAmount });
    GA.event({
      category: 'bets-history',
      label: 'confirm-cashout',
    });
  };

  getBetDescription = () => {
    const { stakes } = this.props;

    return stakes.reduce((prev, cur, idx) => {
      const {
        stakeCode,
        teamA,
        teamB,
        stakeArgument,
        stakeTypeId,
        stakeName,
        stakeTypeName,
        isOutright,
        periodName,
      } = cur;
      const formattedStakeTypeName = prepareStakeTypeName(periodName, stakeTypeName);
      let formattedStakeName = `${stakeName}${stakeArgument === null ? '' : ` ${stakeArgument}`}`;

      if (handicapStakeTypesIds.includes(stakeTypeId)) {
        formattedStakeName = `${stakeCode === 1 ? teamA : teamB} ${stakeArgument > 0 ? `+${stakeArgument}` : stakeArgument}`;
      } else if (restOfTheMatchStakeTypeIds.includes(stakeTypeId)) {
        formattedStakeName = `${stakeName} (${stakeArgument.toFixed(1).replace('.', ':')})`;
      }

      if (idx === 0) {
        return `${isOutright ? `${teamA} ` : ''}${formattedStakeTypeName} ${formattedStakeName}`;
      }

      return `${prev}, ${isOutright ? `${teamA} ` : ''}${formattedStakeTypeName} ${formattedStakeName}`;
    }, '');
  };

  onEventClick = (e) => {
    const { dataset: { eventId, isLive } } = e.currentTarget;
    const { closeModal, sendEventClickFeedback } = this.props;
    e.stopPropagation();
    closeModal(MODAL_IDS.BET_HISTORY);
    GA.event({
      category: 'go-to-event-page',
      label: 'my-bets-event-click',
    });
    sendEventClickFeedback(eventId, convertStringifiedBoolean(isLive));
  };

  repeatBet = (e) => {
    e.stopPropagation();
    const {
      closeModal,
      repeatBet,
      betAmount,
      betType,
      stakes,
      systemIndex,
    } = this.props;
    repeatBet({
      betAmount,
      betType,
      stakes: prepareBetsForRepeat(stakes),
      systemIndex,
    });

    closeModal(MODAL_IDS.BET_HISTORY);
    GA.event({
      category: 'bet-slip',
      label: 'repeat-last-bet-click',
    });
  };

  render() {
    const {
      locale,
      currency,
      digitainOrderId,
      betFactor,
      betAmount,
      maxWinAmount,
      stakes,
      isCashouted,
      systemIndex,
      betType,
      bettingBonusType,
      bettingBonusValue,
      accumulatorId,
      order: {
        statusId,
        winAmount,
        fillDate,
        isWon,
        cashoutAmount,
      },
      isCashoutInProgress,
      isCashoutOpen,
      cashoutX,
      cashoutY,
    } = this.props;
    const { isOpen } = this.state;
    const isActive = statusId !== BET_STATUS.FINISHED;
    const StatusIcon = getMultipleBetStatusIcon(isActive, isWon, stakes, bettingBonusType);
    const isLiveExists = !!stakes.find(({ isLive }) => isLive);
    const isExpress = betType === BET_TYPE.EXPRESS;
    const betDescription = this.getBetDescription();
    let intlId = 'bet-slip.type.system';
    const withRepeat = isBetWithRepeat(stakes);

    if (accumulatorId) {
      intlId = 'bet-slip.type.boosted-express';
    } else if (isExpress) {
      intlId = 'bet-slip.type.express';
    }

    return (
      <div
        role="button"
        tabIndex="0"
        onClick={this.toggleIsOpen}
        onKeyPress={this.toggleIsOpen}
        className={classNames('bets-table-item text-small', {
          'is-open': isOpen,
          'is-won': isWon && !isCashouted,
        })}
      >
        <div className="d-flex justify-content-between align-items-center py-1_5">
          <div className="bets-table-column align-self-start text-right">
            {isLiveExists && <IconLiveStatus />}
          </div>
          <div className="bets-table-column pl-1_5">
            <FormattedTag
              id={intlId}
              values={isExpress ? {} : { systemIndex, stakesAmount: stakes.length }}
              tag="div"
              className="font-weight-bold mb-0_5"
            />
            <div title={betDescription} className="bets-table-bet-descr text-truncate">{betDescription}</div>
          </div>
          <div className="bets-table-column pl-1_5">
            {toShortFormat(fillDate, locale)}
          </div>
          <div className="bets-table-column pl-1_5">
            {digitainOrderId}
          </div>
          <div className="bets-table-column text-right px-1_5">
            {isExpress && !!betFactor && mathRound(betFactor)}
          </div>
          <div className="bets-table-column text-right pr-2_5">
            {!!betAmount && <span>{mathRound(betAmount)} {prepareCurrency(currency)}</span>}
          </div>
          <div className="bets-table-column d-flex align-items-center justify-content-end pr-1_5">
            {isActive && !!cashoutAmount && (
              <ButtonWithLoader
                onClick={this.onCashoutClick}
                className={classNames('bets-table-cashout-btn px-1 py-0_25', { 'mr-3_5': !withRepeat })}
                contentClassName="d-flex flex-column align-items-center justify-content-center"
                size="sm"
                color="primary"
                isLoading={isCashoutInProgress}
              >
                <FormattedTag id="my-bets.cashout" className="caption font-weight-normal" />
                <span className="text-small font-weight-bold">{formatAmountWithCurrencySymbol(cashoutAmount, currency)}</span>
              </ButtonWithLoader>
            )}

            {withRepeat && (
              <div
                role="button"
                tabIndex="0"
                onClick={this.repeatBet}
                onKeyPress={this.repeatBet}
                className="ml-1_5 flex-shrink-0"
              >
                <IconRepeat className="icon-repeat" />
              </div>
            )}
          </div>
          <div className="bets-table-column d-flex justify-content-end align-items-center text-right pr-1_5">
            {isCashouted
              ? (
                <div className="d-flex flex-column justify-content-center align-items-end text-extra-2">
                  <FormattedTag id="bets-history.cashed-out" className="mb-0_5 font-weight-bold" />
                  {!!winAmount && <span className="caption">{mathRound(winAmount)} {prepareCurrency(currency)}</span>}
                </div>
              )
              : (
                <div className="d-flex align-items-center">
                  <StatusIcon className={classNames('bet-status-icon mr-1 flex-shrink-0', { 'is-won': isWon && !(bettingBonusType === BONUS_TYPES.EXPRESS_CASHBACK && findLostStake(stakes)) })} />
                  {(maxWinAmount || winAmount) && (
                    <span className={classNames('bets-table-win-amount font-weight-bold', {
                      'text-extra-3': (!isActive && !isWon) || (bettingBonusType === BONUS_TYPES.EXPRESS_CASHBACK && findLostStake(stakes)),
                      'is-won': isWon,
                    })}
                    >
                      {isActive ? mathRound(maxWinAmount) : mathRound(winAmount)} {prepareCurrency(currency)}
                    </span>
                  )}
                </div>

              )}
            <IconArrowBottom className="icon-arrow-bottom ml-3 flex-shrink-0" backgroundColor="transparent" />
          </div>
        </div>

        <Collapse isOpen={isOpen}>
          <div className="bets-table-collapse mx-1_5 py-2 text-small">
            <div className="d-flex caption text-extra-2">
              <div className="bets-table-collapse-item-multiple pr-1_5 pl-5">
                <FormattedMessage id="bets-history.bet" />
              </div>
              <div className="bets-table-collapse-item-multiple pr-1_5">
                <FormattedMessage id="bets-history.event" />
              </div>
              <div className="bets-table-collapse-item-multiple text-right pr-1_5">
                <FormattedMessage id="bets-history.odd" />
              </div>
              <div className="bets-table-collapse-item-multiple text-right pr-5 mr-0_5">
                <FormattedMessage id="bets-history.status" />
              </div>
            </div>

            <div>
              {stakes.map(({
                teamA,
                teamB,
                stakeName,
                stakeCode,
                stakeTypeId,
                stakeTypeName,
                stakeArgument,
                isOutright,
                periodName,
                betFactor: stakeBetFactor,
                eventDateTimeStamp,
                sportId,
                eventNumber,
                order: {
                  statusId: stakeStatusId,
                  currentScore,
                }
              }) => {
                const formattedStakeTypeName = prepareStakeTypeName(periodName, stakeTypeName);
                console.log('currentScore: ', currentScore);
                console.log('sportId: ', sportId);
                const score = prepareBetsScore(currentScore, sportId);
                let formattedStakeName = `${stakeName}${stakeArgument === null ? '' : ` ${stakeArgument}`}`;
                const isStakeActive = stakeStatusId === BET_STAKE_STATUS.NEW;
                const LinkComponent = isStakeActive ? RouteLink : 'span';
                const linkProps = isStakeActive
                  ? {
                    locale,
                    to: `/event/${eventNumber}?isLive=${!!score}&sectionBeforeEventPage=${AI_OUTCOME_SECTION.MY_BETS}`,
                    onClick: this.onEventClick,
                    'data-event-id': eventNumber,
                    'data-is-live': !!score,
                  }
                  : {};

                if (handicapStakeTypesIds.includes(stakeTypeId)) {
                  formattedStakeName = `${stakeCode === 1 ? teamA : teamB} ${stakeArgument > 0 ? `+${stakeArgument}` : stakeArgument}`;
                } else if (restOfTheMatchStakeTypeIds.includes(stakeTypeId)) {
                  formattedStakeName = `${stakeName} (${stakeArgument.toFixed(1).replace('.', ':')})`;
                }

                return (
                  <div className="d-flex mt-1_5">
                    <div className="bets-table-collapse-item-multiple pr-1_5 pl-5">
                      {formattedStakeTypeName} {formattedStakeName}
                    </div>
                    <div className="bets-table-collapse-item-multiple d-flex pr-1_5">
                      <LinkComponent
                        {...linkProps}
                        title={isOutright ? teamA : `${teamA} - ${teamB}`}
                        className={classNames('text-truncate', { 'bet-item-event-link': isStakeActive, 'text-extra-2': !isStakeActive })}
                      >
                        {isOutright ? teamA : `${teamA} - ${teamB}`}
                      </LinkComponent>

                      <span className="mx-0_5 text-extra-3">&middot;</span>

                      {score
                        ? <span className={classNames('text-nowrap font-weight-bold', { 'text-success': isStakeActive })}>{score[0]} - {score[1]}</span>
                        : <span className="text-extra-2 text-nowrap">{toShortFormat(eventDateTimeStamp, locale)}</span>}
                    </div>
                    <div className="bets-table-collapse-item-multiple text-right pr-1_5">
                      {!!stakeBetFactor && mathRound(stakeBetFactor)}
                    </div>
                    <div className="bets-table-collapse-item-multiple text-right pr-5 mr-0_5">
                      <BetStatus status={stakeStatusId} />
                    </div>
                  </div>
                );
              })}
              {!!bettingBonusValue && (
                <div className="d-flex mt-1_5">
                  <div className="bets-table-collapse-item-multiple pr-1_5 d-flex align-items-center pl-5">
                    <IconBonus />
                    <FormattedTag id={`general.${bettingBonusType === BONUS_TYPES.EXPRESS_PERCENT ? 'bonus' : 'cashback'}`} className="text-small ml-1 mr-0_25" />
                    {bettingBonusType === BONUS_TYPES.EXPRESS_CASHBACK && bettingBonusValue && `${mathRound(bettingBonusValue)} ${prepareCurrency(currency)}`}
                  </div>
                  <div className="bets-table-collapse-item-multiple pr-1_5" />
                  <div className="bets-table-collapse-item-multiple text-right pr-1_5">
                    {bettingBonusType === BONUS_TYPES.EXPRESS_PERCENT && bettingBonusValue
                    && mathRound(bettingBonusValue)}
                  </div>
                  <div className="bets-table-collapse-item-multiple pr-5 mr-0_5" />
                </div>
              )}
            </div>
          </div>
        </Collapse>

        {isCashoutOpen && (
          <CashoutConfirm
            currency={currency}
            isPortal
            xPosition={cashoutX}
            yPosition={cashoutY}
            cashoutAmount={cashoutAmount}
            cancelCashout={this.cancelCashout}
            makeCashout={this.makeCashout}
          />
        )}
      </div>
    );
  }
}
