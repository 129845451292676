import React from 'react';
import PropTypes from 'prop-types';

export const IconRepeat = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.125 0.9375V2.81982L9.21387 2.90955L8.15796 2.93335L7.63 2.94556L7.36572 2.95166L7.0459 2.96509C6.17965 3.02728 5.32727 3.2864 4.58008 3.71765C3.07977 4.57359 2.02217 6.12445 1.78467 7.80945C1.75279 8.01914 1.73575 8.23054 1.72668 8.44116L1.72424 8.75549L1.73584 9.01978L1.78223 10.0757L1.875 12.1875H3.4375L3.53027 10.0751L3.57666 9.01917L3.60046 8.54248C3.61453 8.40217 3.63245 8.2625 3.66089 8.125C3.87245 7.0225 4.61945 6.05585 5.5957 5.56335C6.08227 5.31554 6.61852 5.1837 7.1582 5.17151L9.21387 5.21545L13.125 5.30518V7.1875L17.5 4.0625L13.125 0.9375ZM16.5625 7.8125L16.4697 9.92493L16.4233 10.9808L16.3995 11.4575C16.3855 11.5978 16.3675 11.7375 16.3391 11.875C16.1275 12.9775 15.3805 13.9441 14.4043 14.4366C13.9177 14.6845 13.3815 14.8163 12.8418 14.8285L10.7861 14.7845L6.875 14.6948V12.8125L2.5 15.9375L6.875 19.0625V17.1802L10.7861 17.0905L11.842 17.0667L12.37 17.0544L12.6343 17.0483L12.9541 17.0349C13.8204 16.9727 14.6727 16.7136 15.4199 16.2823C16.9202 15.4264 17.9778 13.8756 18.2153 12.1906C18.2472 11.9809 18.2643 11.7695 18.2733 11.5588L18.2758 11.2445L18.2642 10.9802L18.2178 9.92432L18.125 7.8125H16.5625Z" fill="white" fillOpacity="0.65" />
  </svg>
);

IconRepeat.propTypes = {
  className: PropTypes.string,
};

IconRepeat.defaultProps = {
  className: null,
};
