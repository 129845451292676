import React from 'react';
import PropTypes from 'prop-types';

export const IconFreebetLearnMore = ({ className }) => (
  <svg className={className} width="144" height="120" viewBox="0 0 144 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M65.4 52C61.782 52 58.8 54.982 58.8 58.6C58.8 59.4508 59.4102 60.0438 59.7109 60.8H50V74H52.2V89.6148C52.2 91.8922 54.1078 93.8 56.3766 93.8H87.6148C89.8922 93.8 91.8 91.8922 91.8 89.6148V74H94V60.8H84.2805C84.5898 60.0438 85.2 59.4508 85.2 58.6C85.2 54.982 82.218 52 78.6 52C76.5891 52 74.793 52.9453 73.5813 54.3891L72 55.9617L70.4188 54.3891C69.207 52.9453 67.4109 52 65.4 52ZM65.4 56.4C66.6203 56.4 67.6 57.3797 67.6 58.6C67.6 59.8203 66.6203 60.8 65.4 60.8C64.1797 60.8 63.2 59.8203 63.2 58.6C63.2 57.3797 64.1797 56.4 65.4 56.4ZM78.6 56.4C79.8203 56.4 80.8 57.3797 80.8 58.6C80.8 59.8203 79.8203 60.8 78.6 60.8C77.3797 60.8 76.4 59.8203 76.4 58.6C76.4 57.3797 77.3797 56.4 78.6 56.4ZM54.4 65.2H89.6V69.6H54.4V65.2ZM56.6 74H69.8V89.4H56.6V74ZM74.2 74H87.4V89.4H74.2V74Z" fill="#FFEB3B" />
    <path d="M72 26.9998V3" stroke="#FFEB3B" />
    <path d="M54.0001 29.9995L48.923 18.0761" stroke="#FFEB3B" />
    <path d="M90.1357 29.9995L95.2128 18.0761" stroke="#FFEB3B" />
    <path d="M28.5001 55.4992L16.6155 50.3839" stroke="#FFEB3B" />
    <path d="M115.636 55.4992L127.52 50.3839" stroke="#FFEB3B" />
    <path d="M0 73.4619L24 73.4619" stroke="#FFEB3B" />
    <path d="M39.9199 40.0211L23 23" stroke="#FFEB3B" />
    <path d="M104 39.9544L120.987 23" stroke="#FFEB3B" />
    <path d="M144 73.4619L120 73.4619" stroke="#FFEB3B" />
    <path d="M16.6154 95.8638L28.4999 91.5" stroke="#FFEB3B" />
    <path d="M127.52 95.8638L115.636 91.5" stroke="#FFEB3B" />
  </svg>
);

IconFreebetLearnMore.propTypes = {
  className: PropTypes.string,
};

IconFreebetLearnMore.defaultProps = {
  className: null,
};
