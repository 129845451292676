import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'reactstrap/lib/Button';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import {
  withUser,
  withModalActions,
  withPostMessagesActions,
  withFeedbackActions,
} from 'core/hocs';
import { USER_STORE_FIELDS } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { IconArrowBottom } from 'components/icons/icon-arrow-bottom/icon-arrow-bottom';
import { BetsHistoryModal } from 'components/modals/bets-history-modal/bets-history-modal';
import { Freebets } from 'components/my-bets/freebets/freebets';
import { GA } from 'helpers/ga';
import { ActiveBets } from './active-bets/active-bets';
import { FinishedBets } from './finished-bets/finished-bets';
import { MODAL_IDS } from '../../../constants';

import './my-bets-tab.scss';

const MY_BETS = {
  ACTIVE: 'active',
  FINISHED: 'finished',
};

class MyBetsTabUI extends Component {
  static propTypes = {
    isUserLoggedIn: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    getCurrentModalOpened: PropTypes.func.isRequired,
    postUnauthorizedMessage: PropTypes.func.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
  };

  state = {
    isOpen: false,
    activeItem: MY_BETS.ACTIVE,
  };

  toggle = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }));

  onActiveClick = (e) => {
    e.stopPropagation();
    this.setState({ activeItem: MY_BETS.ACTIVE });
    GA.event({
      category: 'my-bets',
      label: 'unsettled-status-click',
    });
  };

  onFinishedClick = (e) => {
    e.stopPropagation();
    this.setState({ activeItem: MY_BETS.FINISHED });
    GA.event({
      category: 'my-bets',
      label: 'settled-status-click',
    });
  };

  onViewAllClick = () => {
    const { isUserLoggedIn, openModal, postUnauthorizedMessage } = this.props;

    if (isUserLoggedIn) {
      openModal(MODAL_IDS.BET_HISTORY);
    } else {
      postUnauthorizedMessage();
    }

    GA.event({
      category: 'my-bets',
      label: 'view-all-bets-click',
    });
  };

  onDropdownClick = () => {
    GA.event({
      category: 'my-bets',
      label: 'status-dropdown-click',
    });
  };

  render() {
    const { getCurrentModalOpened, sendEventClickFeedback } = this.props;
    const { isOpen, activeItem } = this.state;

    return (
      <Fragment>
        <div className="my-bets-tab">
          <div className="my-bets-tab-header d-flex align-items-center px-1_5 bg-main-2">
            <Dropdown isOpen={isOpen} toggle={this.toggle} onClick={this.onDropdownClick}>
              <DropdownToggle className="my-bets-tab-toggle caption btn-outline-secondary pl-1_5 pr-1">
                <FormattedMessage id={`my-bets.tab.${activeItem === MY_BETS.ACTIVE ? 'active' : 'finished'}`} />
                <IconArrowBottom className="ml-1" color="white" opacity={0.65} backgroundColor="transparent" />
              </DropdownToggle>
              <DropdownMenu positionFixed>
                <FormattedTag
                  id="my-bets.tab.active"
                  tag={DropdownItem}
                  onClick={this.onActiveClick}
                  className="text-small"
                />
                <FormattedTag
                  id="my-bets.tab.finished"
                  tag={DropdownItem}
                  onClick={this.onFinishedClick}
                  className="text-small"
                />
              </DropdownMenu>
            </Dropdown>
          </div>

          <Freebets />

          {activeItem === MY_BETS.ACTIVE && <ActiveBets sendEventClickFeedback={sendEventClickFeedback} />}
          {activeItem === MY_BETS.FINISHED && <FinishedBets sendEventClickFeedback={sendEventClickFeedback} />}

          <div className="my-bets-tab-view-all px-2 py-2_5 bg-main-4">
            <FormattedTag
              id="my-bets.view-all"
              tag={Button}
              outline
              block
              onClick={this.onViewAllClick}
            />
          </div>
        </div>

        <BetsHistoryModal getCurrentModalOpened={getCurrentModalOpened} />
      </Fragment>
    );
  }
}

export const MyBetsTab = withFeedbackActions(withPostMessagesActions(withModalActions(withUser(
  MyBetsTabUI,
  [USER_STORE_FIELDS.IS_USER_LOGGED_IN],
))));
