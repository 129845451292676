import React, { memo } from 'react';
import PropTypes from 'prop-types';

export const IconIndividualPlaceholder = memo(({ className }) => (
  <svg className={className} width="96" height="96" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M88 48C88 70.09 70.09 88 48 88C25.91 88 8 70.09 8 48C8 25.91 25.91 8 48 8C70.09 8 88 25.91 88 48Z" fill="url(#icon-individual-placeholder-a)" />
    <mask id="path-2-inside-1_4591_388211" fill="white">
      <path fillRule="evenodd" clipRule="evenodd" d="M88 48C88 70.09 70.09 88 48 88C25.91 88 8 70.09 8 48C8 25.91 25.91 8 48 8C70.09 8 88 25.91 88 48Z" />
    </mask>
    <path d="M87 48C87 69.5377 69.5377 87 48 87V89C70.6423 89 89 70.6423 89 48H87ZM48 87C26.4623 87 9 69.5377 9 48H7C7 70.6423 25.3577 89 48 89V87ZM9 48C9 26.4623 26.4623 9 48 9V7C25.3577 7 7 25.3577 7 48H9ZM48 9C69.5377 9 87 26.4623 87 48H89C89 25.3577 70.6423 7 48 7V9Z" fill="white" fillOpacity="0.5" mask="url(#path-2-inside-1_4591_388211)" />
    <mask id="path-4-outside-2_4591_388211" maskUnits="userSpaceOnUse" x="5" y="5" width="86" height="86" fill="black">
      <rect fill="white" x="5" y="5" width="86" height="86" />
      <path fillRule="evenodd" clipRule="evenodd" d="M88 48C88 70.09 70.09 88 48 88C25.91 88 8 70.09 8 48C8 25.91 25.91 8 48 8C70.09 8 88 25.91 88 48Z" />
    </mask>
    <path d="M85 48C85 68.4331 68.4331 85 48 85V91C71.7469 91 91 71.7469 91 48H85ZM48 85C27.5669 85 11 68.4331 11 48H5C5 71.7469 24.2531 91 48 91V85ZM11 48C11 27.5669 27.5669 11 48 11V5C24.2531 5 5 24.2531 5 48H11ZM48 11C68.4331 11 85 27.5669 85 48H91C91 24.2531 71.7469 5 48 5V11Z" fill="black" fillOpacity="0.3" mask="url(#path-4-outside-2_4591_388211)" />
    <path fillRule="evenodd" clipRule="evenodd" d="M71.6514 66.4579C75.6293 61.3679 78 54.9608 78 48C78 31.4315 64.5685 18 48 18C31.4315 18 18 31.4315 18 48C18 54.9608 20.3707 61.368 24.3487 66.458C25.0655 65.9017 25.88 65.4617 26.7658 65.1668L37.1238 61.7275C39.5574 60.7103 40.6961 56.6806 40.9412 55.1523C39.0235 53.5663 37.7889 51.3041 37.4924 48.8336C37.4924 48.0076 37.2474 47.6692 37.1166 47.6039C36.7827 47.5176 36.5177 47.264 36.4166 46.9342C35.7446 45.3068 35.323 43.5871 35.1659 41.8335C35.1656 41.7384 35.1773 41.6434 35.2009 41.5512C35.368 40.8701 35.7685 40.2691 36.3326 39.8525V33.6668C36.5877 27.3322 41.6648 22.2552 47.9996 22.0002C55.1398 22.0002 57.0789 25.6612 57.3097 27.4718C58.5184 28.3655 59.6664 29.9077 59.6664 33.6667V39.8524C60.2306 40.269 60.631 40.87 60.7982 41.5511C60.8218 41.6433 60.8335 41.7381 60.8332 41.8334C60.6759 43.5861 60.2542 45.3051 59.5824 46.9317C59.4437 47.28 59.1459 47.5406 58.7821 47.6317C58.557 47.9898 58.4577 48.4126 58.4998 48.8334C58.205 51.2969 56.9775 53.554 55.0697 55.1404C55.3171 56.671 56.4628 60.703 58.9665 61.746L69.2335 65.1667C70.1196 65.4615 70.9344 65.9015 71.6514 66.4579Z" fill="url(#icon-individual-placeholder-b)" fillOpacity="0.6" />
  </svg>
));

IconIndividualPlaceholder.propTypes = {
  className: PropTypes.string,
};

IconIndividualPlaceholder.defaultProps = {
  className: null,
};

