import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BET_TYPE, AI_OUTCOME_SECTION } from 'core/constants';
import { mathRound } from 'core/helpers';
import { handicapStakeTypesIds, restOfTheMatchStakeTypeIds } from 'core/mapping-stake-types';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { RouteLink } from 'components/route-link/route-link';
import { IconClose } from 'components/icons/icon-close/icon-close';
import { IconWarning } from 'components/icons/icon-warning/icon-warning';
import { prepareStakeTypeName } from 'helpers/stake';
import { GA } from 'helpers/ga';

import './stake-item.scss';

export class StakeItem extends Component {
  static propTypes = {
    betType: PropTypes.oneOf([
      BET_TYPE.ORDINAR,
      BET_TYPE.EXPRESS,
      BET_TYPE.SYSTEM,
    ]).isRequired,
    stake: PropTypes.shape().isRequired,
    toggleStake: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
  };

  onRemoveClick = () => {
    const { toggleStake, stake } = this.props;
    toggleStake(stake);
    GA.event({
      category: 'bet-slip',
      label: 'delete-outcome',
    });
  };

  onEventClick = (e) => {
    const { sendEventClickFeedback } = this.props;

    sendEventClickFeedback(e);
    GA.event({
      category: 'go-to-event-page',
      label: 'bet-slip-event-click',
    });
  };

  render() {
    const {
      locale,
      betType,
      stake: {
        stakeTypeId,
        betFactor,
        stakeArgument,
        stakeName,
        stakeCode,
        teamA,
        teamB,
        stakeTypeName,
        isMultipleRestricted,
        isBetFactorDecreased,
        isBetFactorIncreased,
        isExpressDenied,
        isSuspended,
        isOutright,
        periodName,
        eventNumber,
        isEventInLive,
      },
    } = this.props;
    const isOrdinar = betType === BET_TYPE.ORDINAR;
    const teams = isOutright ? teamA : `${teamA} - ${teamB}`;
    const formattedStakeTypeName = prepareStakeTypeName(periodName, stakeTypeName);
    let formattedStakeName = `${stakeName}${stakeArgument === null ? '' : ` ${stakeArgument}`}`;

    if (handicapStakeTypesIds.includes(stakeTypeId)) {
      formattedStakeName = `${stakeCode === 1 ? teamA : teamB} ${stakeArgument > 0 ? `+${stakeArgument}` : stakeArgument}`;
    } else if (restOfTheMatchStakeTypeIds.includes(stakeTypeId)) {
      formattedStakeName = `${stakeName} (${stakeArgument.toFixed(1).replace('.', ':')})`;
    }

    return (
      <div className={classNames('stake-item pr-1 bg-main-4 position-relative', {
        'pt-2 pb-1_5': isOrdinar,
        'py-2': !isOrdinar,
        'is-suspended': !isOrdinar && isSuspended,
      })}
      >
        <div className="d-flex justify-content-between align-items-center font-weight-bold mb-0_5 pr-3_5">
          <div className="d-flex align-items-center mr-1 overflow-hidden">
            <span title={formattedStakeName} className="text-truncate">
              {formattedStakeName}
            </span>
            {(isMultipleRestricted || isExpressDenied) && <IconWarning className="ml-0_5 flex-shrink-0" />}
          </div>

          {!!betFactor && (
            <span className={classNames('stake-item-bet-factor position-relative', {
              'is-decreased': isBetFactorDecreased && !isSuspended,
              'is-increased': isBetFactorIncreased && !isSuspended,
            })}
            >
              {mathRound(betFactor)}
            </span>
          )}
        </div>
        <div title={formattedStakeTypeName} className="text-extra-2 caption text-truncate mb-0_25">{formattedStakeTypeName}</div>
        <RouteLink
          locale={locale}
          to={`/event/${eventNumber}?isLive=${isEventInLive}&sectionBeforeEventPage=${AI_OUTCOME_SECTION.BET_SLIP}`}
          className="stake-item-event-link caption"
          onClick={this.onEventClick}
          data-event-id={eventNumber}
          data-is-live={isEventInLive}
        >
          {teams}
        </RouteLink>

        <div
          role="button"
          tabIndex="0"
          onClick={this.onRemoveClick}
          onKeyPress={this.onRemoveClick}
          className="icon-close d-flex justify-content-center align-items-center position-absolute"
        >
          <IconClose />
        </div>

        {!isOrdinar && isSuspended && (
          <FormattedTag
            id="bet-slip.suspended"
            className="stake-item-suspended-label h6 d-flex align-items-center position-absolute bg-info"
          />
        )}
      </div>
    );
  }
}
