import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportVolleyball = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport5Bg : colors.cpExtra4} d="M16.75 7.91c-1.9-1.34-4.13-2.22-6.49-2.59-.05 1.36.07 2.74.39 4.11 2.27.7 4.31 1.8 6.06 3.32.74.63 1.42 1.34 2.03 2.11.66-1.18 1.08-2.49 1.21-3.9-.91-1.18-1.99-2.21-3.2-3.05m3.15.73C19.31 4.22 15.82.72 11.41.11c-.41.97-.72 1.97-.91 2.99-.05.24-.07.49-.1.73 2.61.39 5.1 1.36 7.21 2.85.83.58 1.6 1.24 2.29 1.96m-4.17 5.24c-.19-.16-.39-.3-.58-.45-1.65 2.07-3.74 3.73-6.08 4.82-.91.43-1.86.76-2.83 1.01 1.16.48 2.43.74 3.76.74 3.19 0 6.03-1.5 7.87-3.83-.64-.84-1.36-1.61-2.14-2.29m-1.83-1.33c-1.14-.72-2.39-1.29-3.73-1.7-1.74 1.61-3.72 2.83-5.9 3.59-.92.32-1.88.55-2.84.7.7 1.18 1.65 2.21 2.77 3 1.48-.21 2.9-.63 4.24-1.25 2.1-.98 3.97-2.48 5.46-4.34M9.18 9.72c-.53-2.31-.59-4.63-.16-6.9.19-.96.46-1.9.81-2.82-1.42.03-2.77.35-3.98.91-.56 1.38-.91 2.83-1.04 4.29-.2 2.31.15 4.69 1.01 6.91 1.21-.63 2.33-1.43 3.36-2.39m-5.4 3.3c-.98.34-2.01.58-3.05.71C.26 12.58 0 11.32 0 10c0-3.21 1.51-6.07 3.87-7.89-.28.97-.46 1.96-.55 2.96-.23 2.57.17 5.21 1.13 7.67-.22.09-.44.2-.67.28" />
  </svg>
);

IconSportVolleyball.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportVolleyball.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
