import React from 'react';
import PropTypes from 'prop-types';

export const IconLiveTr = ({ color, className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path fill={color} d="M11 14.5l-.001 2.5H14v2H6v-2h2.999L9 14.5h2zM18.01 2C19.108 2 20 2.9 20 3.992v7.016c0 1.1-.898 1.992-1.99 1.992H1.99C.892 13 0 12.1 0 11.008V3.992C0 2.892.898 2 1.99 2h16.02zM3.028 4.591c-.535 0-.961.17-1.28.51-.283.304-.44.716-.472 1.238l-.006.2v1.593c0 .62.165 1.1.494 1.438.33.338.77.507 1.322.507s.97-.149 1.253-.447c.252-.265.39-.643.414-1.136l.003-.19-.01-.022H3.742c.012.352-.031.6-.13.746-.099.145-.274.218-.526.218-.256 0-.45-.092-.584-.275-.114-.157-.179-.385-.195-.683l-.004-.156v-1.6c0-.371.062-.648.186-.831.125-.183.304-.275.539-.275.273 0 .465.077.575.23.094.133.142.336.145.611l-.002.144H4.75l.011-.022c-.01-.593-.16-1.041-.449-1.344-.289-.303-.717-.454-1.283-.454zm4.5.077H6.443L4.943 10h1.051l.286-1.132h1.406L7.976 10h1.05L7.528 4.668zm2.855 0H9.34V10h1.044V6.598l.022-.007L12.036 10h1.036V4.668h-1.037v3.398l-.022.008-1.63-3.406zm4.444 0h-1.043V10h2.992v-.831h-1.949V4.668zm3.46 0h-1.036V10h1.036V4.668zM6.994 6.144l.48 1.886h-.982l.48-1.886h.022z" />
  </svg>
);

IconLiveTr.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconLiveTr.defaultProps = {
  className: null,
  color: '#FFFFFF',
};
