import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withLocale, withModalActions, withFeedbackActions } from 'core/hocs';
import { BET_TYPE, BETS_HISTORY_TYPES } from 'core/constants';

import { Spinner } from 'components/spinner/spinner';
import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { IconStub } from 'components/icons/icon-stub/icon-stub';
import { withInfiniteScroll } from 'hocs/with-infinite-scroll';
import { GA } from 'helpers/ga';
import { BetItemOrdinar } from './bet-item/bet-item-ordinar';
import { BetItemMultiple } from './bet-item/bet-item-multiple';

import './bets-table.scss';

class BetsTableUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    bets: PropTypes.arrayOf(PropTypes.shape()),
    isInProgress: PropTypes.bool.isRequired,
    isLoadMoreInProgress: PropTypes.bool.isRequired,
    makeCashout: PropTypes.func,
    closeModal: PropTypes.func.isRequired,
    repeatBet: PropTypes.func.isRequired,
    type: PropTypes.oneOf([
      BETS_HISTORY_TYPES.ALL,
      BETS_HISTORY_TYPES.ACTIVE,
      BETS_HISTORY_TYPES.FINISHED,
      BETS_HISTORY_TYPES.CASHOUTED,
    ]).isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    bets: null,
    makeCashout: null,
  };

  state = {
    cashoutBetId: null,
    cashoutX: null,
    cashoutY: null,
  };

  componentDidUpdate(prevProps) {
    const { type } = this.props;
    const { type: prevType } = prevProps;
    const { cashoutBetId } = this.state;

    if (cashoutBetId && prevType !== type) {
      this.closeCashout();
    }
  }

  componentWillUnmount() {
    const parrentWithScroll = document.querySelector('.bets-history-modal');

    if (parrentWithScroll) {
      parrentWithScroll.removeEventListener('scroll', this.onParrentScroll);
    }
  }

  onCashoutClick = (e, cashoutBetId) => {
    e.stopPropagation();
    const { right, bottom } = e.currentTarget.getBoundingClientRect();
    const { innerWidth, innerHeight } = window;
    const offsetX = right + 20;
    const offsetY = bottom + 20;

    this.setState({
      cashoutBetId,
      cashoutX: offsetX > innerWidth ? innerWidth : offsetX,
      cashoutY: offsetY > innerHeight ? innerHeight : offsetY,
    });
    GA.event({
      category: 'bets-history',
      label: 'cashout-click',
    });

    const parrentWithScroll = document.querySelector('.bets-history-modal');

    if (parrentWithScroll) {
      parrentWithScroll.addEventListener('scroll', this.onParrentScroll);
    }
  };

  closeCashout = () => {
    const parrentWithScroll = document.querySelector('.bets-history-modal');
    this.setState({ cashoutBetId: null, cashoutX: null, cashoutY: null });

    if (parrentWithScroll) {
      parrentWithScroll.removeEventListener('scroll', this.onParrentScroll);
    }
  };

  onParrentScroll = () => {
    this.closeCashout();
  };

  render() {
    const {
      locale,
      currency,
      bets,
      isInProgress,
      isLoadMoreInProgress,
      makeCashout,
      closeModal,
      repeatBet,
      sendEventClickFeedback,
    } = this.props;
    const { cashoutBetId, cashoutX, cashoutY } = this.state;

    if (isInProgress) {
      return (
        <div className="d-flex align-items-center justify-content-center flex-fill">
          <Spinner />
        </div>
      );
    }

    if (!isInProgress && bets && !bets.length) {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center flex-fill">
          <IconStub className="mb-4" />
          <FormattedTag id="bets-history.empty" className="text-extra-3" />
        </div>
      );
    }

    return (
      <div>
        {bets && (
          <div className="bets-table bg-main-1 rounded">
            <div className="bets-table-header bg-main-3 d-flex justify-content-between align-items-center caption text-extra-2 rounded-top">
              <div className="bets-table-column" />
              <div className="bets-table-column pl-1_5">
                <FormattedMessage id="bets-history.bet" />
              </div>
              <div className="bets-table-column pl-1_5">
                <FormattedMessage id="bets-history.date" />
              </div>
              <div className="bets-table-column pl-1_5">
                <FormattedMessage id="bets-history.id" />
              </div>
              <div className="bets-table-column text-right px-1_5">
                <FormattedMessage id="bets-history.odd" />
              </div>
              <div className="bets-table-column text-right pr-2_5">
                <FormattedMessage id="bets-history.stake" />
              </div>
              <div className="bets-table-column pr-1_5" />
              <div className="bets-table-column text-right pr-6">
                <FormattedMessage id="bets-history.return" />
              </div>
            </div>

            {bets.map((bet) => {
              const BetItem = bet.betType === BET_TYPE.ORDINAR ? BetItemOrdinar : BetItemMultiple;

              return (
                <BetItem
                  key={bet.orderId}
                  {...bet}
                  locale={locale}
                  currency={currency}
                  makeCashout={makeCashout}
                  closeModal={closeModal}
                  repeatBet={repeatBet}
                  onCashoutClick={this.onCashoutClick}
                  closeCashout={this.closeCashout}
                  isCashoutOpen={cashoutBetId === bet.orderId}
                  cashoutX={cashoutX}
                  cashoutY={cashoutY}
                  sendEventClickFeedback={sendEventClickFeedback}
                />
              );
            })}
          </div>
        )}

        {isLoadMoreInProgress && (
          <div className="d-flex justify-content-center mt-3">
            <Spinner />
          </div>
        )}
      </div>
    );
  }
}

export const BetsTable = withFeedbackActions(withModalActions(withInfiniteScroll(withLocale(BetsTableUI))));
