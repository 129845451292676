import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import { isEmptyOrNil } from 'core/helpers';

import { IconExpand } from 'components/icons/icon-expand/icon-expand';
import { OddsCell } from 'components/betting-table/odds-cell/odds-cell';

import './game-outcome-dropdown.scss';

export class GameOutcomeDropdown extends Component {
  static propTypes = {
    className: PropTypes.string,
    classNameMenu: PropTypes.string,
    stakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    gameOutcomeFiltersRef: PropTypes.node.isRequired,
    isTotal: PropTypes.bool.isRequired,
    isHandicap: PropTypes.bool.isRequired,
    oddProps: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    className: null,
    classNameMenu: null
  };

  state = {
    isOpen: false,
  };

  toggle = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }));

  render() {
    const {
      className,
      classNameMenu,
      stakes,
      gameOutcomeFiltersRef,
      isTotal,
      isHandicap,
      oddProps
    } = this.props;
    const { isOpen } = this.state;

    return (
      <div className={classNames('game-outcome-dropdown', className)}>
        <Dropdown isOpen={isOpen} toggle={this.toggle} className="d-flex align-items-center">
          <DropdownToggle className="game-outcome-dropdown-toggle p-0 bg-transparent">
            <IconExpand />
          </DropdownToggle>
          <DropdownMenu container={gameOutcomeFiltersRef} right className={classNames('game-outcome-dropdown-menu p-1', classNameMenu)}>
            {stakes.map(marketStakes => (
              <div
                key={marketStakes.length > 1 ? `${marketStakes[0].stakeId}/${marketStakes[1].stakeId}` : marketStakes[0].stakeId}
                className="game-outcome-dropdown-item caption overflow-hidden"
              >
                {marketStakes.length === 1 && marketStakes[0].stakeCode === 2 && (
                  <div className="odd-cell bg-main-5 is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                    &#8212;
                  </div>
                )}
                {marketStakes.map(stake =>
                  !isEmptyOrNil(stake) ? (
                    <OddsCell
                      key={stake.stakeId}
                      className="game-outcome-dropdown-odds"
                      stake={stake}
                      isTotal={isTotal}
                      isHandicap={isHandicap}
                      oddsType="secondary"
                      {...oddProps}
                    />
                  ) : (
                    <div className="odd-cell bg-main-5 is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                      &#8212;
                    </div>
                  ))}
                {marketStakes.length === 1 && marketStakes[0].stakeCode === 1 && (
                  <div className="odd-cell bg-main-5 is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                    &#8212;
                  </div>
                )}
              </div>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}
