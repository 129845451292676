import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Collapse from 'reactstrap/lib/Collapse';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import Button from 'reactstrap/lib/Button';
import classNames from 'classnames';
import * as R from 'ramda';
import { FormattedMessage } from 'react-intl';

import { RouteLink } from 'components/route-link/route-link';
import { SportTypeIcon } from 'components/sport-type-icon/sport-type-icon';
import { findTopLeague, findCountryByLeague } from 'helpers/sports';
import { SportItemBody } from './sport-item-body';
import { FavoritesToggleButton } from '../favorites-toggle-button/favorites-toggle-button';
import { MODAL_IDS } from '../../../../constants';

import './sport-item.scss';

export class SportItem extends Component {
  static propTypes = {
    params: PropTypes.shape({
      sportId: PropTypes.string,
      leagueId: PropTypes.string,
    }),
    locale: PropTypes.string.isRequired,
    sportId: PropTypes.number.isRequired,
    sportName: PropTypes.string.isRequired,
    eventsCount: PropTypes.number.isRequired,
    inplayEventsCount: PropTypes.number.isRequired,
    countries: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    topLeagues: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isOpen: PropTypes.bool.isRequired,
    isActive: PropTypes.bool.isRequired,
    setActiveSportId: PropTypes.func.isRequired,
    activeLeagueId: PropTypes.number,
    activeCountries: PropTypes.arrayOf(PropTypes.number).isRequired,
    setActiveCountries: PropTypes.func.isRequired,
    setActiveLeagueId: PropTypes.func.isRequired,
    clearActiveLeagueId: PropTypes.func.isRequired,
    isAllSportsModal: PropTypes.bool.isRequired,
    clearActiveCountriesBySport: PropTypes.func.isRequired,
    clearActiveCountriesExceptSport: PropTypes.func.isRequired,
    clearActiveCountries: PropTypes.func.isRequired,
    clearActiveCountriesExceptSportAndCountry: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    idx: PropTypes.number.isRequired,
    sendSportClickFeedback: PropTypes.func.isRequired,
    sendLeagueClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    params: {},
    activeLeagueId: null,
  };

  constructor(props) {
    super(props);
    const {
      isActive,
      activeLeagueId,
      topLeagues,
      countries,
    } = props;
    let isCollapsed = false;

    if (isActive && activeLeagueId) {
      const foundTopLeague = findTopLeague(topLeagues, activeLeagueId);

      if (!foundTopLeague) {
        const [, otherCountries] = R.splitAt(10, countries || []);
        const foundOtherCountry = findCountryByLeague(otherCountries, activeLeagueId);

        if (foundOtherCountry) {
          isCollapsed = true;
        }
      }
    }

    this.state = { isCollapsed };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isOpen,
      params: { leagueId },
      activeLeagueId,
      countries
    } = this.props;
    const { isOpen: prevIsOpen, params: { leagueId: prevLeagueId } } = prevProps;
    const { isCollapsed } = this.state;
    const { isCollapsed: prevIsCollapsed } = prevState;

    if (isCollapsed) {
      if (prevIsOpen && !isOpen || prevLeagueId && !leagueId) {
        this.toggle();
      }
    } else if (!prevIsCollapsed && !isCollapsed) {
      const [, otherCountries] = R.splitAt(10, countries || []);
      const foundOtherCountry = findCountryByLeague(otherCountries, activeLeagueId);

      if (foundOtherCountry) {
        this.toggle();
      }
    }
  }

  toggle = () => this.setState(prevState => ({ isCollapsed: !prevState.isCollapsed }));

  onSportClick = () => {
    const {
      isActive,
      isOpen,
      setActiveSportId,
      sportId,
      clearActiveLeagueId,
      activeLeagueId,
      clearActiveCountriesBySport,
      clearActiveCountriesExceptSport,
      isAllSportsModal,
      closeModal,
      sendSportClickFeedback,
    } = this.props;

    clearActiveLeagueId();

    if (isOpen) {
      if (isActive) {
        if (!activeLeagueId) {
          setActiveSportId(null);
          clearActiveCountriesBySport(sportId);
        }
      }
    } else if (!isActive) {
      setActiveSportId(sportId);
      clearActiveCountriesExceptSport(sportId);
    }

    if (isAllSportsModal) {
      closeModal(MODAL_IDS.SPORTS);
    }

    sendSportClickFeedback(sportId);
  };

  render() {
    const {
      locale,
      sportId,
      sportName,
      eventsCount,
      inplayEventsCount,
      countries,
      topLeagues,
      isOpen,
      isActive,
      activeLeagueId,
      activeCountries,
      setActiveCountries,
      setActiveLeagueId,
      clearActiveCountries,
      clearActiveCountriesExceptSportAndCountry,
      idx,
      isAllSportsModal,
      sendLeagueClickFeedback,
    } = this.props;
    const { isCollapsed } = this.state;
    const [topCountries, otherCountries] = R.splitAt(10, countries || []);

    return (
      <div
        className={classNames('sports-list-element position-relative', {
          'active bg-main-4': isActive && !isOpen,
          opened: isOpen,
        })}
        id={!isAllSportsModal && idx === 0 ? 'health-check-1' : null}
      >
        <div className={classNames('sports-list-element-content d-flex justify-content-between align-items-center position-relative', {
          'hover-style': isOpen,
          'with-live-status': inplayEventsCount > 0,
        })}
        >
          <RouteLink
            className={classNames('d-flex align-items-center flex-grow-1 pl-2 pl-sm-2_5 text-capitalize align-self-stretch overflow-hidden', {
              'font-weight-bold': isActive,
            })}
            onClick={this.onSportClick}
            to={`/sport/${sportId}`}
            locale={locale}
          >
            <div className="d-flex align-items-center overflow-hidden pr-8 pr-sm-8_5 pr-lg-5 my-1 py-0_25">
              <SportTypeIcon sportId={sportId} isActive={isActive} />
              <span
                title={sportName}
                className={classNames('sport-list-title navigation-item-name pl-2_5 align-text-top', {
                  'font-weight-bold': isOpen,
                })}
              >
                {sportName}
              </span>
              <span className="align-self-end text-extra-3 pl-1 font-weight-normal">{eventsCount}</span>
            </div>
          </RouteLink>

          <FavoritesToggleButton
            isSport
            sportId={sportId}
            sportName={sportName}
            className="position-absolute favorites-button"
          />
        </div>

        {!isAllSportsModal && !R.isEmpty(topCountries) && (
          <Collapse isOpen={isOpen}>
            <Card className="d-none d-lg-block collapse-card border-0">
              <CardBody className="px-0 pt-0 pb-1 card-body-item">
                <SportItemBody
                  locale={locale}
                  topLeagues={topLeagues}
                  countries={topCountries}
                  sportId={sportId}
                  activeLeagueId={activeLeagueId}
                  activeCountries={activeCountries}
                  setActiveCountries={setActiveCountries}
                  setActiveLeagueId={setActiveLeagueId}
                  clearActiveCountries={clearActiveCountries}
                  clearActiveCountriesExceptSportAndCountry={clearActiveCountriesExceptSportAndCountry}
                  sendLeagueClickFeedback={sendLeagueClickFeedback}
                />
                {!R.isEmpty(otherCountries) && (
                  <Fragment>
                    {!isCollapsed && (
                      <Button
                        color="link"
                        size="xs"
                        className="w-100 text-extra-2 justify-content-start my-0_5 px-2_5"
                        onClick={this.toggle}
                      >
                        <FormattedMessage id="general.show-more" />
                      </Button>
                    )}

                    <Collapse isOpen={isCollapsed}>
                      <Card className="border-0 collapse-card">
                        <CardBody className="p-0 card-body-item">
                          <SportItemBody
                            countries={otherCountries}
                            sportId={sportId}
                            setActiveCountries={setActiveCountries}
                            locale={locale}
                            activeLeagueId={activeLeagueId}
                            activeCountries={activeCountries}
                            setActiveLeagueId={setActiveLeagueId}
                            clearActiveCountriesExceptSportAndCountry={clearActiveCountriesExceptSportAndCountry}
                            sendLeagueClickFeedback={sendLeagueClickFeedback}
                          />
                          {isCollapsed && (
                            <Button
                              color="link"
                              size="xs"
                              className="w-100 text-extra-2 justify-content-start my-0_5 px-2_5"
                              onClick={this.toggle}
                            >
                              <FormattedMessage id="general.show-less" />
                            </Button>
                          )}
                        </CardBody>
                      </Card>
                    </Collapse>
                  </Fragment>
                )}
              </CardBody>
            </Card>
          </Collapse>
        )}
      </div>
    );
  }
}
