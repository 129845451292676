import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportJudo = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M10.788 14.595c-.893.178-1.471 1.046-1.292 1.938.178.891 1.046 1.469 1.938 1.291.89-.178 1.469-1.045 1.29-1.938-.177-.892-1.044-1.47-1.936-1.291M9.332 5.862c.892-.179 1.47-1.046 1.292-1.938-.18-.892-1.046-1.471-1.938-1.292-.891.179-1.47 1.046-1.292 1.938.179.891 1.046 1.469 1.938 1.292m10.423-2.11l-.673.813c-.906 1.094-1.464 2.409-1.622 3.814.273.26.458.615.494 1.022.03.344.004 1.124-.58 2.474-.705 1.635-2.858 2.675-3.061 2.786-.17.105-.365.16-.561.16-.222 0-.443-.068-.635-.208-1.208-.891-2.322-1.915-3.312-3.044l-.503-.574c-.034-.038-.064-.079-.091-.121l-.002-.003-.503-.573c-.525-.599-1.091-1.164-1.69-1.691l-.004.009c-.414.234-1.84 1.544-1.707 2.75 1.313 1.82 1.928 4.023 1.74 6.283-.05.589-.565 1.025-1.155.977-.59-.05-1.028-.567-.978-1.156.1-1.194-.091-2.368-.547-3.441-.354 1.24-.961 2.395-1.798 3.406l-.673.812c-.211.256-.516.388-.825.388-.24 0-.481-.08-.681-.245-.455-.377-.52-1.052-.142-1.507l.673-.812c.96-1.163 1.53-2.57 1.646-4.073l.002-.02c-.22-.248-.368-.567-.4-.923-.03-.344.004-1.124.588-2.475.646-1.498 2.59-2.545 3.066-2.796.03-.017.06-.033.092-.047.344-.162.763-.134 1.09.107 1.208.891 2.322 1.915 3.312 3.043l.503.573c.033.039.063.08.09.122l.003.002.502.574c.525.598 1.09 1.163 1.688 1.691.388-.262 1.064-.738 1.333-1.246.412-.775.34-1.172.333-1.237-1.363-1.837-2.003-4.08-1.811-6.379.049-.589.564-1.026 1.155-.978.589.05 1.027.567.978 1.156-.101 1.194.09 2.368.546 3.442.354-1.241.962-2.396 1.798-3.407l.672-.812c.212-.256.518-.388.826-.388.24 0 .48.08.68.245.456.377.52 1.052.144 1.507" />
  </svg>
);

IconSportJudo.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportJudo.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
