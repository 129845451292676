import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Label from 'reactstrap/lib/Label';
import { BONUS_TYPES } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { RadioButton } from 'components/radio-button/radio-button';
import { IconStar } from 'components/icons/icon-star/icon-star';
import { IconApproved } from 'components/icons/icon-approved/icon-approved';
import { IconArrowBottom } from 'components/icons/icon-arrow-bottom/icon-arrow-bottom';
import { formatAmountWithCurrencySymbol } from 'helpers/currency';
import { GA } from 'helpers/ga';
import colors from '../../customizations/js/color-variables';

import './bonuses.scss';

export const getReachedStakesAmount = (bonuses, stakes) => R.compose(
  R.length,
  R.filter(({ betFactor }) => betFactor >= bonuses[0].minBetStakeCount),
  R.reject(({ isSuspended }) => isSuspended)
)(stakes);

export class Bonuses extends Component {
  static propTypes = {
    currency: PropTypes.string.isRequired,
    bonuses: PropTypes.arrayOf(PropTypes.shape()),
    stakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    bonusType: PropTypes.oneOf([
      BONUS_TYPES.EXPRESS_PERCENT,
      BONUS_TYPES.EXPRESS_CASHBACK,
    ]),
    isBonusConditionsReached: PropTypes.bool.isRequired,
    bonusAmount: PropTypes.number,
    setBonusType: PropTypes.func.isRequired,
    isMobile: PropTypes.func,
    betAmount: PropTypes.number,
    setTicketMaxHeight: PropTypes.func,
  };

  static defaultProps = {
    bonuses: null,
    bonusType: null,
    bonusAmount: null,
    isMobile: false,
    betAmount: null,
    setTicketMaxHeight: null,
  };

  timeoutId;

  state = {
    currentIdx: null,
    nextIdx: null,
    isOpen: false,
  };

  componentDidMount() {
    const { stakes } = this.props;

    this.setState({
      currentIdx: this.getCurrentIdx(stakes),
      nextIdx: this.getNextIdx(stakes),
    });
  }

  componentDidUpdate(prevProps) {
    const { stakes, isBonusConditionsReached, setTicketMaxHeight } = this.props;
    const { stakes: prevStakes } = prevProps;
    const { isOpen } = this.state;
    const currentIdx = this.getCurrentIdx(stakes);
    const prevCurrentIdx = this.getCurrentIdx(prevStakes);
    const nextIdx = this.getNextIdx(stakes);
    const prevNextIdx = this.getNextIdx(prevStakes);

    if (currentIdx !== prevCurrentIdx || nextIdx !== prevNextIdx) {
      this.setIdxs(currentIdx, nextIdx);
    }

    if (isOpen && !isBonusConditionsReached) {
      this.setIsOpen(false);
    }

    if (setTicketMaxHeight) {
      setTicketMaxHeight();
    }
  }

  setIdxs = (newCurrentIdx, newNextIdx) => {
    const { currentIdx } = this.state;

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    if ((currentIdx === null && newCurrentIdx !== null)
    || (currentIdx !== null && newCurrentIdx !== null && newCurrentIdx > currentIdx)) {
      this.timeoutId = setTimeout(() => {
        this.setState({ currentIdx: newCurrentIdx, nextIdx: newNextIdx });
      }, 500);
    } else {
      this.setState({ currentIdx: newCurrentIdx, nextIdx: newNextIdx });
    }
  };

  getCurrentIdx = (stakes) => {
    const { bonuses } = this.props;
    const reachedStakesAmount = getReachedStakesAmount(bonuses, stakes);
    const { bettingRules } = bonuses[0];

    if (reachedStakesAmount >= bettingRules[bettingRules.length - 1].oddCount) {
      // last
      return bettingRules.length - 1;
    }

    const foundIdx = R.compose(
      R.findIndex(({ oddCount }) => reachedStakesAmount >= oddCount),
      R.reverse,
    )(bettingRules);

    if (foundIdx !== -1) {
      return bettingRules.length - foundIdx - 1;
    }

    return null;
  };

  getNextIdx = (stakes) => {
    const { bonuses } = this.props;
    const { bettingRules } = bonuses[0];
    const currentIdx = this.getCurrentIdx(stakes);
    const nextIdx = currentIdx + 1;

    if (currentIdx === null) {
      return 0;
    }

    if (nextIdx >= bettingRules.length) {
      return null;
    }

    return nextIdx;
  };

  getBonusRules = () => {
    const { bonuses, isBonusConditionsReached, stakes } = this.props;
    const { currentIdx, nextIdx } = this.state;
    const { bettingRules } = bonuses[0];
    const reachedStakesAmount = getReachedStakesAmount(bonuses, stakes);

    if (!isBonusConditionsReached || currentIdx === null) {
      const idx = currentIdx === null ? 0 : currentIdx;
      const oddsDiff = bettingRules[idx].oddCount - reachedStakesAmount;

      return {
        isFirst: true,
        isLast: false,
        oddsDiff: oddsDiff >= 0 ? oddsDiff : 0,
        bonusType1: bonuses[0].bettingRules[idx].oddFactor,
        bonusType2: bonuses[1].bettingRules[idx].oddFactor,
        bonusLevel: currentIdx === null ? 0 : currentIdx + 1,
      };
    }

    if (nextIdx === null) {
      return {
        isFirst: false,
        isLast: true,
        oddsDiff: 0,
        bonusType1: bonuses[0].bettingRules[currentIdx].oddFactor,
        bonusType2: bonuses[1].bettingRules[currentIdx].oddFactor,
        bonusLevel: bettingRules.length,
      };
    }

    const currentOddsDiff = bettingRules[nextIdx].oddCount - reachedStakesAmount;
    const oddsDiff = currentOddsDiff >= 0 ? currentOddsDiff : 0;
    const maxOddsDiff = bettingRules[nextIdx].oddCount - bettingRules[currentIdx].oddCount;

    return {
      isFirst: false,
      isLast: false,
      oddsDiff: oddsDiff > maxOddsDiff ? maxOddsDiff : oddsDiff,
      bonusType1: bonuses[0].bettingRules[nextIdx].oddFactor,
      bonusType2: bonuses[1].bettingRules[nextIdx].oddFactor,
      bonusLevel: nextIdx,
    };
  };

  getCurrentBonus = () => {
    const { bonuses, stakes, bonusType } = this.props;
    const reachedStakesAmount = getReachedStakesAmount(bonuses, stakes);
    const { bettingRules } = bonuses[bonusType - 1];

    return R.compose(
      R.prop('oddFactor'),
      R.find(({ oddCount }) => reachedStakesAmount >= oddCount),
      R.reverse,
    )(bettingRules);
  };

  onClick = () => {
    const { isOpen } = this.state;
    const { isFirst } = this.getBonusRules();

    if (isFirst) {
      return;
    }

    this.setState(prevState => ({ isOpen: !prevState.isOpen }));

    if (!isOpen) {
      GA.event({
        category: 'bet-slip',
        label: 'bonus-dropdown-click',
      });
    }
  };

  setIsOpen = isOpen => this.setState({ isOpen });

  getCurrentBonusRule = (idx) => {
    const { bonuses, stakes } = this.props;
    const { bettingRules } = bonuses[idx];
    const reachedStakesAmount = getReachedStakesAmount(bonuses, stakes);

    return R.compose(
      R.find(({ oddCount }) => reachedStakesAmount >= oddCount),
      R.reverse,
    )(bettingRules);
  };

  onChange = (e) => {
    const { dataset: { id } } = e.currentTarget;
    const { setBonusType } = this.props;
    let label;

    setBonusType(Number(id));

    if (Number(id) === BONUS_TYPES.EXPRESS_PERCENT) {
      label = 'multiplier-click';
    } else if (Number(id) === BONUS_TYPES.EXPRESS_CASHBACK) {
      label = 'cashback-click';
    }

    if (label) {
      GA.event({
        category: 'bet-slip',
        label,
      });
    }
  };

  onLabelClick = (e) => {
    e.stopPropagation();
  };

  render() {
    const {
      currency,
      bonuses,
      bonusType,
      bonusAmount,
      isMobile,
      betAmount,
    } = this.props;
    const { currentIdx, nextIdx, isOpen } = this.state;

    if (!bonuses || (bonuses && !bonuses.length) || (currentIdx === null && nextIdx === null)) {
      return null;
    }

    const {
      minBetSum,
      minBetStakeCount,
      bettingRules,
    } = bonuses[0];
    const maxBonusLevel = bettingRules.length;
    const isBetAmountReached = betAmount >= minBetSum;
    const {
      isFirst,
      isLast,
      oddsDiff,
      bonusType1,
      bonusType2,
      bonusLevel,
    } = this.getBonusRules();

    return (
      <div
        role="button"
        tabIndex="0"
        onClick={this.onClick}
        onKeyPress={this.onClick}
        className={classNames('bonuses rounded my-2 position-relative py-1 px-1_5', {
          'is-set': !isFirst,
          'is-open': isOpen,
        })}
      >
        <div className="d-flex flex-column mb-1">
          <div className="bonuses-title">
            {isFirst && <FormattedTag id="bonuses.no-bonus" className="text-small font-weight-bold" />}
            {!isFirst && <FormattedTag id={`bonuses.${bonusType}.current`} values={{ amount: this.getCurrentBonus() }} className="text-small font-weight-bold" />}
            {!isFirst && !bonusAmount && <IconArrowBottom className="icon-arrow-bottom flex-shrink-0 ml-0_5" backgroundColor="transparent" />}
          </div>

          {!isFirst && !!bonusAmount && (
            <div>
              <span className="text-small"> ({formatAmountWithCurrencySymbol(bonusAmount, currency)})</span>
              <IconArrowBottom className="icon-arrow-bottom flex-shrink-0 ml-0_5" backgroundColor="transparent" />
            </div>
          )}
        </div>

        {isOpen && (
          <div className="py-0_5">
            {bonuses.map((bonus, idx) => {
              const foundBettingRule = this.getCurrentBonusRule(idx);

              return !!foundBettingRule && (
                <Label
                  key={bonus.id}
                  role="button"
                  check
                  className={classNames('d-flex align-items-center flex-wrap text-small', {
                    'mt-1': idx !== 0,
                  })}
                  onClick={this.onLabelClick}
                >
                  <RadioButton
                    id={`${isMobile ? 'm-' : ''}${bonus.id}`}
                    type="radio"
                    name={`${isMobile ? 'm-' : ''}bonus-type-radio`}
                    data-id={bonus.id}
                    checked={bonus.id === bonusType}
                    onChange={this.onChange}
                    className="mr-1"
                  />
                  <FormattedMessage id={`bonuses.${bonus.id}.value`} values={{ amount: foundBettingRule.oddFactor }}>
                    {(intl) => {
                      const splittedIntl = intl.split(' ');

                      return splittedIntl.map(word => (
                        <span className="mr-0_5">
                          {word}
                        </span>
                      ));
                    }}
                  </FormattedMessage>
                  <FormattedMessage id={`bonuses.${bonus.id}.description`}>
                    {intl => intl.split(' ').map(word => <span className="mr-0_25 text-extra-2">{word}</span>)}
                  </FormattedMessage>
                </Label>
              );
            })}
          </div>
        )}

        {isLast
          ? <FormattedTag id="bonuses.last" className="mt-1 caption" />
          : (
            <div className="d-flex flex-wrap mt-1 caption">
              {!isLast && (
                <FormattedTag
                  id={`bonuses.${bonusType}.${isFirst ? 'first' : 'next'}`}
                  values={{ amount: bonusType === BONUS_TYPES.EXPRESS_PERCENT ? bonusType1 : bonusType2 }}
                  className="caption mr-0_5"
                />
              )}

              <FormattedMessage id={`bonuses.${oddsDiff === 0 ? 'no-more-bets' : 'more-bets'}`} values={{ amount: oddsDiff, odds: minBetStakeCount }}>
                {(intl) => {
                  const splittedIntl = intl.split(' ');

                  return splittedIntl.map((word, idx) => {
                    if (idx === 0) {
                      return (
                        <span className="d-flex">
                          <IconApproved color={oddsDiff === 0 ? colors.cpSecondary1 : colors.cpExtra3} className="icon-approved mr-0_5 flex-shrink-0" />
                          <span>{word}</span>
                        </span>
                      );
                    }

                    return <span className={classNames('ml-0_25', { 'mr-0_5': idx === splittedIntl.length - 1 })}>{word}</span>;
                  });
                }}
              </FormattedMessage>

              <FormattedMessage id="bonuses.min-stake" values={{ amount: formatAmountWithCurrencySymbol(minBetSum, currency) }}>
                {intl => intl.split(' ').map((word, idx) => {
                  if (idx === 0) {
                    return (
                      <span className="d-flex">
                        <IconApproved color={isBetAmountReached ? colors.cpSecondary1 : colors.cpExtra3} className="icon-approved mr-0_5 flex-shrink-0" />
                        <span>{word}</span>
                      </span>
                    );
                  }

                  return <span className="ml-0_25">{word}</span>;
                })}
              </FormattedMessage>
            </div>
          )}

        <div
          className={classNames('bonuses-level position-absolute px-0_5 d-flex align-items-center', {
            'bg-primary': !isFirst,
            'bg-main-4': isFirst,
          })}
        >
          <IconStar color={isFirst ? colors.cpExtra2 : colors.cpExtra1} className="icon-star mr-0_5" />
          <FormattedTag
            id="bonuses.level"
            values={{ current: bonusLevel, max: maxBonusLevel }}
            className={classNames('font-weight-bold text-uppercase', {
              'text-extra-1': !isFirst,
              'text-extra-2': isFirst,
            })}
          />
        </div>
      </div>
    );
  }
}
