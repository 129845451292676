import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportLottery = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M15.57 9.73l-4.64-1.86-.005-.002c-.1.255-.302.457-.557.557l.002.005 1.86 4.64c1.52-.61 2.73-1.82 3.34-3.34m-7.8 3.34l1.86-4.64.002-.005c-.255-.1-.457-.302-.558-.557l-.004.002-4.64 1.86c.61 1.52 1.82 2.73 3.34 3.34m-3.34-7.8l4.64 1.86.004.002c.101-.255.303-.456.558-.558l-.002-.003L7.77 1.93c-1.52.61-2.73 1.82-3.34 3.34m7.8-3.34l-1.86 4.64-.002.004c.255.102.456.303.557.558l.004-.002 4.641-1.86c-.61-1.52-1.82-2.73-3.34-3.34M17.5 7.5c0 .98-.19 1.92-.54 2.78-.75 1.91-2.27 3.43-4.18 4.18-.86.35-1.8.54-2.78.54-.98 0-1.92-.19-2.78-.54-1.91-.75-3.43-2.27-4.18-4.18-.35-.86-.54-1.8-.54-2.78 0-.98.19-1.92.54-2.78.75-1.91 2.27-3.43 4.18-4.18C8.08.19 9.02 0 10 0c.98 0 1.92.19 2.78.54 1.91.75 3.43 2.27 4.18 4.18.35.86.54 1.8.54 2.78M16 19v1H4v-1c0-.552.447-1 1-1h1.334l.675-2.02c.931.34 1.941.52 2.991.52s2.06-.18 2.99-.52l.676 2.02H15c.552 0 1 .448 1 1" />
  </svg>
);

IconSportLottery.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportLottery.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
