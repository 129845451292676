import React from 'react';
import PropTypes from 'prop-types';

import colors from '../../../customizations/js/color-variables';

export const IconArrowBottom = ({
  className,
  color,
  backgroundColor,
  opacity,
}) => (
  <svg className={className} opacity={opacity} width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <rect fill={backgroundColor} x="0" y="0" width="12" height="12" />
        <polygon fill={color} fillRule="nonzero" points="6.0595297 7.00014982 10.06081 3 11.1213203 4.06081002 6.0595297 9.12117051 1 4.0630703 2.0605103 3.00226028" />
      </g>
    </g>
  </svg>
);

IconArrowBottom.propTypes = {
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.number,
};

IconArrowBottom.defaultProps = {
  className: '',
  backgroundColor: colors.cpMain2,
  color: colors.cpExtra2,
  opacity: 1,
};
