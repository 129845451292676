import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ChangeRoute } from 'core/components';
import { withUser, withPostMessagesActions } from 'core/hocs';
import { USER_STORE_FIELDS } from 'core/constants';

export const withLogin = (WrappedComponent) => {
  class WithLoginUI extends Component {
    static propTypes = {
      isUserLoggedIn: PropTypes.bool.isRequired,
      postUnauthorizedMessage: PropTypes.func.isRequired,
    };

    constructor(props) {
      super(props);
      const { isUserLoggedIn, postUnauthorizedMessage } = this.props;

      if (!isUserLoggedIn) {
        postUnauthorizedMessage();
      }
    }

    render() {
      const { isUserLoggedIn, ...props } = this.props;

      if (!isUserLoggedIn) {
        return <ChangeRoute to="/" />;
      }

      return <WrappedComponent {...props} />;
    }
  }

  return withPostMessagesActions(withUser(WithLoginUI, [USER_STORE_FIELDS.IS_USER_LOGGED_IN]));
};
