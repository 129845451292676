import React from 'react';
import PropTypes from 'prop-types';

import colors from '../../../customizations/js/color-variables';

export const IconApproved = ({ className, color }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <path fill={color} d="M8 14A6 6 0 1 1 8 2a6 6 0 0 1 0 12zM7 8.94L5.53 7.47 4.47 8.53 7 11.06l4.53-4.53-1.06-1.06L7 8.94z" />
    </g>
  </svg>
);

IconApproved.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconApproved.defaultProps = {
  className: null,
  color: colors.cpSecondary1,
};
