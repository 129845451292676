import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportCanoeing = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M19.853 17.024l-1.769-1.768c-.592-.593-1.465-.733-2.193-.424L13.88 12.82c-.17.18-.339.36-.519.54-.18.18-.36.35-.54.52l2.012 2.011c-.309.728-.168 1.601.424 2.194l1.768 1.768c.195.194.512.194.707 0l2.122-2.122c.195-.195.195-.512 0-.707zM7.18 6.12L5.167 4.107c.308-.728.168-1.6-.425-2.193L2.974.146c-.195-.195-.512-.195-.707 0L.147 2.267c-.196.195-.196.512 0 .707l1.767 1.768c.593.594 1.465.733 2.193.426L6.12 7.18c.17-.179.34-.359.52-.54.18-.179.36-.349.54-.519zm3.351 7.5c-.57.57-1.32.86-2.07.86-.75 0-1.51-.29-2.08-.86-1.14-1.15-1.14-3.01 0-4.15L7.4 8.46 6.12 7.18C1.55 12.02.34 17.22.01 19.32c-.06.4.27.73.67.67 2.1-.33 7.3-1.54 12.14-6.11l-1.27-1.27-1.02 1.01zM9.47 6.38c1.14-1.14 3-1.14 4.15 0 .57.58.86 1.33.86 2.08s-.29 1.5-.86 2.07l-1.01 1.02 1.27 1.27C18.45 7.98 19.66 2.78 19.99.68c.06-.4-.27-.73-.67-.67-2.1.33-7.3 1.54-12.14 6.11L8.46 7.4l1.01-1.02zm3.09 1.06c-.28-.28-.65-.42-1.02-.42-.36 0-.73.14-1.01.42L9.52 8.46l2.03 2.03 1.01-1.02c.56-.56.56-1.47 0-2.03zm-4.1 2.08l2.03 2.03-1.02 1.01c-.56.56-1.47.56-2.03 0-.56-.56-.56-1.47 0-2.03l1.02-1.01z" />
  </svg>
);

IconSportCanoeing.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportCanoeing.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
