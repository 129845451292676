import React from 'react';
import PropTypes from 'prop-types';
import { GlobalNotificationsStack as GlobalNotificationsStackCore } from 'core/components';
import { withCoreComponent } from 'core/hocs';

import { NotificationPopup } from './global-notification-popup';

import './global-notifications-stack.scss';

export const GlobalNotificationsStackUI = ({ notifications }) => (
  <div className="global-notifications-stack position-fixed d-flex flex-column px-1 mt-sm-3 px-sm-2">
    {notifications.map(({
      type, duration, timestamp, message, data, values,
    }) => (
      <NotificationPopup
        key={`${type}-${timestamp}`}
        timestamp={timestamp}
        type={type}
        message={message}
        values={values}
        data={data}
        coreProps={{
          timestamp,
          duration,
        }}
      />
    ))}
  </div>
);

GlobalNotificationsStackUI.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape()),
};

GlobalNotificationsStackUI.defaultProps = {
  notifications: [],
};

export const GlobalNotificationsStack = withCoreComponent(GlobalNotificationsStackCore, GlobalNotificationsStackUI);
