import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportEicehockey = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M8.75 1C3.92 1 0 2.76 0 4.94c0 2.17 3.92 3.94 8.75 3.94s8.75-1.77 8.75-3.94C17.5 2.76 13.58 1 8.75 1M0 7.61v3.45c0 1.84 2.78 3.37 6.55 3.81.16-1.01.5-2.14 1.17-3.23.38-.62.83-1.11 1.341-1.45H8.75C4.9 10.19 1.7 9.17 0 7.61m12.72 2.18c.5.19.87.41 1.06.54h.36c.34-.23 1.34-.83 2.59-.83.26 0 .52.03.77.09V7.61c-1.07.98-2.73 1.75-4.78 2.18m5.181 4.737c-.332 0-.604-.272-.604-.605 0-.332.272-.605.604-.605.333 0 .605.273.605.605 0 .333-.272.605-.605.605m-1.511-.907c-.332 0-.605-.272-.605-.604 0-.333.273-.606.605-.606.333 0 .604.273.604.606 0 .332-.271.604-.604.604m0 1.814c-.332 0-.605-.272-.605-.605 0-.332.273-.604.605-.604.333 0 .604.272.604.604 0 .333-.271.605-.604.605m-4.536-.756h-.453v.454c0 .248-.206.453-.454.453-.248 0-.453-.205-.453-.453v-.454h-.454c-.247 0-.453-.205-.453-.453 0-.248.206-.454.453-.454h.454v-.454c0-.248.205-.453.453-.453.248 0 .454.205.454.453v.454h.453c.249 0 .454.206.454.454 0 .248-.205.453-.454.453m7.063-2.261c-1.596-2.625-3.997-.799-3.997-.799-.157.115-.441.212-.641.212h-.64c-.194.006-.485-.097-.635-.212 0 0-2.407-1.826-4.004.799-1.596 2.618-.949 5.472-.949 5.472.109.695.484 1.161 1.179 1.107.702-.06 2.219-1.881 2.219-1.881.121-.151.387-.278.581-.278h3.858c.193 0 .46.127.581.278 0 0 1.517 1.821 2.219 1.881.701.054 1.07-.412 1.185-1.107 0 0 .641-2.854-.956-5.472" />
  </svg>
);

IconSportEicehockey.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportEicehockey.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
