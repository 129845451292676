import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportModernPentathlon = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M6.62 4.712c1.025 0 1.855-.831 1.855-1.856C8.475 1.83 7.645 1 6.62 1s-1.856.83-1.856 1.856c0 1.025.831 1.856 1.856 1.856M20 6.296v.08c0 .333-.282.593-.614.593h-4.572v.575c0 .333-.237.594-.57.594h-.08c-.332 0-.59-.261-.59-.594v-.065l-.05.002H8.22c.005.059.01.12.01.182 0 0 0 3.614-.002 3.632 1.764 1.508 2.99 3.536 3.503 5.838l.008.037c.132.59-.24 1.176-.83 1.308-.08.018-.16.026-.24.026-.5 0-.954-.347-1.069-.856l-.007-.04c-.41-1.84-1.394-3.462-2.809-4.666-1.194 1.864-2.678 3.505-4.416 4.883l-.564.448c-.202.16-.442.237-.68.237-.323 0-.643-.143-.859-.415-.376-.474-.297-1.163.177-1.54l.565-.447c1.577-1.25 2.857-2.745 3.93-4.443.012-.168.006-4.002.006-4.002 0-.077.006-.153.017-.227C2.754 7.093.878 5.589.086 3.426l-.019-.053C-.14 2.806.151 2.176.72 1.969c.567-.208 1.197.084 1.405.652l.018.052c.608 1.66 2.202 2.722 3.968 2.642l.52-.024.05-.001h6.893v-.28c0-.332.258-.597.59-.597h.08c.333 0 .57.265.57.598v.717h4.572c.332 0 .614.235.614.568" />
  </svg>
);

IconSportModernPentathlon.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportModernPentathlon.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
