import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportChess = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M15 18H5c-.55 0-1.05.22-1.41.59-.37.36-.59.86-.59 1.41h14c0-1.1-.9-2-2-2zm0-18h-2.5v3H11V0H9v3H7.5V0H5v5h10V0zM5.11 16.5L7 8 6 6.5h8L13 8l1.89 8.5H5.11z" />
  </svg>
);

IconSportChess.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportChess.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
