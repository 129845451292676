import React from 'react';
import PropTypes from 'prop-types';
import R from 'ramda';
import { FormattedMessage } from 'react-intl';
import { isNotNil } from 'core/helpers';

export const FormattedTag = ({
  tag, className, id, values, defaultMessage, isHtml, ...props
}) => {
  const Tag = R.defaultTo('span', tag);

  return (
    <FormattedMessage
      id={id}
      {...(isNotNil(defaultMessage) && { defaultMessage })}
      {...(isNotNil(values) && { values })}
    >
      {txt => isHtml
        ? <Tag className={className} {...props} dangerouslySetInnerHTML={{ __html: txt }} />
        : <Tag className={className} {...props}>{txt}</Tag>}
    </FormattedMessage>
  );
};

FormattedTag.propTypes = {
  id: PropTypes.string.isRequired,
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]), // object - fix type of RouteLink
  className: PropTypes.string,
  defaultMessage: PropTypes.string,
  isHtml: PropTypes.bool,
  values: PropTypes.shape(),
};

FormattedTag.defaultProps = {
  tag: 'span',
  className: null,
  defaultMessage: null,
  isHtml: null,
  values: null,
};
