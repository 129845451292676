import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportShooting = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M11.47 9.97c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5.67 1.5 1.5 1.5 1.5-.67 1.5-1.5m3.5.75h.95c-.33 2.71-2.49 4.87-5.2 5.2v-.95c0-.41-.34-.75-.75-.75s-.75.34-.75.75v.95c-2.71-.33-4.87-2.49-5.2-5.2h.95c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-.95c.33-2.71 2.49-4.87 5.2-5.2v.95c0 .41.34.75.75.75s.75-.34.75-.75v-.95c2.71.33 4.87 2.49 5.2 5.2h-.95c-.41 0-.75.34-.75.75s.34.75.75.75m-3.5-.75c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5.67 1.5 1.5 1.5 1.5-.67 1.5-1.5m3.5.75h.95c-.33 2.71-2.49 4.87-5.2 5.2v-.95c0-.41-.34-.75-.75-.75s-.75.34-.75.75v.95c-2.71-.33-4.87-2.49-5.2-5.2h.95c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-.95c.33-2.71 2.49-4.87 5.2-5.2v.95c0 .41.34.75.75.75s.75-.34.75-.75v-.95c2.71.33 4.87 2.49 5.2 5.2h-.95c-.41 0-.75.34-.75.75s.34.75.75.75m-4.25 6.71v2.51c4.92-.37 8.85-4.3 9.22-9.22h-2.51c-.35 3.54-3.17 6.36-6.71 6.71m.75-7.46c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5.67 1.5 1.5 1.5 1.5-.67 1.5-1.5m2.75 0c0 .41.34.75.75.75h.95c-.33 2.71-2.49 4.87-5.2 5.2v-.95c0-.41-.34-.75-.75-.75s-.75.34-.75.75v.95c-2.71-.33-4.87-2.49-5.2-5.2h.95c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-.95c.33-2.71 2.49-4.87 5.2-5.2v.95c0 .41.34.75.75.75s.75-.34.75-.75v-.95c2.71.33 4.87 2.49 5.2 5.2h-.95c-.41 0-.75.34-.75.75m5.72-.75h-2.51c-.35-3.54-3.17-6.36-6.71-6.71V0c4.92.37 8.85 4.3 9.22 9.22M9.22 0v2.51c-3.54.35-6.36 3.17-6.71 6.71H0C.37 4.3 4.3.37 9.22 0m0 17.43v2.51C4.3 19.57.37 15.64 0 10.72h2.51c.35 3.54 3.17 6.36 6.71 6.71" />
  </svg>
);

IconSportShooting.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportShooting.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
