import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconArrowRight = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14">
    <g fill="none" fillRule="evenodd">
      <path stroke={colors.cpExtra2} strokeLinecap="square" strokeWidth="2" d="M2 12.286L6.966 7.32 2 2" />
    </g>
  </svg>
);

IconArrowRight.propTypes = {
  className: PropTypes.string,
};
IconArrowRight.defaultProps = {
  className: null,
};
