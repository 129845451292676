import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportArchery = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M16 12v-1.25h-1.554c-.049.546-.132 1.059-.276 1.5-.76 2.39-2.72 3.43-4.25 4.24-.66.35-1.28.68-1.64 1.04-.75.75-.78 1.45-.78 1.48v.24c0 .41-.34.75-.75.75S6 19.66 6 19.25v-7h1.5v3.98c.49-.4 1.08-.73 1.72-1.07 1.32-.7 2.68-1.41 3.34-2.91.198-.425.333-.924.396-1.5H4.625L4 12H0l1-2-1-2h4l.625 1.25h8.331c-.063-.576-.198-1.075-.396-1.5-.66-1.5-2.02-2.21-3.34-2.91-.64-.34-1.23-.67-1.72-1.07v3.98H6v-7c0-.41.34-.75.75-.75s.75.34.75.75v.24c0 .05.05.74.78 1.48.36.36.98.69 1.64 1.04 1.53.81 3.49 1.85 4.25 4.24.144.441.227.954.276 1.5H16V8l4 2-4 2z" />
  </svg>
);

IconSportArchery.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportArchery.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
