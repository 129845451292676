import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import Label from 'reactstrap/lib/Label';
import { withModalActions } from 'core/hocs';

import { ModalWindow } from 'components/modal-window/modal-window';
import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { Checkbox } from 'components/checkbox/checkbox';
import { setIsAddAccumulatorModalHidden } from 'core/helpers';
import { MODAL_IDS } from '../../../constants';

import './add-accumulator-modal.scss';

class AddAccumulatorModalUI extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    currentModal: PropTypes.shape().isRequired,
  };

  state = {
    isChecked: false,
  };

  onChange = () => this.setState(prevState => ({ isChecked: !prevState.isChecked }));

  onCancelClick = () => {
    const { closeModal } = this.props;
    closeModal(MODAL_IDS.ADD_ACCUMULATOR);
  };

  onClick = () => {
    const { closeModal, currentModal: { data: { addAccumulatorOfTheDay, payload } = {} } = {} } = this.props;
    const { isChecked } = this.state;

    if (addAccumulatorOfTheDay) {
      addAccumulatorOfTheDay(payload);
      closeModal(MODAL_IDS.ADD_ACCUMULATOR);

      if (isChecked) {
        setIsAddAccumulatorModalHidden(isChecked);
      }
    }
  };

  render() {
    const { isChecked } = this.state;

    return (
      <ModalWindow
        id={MODAL_IDS.ADD_ACCUMULATOR}
        coreProps={{ id: MODAL_IDS.ADD_ACCUMULATOR }}
        className="add-accumulator-modal mx-auto"
        bodyClassName="px-sm-5 py-sm-4"
        fade
        centered
      >
        <FormattedTag tag="div" id="add-accumulator-modal.title" className="h3 mb-2" />
        <FormattedTag tag="div" id="add-accumulator-modal.description" className="mb-5" />
        <div className="d-flex justify-content-between align-items-center">
          <Label
            role="button"
            check
            className="d-flex align-items-center"
          >
            <Checkbox
              id="add-accumulator-modal-checkbox"
              name="add-accumulator-modal-checkbox"
              checked={isChecked}
              onChange={this.onChange}
              className="mr-1_5"
            />
            <FormattedTag id="add-accumulator-modal.checkbox" className="text-extra-2 text-small" />
          </Label>

          <div className="d-flex">
            <FormattedTag
              tag={Button}
              id="general.cancel"
              outline
              color="secondary"
              size="sm"
              onClick={this.onCancelClick}
              className="mr-1_5"
            />
            <FormattedTag
              tag={Button}
              id="general.yes"
              color="primary"
              size="sm"
              onClick={this.onClick}
            />
          </div>
        </div>
      </ModalWindow>
    );
  }
}

export const AddAccumulatorModal = withModalActions(AddAccumulatorModalUI);
