import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportBowls = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M15.351 5.503c-.05.52-.489.9-1 .9h-.09c-.55-.06-.95-.54-.899-1.09.05-.51.48-.91.98-.91.04 0 .07.01.11.01.52.05.9.49.9 1v.09zm-.88 3.25c-.17.37-.529.59-.91.59-.14 0-.279-.03-.409-.09-.51-.23-.73-.82-.5-1.33.17-.37.54-.59.92-.59.13 0 .27.03.4.09.37.17.59.53.59.91 0 .14-.03.28-.09.42zm-1.809 2.8c-.2.22-.48.34-.76.34-.23 0-.46-.08-.65-.25-.42-.36-.46-.99-.1-1.41.2-.23.48-.34.75-.34.24 0 .47.08.66.25.23.2.34.47.34.75 0 .23-.08.47-.24.66zm-2.52 2.17c-.17.1-.36.15-.54.15-.33 0-.66-.16-.85-.46-.29-.47-.15-1.08.31-1.38.16-.11.35-.16.53-.16.34 0 .66.17.85.47.11.16.16.35.16.53 0 .33-.16.66-.46.85zm-3.07 1.35c-.08.02-.17.03-.25.03-.45 0-.85-.3-.97-.74-.14-.54.18-1.08.71-1.23.09-.02.17-.03.26-.03.44 0 .84.3.96.75.02.09.03.17.03.26 0 .44-.29.84-.74.96zm-2.28-.61c-.06.51-.49.89-1 .89h-.1c-.55-.06-.95-.55-.89-1.1.05-.51.49-.9.99-.9.04 0 .07.01.11.01.52.05.89.49.89 1.01v.09zm8.97-13.03c.1-.03.2-.05.3-.05.43 0 .82.28.96.7.03.1.04.2.04.3 0 .42-.27.82-.7.96-.1.03-.2.04-.3.04-.43 0-.82-.27-.95-.7-.17-.52.12-1.08.65-1.25zm3.31 1.5c-1.13-1.13-2.46-1.93-3.88-2.41-.19.18-.43.27-.68.27-.26 0-.52-.1-.71-.3l-.03-.03c-.09-.1-.17-.22-.22-.34-.87-.14-1.75-.16-2.62-.06.6.17 1.15.47 1.6.92.94.94 1.28 2.33.96 3.92-.29 1.48-1.14 2.98-2.37 4.22-1.64 1.63-3.61 2.5-5.32 2.5-1.09 0-2.08-.36-2.82-1.09-.44-.44-.75-.98-.92-1.6-.14 1.22-.04 2.47.28 3.66.17-.13.37-.19.58-.19.18 0 .36.05.52.15.31.18.48.51.48.85 0 .18-.05.36-.15.52-.17.28-.46.44-.75.47.47.97 1.11 1.88 1.91 2.68 3.9 3.91 10.24 3.91 14.14 0 3.91-3.9 3.91-10.24 0-14.14zm-10.61 3.53c-1.17 1.18-2.67 1.57-3.35.89-.69-.69-.29-2.19.88-3.36s2.67-1.57 3.36-.88c.68.68.29 2.18-.89 3.35zm3.01-4.42c-.43-.43-1.04-.63-1.74-.63-1.32 0-2.95.73-4.27 2.05-1.03 1.03-1.73 2.25-1.97 3.44-.21 1.09-.02 2 .55 2.57 1.25 1.24 4 .6 6.02-1.41 1.02-1.03 1.72-2.26 1.96-3.45.22-1.08.02-1.99-.55-2.57z" />
  </svg>
);

IconSportBowls.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportBowls.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
