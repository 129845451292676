import React from 'react';
import PropTypes from 'prop-types';

import './live-streams.scss';

export const LiveStreams = ({ activeItem, parent }) => activeItem && (
  <div className="live-streams">
    <div className="live-streams-iframe-container position-relative">
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe
        src={parent ? `${activeItem.url}&parent=${parent}` : activeItem.url}
        frameBorder="0"
        width="100%"
        height="100%"
        className="live-streams-iframe position-absolute"
        allow="autoplay;fullscreen"
      />
    </div>
  </div>
);

LiveStreams.propTypes = {
  activeItem: PropTypes.shape(),
  parent: PropTypes.string,
};

LiveStreams.defaultProps = {
  activeItem: null,
  parent: null,
};
