import { LOCALE_KEYS } from 'core/constants';

export const SPORTS = {
  FOOTBALL: 1,
  WATER_POLO: 2,
  TENNIS: 3,
  BASKETBALL: 4,
  BASEBALL: 5,
  AMERICAN_FOOTBALL: 6,
  BOXING: 7,
  GOLF: 8,
  CHESS: 9,
  ICE_HOCKEY: 10,
  BANDY: 11,
  VOLLEYBALL: 12,
  HANDBALL: 13,
  RUGBY_UNION: 14,
  BIATHLON: 15,
  WHAT_WHERE_WHEN: 16,
  FUTSAL: 17,
  FORMULA_1: 18,
  SNOOKER: 19,
  DARTS: 20,
  FIELD_HOCKEY: 21,
  BEACH_FOOTBALL: 22,
  CURLING: 23,
  OTHERS: 24,
  TABLE_TENNIS: 25,
  BEACH_VOLLEY: 26,
  ROLLER_HOCKEY: 27,
  EUROVISION: 28,
  BADMINTON: 29,
  RUGBY_LEAGUE: 30,
  BOWLS: 31,
  SQUASH: 32,
  POLITICS: 33,
  OLYMPIC_GAMES: 34,
  SPECIALS: 35,
  CRICKET: 36,
  FLOORBALL: 37,
  SPEEDWAY: 38,
  AUSSIE_RULES: 39,
  PADEL_TENNIS: 40,
  CYCLING: 41,
  SKI_RACING: 42,
  SKI_JUMPING: 43,
  SAILING: 44,
  MINI_FOOTBALL: 45,
  MMA: 46,
  NETBALL: 47,
  ATHLETICS: 48,
  JUDO: 49,
  EQUESTRIAN: 50,
  SOFTBALL: 51,
  GAELIC_FOOTBALL: 52,
  E_SPORT: 53,
  WEIGHTLIFTING: 54,
  POKER: 55,
  SUMO: 56,
  GYMNASTICS: 57,
  TAEKWONDO: 58,
  POOL: 59,
  SHOOTING: 60,
  ARCHERY: 61,
  WATER_SPORTS: 62,
  LACROSSE: 63,
  MOTORCYCLING: 64,
  SURFING: 65,
  RALLY: 66,
  NASCAR: 67,
  CANOEING: 68,
  AUTO_RACING: 69,
  LOTTERY: 70,
  SKIING: 71,
  GREYHOUND: 72,
  HORSE_RACING: 73,
  YACHTING: 74,
  BILLIARD: 75,
  FENCING: 76,
  PRO_WRESTLING: 77,
  OSCAR: 78,
  GRAMMY: 79,
  BASKETBALL_3X3: 80,
  TABLE_FOOTBALL: 81,
  MODERN_PENTATHLON: 83,
  TRIATHLON: 84,
  WRESTLING: 85,
  BEACH_HANDBALL: 86,
  HURLING: 87,
  ARMY_GAMES: 88,
  BARE_KNUCKLE_BOXING: 89,
  KABADDI: 90,
  INDY_CAR: 91,
  KICKBOXING: 92,
  SEPAK_TAKRAW: 93,
  E_FOOTBALL: 94,
  E_BASKETBALL: 95,
  E_ICE_HOCKEY: 96,
  E_TENNIS: 97,
  E_VOLLEYBALL: 99,
};

export const SPORTS_WITH_3WAY_MAIN_MARKET = [
  SPORTS.FOOTBALL,
  SPORTS.ICE_HOCKEY,
  SPORTS.BANDY,
  SPORTS.FUTSAL,
  SPORTS.RUGBY_LEAGUE,
  SPORTS.RUGBY_UNION,
  SPORTS.FIELD_HOCKEY,
  SPORTS.BEACH_FOOTBALL,
  SPORTS.ROLLER_HOCKEY,
  SPORTS.FLOORBALL,
  SPORTS.MINI_FOOTBALL,
  SPORTS.JUDO,
  SPORTS.GAELIC_FOOTBALL,
  SPORTS.BEACH_HANDBALL,
  SPORTS.KABADDI,
  SPORTS.E_FOOTBALL,
  SPORTS.E_ICE_HOCKEY,
  SPORTS.BOXING,
  SPORTS.HANDBALL,
  SPORTS.GOLF,
];

export const SPORTS_WITH_MAIN_MARKET_1 = [
  SPORTS.FOOTBALL,
  SPORTS.ICE_HOCKEY,
  SPORTS.BANDY,
  SPORTS.FUTSAL,
  SPORTS.RUGBY_LEAGUE,
  SPORTS.RUGBY_UNION,
  SPORTS.FIELD_HOCKEY,
  SPORTS.BEACH_FOOTBALL,
  SPORTS.ROLLER_HOCKEY,
  SPORTS.FLOORBALL,
  SPORTS.MINI_FOOTBALL,
  SPORTS.JUDO,
  SPORTS.GAELIC_FOOTBALL,
  SPORTS.BEACH_HANDBALL,
  SPORTS.KABADDI,
  SPORTS.E_FOOTBALL,
  SPORTS.E_ICE_HOCKEY,
  SPORTS.BOXING,
  SPORTS.HANDBALL,
  SPORTS.GOLF,
];

export const SPORTS_WITH_MAIN_MARKET_702 = [
  SPORTS.BASKETBALL,
  SPORTS.VOLLEYBALL,
  SPORTS.TENNIS,
  SPORTS.AMERICAN_FOOTBALL,
  SPORTS.BASEBALL,
  SPORTS.AUSSIE_RULES,
  SPORTS.BADMINTON,
  SPORTS.BEACH_VOLLEY,
  SPORTS.BOWLS,
  SPORTS.CRICKET,
  SPORTS.DARTS,
  SPORTS.SNOOKER,
  SPORTS.SQUASH,
  SPORTS.TABLE_TENNIS,
  SPORTS.MMA,
  SPORTS.WATER_POLO,
  SPORTS.CHESS,
  SPORTS.CURLING,
  SPORTS.PADEL_TENNIS,
  SPORTS.NETBALL,
  SPORTS.SOFTBALL,
  SPORTS.E_SPORT,
  SPORTS.SUMO,
  SPORTS.POOL,
  SPORTS.SHOOTING,
  SPORTS.ARCHERY,
  SPORTS.BILLIARD,
  SPORTS.BASKETBALL_3X3,
  SPORTS.TABLE_FOOTBALL,
  SPORTS.WRESTLING,
  SPORTS.HURLING,
  SPORTS.BARE_KNUCKLE_BOXING,
  SPORTS.KICKBOXING,
  SPORTS.SEPAK_TAKRAW,
  SPORTS.WHAT_WHERE_WHEN,
  SPORTS.E_BASKETBALL,
  SPORTS.E_TENNIS,
  SPORTS.E_VOLLEYBALL,
];

export const SPORTS_WITH_MAIN_MARKET_80 = [
  SPORTS.BIATHLON,
  SPORTS.CYCLING,
  SPORTS.SPEEDWAY,
  SPORTS.SKI_RACING,
  SPORTS.SKI_JUMPING,
  SPORTS.SAILING,
  SPORTS.ATHLETICS,
  SPORTS.EQUESTRIAN,
  SPORTS.WEIGHTLIFTING,
  SPORTS.GYMNASTICS,
  SPORTS.TAEKWONDO,
  SPORTS.WATER_SPORTS,
  SPORTS.LACROSSE,
  SPORTS.MOTORCYCLING,
  SPORTS.SURFING,
  SPORTS.RALLY,
  SPORTS.NASCAR,
  SPORTS.CANOEING,
  SPORTS.AUTO_RACING,
  SPORTS.SKIING,
  SPORTS.YACHTING,
  SPORTS.FENCING,
  SPORTS.PRO_WRESTLING,
  SPORTS.MODERN_PENTATHLON,
  SPORTS.TRIATHLON,
  SPORTS.OSCAR,
  SPORTS.GRAMMY,
  SPORTS.OTHERS,
  SPORTS.POLITICS,
  SPORTS.POKER,
  SPORTS.SPECIALS,
  SPORTS.OLYMPIC_GAMES,
];

export const SPORTS_WITH_BG = [
  SPORTS.AMERICAN_FOOTBALL,
  SPORTS.AUSSIE_RULES,
  SPORTS.BADMINTON,
  SPORTS.BASEBALL,
  SPORTS.BASKETBALL,
  SPORTS.BASKETBALL_3X3,
  SPORTS.E_BASKETBALL,
  SPORTS.BEACH_VOLLEY,
  SPORTS.BOWLS,
  SPORTS.CRICKET,
  SPORTS.DARTS,
  SPORTS.FIELD_HOCKEY,
  SPORTS.FUTSAL,
  SPORTS.HANDBALL,
  SPORTS.ICE_HOCKEY,
  SPORTS.E_ICE_HOCKEY,
  SPORTS.POOL,
  SPORTS.RUGBY_LEAGUE,
  SPORTS.RUGBY_UNION,
  SPORTS.SNOOKER,
  SPORTS.FOOTBALL,
  SPORTS.E_FOOTBALL,
  SPORTS.SQUASH,
  SPORTS.TABLE_TENNIS,
  SPORTS.TENNIS,
  SPORTS.E_TENNIS,
  SPORTS.VOLLEYBALL,
  SPORTS.E_VOLLEYBALL,
];

export const TEAM_SPORTS = [
  SPORTS.FOOTBALL,
  SPORTS.ICE_HOCKEY,
  SPORTS.BASKETBALL,
  SPORTS.VOLLEYBALL,
  SPORTS.AMERICAN_FOOTBALL,
  SPORTS.BASEBALL,
  SPORTS.HANDBALL,
  SPORTS.AUSSIE_RULES,
  SPORTS.CRICKET,
  SPORTS.FIELD_HOCKEY,
  SPORTS.BEACH_FOOTBALL,
  SPORTS.CURLING,
  SPORTS.ROLLER_HOCKEY,
  SPORTS.FLOORBALL,
  SPORTS.MINI_FOOTBALL,
  SPORTS.NETBALL,
  SPORTS.SOFTBALL,
  SPORTS.GAELIC_FOOTBALL,
  SPORTS.LACROSSE,
  SPORTS.BASKETBALL_3X3,
  SPORTS.BEACH_HANDBALL,
  SPORTS.HURLING,
  SPORTS.KABADDI,
];

export const INDIVIDUAL_SPORTS = [
  SPORTS.TENNIS,
  SPORTS.BADMINTON,
  SPORTS.BOWLS,
  SPORTS.DARTS,
  SPORTS.SNOOKER,
  SPORTS.SQUASH,
  SPORTS.TABLE_TENNIS,
  SPORTS.BIATHLON,
  SPORTS.BOXING,
  SPORTS.CYCLING,
  SPORTS.FORMULA_1,
  SPORTS.GOLF,
  SPORTS.MMA,
  SPORTS.CHESS,
  SPORTS.SPEEDWAY,
  SPORTS.SKI_RACING,
  SPORTS.SKI_JUMPING,
  SPORTS.SAILING,
  SPORTS.ATHLETICS,
  SPORTS.JUDO,
  SPORTS.EQUESTRIAN,
  SPORTS.WEIGHTLIFTING,
  SPORTS.SUMO,
  SPORTS.GYMNASTICS,
  SPORTS.TAEKWONDO,
  SPORTS.POOL,
  SPORTS.SHOOTING,
  SPORTS.ARCHERY,
  SPORTS.MOTORCYCLING,
  SPORTS.SURFING,
  SPORTS.RALLY,
  SPORTS.NASCAR,
  SPORTS.CANOEING,
  SPORTS.AUTO_RACING,
  SPORTS.SKIING,
  SPORTS.YACHTING,
  SPORTS.BILLIARD,
  SPORTS.FENCING,
  SPORTS.PRO_WRESTLING,
  SPORTS.TABLE_FOOTBALL,
  SPORTS.MODERN_PENTATHLON,
  SPORTS.TRIATHLON,
  SPORTS.WRESTLING,
  SPORTS.BARE_KNUCKLE_BOXING,
  SPORTS.INDY_CAR,
  SPORTS.KICKBOXING,
  SPORTS.SEPAK_TAKRAW,
];

export const SPORTS_WITH_MAIN_TOTAL_AND_HANDICAP = [
  SPORTS.FOOTBALL,
  SPORTS.ICE_HOCKEY,
  SPORTS.BASKETBALL,
  SPORTS.VOLLEYBALL,
  SPORTS.TENNIS,
  SPORTS.AMERICAN_FOOTBALL,
  SPORTS.BASEBALL,
  SPORTS.HANDBALL,
  SPORTS.AUSSIE_RULES,
  SPORTS.BADMINTON,
  SPORTS.BEACH_VOLLEY,
  SPORTS.BOWLS,
  SPORTS.CRICKET,
  SPORTS.DARTS,
  SPORTS.BANDY,
  SPORTS.FUTSAL,
  SPORTS.RUGBY_LEAGUE,
  SPORTS.RUGBY_UNION,
  SPORTS.SNOOKER,
  SPORTS.SQUASH,
  SPORTS.TABLE_TENNIS,
  SPORTS.BOXING,
  SPORTS.MMA,
  SPORTS.FIELD_HOCKEY,
  SPORTS.WATER_POLO,
  SPORTS.CHESS,
  SPORTS.BEACH_FOOTBALL,
  SPORTS.CURLING,
  SPORTS.ROLLER_HOCKEY,
  SPORTS.FLOORBALL,
  SPORTS.PADEL_TENNIS,
  SPORTS.MINI_FOOTBALL,
  SPORTS.NETBALL,
  SPORTS.SOFTBALL,
  SPORTS.GAELIC_FOOTBALL,
  SPORTS.E_SPORT,
  SPORTS.BASKETBALL_3X3,
  SPORTS.TABLE_FOOTBALL,
  SPORTS.BEACH_HANDBALL,
  SPORTS.HURLING,
  SPORTS.KABADDI,
  SPORTS.E_FOOTBALL,
  SPORTS.E_BASKETBALL,
  SPORTS.E_TENNIS,
  SPORTS.E_ICE_HOCKEY,
  SPORTS.E_VOLLEYBALL,
];

export const GAME_STATUSES_WITHOUT_TIME_EN = [
  'Not Started',
  'Halftime',
  'Half-time',
  'Break Time',
  'Finished',
  'No Information',
];

export const GAME_STATUSES_WITHOUT_TIME_TR = [
  'Henüz başlamadı',
  'Devre Arası',
  'Ara',
  'Tamamlandı',
];

export const GAME_STATUSES_WITHOUT_TIME = {
  [LOCALE_KEYS.EN]: GAME_STATUSES_WITHOUT_TIME_EN,
  [LOCALE_KEYS.TR]: GAME_STATUSES_WITHOUT_TIME_TR,
};

export const SPORTS_WITH_TIME_STATUS = [
  SPORTS.FOOTBALL,
  SPORTS.HANDBALL,
  SPORTS.AMERICAN_FOOTBALL,
  SPORTS.AUSSIE_RULES,
  SPORTS.FIELD_HOCKEY,
  SPORTS.FUTSAL,
  SPORTS.RUGBY_LEAGUE,
  SPORTS.RUGBY_UNION,
];

export const SPORTS_WITH_PERIOD_SCORE = [
  SPORTS.FOOTBALL,
  SPORTS.TENNIS,
  SPORTS.ICE_HOCKEY,
  SPORTS.BASKETBALL,
  SPORTS.HANDBALL,
  SPORTS.VOLLEYBALL,
  SPORTS.AMERICAN_FOOTBALL,
  SPORTS.AUSSIE_RULES,
  SPORTS.BADMINTON,
  SPORTS.BEACH_VOLLEY,
  SPORTS.BOWLS,
  SPORTS.FUTSAL,
  SPORTS.RUGBY_LEAGUE,
  SPORTS.RUGBY_UNION,
  SPORTS.SQUASH,
  SPORTS.TABLE_TENNIS,
  SPORTS.E_FOOTBALL,
  SPORTS.E_BASKETBALL,
  SPORTS.E_TENNIS,
  SPORTS.E_VOLLEYBALL,
  SPORTS.E_ICE_HOCKEY,
];

export const SPORTS_WITHOUT_LIVE_STATS = [
  SPORTS.SNOOKER,
  SPORTS.DARTS,
];

export const TOP_SPORTS = [
  SPORTS.FOOTBALL,
  SPORTS.TENNIS,
  SPORTS.BASKETBALL,
  SPORTS.ICE_HOCKEY,
  SPORTS.VOLLEYBALL,
  SPORTS.HANDBALL,
];

export const SPORTS_WITHOUT_LIVE_SCORE = [
  SPORTS.BIATHLON,
  SPORTS.BOXING,
  SPORTS.CYCLING,
  SPORTS.FORMULA_1,
  SPORTS.GOLF,
  SPORTS.MMA,
  SPORTS.SPEEDWAY,
  SPORTS.SKI_RACING,
  SPORTS.SKI_JUMPING,
  SPORTS.SAILING,
  SPORTS.ATHLETICS,
  SPORTS.EQUESTRIAN,
  SPORTS.WEIGHTLIFTING,
  SPORTS.GYMNASTICS,
  SPORTS.TAEKWONDO,
  SPORTS.RALLY,
  SPORTS.NASCAR,
  SPORTS.CANOEING,
  SPORTS.AUTO_RACING,
  SPORTS.SKIING,
  SPORTS.YACHTING,
  SPORTS.TRIATHLON,
  SPORTS.WRESTLING,
  SPORTS.BARE_KNUCKLE_BOXING,
  SPORTS.INDY_CAR,
  SPORTS.KICKBOXING,
  SPORTS.SEPAK_TAKRAW,
  SPORTS.OSCAR,
  SPORTS.GRAMMY,
  SPORTS.WHAT_WHERE_WHEN,
  SPORTS.OTHERS,
  SPORTS.EUROVISION,
  SPORTS.POLITICS,
  SPORTS.LOTTERY,
  SPORTS.POKER,
  SPORTS.ARMY_GAMES,
  SPORTS.SPECIALS,
  SPORTS.OLYMPIC_GAMES,
];

export const ALL_INPLAY_SPORTS = 'allInplaySports';

export const MODAL_IDS = {
  SPORTS: 'sports-modal',
  LEAGUES: 'leagues-modal',
  BET_HISTORY: 'bets-history-modal',
  ADD_ACCUMULATOR: 'add-accumulator-modal',
  RESULTS_DATE: 'results-date-modal',
  RESULTS_SPORTS: 'results-sports-modal',
  RESULTS_LEAGUES: 'results-leagues-modal',
  BETS_FEED: 'bets-feed-modal',
  BETS_FEED_SPORTS: 'bets-feed-sports-modal',
  FREEBETS: 'freebets-modal',
};

export const CURRENCY_SYMBOLS = {
  EUR: '€',
  USD: '$',
  TRY: '₺',
  DZD: 'دج',
};

export const PAGE_NAMES = {
  HOME: 'home',
  SPORT: 'sport',
  LEAGUE: 'league',
  LIVE: 'live',
  EVENT: 'event',
  SEARCH: 'search',
  WATCHLIST: 'watchlist',
  MY_BETS: 'myBets',
  CALENDAR: 'calendar',
  BETS_FEED: 'bets-feed',
  SPORTS: 'sports',
};

export const TODAY = 'today';
export const TOMORROW = 'tomorrow';

export const LIVE_WIDGET_STUBS = {
  NO_STREAM: 'NO_STREAM',
  NO_STATS: 'NO_STATS',
};

export const LIVE_WIDGET_EVENT_TABS = {
  LIVE_STREAM: 'LIVE_STREAM',
  LIVE_STATS: 'LIVE_STATS',
  INFO: 'INFO',
};

export const SCHEDULE_TIME_FILTERS = {
  ALL_DAY: 'all',
  NIGHT: 'night',
  MORNING: 'morning',
  DAY: 'day',
  EVENING: 'evening',
};

export const BETS_FEED_EVENT_STATUSES = {
  LIVE: 'live',
  UPCOMING: 'upcoming',
  FINISHED: 'finished',
  COMPLETED: 'completed',
  RESULTS: 'results',
};
