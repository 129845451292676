import React from 'react';
import PropTypes from 'prop-types';

export const IconUnsupportedCurrency = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="66" height="86" viewBox="0 0 66 86">
    <g fill="none" fillRule="evenodd">
      <path fill="#535457" d="M12.715 41.5C9.78 43.728 6.087 47.248 3.29 52.464c0 0-2.557 4.77-2.779 10.505-.043 1.107 0 2.439.846 3.414.84.969 2.357 1.454 3.685.853.961-.434 2.265-1.637 1.994-3.151-.195-1.09-1.143-1.982-2.356-2.167.202-2.174.758-5.493 2.416-9.126 2.02-4.426 4.731-7.24 6.404-8.731l-.785-2.561M53.285 41.5c2.934 2.228 6.628 5.748 9.425 10.964 0 0 2.557 4.77 2.779 10.505.043 1.107 0 2.439-.846 3.414-.84.969-2.357 1.454-3.685.853-.961-.434-2.265-1.637-1.994-3.151.195-1.09 1.143-1.982 2.356-2.167-.202-2.174-.758-5.493-2.416-9.126-2.021-4.426-4.732-7.24-6.404-8.731l.785-2.561M20.586 59.5l-.877 23.118c-.11-.092-1.645-1.333-3.509-.832-1.597.43-2.745 1.955-2.699 3.714l10.999-.064-1.552-25.424-2.362-.512M45.414 59.5l.877 23.118c.11-.092 1.645-1.333 3.509-.832 1.597.43 2.745 1.955 2.699 3.714L41.5 85.436l1.552-25.424 2.362-.512" />
      <path fill="#414248" d="M64.5 32.5c0 17.674-14.103 32-31.5 32S1.5 50.174 1.5 32.5C1.5 14.827 15.603.5 33 .5s31.5 14.327 31.5 32" />
      <path fill="#292A2E" d="M58.5 32.5c0 13.807-11.417 25-25.5 25S7.5 46.307 7.5 32.5s11.417-25 25.5-25 25.5 11.193 25.5 25" />
      <path fill="#242529" d="M8.829 33.296c0 9.024 5.09 16.914 12.685 21.204C12.65 50.596 6.5 42.048 6.5 32.13 6.5 18.527 18.07 7.5 32.343 7.5c4.804 0 9.303 1.25 13.157 3.427-3.294-1.451-6.963-2.26-10.83-2.26-14.272 0-25.841 11.026-25.841 24.63" />
      <path fill="#535457" d="M43.5 26.5c0 1.105-.895 2-2 2s-2-.895-2-2 .895-2 2-2 2 .895 2 2M24.5 26.5c0 1.105-.895 2-2 2s-2-.895-2-2 .895-2 2-2 2 .895 2 2M32.838 40.5c-.626 0-1.267.023-1.923.072-3.114.233-6.127 1.02-8.956 2.343-.411.192-.577.657-.37 1.04.207.381.707.535 1.118.343 2.637-1.231 5.443-1.966 8.342-2.183 5.277-.394 9.57 1.033 12.24 2.3.409.195.911.043 1.12-.337.209-.381.046-.848-.363-1.042-2.532-1.2-6.423-2.536-11.208-2.536" />
    </g>
  </svg>
);

IconUnsupportedCurrency.propTypes = {
  className: PropTypes.string,
};

IconUnsupportedCurrency.defaultProps = {
  className: null,
};
