import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import Input from 'reactstrap/lib/Input';
import { withLocale, withSearchedGames } from 'core/hocs';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { IconSearch } from 'components/icons/icon-search/icon-search';
import { IconSearchBold } from 'components/icons/icon-search-bold/icon-search-bold';
import { IconClose } from 'components/icons/icon-close/icon-close';
import { GA } from 'helpers/ga';

import './searched-games-input.scss';

class SearchedGamesInputUI extends Component {
  static propTypes = {
    searchValue: PropTypes.string.isRequired,
    setSearchValue: PropTypes.func.isRequired,
    getSearchedGames: PropTypes.func.isRequired,
    setSearchedGames: PropTypes.func.isRequired,
    setSearchOpen: PropTypes.func,
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    history: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    setSearchOpen: null,
    className: null,
    isMobile: false,
  };

  state = {
    value: this.props.searchValue,
  };

  onChangeSubject;

  onChangeSubsription;

  inputRef = createRef();

  componentDidMount() {
    const { isMobile } = this.props;
    this.onChangeSubject = new Subject();
    this.onChangeSubsription = this.onChangeSubject
      .pipe(debounceTime(500))
      .subscribe(this.onChange);

    if (isMobile) {
      this.focusInput();
    }
  }

  componentWillUnmount() {
    if (this.onChangeSubsription) {
      this.onChangeSubsription.unsubscribe();
    }
  }

  setValue = (e) => {
    const { value } = e.target;

    this.setState({ value });
    this.onChangeSubject.next(value);
  }

  clearValue = () => {
    const { setSearchedGames, setSearchOpen } = this.props;

    if (setSearchOpen) {
      setSearchOpen(false);
    }

    this.setState({ value: '' });
    this.onChangeSubject.next('');
    setSearchedGames(null);
  }

  onChange = (value) => {
    const {
      setSearchValue,
      getSearchedGames,
      setSearchedGames,
      setSearchOpen,
    } = this.props;
    const trimmedValue = value.trim();

    setSearchValue(trimmedValue);

    if (setSearchOpen) {
      setSearchOpen(!!trimmedValue.length);
    }

    if (trimmedValue.length) {
      getSearchedGames(value);
      GA.event({
        category: 'search',
        label: 'input-phrase',
      });
    } else {
      setSearchedGames(null);
    }
  };

  onFocus = () => {
    const { value } = this.state;
    const { setSearchOpen, isMobile } = this.props;

    if (setSearchOpen && value.length) {
      setSearchOpen(true);
    }

    if (!isMobile) {
      GA.event({
        category: 'search',
        label: 'start',
      });
    }
  };

  focusInput = () => {
    if (this.inputRef) {
      this.inputRef.current.focus();
    }
  };

  onCancelClick = () => {
    const { history } = this.props;

    history.goBack();
  };

  render() {
    const { isMobile, className } = this.props;
    const { value } = this.state;

    return (
      <div className={classNames('d-flex', className)}>
        <div className="position-relative w-100">
          <FormattedMessage id="general.search">
            {text => (
              <Input
                innerRef={this.inputRef}
                type="text"
                value={value}
                onChange={this.setValue}
                onFocus={this.onFocus}
                placeholder={text}
                className="searched-games-input py-2 py-lg-1 pl-6_5 pl-lg-6 pr-4_5 pr-lg-3_5"
              />
            )}
          </FormattedMessage>

          <div
            role="button"
            tabIndex="0"
            onClick={this.focusInput}
            onKeyPress={this.focusInput}
            className="searched-games-icon-search position-absolute"
          >
            {isMobile ? <IconSearchBold /> : <IconSearch />}
          </div>

          {!!value.length && (
            <div
              role="button"
              tabIndex="0"
              onClick={this.clearValue}
              onKeyPress={this.clearValue}
              className="searched-games-icon-close position-absolute d-flex justify-content-center align-items-center"
            >
              <IconClose />
            </div>
          )}
        </div>

        {isMobile && (
          <div
            role="button"
            tabIndex="0"
            onClick={this.onCancelClick}
            onKeyPress={this.onCancelClick}
            className="searched-games-cancel d-flex align-items-center pr-2 bg-main-2"
          >
            <FormattedTag id="general.cancel" className="text-extra-2" />
          </div>
        )}
      </div>
    );
  }
}

export const SearchedGamesInput = withRouter(withLocale(withSearchedGames(SearchedGamesInputUI)));
