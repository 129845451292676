import React from 'react';
import PropTypes from 'prop-types';

import { ModalWindow } from 'components/modal-window/modal-window';
import { BetsHistory } from './bets-history/bets-history';
import { MODAL_IDS } from '../../../constants';

export const BetsHistoryModal = ({ getCurrentModalOpened }) => {
  const isModalOpen = getCurrentModalOpened(MODAL_IDS.BET_HISTORY);

  return (
    <ModalWindow
      id={MODAL_IDS.BET_HISTORY}
      coreProps={{ id: MODAL_IDS.BET_HISTORY }}
      modalClassName="bets-history-modal"
      bodyClassName="px-3 py-2"
      fade
      centered
      size="lg"
    >
      {isModalOpen && <BetsHistory />}
    </ModalWindow>
  );
};

BetsHistoryModal.propTypes = {
  getCurrentModalOpened: PropTypes.func.isRequired,
};
