import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { STAKE_TYPES_WITH_3WAY_MAIN_MARKET } from 'core/constants';
import { isEmptyOrNil } from 'core/helpers';

import { GameOutcomeDropdown } from 'components/betting-table/game-outcome-dropdown/game-outcome-dropdown';
import { OddsCell } from 'components/betting-table/odds-cell/odds-cell';
import { setEmptyStakes } from 'helpers/stake';

import './searched-games-outcome-group.scss';

export class SearchedGamesOutcomeGroup extends PureComponent {
  static propTypes = {
    marketStake: PropTypes.shape().isRequired,
    stakeTypeName: PropTypes.string.isRequired,
    stakeTypeId: PropTypes.number.isRequired,
    isTotal: PropTypes.bool.isRequired,
    isHandicap: PropTypes.bool.isRequired,
    oddProps: PropTypes.shape().isRequired,
    gameOutcomeFiltersRef: PropTypes.node.isRequired,
    isLastMarket: PropTypes.bool.isRequired,
    isFirstMarket: PropTypes.bool.isRequired,
    stakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  render() {
    const {
      stakeTypeId,
      stakeTypeName,
      isTotal,
      isHandicap,
      stakes,
      oddProps,
      gameOutcomeFiltersRef,
      isLastMarket,
      isFirstMarket,
      marketStake,
    } = this.props;

    const isOutcomesDropdown = (isHandicap || isTotal) && Object.keys(stakes).length > 1;
    const is3way = STAKE_TYPES_WITH_3WAY_MAIN_MARKET.includes(stakeTypeId);
    const marketStakeWithEmptyValue = setEmptyStakes(marketStake, is3way);

    return (
      <div className={classNames('searched-games-outcome-group d-flex flex-column flex-shrink-0 position-relative', {
        'with-draw': is3way,
        'with-dropdown': isOutcomesDropdown,
        'mr-1': !isOutcomesDropdown && !isLastMarket
      })}
      >
        <div className="market-name overflow-hidden caption position-absolute text-center">
          <span className="text-nowrap text-extra-3">
            {stakeTypeName}
          </span>
        </div>
        <div className="d-flex align-items-center flex-shrink-0 mt-3">
          <div className="searched-games-outcome-group-odds-group">
            {marketStakeWithEmptyValue.map(stake =>
              !isEmptyOrNil(stake) ? (
                <OddsCell
                  key={stake.stakeId}
                  stake={stake}
                  isHandicap={isHandicap}
                  isTotal={isTotal}
                  className="searched-games-outcome-group-odds"
                  oddsType="secondary"
                  {...oddProps}
                />
              ) : (
                <div className="odd-cell is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                  &#8212;
                </div>
              ))}
          </div>
          {isOutcomesDropdown && (
            <GameOutcomeDropdown
              gameOutcomeFiltersRef={gameOutcomeFiltersRef}
              className="ml-0_25 mr-0_5"
              classNameMenu={classNames('searched-games-outcome-group-dropdown-menu', { 'is-first': isFirstMarket })}
              stakes={stakes}
              isTotal={isTotal}
              isHandicap={isHandicap}
              oddProps={oddProps}
            />
          )}
        </div>
      </div>
    );
  }
}
