import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportOlympicGames = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M16.443 10.138c-.528-.978-1.455-1.71-2.574-1.943-.019-.12-.036-.238-.036-.362 0-1.286 1.047-2.333 2.334-2.333 1.286 0 2.333 1.047 2.333 2.333 0 1.192-.902 2.166-2.057 2.305m-3.36 4.14c-1.286 0-2.333-1.047-2.333-2.333 0-.124.017-.243.036-.362.94-.196 1.747-.745 2.297-1.494.55.749 1.356 1.298 2.298 1.494.018.119.036.238.036.362 0 1.286-1.047 2.333-2.334 2.333m-6.166 0c-1.287 0-2.334-1.047-2.334-2.333 0-.124.017-.243.036-.362.941-.196 1.748-.745 2.298-1.494.55.749 1.356 1.298 2.297 1.494.018.119.036.238.036.362 0 1.286-1.047 2.333-2.333 2.333M1.5 7.833C1.5 6.547 2.547 5.5 3.833 5.5c1.287 0 2.334 1.047 2.334 2.333 0 .124-.018.242-.037.362-1.119.234-2.045.965-2.574 1.943C2.401 9.998 1.5 9.025 1.5 7.833M10 5.5c1.286 0 2.333 1.047 2.333 2.333 0 .124-.018.242-.036.362-.94.196-1.748.745-2.297 1.494-.55-.75-1.357-1.298-2.297-1.494-.019-.12-.036-.238-.036-.362C7.667 6.547 8.714 5.5 10 5.5m10 2.333C20 5.72 18.28 4 16.167 4c-1.269 0-2.386.626-3.084 1.577C12.384 4.626 11.267 4 10 4c-1.268 0-2.385.626-3.083 1.577C6.218 4.626 5.101 4 3.833 4 1.72 4 0 5.72 0 7.833c0 1.868 1.345 3.425 3.118 3.76-.01.118-.035.232-.035.352 0 2.113 1.72 3.833 3.834 3.833 1.267 0 2.384-.626 3.083-1.578.698.952 1.815 1.578 3.083 1.578 2.114 0 3.834-1.72 3.834-3.833 0-.12-.025-.234-.036-.351 1.773-.336 3.12-1.893 3.12-3.761" />
  </svg>
);

IconSportOlympicGames.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportOlympicGames.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
