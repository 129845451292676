import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportFloorball = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M14.8 6.8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2m0 10c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2m-5-5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2m-5-5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2m0 10c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2m13-7c0-1.03.79-1.89 1.8-1.99C18.81 3.88 15.72.79 11.79 0c-.1 1.01-.96 1.8-1.99 1.8S7.91 1.01 7.81 0C3.88.79.79 3.88 0 7.81c1.01.1 1.8.96 1.8 1.99s-.79 1.89-1.8 1.99c.79 3.93 3.88 7.02 7.81 7.81.1-1.01.96-1.8 1.99-1.8s1.89.79 1.99 1.8c3.93-.79 7.02-3.88 7.81-7.81-1.01-.1-1.8-.96-1.8-1.99" />
  </svg>
);

IconSportFloorball.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportFloorball.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
