import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withLocale, withModalActions } from 'core/hocs';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { prepareFavoriteSports, prepareFavoriteLeagues } from 'helpers/favorites';
import { SportItem } from './sport-item/sport-item';
import { SportsPreloader } from './sports-preloader/sports-preloader';
import { FavoriteSportItem } from './favorite-sport-item/favorite-sport-item';
import { FavoriteLeagueItem } from './favorite-league-item/favorite-league-item';
import { TopLeagueItem } from './top-league-item/top-league-item';
import { PAGE_NAMES } from '../../../constants';

import './sports.scss';

class SportsUI extends Component {
  static propTypes = {
    isUserLoggedIn: PropTypes.bool.isRequired,
    pageName: PropTypes.string.isRequired,
    sports: PropTypes.arrayOf(PropTypes.shape()),
    topLeagues: PropTypes.arrayOf(PropTypes.shape()),
    isSportsInProgress: PropTypes.bool.isRequired,
    params: PropTypes.shape({
      sportId: PropTypes.string,
      leagueId: PropTypes.string,
    }),
    locale: PropTypes.string.isRequired,
    isAllSportsModal: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    activeSportId: PropTypes.number,
    activeLeagueId: PropTypes.number,
    activeCountries: PropTypes.shape(),
    setActiveSportId: PropTypes.func,
    setActiveCountries: PropTypes.func,
    setActiveLeagueId: PropTypes.func,
    clearActiveLeagueId: PropTypes.func,
    clearActiveCountries: PropTypes.func,
    clearActiveCountriesBySport: PropTypes.func,
    clearActiveCountriesExceptSport: PropTypes.func,
    clearActiveCountriesExceptSportAndCountry: PropTypes.func,
    favoriteSports: PropTypes.arrayOf(PropTypes.shape()),
    favoriteLeagues: PropTypes.arrayOf(PropTypes.shape()),
    isFavoritesInProgress: PropTypes.bool.isRequired,
    onFavoriteLeagueClick: PropTypes.func,
    sendSportClickFeedback: PropTypes.func.isRequired,
    sendLeagueClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    sports: null,
    topLeagues: null,
    params: {},
    isAllSportsModal: false,
    activeSportId: null,
    activeLeagueId: null,
    activeCountries: {},
    favoriteSports: null,
    favoriteLeagues: null,
    setActiveSportId: () => {},
    setActiveCountries: () => {},
    setActiveLeagueId: () => {},
    clearActiveLeagueId: () => {},
    clearActiveCountries: () => {},
    clearActiveCountriesBySport: () => {},
    clearActiveCountriesExceptSport: () => {},
    clearActiveCountriesExceptSportAndCountry: () => {},
    onFavoriteLeagueClick: () => {},
  };

  state = {};

  render() {
    const {
      isUserLoggedIn,
      params,
      pageName,
      locale,
      sports,
      topLeagues,
      isSportsInProgress,
      isAllSportsModal,
      closeModal,
      activeSportId,
      activeLeagueId,
      activeCountries,
      setActiveSportId,
      setActiveCountries,
      setActiveLeagueId,
      clearActiveLeagueId,
      clearActiveCountries,
      clearActiveCountriesBySport,
      clearActiveCountriesExceptSport,
      clearActiveCountriesExceptSportAndCountry,
      favoriteSports,
      favoriteLeagues,
      isFavoritesInProgress,
      onFavoriteLeagueClick,
      sendSportClickFeedback,
      sendLeagueClickFeedback,
    } = this.props;
    const preparedFavoriteSports = prepareFavoriteSports({
      isUserLoggedIn,
      favoriteSports,
      sports: sports || [],
      isFavoritesInProgress,
    });
    const preparedFavoriteLeagues = prepareFavoriteLeagues({
      isUserLoggedIn,
      favoriteLeagues,
      sports: sports || [],
      isFavoritesInProgress,
    });

    return isSportsInProgress || isFavoritesInProgress
      ? <SportsPreloader />
      : (
        <div className="sports">
          {((preparedFavoriteSports ? preparedFavoriteSports.length : 0)
          + (preparedFavoriteLeagues ? preparedFavoriteLeagues.length : 0)) > 0
            && (
              <Fragment>
                <FormattedTag
                  tag="div"
                  className={classNames('text-uppercase pt-2_5 pl-lg-2_5 label text-extra-3', {
                    'pb-1 pl-2 pl-sm-2_5': isAllSportsModal,
                    'pb-1_5': !isAllSportsModal,
                  })}
                  id="general.favorites"
                />
                {preparedFavoriteSports && preparedFavoriteSports.map(favoriteSport => (
                  <FavoriteSportItem
                    locale={locale}
                    key={favoriteSport.sportId}
                    {...favoriteSport}
                    isActive={pageName === PAGE_NAMES.SPORT && favoriteSport.sportId === (isAllSportsModal
                      ? Number(params.sportId)
                      : activeSportId)}
                    setActiveSportId={setActiveSportId}
                    isAllSportsModal={isAllSportsModal}
                    closeModal={closeModal}
                    sendSportClickFeedback={sendSportClickFeedback}
                  />
                ))}

                {preparedFavoriteLeagues && preparedFavoriteLeagues.map(favoriteLeague => (
                  <FavoriteLeagueItem
                    key={favoriteLeague}
                    {...favoriteLeague}
                    locale={locale}
                    isActive={isAllSportsModal
                      ? favoriteLeague.leagueId === Number(params.leagueId)
                      : activeLeagueId === favoriteLeague.leagueId}
                    onFavoriteLeagueClick={onFavoriteLeagueClick}
                    isAllSportsModal={isAllSportsModal}
                    closeModal={closeModal}
                    sendLeagueClickFeedback={sendLeagueClickFeedback}
                  />
                ))}
                <div className="sports-divider mt-1" />
              </Fragment>
            )}

          {topLeagues && !!topLeagues.length && (
            <Fragment>
              <FormattedTag
                tag="div"
                className={classNames('text-uppercase pt-2_5 pl-lg-2_5 label text-extra-3', {
                  'pb-1 pl-2 pl-sm-2_5': isAllSportsModal,
                  'pb-1_5': !isAllSportsModal,
                })}
                id="general.top-leagues"
              />

              {topLeagues.map(topLeague => (
                <TopLeagueItem
                  key={topLeague.leagueId}
                  {...topLeague}
                  locale={locale}
                  isActive={isAllSportsModal
                    ? topLeague.leagueId === Number(params.leagueId)
                    : activeLeagueId === topLeague.leagueId}
                  isAllSportsModal={isAllSportsModal}
                  closeModal={closeModal}
                  onFavoriteLeagueClick={onFavoriteLeagueClick}
                  sendLeagueClickFeedback={sendLeagueClickFeedback}
                />
              ))}
              <div className="sports-divider mt-1" />
            </Fragment>
          )}

          {sports && !!sports.length && (
            <Fragment>
              <FormattedTag
                tag="div"
                className={classNames('text-uppercase pt-2_5 pl-lg-2_5 label text-extra-3', {
                  'pb-1 pl-2 pl-sm-2_5': isAllSportsModal,
                  'pb-1_5': !isAllSportsModal,
                })}
                id="general.all-sports"
              />

              {sports.map(({
                sportId,
                sportName,
                eventsCount,
                inplayEventsCount,
                countries,
                topLeagues: sportTopLeagues,
              }, idx) => (
                <SportItem
                  key={sportId}
                  idx={idx}
                  params={params}
                  locale={locale}
                  sportId={sportId}
                  sportName={sportName}
                  eventsCount={eventsCount}
                  inplayEventsCount={inplayEventsCount}
                  countries={countries}
                  topLeagues={sportTopLeagues}
                  isOpen={activeSportId === sportId}
                  isActive={isAllSportsModal
                    ? pageName === PAGE_NAMES.SPORT && sportId === Number(params.sportId)
                    : activeSportId === sportId}
                  setActiveSportId={setActiveSportId}
                  activeLeagueId={activeLeagueId}
                  activeCountries={activeCountries[sportId] || []}
                  setActiveCountries={setActiveCountries}
                  setActiveLeagueId={setActiveLeagueId}
                  clearActiveLeagueId={clearActiveLeagueId}
                  clearActiveCountries={clearActiveCountries}
                  clearActiveCountriesBySport={clearActiveCountriesBySport}
                  clearActiveCountriesExceptSport={clearActiveCountriesExceptSport}
                  clearActiveCountriesExceptSportAndCountry={clearActiveCountriesExceptSportAndCountry}
                  isAllSportsModal={isAllSportsModal}
                  closeModal={closeModal}
                  sendSportClickFeedback={sendSportClickFeedback}
                  sendLeagueClickFeedback={sendLeagueClickFeedback}
                />
              ))}
            </Fragment>
          )}
        </div>
      );
  }
}

export const Sports = withLocale(withModalActions(SportsUI));
