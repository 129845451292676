import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportGrammy = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M5.811 6.29C7.415 3.304 8.032.516 7.188.062c-.844-.454-2.829 1.6-4.434 4.583C1.15 7.633.534 10.42 1.377 10.873c.845.454 2.83-1.6 4.434-4.583zm11.491 5.5c-.229-.18-.49-.34-.62-.46l-.489-.44c-.33-.28-.64-.6-.95-.9-.61-.65-1.2-1.33-1.78-2.05-1.15-1.42-2.32-2.81-3.45-4.27-.36-.46-.73-.92-1.08-1.39-.01.09-.03.18-.05.27-.29 1.31-.91 2.89-1.75 4.45-.43.79-1.54 2.72-2.89 4.05.56.03 1.12.09 1.67.14 1.84.16 3.64.4 5.46.59.91.1 1.81.22 2.68.39.43.1.86.19 1.28.31.38.11.94.23 1.08.38.34.4.46.93.18 1.36-.12.21-.34.38-.58.46-.13.04-.26.07-.39.07h-.62v1.5h.64c.29 0 .58-.06.86-.15.55-.19 1.05-.57 1.36-1.08.68-1.01.44-2.56-.56-3.23zm-4.8 2.21h-6c-.552 0-1 .447-1 1v2h8v-2c0-.553-.447-1-1-1zm2 4.5l2 1.5h-14l2-1.5h10z" />
  </svg>
);

IconSportGrammy.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportGrammy.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
