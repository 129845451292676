import React from 'react';
import PropTypes from 'prop-types';

export const IconCalendar = ({ className, color }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3 2C1.89543 2 1 2.89543 1 4V16C1 17.1046 1.89543 18 3 18H17C18.1046 18 19 17.1046 19 16V4C19 2.89543 18.1046 2 17 2H3ZM3 5C2.44772 5 2 5.44772 2 6V16C2 16.5523 2.44772 17 3 17H17C17.5523 17 18 16.5523 18 16V6C18 5.44772 17.5523 5 17 5H3Z" fill={color} />
    <circle cx="6" cy="9" r="1" fill={color} />
    <circle cx="6" cy="13" r="1" fill={color} />
    <circle cx="10" cy="9" r="1" fill={color} />
    <circle cx="10" cy="13" r="1" fill={color} />
    <circle cx="14" cy="9" r="1" fill={color} />
    <circle cx="14" cy="13" r="1" fill={color} />
  </svg>
);

IconCalendar.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconCalendar.defaultProps = {
  className: null,
  color: '#FFFFFF',
};
