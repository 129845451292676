import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportFencing = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M17.95 16.89l1.24-1.228c.24-.25.24-.641 0-.891-.59-.59-1.38-.91-2.21-.91-.33 0-.65.06-.95.16l-3.31-3.06-1.7 1.83 3 3.24c-.1.31-.16.62-.16.95 0 .84.32 1.62.91 2.21.12.12.28.19.44.19.16 0 .32-.07.44-.19l1.24-1.24 1.83 1.83c.15.15.34.22.53.22s.38-.07.53-.22c.29-.29.29-.77 0-1.06l-1.83-1.83zm-11.968-.857c.098.305.16.623.16.95 0 .835-.322 1.62-.913 2.21-.123.122-.283.183-.443.183-.16 0-.32-.061-.44-.183l-1.24-1.238-1.825 1.827c-.146.146-.338.219-.53.219-.193 0-.384-.073-.53-.22-.293-.292-.293-.767 0-1.06l1.826-1.826-1.238-1.237c-.244-.244-.244-.64 0-.884.59-.59 1.376-.915 2.21-.915.329 0 .646.064.952.162l4.414-4.083L.17 1.05C-.06.8-.05.42.18.18c.24-.24.62-.24.87-.01L10 8.445 18.95.167c.246-.227.629-.22.867.018.238.237.245.62.018.866L5.982 16.033z" />
  </svg>
);

IconSportFencing.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportFencing.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
