import * as Sentry from '@sentry/browser';
import { initClient } from 'core/client';
import { getEnv } from 'core/helpers';
import { LOCAL } from 'core/constants';
import routesConfig from '../routes';

const projectEnv = getEnv('PROJECT_ENV');

if (projectEnv !== LOCAL) {
  Sentry.init({
    dsn: getEnv('SENTRY_DSN'),
    environment: projectEnv,
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured'
    ],
  });
  Sentry.setTag('SSR', 'false');
}

initClient(routesConfig);
