import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportSurfing = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M20.003.541c.03-.31-.23-.56-.53-.54-2.33.2-8.57 1.17-13.6 6.24h7.12c.41 0 .75.34.75.75v7.16c5.08-5.04 6.06-11.29 6.26-13.61m-16.6 8.7h6.59c.41 0 .75.34.75.75v6.61c.5-.34 1.01-.71 1.5-1.12v-7.74h-7.71c-.41.5-.79 1-1.13 1.5m-.93 1.5h6.77v6.8c-2.42 1.39-4.69 2.12-6.12 2.45-.29.06-.57-.14-.6-.44l-.21-1.86-1.86-.21c-.3-.03-.51-.31-.44-.61.33-1.43 1.06-3.71 2.46-6.13" />
  </svg>
);

IconSportSurfing.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportSurfing.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
