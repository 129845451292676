import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportBasketball = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport3Bg : colors.cpExtra4} d="M19.939 9.22c-.16-2.23-1.06-4.26-2.45-5.84-1.539 1.52-2.55 3.57-2.73 5.84h5.18zm-2.45 7.34c1.39-1.58 2.29-3.61 2.45-5.84h-5.18c.18 2.27 1.191 4.32 2.73 5.84zm-1.06-14.23C14.87 1.01 12.889.16 10.719 0v9.22h2.54c.18-2.69 1.361-5.11 3.17-6.89zm-5.71 8.39v9.22c2.17-.16 4.151-1.01 5.71-2.33-1.809-1.78-2.99-4.2-3.17-6.89h-2.54zm-7.21 6.89c1.56 1.32 3.54 2.17 5.71 2.33v-9.22h-2.54c-.179 2.69-1.36 5.11-3.17 6.89zm3.17-8.39h2.54V0c-2.17.16-4.15 1.01-5.71 2.33 1.81 1.78 2.991 4.2 3.17 6.89zM2.45 3.38C1.059 4.96.16 6.99 0 9.22h5.179C5 6.95 3.989 4.9 2.45 3.38zm2.729 7.34C5 12.99 3.989 15.04 2.45 16.56 1.059 14.98.16 12.95 0 10.72h5.179z" />
  </svg>
);

IconSportBasketball.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportBasketball.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
