import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportEurovision = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M16.834.47L15.355.08c-1.221-.33-2.481.4-2.811 1.62l-.13.47 5.92 1.57.12-.46c.33-1.22-.4-2.48-1.62-2.81zm-5.08 4.18c-.21.8.03 1.61.55 2.17l-.24 1.28c.2.24.35.5.47.75h3.21l.361-1.02c.739-.22 1.349-.8 1.559-1.59l.28-1.05-5.91-1.58-.28 1.04zm4.624 8.458s.432.21.422 1.066c-.002.126-.015.25-.044.364-.107.423-.398.768-.867 1.027.079.195.168.486.168.81 0 .193-.032.375-.095.543-.161.431-.514.757-1.049.97-.056.336-.229 2.02-2.293 2.097-.995.037-2.898.055-5.62-1.06-1.2-.493-2.641-1.322-5-1.322V11.81s1.378.1 2.516-.283c.925-.312 2.446-1.994 3.79-2.796.595-.356 1.384-.702 2.332.05.531.421.716 1.163.899 1.567h4.164c.833-.007 1.52.262 1.53 1.252.011.991-.853 1.51-.853 1.51z" />
  </svg>
);

IconSportEurovision.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportEurovision.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
