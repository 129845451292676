import React from 'react';
import PropTypes from 'prop-types';

export const IconDeposit = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="64" height="63" viewBox="0 0 64 63">
    <g fill="#FFF" fillRule="evenodd">
      <path fillOpacity=".12" d="M55.915 18c2.902.23 4.968 1.816 5.08 4.663l.005.247v4.669c0 .745-.575 1.356-1.312 1.425l-.14.007H49.38c-6.407 0-11.35 4.825-11.35 11.14 0 6.22 4.792 11.691 11.055 11.858l.295.004h10.167c.755 0 1.375.567 1.445 1.294l.007.138v3.828c0 3.085-2.486 5.607-5.587 5.723L55.19 63H5.81C2.68 63 .122 60.55.004 57.492L0 57.272V22.91c0-3.08 2.404-4.822 5.494-4.907L5.733 18h50.182z" />
      <path fillOpacity=".12" d="M59.688 32c2.308 0 4.198 1.796 4.307 4.044l.005.206v8.5c0 2.275-1.822 4.137-4.104 4.245l-.208.005H49.625C44.869 49 41 45.187 41 40.5c0-4.602 3.73-8.361 8.366-8.496l.259-.004h10.063zM49.5 37.5c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3z" />
      <path fillOpacity=".08" d="M47.792 12L38 18H8.755L37.425.771c1.331-.763 2.885-.969 4.372-.577 1.406.371 2.594 1.234 3.373 2.442l.142.23L53.915 18H51l-3.208-6zM39.31 3.293l-.18.099L15 18h5.688L43 4.46c-.381-.677-.995-1.162-1.73-1.362-.589-.16-1.198-.123-1.755.102l-.205.093z" />
    </g>
  </svg>
);

IconDeposit.propTypes = {
  className: PropTypes.string,
};

IconDeposit.defaultProps = {
  className: null,
};
