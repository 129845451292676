import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportBareKnuckleBoxing = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M13.44 11.274c-.535-.106-1.047-.365-1.461-.78-.518-.518-.795-1.187-.831-1.866-.632.042-1.276-.125-1.818-.5-.287.712-.715 1.368-1.261 1.917-.266.267-.703.278-.967.01-.258-.26-.26-.68-.004-.941.689-.708 1.162-1.597 1.272-2.578l.12-1.085 1.376 1.376c.599.599 1.594.61 2.18 0 .572-.593.565-1.535-.02-2.12L9.347 2.03c-.59-.591-1.548-.591-2.146.008L4.1 5.136c-.363.363-.665.785-.89 1.247-.218.453-.363.94-.425 1.44-.063.5-.042 1.012.06 1.506.119.57.37 1.077.664 1.577.217.367.459.72.716 1.06.325.433.676.845 1.048 1.238.17.179.349.35.524.527 2.079 1.994 3.382 2.777 4.249 2.99.956.236 2.234-.107 3.02-.894l2.494-2.493c-.505-.117-.984-.371-1.377-.763-.372-.372-.62-.821-.743-1.297zm5.815-3.776c-.59-.591-1.548-.591-2.14 0l.635-.634c.59-.591.59-1.55 0-2.141-.591-.591-1.55-.591-2.14 0l.634-.634c.59-.59.59-1.548 0-2.14-.591-.591-1.55-.591-2.14 0l-.635.635c.59-.591.59-1.55 0-2.141-.591-.591-1.55-.591-2.14 0l-.832.833 2.48 2.48c.784.785 1.017 1.914.698 2.902-.134.418-.366.81-.697 1.14-.143.144-.298.269-.461.374-.097.48.04.998.413 1.37.59.592 1.549.592 2.14.002l.064-.064c-.591.59-.591 1.549 0 2.14.59.59 1.549.59 2.14 0l1.98-1.982c.593-.591.593-1.55 0-2.14zM4.758 14.812c1.51 1.45 2.73 2.387 3.8 2.927l-2.07 2.05c-.26.26-.67.28-.91.04l-5.41-5.427c-.24-.23-.22-.64.04-.9l1.95-1.93c.02.03.04.07.06.1.23.39.5.79.81 1.2.35.46.74.92 1.16 1.37l.4.4.17.17z" />
  </svg>
);

IconSportBareKnuckleBoxing.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportBareKnuckleBoxing.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
