import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportTennis = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport4Bg : colors.cpExtra4} d="M10.62 0c-.013 2.39.892 4.794 2.709 6.611 1.817 1.818 4.222 2.723 6.61 2.709-.147-2.341-1.108-4.632-2.897-6.421C15.252 1.11 12.962.148 10.62 0M9.129.007c-2.27.191-4.49 1.153-6.23 2.892-1.74 1.74-2.701 3.96-2.891 6.23 2.764-.022 5.55 1.025 7.665 3.139 2.114 2.114 3.16 4.9 3.139 7.665 2.27-.19 4.49-1.152 6.23-2.891 1.739-1.74 2.7-3.96 2.892-6.23-2.765.021-5.551-1.026-7.666-3.14-2.114-2.114-3.16-4.9-3.14-7.665M6.613 13.329C8.43 15.146 9.334 17.55 9.32 19.94c-2.341-.148-4.632-1.109-6.421-2.898-1.79-1.79-2.75-4.081-2.9-6.421 2.392-.015 4.796.89 6.613 2.708" />
  </svg>
);

IconSportTennis.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportTennis.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
