import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportEbasketball = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M0 9.68c.14 2.01.96 3.84 2.21 5.27 1.39-1.37 2.3-3.22 2.47-5.27H0zm2.21-6.63C.96 4.48.14 6.31 0 8.32h4.68c-.17-2.05-1.08-3.9-2.47-5.27zm.96-.95c1.63 1.61 2.7 3.79 2.86 6.22h2.29V0C6.36.14 4.58.91 3.17 2.1zm2.86 7.58c-.16 2.43-1.23 4.61-2.86 6.22.94.8 2.05 1.4 3.269 1.76-.039-1.29.171-3.2 1.281-5.02.18-.3.38-.57.6-.8V9.68H6.03zm5.94 0H9.68v1.14c.47-.21.98-.32 1.509-.32.311 0 .611.04.881.1-.04-.3-.08-.61-.1-.92zM9.68 0v8.32h2.29c.16-2.43 1.23-4.61 2.86-6.22C13.42.91 11.64.14 9.68 0zm3.64 9.68c.04.52.14 1.03.27 1.53.07.04.14.09.19.12h.36c.34-.23 1.34-.83 2.59-.83.4 0 .78.06 1.14.18.06-.33.11-.66.13-1h-4.68zm2.47-6.63c-1.39 1.37-2.3 3.22-2.47 5.27H18c-.14-2.01-.96-3.84-2.21-5.27zm2.111 12.478c-.332 0-.604-.273-.604-.606 0-.332.272-.604.604-.604.333 0 .605.272.605.604 0 .333-.272.606-.605.606zm-1.511-.908c-.332 0-.605-.272-.605-.604 0-.333.273-.606.605-.606.333 0 .604.273.604.606 0 .332-.271.604-.604.604zm0 1.814c-.332 0-.605-.272-.605-.605 0-.332.273-.604.605-.604.333 0 .604.272.604.604 0 .333-.271.605-.604.605zm-4.536-.756h-.453v.454c0 .248-.206.453-.454.453-.248 0-.453-.205-.453-.453v-.454h-.454c-.247 0-.453-.205-.453-.453 0-.248.206-.453.453-.453h.454v-.454c0-.248.205-.453.453-.453.248 0 .454.205.454.453v.454h.453c.249 0 .454.205.454.453 0 .248-.205.453-.454.453zm7.063-2.261c-1.596-2.625-3.997-.8-3.997-.8-.157.117-.441.213-.641.213h-.64c-.194.006-.485-.096-.635-.212 0 0-2.407-1.826-4.004.799-1.596 2.618-.949 5.472-.949 5.472.109.695.484 1.16 1.179 1.107.702-.06 2.219-1.88 2.219-1.88.121-.152.387-.28.581-.28h3.858c.193 0 .46.128.581.28 0 0 1.517 1.82 2.218 1.88.702.054 1.071-.412 1.186-1.107 0 0 .641-2.854-.956-5.472z" />
  </svg>
);

IconSportEbasketball.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportEbasketball.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
