import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportArmyGames = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M17 13.998c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1zm-4 0c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1zm-4 0c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1zm-4 0c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1zm15 0c0 1.66-1.34 3-3 3H3c-.83 0-1.58-.34-2.12-.88S0 14.828 0 13.998c0-1.66 1.34-3 3-3h14c.83 0 1.58.34 2.12.88s.88 1.29.88 2.12zm-16.5-4.5c-.17 0-.34.01-.5.03.009-1.213.867-2.22 2.006-2.468 0-.022-.006-.04-.006-.062v-2c0-1.104.896-2 2-2h4c.648 0 1.219.313 1.585.792l6.468-1.764c.406-.108.812.127.92.527.109.4-.127.811-.526.921L13 5.231v1.767h1.45c1.4 0 2.54 1.13 2.55 2.53-.16-.02-.33-.03-.5-.03h-13z" />
  </svg>
);

IconSportArmyGames.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportArmyGames.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
