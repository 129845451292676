import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import { wrapRoute2Locale } from 'core/helpers';
import { withLocale, withFeedbackActions } from 'core/hocs';
import { LIVE_WIDGET_TYPES, AI_OUTCOME_SECTION } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { SportTypeIcon } from 'components/sport-type-icon/sport-type-icon';
import { TeamLogoPlaceholder } from 'components/team-logo-placeholder/team-logo-placeholder';
import { IconMenu } from 'components/icons/icon-menu/icon-menu';
import { IconColon } from 'components/icons/icon-colon/icon-colon';
import { IconPin } from 'components/icons/icon-pin/icon-pin';
import { IconArrowRight } from 'components/icons/icon-arrow-right/icon-arrow-right';
import { GA } from 'helpers/ga';
import { PAGE_NAMES, LIVE_WIDGET_STUBS, SPORTS_WITHOUT_LIVE_SCORE } from '../../../constants';

import './live-widget-header.scss';

class LiveWidgetHeaderUI extends Component {
  static propTypes = {
    pageName: PropTypes.string.isRequired,
    activeItem: PropTypes.shape(),
    setStubs: PropTypes.func.isRequired,
    type: PropTypes.oneOf([
      LIVE_WIDGET_TYPES.LIVE_STREAMS,
      LIVE_WIDGET_TYPES.LIVE_STATS,
    ]).isRequired,
    setIsLiveWidgetPinned: PropTypes.func.isRequired,
    isWidgetLoaded: PropTypes.bool.isRequired,
    isPinned: PropTypes.bool.isRequired,
    eventData: PropTypes.shape(),
    isEventInProgress: PropTypes.bool.isRequired,
    setActiveLiveWidgetItem: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    history: PropTypes.shape().isRequired,
    locale: PropTypes.string.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activeItem: null,
    eventData: null,
  };

  state = {
    isOpen: false,
  };

  toggle = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }));

  onClick = (e) => {
    e.stopPropagation();
    const { dataset: { eventId } } = e.currentTarget;
    const {
      setActiveLiveWidgetItem,
      setStubs,
      items,
      type,
      locale,
      history,
      setIsLiveWidgetPinned,
      sendEventClickFeedback,
    } = this.props;

    setActiveLiveWidgetItem(Number(eventId));
    sendEventClickFeedback(eventId, true);

    const { hasLiveStream, hasLiveStats } = R.find(R.propEq('eventId', Number(eventId)), items) || {};

    if (type === LIVE_WIDGET_TYPES.LIVE_STREAMS) {
      setStubs(hasLiveStream ? null : [LIVE_WIDGET_STUBS.NO_STREAM]);
    } else {
      setStubs(hasLiveStats ? null : [LIVE_WIDGET_STUBS.NO_STATS]);
    }

    history.push(wrapRoute2Locale(`/event/${eventId}?isLive=true&sectionBeforeEventPage=${AI_OUTCOME_SECTION.LIVE_WIDGET}`, locale));
    setIsLiveWidgetPinned(false);
  };

  onPinClick = () => {
    const { isPinned, setIsLiveWidgetPinned, activeItem } = this.props;

    if (activeItem) {
      setIsLiveWidgetPinned(!isPinned);
      GA.event({
        category: 'event-page',
        label: isPinned ? 'unpin-widget' : 'pin-widgets',
      });
    }
  };

  onDropdownClick = () => {
    GA.event({
      category: 'event-page',
      label: 'widget-events-list-click',
    });
  };

  render() {
    const {
      items,
      activeItem,
      isPinned,
      pageName,
      eventData,
      isEventInProgress,
      isWidgetLoaded,
    } = this.props;
    const { isOpen } = this.state;
    const isEventLoaded = pageName === PAGE_NAMES.EVENT && !isEventInProgress && !!eventData;

    if (!isWidgetLoaded && !isPinned) {
      return (
        <div className="live-widget-header d-flex justify-content-between bg-main-2">
          <div className="live-widget-header-menu-loader d-flex align-items-center justify-content-center">
            <IconMenu className="mx-1" />
          </div>

          <div className="live-widget-header-pin is-disabled d-flex align-items-center justify-content-center">
            <IconPin />
          </div>
        </div>
      );
    }

    const homeScore = isEventLoaded
      ? eventData.homeScore
      : activeItem && activeItem.homeScore;
    const awayScore = isEventLoaded
      ? eventData.awayScore
      : activeItem && activeItem.awayScore;
    const withScore = isEventLoaded
      ? !SPORTS_WITHOUT_LIVE_SCORE.includes(eventData.sportId)
      : activeItem && !SPORTS_WITHOUT_LIVE_SCORE.includes(activeItem.sportId);

    return items && (
      <div className={classNames('live-widget-header d-flex bg-main-2 position-relative', {
        'is-empty': !activeItem && !isEventLoaded,
      })}
      >
        <Dropdown isOpen={isOpen} toggle={this.toggle} onClick={this.onDropdownClick}>
          <DropdownToggle className="live-widget-header-toggle p-0 rounded-0">
            <IconMenu className="mx-1" />
            {!activeItem && !isEventLoaded && (
              <div className="d-flex align-items-center">
                <FormattedTag id="live-widget.select-event" className="mr-0_5 text-small font-weight-normal text-extra-2" />
                <IconArrowRight className="icon-arrow-right" />
              </div>
            )}
          </DropdownToggle>
          <DropdownMenu className="live-widget-header-menu">
            {items.map(({
              eventId,
              sportId,
              teamA,
              teamB,
            }) => (
              <DropdownItem
                key={eventId}
                className={classNames('live-widget-header-menu-item position-relative', {
                  'active font-weight-bold': activeItem && eventId === activeItem.eventId,
                })}
                onClick={this.onClick}
                onKeyPress={this.onClick}
                data-event-id={eventId}
              >
                <div className="text-small text-wrap d-flex align-items-center">
                  <SportTypeIcon isActive={activeItem && eventId === activeItem.eventId} sportId={sportId} width="16" height="16" className="mr-1_5" />
                  <span>{teamA} - {teamB}</span>
                </div>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>

        {(activeItem || isEventLoaded) && (
          <Fragment>
            <div className="flex-fill d-flex px-1 align-items-center justify-content-center overflow-hidden">
              <div className="live-widget-header-team d-flex align-items-center justify-content-between overflow-hidden">
                <div className="d-flex align-items-center overflow-hidden">
                  <TeamLogoPlaceholder imageUrl={activeItem ? activeItem.teamALogo : eventData.teamALogo} sportId={activeItem ? activeItem.sportId : eventData.sportId} className="live-widget-header-logo mr-1 flex-shrink-0" />
                  <span title={activeItem ? activeItem.teamA : eventData.teamA} className="text-truncate caption font-weight-bold mr-1">{activeItem ? activeItem.teamA : eventData.teamA}</span>
                </div>
                {withScore && <div className="live-widget-header-score font-weight-bold bg-main-4">{homeScore === null ? '-' : homeScore}</div>}
              </div>

              <IconColon className="mx-0_25 flex-shrink-0" />

              <div className="live-widget-header-team d-flex align-items-center justify-content-between overflow-hidden">
                {withScore && <div className="live-widget-header-score font-weight-bold bg-main-4">{awayScore === null ? '-' : awayScore}</div>}
                <div className="d-flex align-items-center justify-content-end overflow-hidden">
                  <span title={activeItem ? activeItem.teamB : eventData.teamB} className="text-truncate caption font-weight-bold ml-1">{activeItem ? activeItem.teamB : eventData.teamB}</span>
                  <TeamLogoPlaceholder imageUrl={activeItem ? activeItem.teamBLogo : eventData.teamBLogo} sportId={activeItem ? activeItem.sportId : eventData.sportId} className="live-widget-header-logo ml-1 flex-shrink-0" />
                </div>
              </div>
            </div>

            <div
              role="button"
              tabIndex="0"
              onClick={this.onPinClick}
              onKeyPress={this.onPinClick}
              className={classNames('live-widget-header-pin d-flex align-items-center justify-content-center', {
                'is-disabled': !activeItem,
                'is-pinned': isPinned,
              })}
            >
              <IconPin />
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

export const LiveWidgetHeader = withFeedbackActions(withRouter(withLocale(LiveWidgetHeaderUI)));
