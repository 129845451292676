import React from 'react';
import PropTypes from 'prop-types';

import colors from '../../../customizations/js/color-variables';

export const IconWarning = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10">
    <path fill={colors.cpSecondary4} d="M7.009.105c.145.086.266.207.352.352l4.747 8.034c.281.476.124 1.089-.352 1.37-.154.091-.33.139-.508.139H1.752c-.552 0-1-.448-1-1 0-.179.048-.355.14-.509L5.639.457c.281-.476.894-.633 1.37-.352zM7 7H6v1h1V7zm0-4H6v3h1V3z" />
  </svg>
);

IconWarning.propTypes = {
  className: PropTypes.string,
};

IconWarning.defaultProps = {
  className: null,
};
