import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaResponsive from 'react-responsive';
import { isNil } from 'ramda';
import breakpoints from '../../customizations/grid-breakpoints.scss';

export const getNext = (name) => {
  const breakpointsKeys = Object.keys(breakpoints);
  const index = breakpointsKeys.indexOf(name);

  return (index !== -1 && index < breakpointsKeys.length - 1) ? breakpointsKeys[index + 1] : null;
};

export const getMin = name => breakpoints[name] !== 0 ? breakpoints[name] : null;

export const getMax = (name) => {
  const next = getNext(name);

  return next && `${parseInt(getMin(next), 10) - 0.02}px`;
};

export const mediaUp = name => `(min-width: ${getMin(name)})`;

export const mediaDown = name => `(max-width: ${getMax(name)})`;

export const mediaBetween = (names) => {
  const min = getMin(names[0]);
  const max = getMax(names[1]);
  let query;

  if (!isNil(min) && !isNil(max)) {
    query = `(min-width: ${min}) and (max-width: ${max})`;
  } else if (isNil(max)) {
    query = mediaUp(names[0]);
  } else {
    query = mediaDown(names[1]);
  }

  return query;
};

export const mediaOnly = (name) => {
  const min = getMin(name);
  const max = getMax(name);
  let query;

  if (!isNil(min) && !isNil(max)) {
    query = `(min-width: ${min}) and (max-width: ${max})`;
  } else if (isNil(max)) {
    query = mediaUp(name);
  } else {
    query = mediaDown(name);
  }

  return query;
};

export class MediaQuery extends Component {
  static propTypes = {
    up: PropTypes.string,
    down: PropTypes.string,
    between: PropTypes.arrayOf(PropTypes.string),
    only: PropTypes.string,
    query: PropTypes.string,
    children: PropTypes.node,
  };

  static defaultProps = {
    up: null,
    down: null,
    between: null,
    only: null,
    query: null,
    children: null,
  };

  constructor(props) {
    super(props);
    const {
      up,
      down,
      between,
      only,
      children,
      query,
      ...otherProps
    } = this.props;
    const queries = [];

    if (query) {
      queries.push(query);
    }

    if (up) {
      queries.push(mediaUp(up));
    }

    if (down) {
      queries.push(mediaDown(down));
    }

    if (between) {
      queries.push(mediaBetween(between));
    }

    if (only) {
      queries.push(mediaOnly(only));
    }

    this.query = queries.join(', ');
    this.otherProps = otherProps;
  }

  render() {
    const { children } = this.props;

    return (
      <MediaResponsive query={this.query} {...this.otherProps}>
        {children}
      </MediaResponsive>
    );
  }
}
