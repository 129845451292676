import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportBoxing = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M8.195 17.39l-2.05 2.05c-.73.74-1.92.74-2.65 0L.556 16.5c-.74-.73-.74-1.92 0-2.66l2.11-2.1c1.08 2 3.35 4.48 5.53 5.65m11.73-9.34c0 .35-.06.7-.18 1.02-.42-.69-.95-1.1-1.4-1.35-1.16-.62-2.28-.43-2.6-.36-.42.1-1.46.43-2.11 1.38-.38.55-.56 1.21-.55 1.9-.82-.41-1.57-.95-2.2-1.6-1.02-1.03-1.57-2.15-1.85-2.91-.05-.14-.05-.28-.01-.43.16-.5.37-.99.63-1.45.28-.51.62-1 1.01-1.43.28-.31.25-.78-.05-1.06-.31-.28-.78-.25-1.06.06-.47.52-.88 1.09-1.22 1.7-.3.55-.55 1.13-.74 1.73-.14.46-.13.95.03 1.4.48 1.28 1.23 2.47 2.19 3.44 1.01 1.03 2.26 1.84 3.62 2.33.03.01.07.02.1.02.19.38.43.75.73 1.1.27.31.75.34 1.06.07.31-.27.34-.74.07-1.06-.83-.95-1.05-2.2-.52-2.97.36-.52 1.02-.71 1.21-.75.95-.2 1.902.23 2.395 1.056.23.385.363.823.436 1.264.05.292.06.592.03.888-.025.247-.078.491-.154.728-.076.231-.19.44-.33.638-.1.142-.21.276-.33.403-.123.131-.255.256-.393.374-.145.122-.295.236-.45.344-.161.113-.327.218-.496.318-.176.102-.355.2-.535.29-.186.093-.375.18-.565.261-.193.083-.39.162-.586.235-.197.074-.396.143-.598.208-.2.064-.4.125-.6.182-.198.054-.397.106-.596.155-.193.046-.387.09-.582.13-.185.036-.372.071-.558.102-.175.03-.352.055-.528.077-.162.022-.324.04-.487.054-.146.013-.292.023-.44.027-.083.004-.168.006-.252.006-2.47 0-6.89-5.02-6.93-6.77-.04-1.87 3-9.8 8.349-9.8 5.24 0 8.04 6.74 8.04 8.05" />
  </svg>
);

IconSportBoxing.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportBoxing.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
