import React from 'react';
import PropTypes from 'prop-types';

export const IconKeyboard = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="19" height="28" viewBox="0 0 19 28">
    <g fill="#FFF" fillRule="evenodd">
      <path d="M0 14h5v5H1c-.552 0-1-.444-1-1v-4z" />
      <rect width="5" height="5" x="7" y="14" rx="1" />
      <path d="M14 14h5v4c0 .552-.444 1-1 1h-4v-5z" />
      <rect width="5" height="5" y="7" rx="1" />
      <rect width="5" height="5" x="7" y="7" rx="1" />
      <rect width="5" height="5" x="14" y="7" rx="1" />
      <path d="M0 1c0-.552.444-1 1-1h4v5H0V1z" />
      <rect width="5" height="5" x="7" rx="1" />
      <path d="M14 0h4c.552 0 1 .444 1 1v4h-5V0z" />
      <path d="M7.473 25.161L12.227 21.377 12.227 29.377z" transform="scale(1 -1) rotate(90 35.227 0)" />
    </g>
  </svg>
);

IconKeyboard.propTypes = {
  className: PropTypes.string,
};

IconKeyboard.defaultProps = {
  className: null,
};
