import React from 'react';
import PropTypes from 'prop-types';

export const IconTrSl = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.9814 5.54967C16.9681 5.41368 16.9275 5 16.6767 5C16.5188 5 16.3749 5.17925 16.3468 5.21641C15.9419 5.58679 15.5266 5.92238 15.1124 6.2137C13.4361 7.38962 11.6352 7.95421 9.77028 7.89038C6.06628 7.76355 3.30398 5.17879 3.27653 5.15271L3.23438 5.11266L3 5.35185L3.00013 5.37418C3.02323 8.66214 3.80993 11.1743 4.46578 12.7027C5.17727 14.3606 5.89428 15.2537 5.90146 15.2625L6.00033 15.3844L6.85946 13.5C5.39399 11.3988 4.61549 8.71955 4.5 8C6.42345 9.18386 7.73042 8.94422 9.76488 9.02532C9.91169 9.03122 10.0606 9.03419 10.2074 9.03419C12.8035 9.03419 14.5583 8.53633 15.5 8C15.4187 8.0538 15.6809 7.89725 15.5 8C15.2083 17.7129 8.61026 17.3013 6.00033 17.3013C6.13488 18.9496 6.8381 19.9875 6.85946 19.9953L6.87221 20L6.88582 19.9987C9.40331 19.7632 11.5012 18.8556 13.1212 17.3013C14.4163 16.0589 15.4095 14.4033 16.0734 12.3808C17.2025 8.9418 16.9839 5.58465 16.9814 5.54967Z" fill="white" fillOpacity="0.4" />
    <path d="M10.5262 0L11.9131 1.10039L13.6161 0.593857L12.9868 2.23876L14 3.68392L12.2236 3.60028L11.1466 5L10.6781 3.30391L9 2.72344L10.487 1.7586L10.5262 0Z" fill="white" fillOpacity="0.4" />
  </svg>
);

IconTrSl.propTypes = {
  className: PropTypes.string,
};

IconTrSl.defaultProps = {
  className: '',
};
