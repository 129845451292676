import React from 'react';
import PropTypes from 'prop-types';

export const IconArrowControl = ({ className }) => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M-3.27835e-07 8.5C-1.46777e-07 12.6421 3.35786 16 7.5 16L8.5 16C12.6421 16 16 12.6421 16 8.5L16 7.5C16 3.35786 12.6421 -5.52605e-07 8.5 -3.71547e-07L7.5 -3.27835e-07C3.35786 -1.46777e-07 -5.52605e-07 3.35786 -3.71547e-07 7.5L-3.27835e-07 8.5ZM6.53553 10.3215L7.71405 11.5L11.2496 7.96447L7.71405 4.42893L6.53553 5.60744L8.89256 7.96447L6.53553 10.3215Z" fill="white" fillOpacity="0.3" />
  </svg>
);

IconArrowControl.propTypes = {
  className: PropTypes.string,
};

IconArrowControl.defaultProps = {
  className: null,
};
