import React from 'react';
import PropTypes from 'prop-types';

export const IconSearchBold = ({ className, color }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path fill={color} fillRule="evenodd" d="M10 15c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5m11.854 4.732L18.1 15.979c-.057-.057-.134-.089-.214-.089l-.994.002-.759-.759C17.297 13.744 18 11.955 18 10c0-4.418-3.582-8-8-8s-8 3.582-8 8 3.582 8 8 8c1.954 0 3.743-.703 5.133-1.867l.759.759-.001.994c0 .08.031.157.088.214l3.753 3.754c.195.193.508.193.703 0l1.419-1.419c.194-.194.194-.509 0-.703" />
  </svg>
);

IconSearchBold.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconSearchBold.defaultProps = {
  className: null,
  color: '#FFFFFF',
};
