import { SPORTS } from '../constants';

const prepareScore = (score, sportId, setAltScore, isHome) => {
  if (sportId !== SPORTS.CRICKET) {
    return score;
  }

  let result;

  try {
    result = setAltScore
      .split(' - ')
      .map(i => i.split(':'))
      .reduce((prev, cur) => {
        const curScore = cur[isHome ? 0 : 1];
        const [curA, curB] = curScore.split('/');

        if (curScore === '0/0') {
          return prev;
        }

        if (curB === '10') {
          return prev.length ? `${prev} & ${curA}` : curA;
        }

        return prev.length ? `${prev} & ${curScore}` : curScore;
      }, '');
  } catch (_) {
    result = score;
  }

  return result;
};

export const prepareHomeScore = (score, sportId, setAltScore) => prepareScore(score, sportId, setAltScore, true);

export const prepareAwayScore = (score, sportId, setAltScore) => prepareScore(score, sportId, setAltScore, false);

export const prepareBetsScore = (score, sec) => {
  console.log('score in prepareBetsScore: ', score);
  console.log('sec in prepareBetsScore: ', sec);

  if (!score) {
    return null;
  }

  let result;

  try {
    result = score.split(' ')[0].split(':');
  } catch (_) {
    result = null;
  }

  return result;
};
