import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { FormattedMessage } from 'react-intl';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import { withCoreComponent, withLocale } from 'core/hocs';
import { LeagueGames as LeagueGamesCore } from 'core/components';
import { convertStringifiedBoolean } from 'core/helpers';
import { AI_OUTCOME_SECTION } from 'core/constants';

import { SportTypeIcon } from 'components/sport-type-icon/sport-type-icon';
import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { IconMenu } from 'components/icons/icon-menu/icon-menu';
import { groupByDate } from 'helpers/games';
import { GA } from 'helpers/ga';
import { GameItem } from '../game-item/game-item';
import { GamesPreloader } from '../games-preloader/games-preloader';
import { TODAY, TOMORROW } from '../../../../constants';

class LeagueGamesUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape()),
    isInProgress: PropTypes.bool.isRequired,
    eventData: PropTypes.shape(),
    params: PropTypes.shape({
      eventId: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape().isRequired,
    toggleNavigation: PropTypes.func.isRequired,
    setActiveCountries: PropTypes.func.isRequired,
    setActiveLeagueId: PropTypes.func.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    items: null,
    eventData: null,
  };

  onClick = () => {
    const {
      eventData,
      toggleNavigation,
      setActiveCountries,
      setActiveLeagueId,
    } = this.props;
    const { sportId, leagueId, countryId } = eventData || {};

    toggleNavigation();
    setActiveCountries(sportId, countryId);
    setActiveLeagueId(sportId, leagueId);
  }

  onEventClick = () => {
    GA.event({
      category: 'go-to-event-page',
      label: 'left-sidebar-prematch-event',
    });
  }

  render() {
    const {
      locale,
      items,
      isInProgress,
      eventData,
      params: { eventId },
      location: { search },
      sendEventClickFeedback,
    } = this.props;
    const formattedItems = items && groupByDate(items);
    const { isLive } = qs.parse(search, { ignoreQueryPrefix: true });

    if (R.isEmpty(eventData)) {
      return null;
    }

    return isInProgress || R.isNil(items)
      ? <GamesPreloader />
      : (
        <div className="list-of-games bg-main-2">
          <div className="p-2 pl-2_5">
            <div
              role="button"
              tabIndex="0"
              onClick={this.onClick}
              onKeyPress={this.onClick}
              className="d-flex align-items-center"
            >
              <IconMenu />
              <FormattedTag
                id="left-sidebar.back"
                className="text-small ml-2_5"
              />
            </div>
          </div>

          <div className="list-of-games-league w-100 py-1 px-2 d-flex align-items-center text-extra-3 caption">
            <SportTypeIcon isActive sportId={eventData.sportId} width="12" height="12" className="sport-type-icon mr-1 flex-sm-shrink-0" />
            <span>{eventData.leagueName}</span>
          </div>

          {formattedItems && Object.entries(formattedItems).map(([date, games]) => (
            <div key={date}>
              <div className="list-of-games-date w-100 py-1 px-2 d-flex align-items-center text-extra-3 caption text-capitalize">
                {date === TODAY && <FormattedMessage id="general.today" />}
                {date === TOMORROW && <FormattedMessage id="general.tomorrow" />}
                {date !== TODAY && date !== TOMORROW && date}
              </div>
              <div>
                {games.map(game => (
                  <GameItem
                    key={game.eventId}
                    eventData={game}
                    locale={locale}
                    isActive={Number(eventId) === game.eventId
                      && convertStringifiedBoolean(isLive) === (game.isLive || game.eventStatus !== null)}
                    onEventClick={this.onEventClick}
                    sendEventClickFeedback={sendEventClickFeedback}
                    sectionBeforeEventPage={AI_OUTCOME_SECTION.LEFT_SIDEBAR_PREMATCH}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      );
  }
}

export const LeagueGames = withRouter(withLocale(withCoreComponent(LeagueGamesCore, LeagueGamesUI)));
