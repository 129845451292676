import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';

import './freebet-title.scss';

const FreebetTitle = ({ className }) => (
  <FormattedTag
    id="freebet.title"
    className={classNames('freebet-title bg-warning text-caption-sb font-weight-bold px-0_5 py-0_25', className)}
  />
);

FreebetTitle.propTypes = {
  className: PropTypes.string,
};

FreebetTitle.defaultProps = {
  className: '',
};

export default FreebetTitle;
