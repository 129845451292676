import React from 'react';
import PropTypes from 'prop-types';

export const IconStub = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="75" height="76" viewBox="0 0 75 76">
    <g fill="none" fillRule="evenodd">
      <path fill="url(#icon-stub-a)" d="M0 22h67v43.2c0 2.651-2.149 4.8-4.8 4.8H4.8C2.149 70 0 67.851 0 65.2V22z" />
      <path fill="url(#icon-stub-b)" d="M4.8 6h57.4c2.651 0 4.8 2.149 4.8 4.8V20H0v-9.2C0 8.149 2.149 6 4.8 6z" />
      <rect width="7" height="14" x="14" fill="url(#icon-stub-c)" rx="3.5" />
      <rect width="7" height="14" x="14" fill="url(#icon-stub-d)" rx="3.5" />
      <rect width="7" height="14" x="46" fill="url(#icon-stub-c)" rx="3.5" />
      <rect width="7" height="14" x="46" fill="url(#icon-stub-e)" rx="3.5" />
      <path fill="url(#icon-stub-f)" d="M33.6 49.8L25.841 53.879 27.323 45.24 21.046 39.121 29.721 37.86 33.6 30 37.479 37.86 46.154 39.121 39.877 45.24 41.359 53.879z" />
      <g transform="translate(45.6 48)">
        <circle cx="14.4" cy="14" r="14" fill="url(#icon-stub-g)" />
        <path fill="url(#icon-stub-h)" d="M14.4 6c.513 0 .936.386.993.883L15.4 7v6.585l3.707 3.708c.36.36.388.928.083 1.32l-.083.094c-.36.36-.928.388-1.32.083l-.094-.083-4-4c-.156-.156-.256-.359-.284-.576L13.4 14V7c0-.552.448-1 1-1z" />
      </g>
    </g>
  </svg>
);

IconStub.propTypes = {
  className: PropTypes.string,
};

IconStub.defaultProps = {
  className: null,
};
