import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportAutoRacing = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M11.108 18.419c.991-5.248 2.903-6.393 7.313-7.328-.49 3.808-3.507 6.829-7.313 7.328m-9.53-7.328c4.41.935 6.322 2.08 7.313 7.328-3.805-.5-6.822-3.52-7.313-7.328M8 10c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2m2-8.5c4.231 0 7.74 3.11 8.384 7.164C17.084 7.997 13.84 6.56 10 6.56c-3.84 0-7.084 1.437-8.385 2.104C2.26 4.61 5.768 1.5 10 1.5M10 0C4.477 0 0 4.478 0 10c0 5.523 4.477 10 10 10 5.522 0 10-4.477 10-10 0-5.522-4.478-10-10-10" />
  </svg>
);

IconSportAutoRacing.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportAutoRacing.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
