import React from 'react';
import PropTypes from 'prop-types';

export const IconLiveStatsStub = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="106" height="84" viewBox="0 0 106 84">
    <g fill="none" fillRule="evenodd">
      <path fill="url(#icon-live-stats-stub-a)" d="M5.564 6h88.872c.892 0 1.215.093 1.54.267.327.174.583.43.757.756.174.326.267.65.267 1.54v48.873c0 .892-.093 1.215-.267 1.54-.174.327-.43.583-.756.757-.326.174-.65.267-1.54.267H5.563c-.892 0-1.215-.093-1.54-.267-.327-.174-.583-.43-.757-.756-.174-.326-.267-.65-.267-1.54V8.563c0-.892.093-1.215.267-1.54.174-.327.43-.583.756-.757.326-.174.65-.267 1.54-.267z" />
      <path fill="url(#icon-live-stats-stub-b)" d="M2 0h96c1.105 0 2 .895 2 2s-.895 2-2 2H2C.895 4 0 3.105 0 2s.895-2 2-2z" />
      <path fill="url(#icon-live-stats-stub-c)" d="M2 66c-1.105 0-2-.895-2-2s.895-2 2-2h96c1.105 0 2 .895 2 2s-.895 2-2 2H54v14h8c1.105 0 2 .895 2 2s-.895 2-2 2H42c-1.105 0-2-.895-2-2s.895-2 2-2h8V66H2z" />
      <path fill="url(#icon-live-stats-stub-d)" d="M63.402 20.24l6.603.66c.55.055.95.545.895 1.095l-.66 6.603c-.055.55-.545.95-1.094.895-.264-.026-.507-.156-.675-.361l-1.738-2.125L51.26 39.554c-.806.652-1.966.577-2.681-.147l-.115-.127-8.646-10.375-13.453 12.557c-.765.714-1.941.713-2.704.024l-.123-.121c-.714-.765-.713-1.941-.024-2.704l.121-.123 15-14c.8-.747 2.04-.707 2.792.061l.11.12 8.736 10.484L64.2 23.911l-1.671-2.043c-.35-.427-.287-1.057.14-1.407.206-.168.47-.247.733-.22z" />
      <g transform="translate(72 39)">
        <circle cx="17" cy="17" r="17" fill="url(#icon-live-stats-stub-e)" />
        <path fill="url(#icon-live-stats-stub-f)" d="M17 17V0C7.611 0 0 7.611 0 17h17z" />
      </g>
    </g>
  </svg>
);

IconLiveStatsStub.propTypes = {
  className: PropTypes.string,
};

IconLiveStatsStub.defaultProps = {
  className: null,
};
