import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';
import { formatAmountWithCurrencySymbol } from 'helpers/currency';

import './cashout-button.scss';

export const CashoutButton = ({
  currency,
  onClick,
  isLoading,
  className,
  cashoutAmount,
}) => (
  <ButtonWithLoader
    size="sm"
    color="primary"
    onClick={onClick}
    isLoading={isLoading}
    className={classNames('cashout-button p-0', className)}
    contentClassName="cashout-button-content d-flex"
  >
    <FormattedTag id="my-bets.cashout" className="caption font-weight-normal" />
    {!!cashoutAmount && <span>{formatAmountWithCurrencySymbol(cashoutAmount, currency)}</span>}
  </ButtonWithLoader>
);

CashoutButton.propTypes = {
  currency: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  className: PropTypes.string,
  cashoutAmount: PropTypes.number.isRequired,
};

CashoutButton.defaultProps = {
  className: null,
};
