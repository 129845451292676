import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportSailing = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M8.42 8c-.22-1.58-.61-2.93-1-4-.09 1.32-.33 3.06-1 5-.76 2.19-1.81 3.9-2.74 5.13l4.63-.47c.25-1.45.41-3.4.11-5.66zm8.54 4.8c-.27-1.49-.98-4.13-2.96-6.8-2.14-2.88-4.68-4.35-6-5 .75 1.64 1.62 4.02 2 7 .27 2.13.22 4 .06 5.49l6.9-.69zM17 19H2l-2-3 20-2-3 5z" />
  </svg>
);

IconSportSailing.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportSailing.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
