import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Alert from 'reactstrap/lib/Alert';
import { NOTIFICATION_TYPES } from 'core/constants';
import { GlobalNotificationPopup as GlobalNotificationPopupCore } from 'core/components';
import { withCoreComponent } from 'core/hocs';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { IconWarning } from '../icons/icon-warning/icon-warning';
import { IconApproved } from '../icons/icon-approved/icon-approved';

const ICONS = {
  [NOTIFICATION_TYPES.SUCCESS]: <IconApproved />,
  [NOTIFICATION_TYPES.ERROR]: <IconWarning />,
};

export const GlobalNotificationPopupUI = ({
  type, message, values, visible,
}) => (
  <Alert
    className="bg-transparent border-0 p-0 w-100 mb-1_5"
    isOpen={visible}
  >
    <div className="global-notification d-flex shadow text-small text-extra-1 pt-2 pb-2_5 pl-2_5 rounded">
      <div className="d-flex">
        <div className="mr-2 align-self-start">
          {ICONS[type]}
        </div>
        <div className="d-flex flex-column justify-content-between font-weight-bold global-notification-text">
          <FormattedMessage id={message.title} />
          <FormattedTag className="text-extra-2 font-weight-normal mt-0_5" values={values || {}} id={message.text} />
        </div>
      </div>
    </div>
  </Alert>
);

GlobalNotificationPopupUI.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
  }),
  visible: PropTypes.bool.isRequired,
  values: PropTypes.shape(),
};

GlobalNotificationPopupUI.defaultProps = {
  message: null,
  values: null,
};

export const NotificationPopup = withCoreComponent(GlobalNotificationPopupCore, GlobalNotificationPopupUI);
