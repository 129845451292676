import React from 'react';
import PropTypes from 'prop-types';

import { FastNavigationItem } from 'components/fast-navigation-item/fast-navigation-item';
import { PAGE_NAMES } from '../../constants';

export const NavigationItemInplay = ({
  inplayEventsCount, activePage, className, handleClick,
}) => (
  <FastNavigationItem
    itemId="left-sidebar.in-play"
    total={inplayEventsCount}
    link="/live"
    page={PAGE_NAMES.LIVE}
    activePage={activePage}
    className={className}
    handleClick={handleClick}
  />
);

NavigationItemInplay.propTypes = {
  className: PropTypes.string,
  inplayEventsCount: PropTypes.number,
  activePage: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
};

NavigationItemInplay.defaultProps = {
  inplayEventsCount: null,
  className: '',
  handleClick: null,
};
