import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportOscar = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M10 4c1.104 0 2-.896 2-2s-.896-2-2-2-2 .896-2 2 .896 2 2 2m1.85 9.03L11.285 17H13c.553 0 1 .447 1 1v2H6v-2c0-.553.447-1 1-1h1.715l-.565-3.97c-.1-.67-.41-1.29-.89-1.77l-.39-.39C6.31 10.31 6 9.56 6 8.76V5.71c0-.44.22-.86.59-1.1l.67-.45C7.89 4.98 8.89 5.5 10 5.5c1.11 0 2.11-.52 2.74-1.34l.67.45c.37.24.59.66.59 1.1v3.05c0 .8-.31 1.55-.87 2.11l-.39.39c-.48.48-.79 1.1-.89 1.77" />
  </svg>
);

IconSportOscar.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportOscar.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
