import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportRugby = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M15.42 4.587c3.666 3.665 4.867 8.784 4.524 11.944-1.101.452-2.42.687-3.72.705-1.445.018-2.916-.262-4.343-.767-1.426-.506-2.8-1.265-4.081-2.167-1.281-.921-2.491-1.97-3.512-3.224.768 1.418 1.788 2.709 2.907 3.919 1.138 1.174 2.438 2.257 3.9 3.124 1.464.866 3.106 1.534 4.858 1.842l.136.026c-3.188.19-8-1.065-11.503-4.568C-.397 10.438-.831 2.78.975.975 2.78-.831 10.437-.397 15.42 4.587" />
  </svg>
);

IconSportRugby.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportRugby.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
