import React from 'react';
import PropTypes from 'prop-types';

export const IconPending = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <g fill="#FFF" fillOpacity=".2">
      <path d="M6 12c3.314 0 6-2.686 6-6S9.314 0 6 0 0 2.686 0 6s2.686 6 6 6zm0-1.5c-2.485 0-4.5-2.015-4.5-4.5S3.515 1.5 6 1.5s4.5 2.015 4.5 4.5-2.015 4.5-4.5 4.5z" />
      <path d="M6.5 5.5L6.5 3 5 3 5 7 9 7 9 5.5z" />
    </g>
  </svg>
);

IconPending.propTypes = {
  className: PropTypes.string,
};

IconPending.defaultProps = {
  className: null,
};
