import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { RouteLink } from 'components/route-link/route-link';
import { FavoritesToggleButton } from '../favorites-toggle-button/favorites-toggle-button';

import './sport-subitem-body.scss';

export class SportSubitemBody extends PureComponent {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    leagueId: PropTypes.number.isRequired,
    leagueName: PropTypes.string.isRequired,
    sportId: PropTypes.number.isRequired,
    countryId: PropTypes.number.isRequired,
    eventsCount: PropTypes.number.isRequired,
    inplayEventsCount: PropTypes.number.isRequired,
    isActive: PropTypes.bool.isRequired,
    setActiveLeagueId: PropTypes.func.isRequired,
    clearActiveCountriesExceptSportAndCountry: PropTypes.func.isRequired,
    sendLeagueClickFeedback: PropTypes.func.isRequired,
  };

  onClick = () => {
    const {
      setActiveLeagueId,
      sportId,
      leagueId,
      countryId,
      clearActiveCountriesExceptSportAndCountry,
      sendLeagueClickFeedback,
    } = this.props;

    setActiveLeagueId(sportId, leagueId);
    clearActiveCountriesExceptSportAndCountry(sportId, countryId);
    sendLeagueClickFeedback(leagueId);
  };

  render() {
    const {
      locale,
      leagueId,
      leagueName,
      sportId,
      eventsCount,
      inplayEventsCount,
      isActive,
    } = this.props;

    return (
      <div className={classNames('sports-list-subitem-element d-flex justify-content-between align-items-center text-small position-relative', {
        'font-weight-bold bg-main-4 active': isActive,
        'with-live-status': inplayEventsCount > 0,
      })}
      >
        <RouteLink
          to={`/league/${sportId}/${leagueId}`}
          locale={locale}
          onClick={this.onClick}
          className="overflow-hidden"
        >
          <div className="d-flex my-1 py-0_25 px-5 overflow-hidden">
            <span className="navigation-item-name">
              {leagueName}
            </span>
            <span className="align-self-end text-extra-3 pl-1 font-weight-normal">{eventsCount}</span>
          </div>
        </RouteLink>

        <FavoritesToggleButton
          sportId={sportId}
          leagueId={leagueId}
          leagueName={leagueName}
          className="position-absolute favorites-button"
        />
      </div>
    );
  }
}
