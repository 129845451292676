import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportGolf = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M14.621 8.354c.293-.293.293-.768 0-1.061-.293-.293-.768-.293-1.06 0-.294.292-.294.767 0 1.06.292.293.767.293 1.06 0m-1.768.707c-.293-.293-.767-.293-1.06 0-.293.293-.293.768 0 1.061.293.293.767.293 1.06 0 .293-.293.293-.768 0-1.06m-1.59-1.237c.293.293.767.293 1.06 0 .293-.293.293-.768 0-1.061-.293-.293-.767-.293-1.06 0-.293.293-.293.768 0 1.06m-.707.707c-.293-.292-.768-.292-1.06 0-.294.293-.294.768 0 1.06.292.293.767.293 1.06 0 .293-.293.293-.767 0-1.06m2.474-2.474c.293.292.768.292 1.061 0 .293-.293.293-.768 0-1.06-.293-.294-.768-.294-1.06 0-.294.292-.294.766 0 1.06M16 6c0 3.313-2.688 6-6 6-3.315 0-6-2.687-6-6s2.685-6 6-6c3.312 0 6 2.687 6 6m-2 13.25c0 .415-.337.75-.75.75h-6.5c-.415 0-.75-.335-.75-.75 0-.416.335-.75.75-.75h2.531c-.087-2.023-.678-3.858-1.352-5.3.66.2 1.35.3 2.07.3.72 0 1.41-.1 2.07-.3-.675 1.442-1.265 3.277-1.352 5.3h2.532c.414 0 .75.334.75.75" />
  </svg>
);

IconSportGolf.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportGolf.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
