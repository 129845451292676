import React, { memo } from 'react';
import PropTypes from 'prop-types';

export const IconTeamPlaceholder = memo(({ className }) => (
  <svg className={className} width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M81 20.0093V53.8791C81 74.1023 48 88 48 88C48 88 15 74.1023 15 53.8791V20.0093C18.025 17.414 30.3817 8 48 8C65.6183 8 77.975 17.414 81 20.0093Z" fill="url(#icon-team-placeholder-a)" />
    <path opacity="0.1" fillRule="evenodd" clipRule="evenodd" d="M15 31.3333V53.879C15 74.1022 48 87.9999 48 87.9999C48 87.9999 74.2661 76.9382 79.9314 60.1916L15 31.3333Z" fill="black" />
    <path d="M48 8.5C63.1347 8.5 74.7351 15.6447 80.5 19.5301V55.7619C80.5 60.1165 78.2369 64.3936 74.9357 68.3065C71.6405 72.2122 67.3594 75.6975 63.4344 78.4595C57.5466 82.5998 51.6664 85.6793 47.9765 87.4459C42.3042 84.7272 34.2201 80.164 27.5295 74.6047C24.142 71.79 21.1269 68.7323 18.9619 65.5431C16.7964 62.3531 15.5 59.0597 15.5 55.7619V19.5301C21.2649 15.6447 32.8653 8.5 48 8.5Z" stroke="white" strokeOpacity="0.5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M81 20.0093V53.8791C81 74.1023 48 88 48 88C48 88 15 74.1023 15 53.8791V20.0093C18.025 17.414 30.3817 8 48 8C65.6183 8 77.975 17.414 81 20.0093Z" fill="url(#icon-team-placeholder-b)" />
    <path fillRule="evenodd" clipRule="evenodd" d="M84 54.32V17.91C80.7 15.12 67.22 5 48 5C28.78 5 15.3 15.12 12 17.91V54.32C12 76.06 48 91 48 91C48 91 84 76.06 84 54.32ZM48 8C63.4864 8 75.2957 15.4037 81 19.2644V55.7619C81 64.8138 71.6068 73.32 63.7221 78.8684C57.6879 83.1117 51.6654 86.2442 47.9764 88C36.5679 82.5673 15 69.4836 15 55.7619V19.2644C20.7043 15.4037 32.5136 8 48 8Z" fill="black" fillOpacity="0.3" />
  </svg>
));

IconTeamPlaceholder.propTypes = {
  className: PropTypes.string,
};

IconTeamPlaceholder.defaultProps = {
  className: null,
};
