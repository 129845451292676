import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportWww = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M12.73 8.99c-.19.22-.4.42-.62.62-.14.12-.29.25-.42.39-.47.52-.74 1.25-.7 1.95.03.55-.4 1.02-.95 1.05h-.05c-.53 0-.97-.42-1-.95-.06-1.25.39-2.49 1.24-3.41.17-.18.35-.35.54-.52.15-.14.32-.28.46-.45.42-.48.2-1.03-.12-1.35-.27-.28-.89-.4-1.44-.27-.54.12-1.05.49-1.26.92-.25.49-.86.69-1.35.44-.49-.25-.69-.85-.44-1.34.49-.96 1.49-1.72 2.61-1.97.94-.22 2.38-.13 3.3.81 1.16 1.18 1.24 2.89.2 4.08M10 17c-.83 0-1.5-.67-1.5-1.5S9.17 14 10 14s1.5.67 1.5 1.5S10.83 17 10 17m0-17C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0" />
  </svg>
);

IconSportWww.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportWww.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
