import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportAmericanFootball = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M19.624 12.273l-7.351 7.351c3.07.71 5.78.39 6.76-.59.98-.98 1.3-3.69.59-6.76m-5.48-1c-.29-.29-.77-.29-1.06 0l-.37.38-1.65-1.65.37-.37c.3-.3.3-.77 0-1.06-.29-.3-.76-.3-1.06 0l-.37.37-1.64-1.64.37-.38c.29-.29.29-.77 0-1.06-.3-.29-.77-.29-1.06 0l-.92.92-.89.88c-.29.3-.29.77 0 1.07.15.14.34.21.53.21s.38-.07.53-.21l.38-.37 1.64 1.64-.37.37c-.3.3-.3.77 0 1.06.14.15.34.22.53.22s.38-.07.53-.22l.37-.37 1.65 1.65-.38.37c-.29.29-.29.77 0 1.06.15.15.34.22.53.22.2 0 .39-.07.53-.22l.87-.87.07-.07.87-.87c.29-.29.29-.76 0-1.06m5-.64l-8.51 8.51c-2.1-.72-4.25-1.92-6.05-3.72-1.8-1.8-3-3.95-3.72-6.05l8.51-8.51c2.1.72 4.25 1.92 6.05 3.72 1.8 1.8 3 3.95 3.72 6.05M7.734.383l-7.35 7.35c-.71-3.07-.39-5.78.59-6.76.98-.98 3.69-1.3 6.76-.59" />
  </svg>
);

IconSportAmericanFootball.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportAmericanFootball.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
