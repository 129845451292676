import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportGymnastics = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M6.147 14.509c.264-1.13-.438-2.26-1.567-2.522-1.13-.264-2.259.438-2.522 1.567-.264 1.13.439 2.259 1.568 2.522 1.129.264 2.257-.438 2.522-1.567m13.29-7.556c-2.235 1.368-4.793 2.086-7.411 2.086-.068 0-.138 0-.207-.002-.082.195-2.995 3.19-2.995 3.19 1.329 1.814 1.97 4.047 1.856 6.463-.001.03-.005.06-.01.09h8.073c.332 0 .622.27.622.605v.012c0 .333-.29.603-.622.603H1.747c-.334 0-.59-.27-.59-.603v-.012c0-.334.256-.604.59-.604h6.66c-.008-.065-.012-.131-.01-.2.091-1.92-.418-3.675-1.47-5.076l-.271-.363-.016-.022-.75-1.051c-.074-.105-.154-.207-.24-.304l-.085-.097c-1.239-1.41-2.856-2.4-4.674-2.866l-.03-.008C.25 8.639-.12 8.016.037 7.404c.155-.611.777-.981 1.389-.825l.032.008c1.982.508 3.772 1.516 5.221 2.936 0 0 2.6-2.582 2.74-2.66-.213-1.885-1.176-3.635-2.692-4.82C6.229 1.656 6.139.939 6.529.44c.389-.497 1.106-.586 1.604-.198 2.039 1.594 3.322 3.963 3.569 6.508l.11.002c2.272.04 4.496-.564 6.435-1.75.54-.328 1.242-.158 1.572.38.329.538.159 1.242-.38 1.57" />
  </svg>
);

IconSportGymnastics.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportGymnastics.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
