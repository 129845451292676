
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import NavItem from 'reactstrap/lib/NavItem';
import NavLink from 'reactstrap/lib/NavLink';

import { isEmptyOrNil } from 'core/helpers';

export class TabLabel extends Component {
  static propTypes = {
    label: PropTypes.string,
    values: PropTypes.shape(),
    isActive: PropTypes.bool.isRequired,
    toggleTab: PropTypes.func.isRequired,
    tabId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    className: PropTypes.string,
    external: PropTypes.node,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    label: null,
    values: {},
    className: null,
    external: null,
    onClick: null,
  };

  toggleTab = () => {
    const {
      isActive,
      toggleTab,
      tabId,
      onClick,
    } = this.props;

    if (!isActive) {
      toggleTab(tabId);
    }

    if (onClick) {
      onClick(tabId);
    }
  }

  render() {
    const {
      label,
      values,
      isActive,
      className,
      external,
    } = this.props;

    return (
      <NavItem onClick={this.toggleTab} className={classNames('cursor-pointer position-relative', className, { active: isActive })}>
        <NavLink className={classNames('h6 text-uppercase text-nowrap p-0 pb-1_5', {
          'text-extra-2': !isActive,
        })}
        >
          {external}
          {isEmptyOrNil(label) ? null : <FormattedMessage id={label} values={values} />}
        </NavLink>
      </NavItem>
    );
  }
}
