import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportRally = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M10 11c-.553 0-1-.447-1-1 0-.553.447-1 1-1 .553 0 1 .447 1 1 0 .553-.447 1-1 1m5.217-3.75l-2.907.944c-.647.21-1.31-.271-1.31-.951V4.191c0-.315.293-.567.6-.49 1.631.41 3.018 1.44 3.896 2.825.17.268.022.625-.279.724m-7.64 4.388l-1.796 2.475c-.197.272-.592.271-.804.012-.502-.609-.901-1.321-1.159-2.116-.258-.796-.354-1.606-.306-2.394.02-.334.339-.567.657-.464l2.908.949c.646.21.899.988.5 1.538m5.346-1.538l2.908-.949c.318-.103.637.13.657.464.048.788-.048 1.598-.306 2.394-.258.795-.657 1.507-1.159 2.116-.212.259-.607.26-.804-.012l-1.796-2.475c-.399-.55-.146-1.328.5-1.538m-2.114 2.711l1.799 2.472c.198.272.075.647-.238.769-.734.289-1.534.448-2.37.448-.836 0-1.636-.159-2.37-.448-.313-.122-.436-.497-.238-.769l1.799-2.472c.4-.549 1.218-.549 1.618 0M7.69 8.194L4.783 7.25c-.301-.099-.449-.456-.279-.724.878-1.385 2.265-2.415 3.896-2.825.307-.077.6.175.6.49v3.052c0 .68-.663 1.161-1.31.951M10 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8m0-18C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0" />
  </svg>
);

IconSportRally.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportRally.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
