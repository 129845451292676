import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportKickboxing = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M17.055.691c.623-.63 1.638-.99 2.268-.368.629.623.154 1.55-.468 2.18l-6.14 6.41c0 .022.007.043.007.065v9.419c0 .885-.362 1.603-1.247 1.603-.886 0-1.246-.718-1.246-1.603v-7.732l-2.4-.139 1.031 1.915c.368.683.113 1.534-.57 1.901-.211.114-.439.168-.664.168-.5 0-.983-.267-1.236-.738L4.052 9.429c-.269-.498-.202-1.085.118-1.51 0-.075 1.59-2.641 1.59-2.641.002-.003.005-.005.006-.008.07-.304.234-.59.5-.797l4.309-3.357c.611-.475 1.493-.366 1.969.245s.366 1.493-.245 1.97L8.227 6.502l.287.199c.182.126.501.175.713.11l2.077-.251zm-14.65 2.33c1.329 0 2.406 1.078 2.406 2.406S3.734 7.832 2.405 7.832C1.077 7.832 0 6.755 0 5.427c0-1.328 1.077-2.405 2.405-2.405z" />
  </svg>
);

IconSportKickboxing.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportKickboxing.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
