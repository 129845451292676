import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportMotorcycling = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M20 9.656v6.02c0 .41-.44.67-.8.48l-5.45-2.97v-4.1l6.25.57zm-8.78-.8l1.03.09v3.42l-1.04-.57c-.29-.15-.46-.44-.46-.77v-1.74c0-.16.09-.27.14-.32.05-.04.17-.12.33-.11zm-6.72 4.14c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm-2.08-7.37c1.55-2.7 4.46-4.38 7.58-4.38.41 0 .75.34.75.75s-.34.75-.75.75c-2.58 0-4.99 1.39-6.29 3.63-.13.24-.39.38-.65.38-.12 0-.25-.03-.37-.1-.36-.21-.48-.67-.27-1.03zm6.83 5.4v-1.74c0-.54.23-1.06.63-1.42.4-.37.93-.54 1.47-.5l8.47.77c-.9-4.81-5.26-8.41-10.39-8.12C4.08.305 0 4.965 0 10.325v2.34c0 .8.49 1.53 1.23 1.84l12.73 5.24c1.68.69 3.61-.1 4.33-1.78l.2-.48-8-4.37c-.77-.41-1.24-1.22-1.24-2.09z" />
  </svg>
);

IconSportMotorcycling.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportMotorcycling.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
