import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Collapse from 'reactstrap/lib/Collapse';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';

import { RouteLink } from 'components/route-link/route-link';
import { IconArrowRight } from 'components/icons/icon-arrow-right/icon-arrow-right';
import { SportSubitemBody } from './sport-subitem-body';
import { FavoritesToggleButton } from '../favorites-toggle-button/favorites-toggle-button';

import './sport-subitem.scss';

export class SportSubitem extends PureComponent {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    countryId: PropTypes.number,
    countryName: PropTypes.string,
    eventsCount: PropTypes.number.isRequired,
    inplayEventsCount: PropTypes.number.isRequired,
    sportId: PropTypes.number.isRequired,
    leagues: PropTypes.arrayOf(PropTypes.shape()),
    isCountries: PropTypes.bool,
    activeLeagueId: PropTypes.number,
    isActive: PropTypes.bool,
    setActiveCountries: PropTypes.func,
    setActiveLeagueId: PropTypes.func.isRequired,
    leagueId: PropTypes.number,
    leagueName: PropTypes.string,
    clearActiveCountries: PropTypes.func,
    clearActiveCountriesExceptSportAndCountry: PropTypes.func,
    sendLeagueClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activeLeagueId: null,
    leagueId: null,
    leagueName: null,
    countryId: null,
    countryName: null,
    leagues: null,
    isCountries: false,
    isActive: false,
    setActiveCountries: () => {},
    clearActiveCountries: () => {},
    clearActiveCountriesExceptSportAndCountry: () => {},
  };

  onCountryClick = () => {
    const { setActiveCountries, sportId, countryId } = this.props;

    setActiveCountries(sportId, countryId);
  };

  onTopLeagueClick = () => {
    const {
      setActiveLeagueId,
      sportId,
      leagueId,
      clearActiveCountries,
      sendLeagueClickFeedback,
    } = this.props;

    setActiveLeagueId(sportId, leagueId);
    clearActiveCountries();
    sendLeagueClickFeedback(leagueId);
  };

  render() {
    const {
      leagueId,
      leagueName,
      countryId,
      countryName,
      locale,
      eventsCount,
      inplayEventsCount,
      sportId,
      leagues,
      isCountries,
      activeLeagueId,
      isActive,
      setActiveLeagueId,
      clearActiveCountriesExceptSportAndCountry,
      sendLeagueClickFeedback,
    } = this.props;

    return (
      <div className={classNames('sports-list-subitem position-relative', {
        active: isActive,
        'with-collapse': isCountries,
      })}
      >
        {!isCountries && (
          <div className={classNames('sports-list-subitem-element d-flex justify-content-between pr-8 pr-sm-8_5 pr-lg-5 align-items-center position-relative', {
            'font-weight-bold bg-main-4 top-league': activeLeagueId === leagueId,
            'with-live-status': inplayEventsCount > 0,
          })}
          >
            <RouteLink
              to={`/league/${sportId}/${leagueId}`}
              locale={locale}
              onClick={this.onTopLeagueClick}
              className="pl-4 pl-lg-2_5 w-100 overflow-hidden"
            >
              <div className="d-flex my-1 py-0_25 overflow-hidden">
                <span className="navigation-item-name">{leagueName}</span>
                <span className="align-self-end text-extra-3 pl-1 font-weight-normal">{eventsCount}</span>
              </div>
            </RouteLink>
            <FavoritesToggleButton
              sportId={sportId}
              leagueId={leagueId}
              leagueName={leagueName}
              className="position-absolute favorites-button"
            />
          </div>
        )}

        {isCountries && leagues.length > 0 && (
          <Fragment>
            <div
              className={classNames('sports-list-subitem-element is-country d-flex pl-4 pl-lg-2_5 pr-8 pr-sm-8_5 pr-lg-5 align-items-center position-relative', {
                'font-weight-bold active': isActive,
                'with-live-status': inplayEventsCount > 0,
              })}
              tabIndex="0"
              role="button"
              onClick={this.onCountryClick}
              onKeyPress={this.onCountryClick}
            >
              <div className="d-flex align-items-center">
                <div className={classNames('right-arrow', { 'arrow-active': isActive })}>
                  <IconArrowRight />
                </div>
              </div>
              <div className="d-flex pl-1_5 my-1 py-0_25 overflow-hidden">
                <span className="navigation-item-name overflow-hidden">{countryName}</span>
                <span className="align-self-end text-extra-3 pl-1 font-weight-normal">{eventsCount}</span>
              </div>
            </div>
            <Collapse isOpen={isActive}>
              <Card className="collapse-card border-0">
                <CardBody className="p-0">
                  {leagues.map(({
                    leagueId: id,
                    leagueName: name,
                    eventsCount: leagueEventCount,
                    inplayEventsCount: leagueInplayEventsCount,
                  }) => (
                    <SportSubitemBody
                      key={id}
                      locale={locale}
                      leagueId={id}
                      leagueName={name}
                      sportId={sportId}
                      countryId={countryId}
                      eventsCount={leagueEventCount}
                      inplayEventsCount={leagueInplayEventsCount}
                      isActive={activeLeagueId === id}
                      setActiveLeagueId={setActiveLeagueId}
                      clearActiveCountriesExceptSportAndCountry={clearActiveCountriesExceptSportAndCountry}
                      sendLeagueClickFeedback={sendLeagueClickFeedback}
                    />
                  ))}
                </CardBody>
              </Card>
            </Collapse>
          </Fragment>
        )}
      </div>
    );
  }
}
