import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportOthers = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M18.498 2.75H16v4.967c1.477-.768 2.498-2.293 2.498-4.069V2.75zM10 7.55l1.83.96-.35-2.03 1.47-1.44-2.04-.3L10 2.89l-.91 1.85-2.04.3 1.469 1.44-.349 2.03L10 7.55zm-6 .169V2.75H1.5v.898c0 1.777 1.022 3.303 2.5 4.071zM19.998 2v1.648c0 2.692-1.755 4.978-4.179 5.787C15.175 12.052 12.814 14 10 14c-2.815 0-5.174-1.947-5.819-4.564C1.756 8.628 0 6.342 0 3.648V2c0-.414.336-.75.75-.75H4V.5c0-.28.22-.5.5-.5h11c.28 0 .5.22.5.5v.75h3.248c.414 0 .75.336.75.75zM14 19v1H6v-1c0-.553.447-1 1-1h2l-.53-2.66c.49.11 1.01.16 1.53.16.519 0 1.04-.05 1.53-.16L11 18h2c.553 0 1 .447 1 1z" />
  </svg>
);

IconSportOthers.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportOthers.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
