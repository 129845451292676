import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './in-play-animation.scss';

export const InPlayAnimation = ({
  firstAnimationPositionY,
  secondAnimationPositionY,
  thridAnimationPositionY,
  animationPositionX,
}) => (
  <Fragment>
    <span
      className={`position-absolute in-play-animation animation-first ${firstAnimationPositionY} ${animationPositionX}`}
    />
    <span
      className={`position-absolute in-play-animation animation-second ${secondAnimationPositionY} ${animationPositionX}`}
    />
    <span
      className={`position-absolute in-play-animation animation-third ${thridAnimationPositionY} ${animationPositionX}`}
    />
  </Fragment>
);

InPlayAnimation.propTypes = {
  firstAnimationPositionY: PropTypes.string.isRequired,
  secondAnimationPositionY: PropTypes.string.isRequired,
  thridAnimationPositionY: PropTypes.string.isRequired,
  animationPositionX: PropTypes.string.isRequired,
};
