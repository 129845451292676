import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as R from 'ramda';

import { FreebetsModal } from 'components/modals/freebets-modal/freebets-modal';
import { SkipServerRender } from 'components/skip-server-render/skip-server-render';
import { USER_STORE_FIELDS } from 'core/constants';
import { isEmptyOrNil, getShowedFreebetsData, setShowedFreebetsData } from 'core/helpers';
import { withFreebets, withModalActions, withUser } from 'core/hocs';

import { MODAL_IDS } from '../../constants';

class FreebetsUI extends Component {
  static propTypes = {
    freebets: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape()),
    }),
    currency: PropTypes.string.isRequired,
    openModal: PropTypes.func.isRequired,
    activateBonus: PropTypes.func.isRequired,
  };

  static defaultProps = {
    freebets: null,
  };

  state = {
    showedId: null,
  };

  componentDidUpdate(prevProps) {
    const { freebets: { items: freebets } } = this.props;
    const { freebets: { items: prevFreebets } } = prevProps;

    if ((isEmptyOrNil(prevFreebets) && !isEmptyOrNil(freebets))
      || (prevFreebets && freebets && freebets.length !== prevFreebets.length)) {
      this.showFreebet();
    }
  }

  showFreebet = () => {
    const { freebets: { items: freebets } } = this.props;

    const showedFreebetsData = getShowedFreebetsData();
    const foundFreebet = R.compose(
      R.head,
      R.reject(({ bonusId, activated }) => showedFreebetsData.includes(bonusId) || activated)
    )(freebets);

    if (foundFreebet) {
      this.setShowedId(foundFreebet.bonusId);
      setShowedFreebetsData(showedFreebetsData.concat(foundFreebet.bonusId));
    }
  };

  setShowedId = (showedId) => {
    const { openModal } = this.props;
    this.setState({ showedId });

    if (showedId) {
      openModal(MODAL_IDS.FREEBETS);
    }
  };

  onClosed = () => {
    this.setShowedId(null);
    this.showFreebet();
  };

  render() {
    const { freebets: { items: freebets }, currency, activateBonus } = this.props;
    const { showedId } = this.state;

    const showedFreebet = showedId && freebets && R.find(R.propEq('bonusId', showedId), freebets);

    return showedFreebet && (
      <SkipServerRender skip>
        <FreebetsModal
          activateBonus={activateBonus}
          key={showedId}
          freebet={showedFreebet}
          currency={currency}
          onClosed={this.onClosed}
        />
      </SkipServerRender>
    );
  }
}

export const Freebets = withModalActions(
  withUser(
    withFreebets(FreebetsUI),
    [USER_STORE_FIELDS.CURRENCY]
  )
);
