import React from 'react';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { IconUnsupportedCurrency } from 'components/icons/icon-unsupported-currency/icon-unsupported-currency';

import './unsupported-currency-state.scss';

export const UnsupportedCurrencyState = () => (
  <div className="unsupported-currency-state d-flex flex-column justify-content-center align-items-center bg-main-2">
    <IconUnsupportedCurrency />
    <FormattedTag id="ticket.unsupported-currency" className="unsupported-currency-state-title mt-3_5 text-center text-extra-3" />
  </div>
);
