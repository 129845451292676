import React from 'react';
import PropTypes from 'prop-types';

export const IconUcl = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14.4995 18.1537C14.7064 17.1934 14.5512 16.301 14.5512 16.301C13.9424 17.1833 13.3432 17.8555 12.7891 18.3673C11.2983 18.4452 10.1349 18.2539 10.1349 18.2539C10.1349 18.2539 10.8005 18.9231 11.5675 19.3098C10.5548 19.941 9.87622 19.9977 9.87622 19.9977C14.3106 20.0924 16.9719 17.1747 16.9719 17.1747C16.2244 17.6713 15.3589 17.975 14.4995 18.1537Z" fill="white" fillOpacity="0.4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.1357 18.2541C10.1357 18.2541 9.23302 17.8911 7.94158 17.1577C7.98729 16.3751 8.10734 15.3789 8.35297 14.1149C8.35297 14.1149 7.32625 15.1385 5.99714 15.9322C5.22509 15.3951 4.41436 14.7644 3.62623 14.037C3.62623 14.037 4.00949 15.2311 4.7489 16.5457C4.04767 16.8099 3.32485 16.9324 2.66431 16.7699C2.66431 16.7699 3.4449 17.7519 5.85247 18.1685C6.52004 18.9673 7.33379 19.6269 8.28265 19.8557C8.28265 19.8557 7.99684 19.5398 7.93304 18.3528C8.59007 18.3619 9.32093 18.3325 10.1357 18.2541Z" fill="white" fillOpacity="0.4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.81465 9.37777C3.7392 9.41169 2.89582 9.50685 2.2388 9.62783C2.05395 8.46814 2.02582 7.63393 2.02582 7.63393C1.49437 8.45093 1.15079 9.23857 0.937814 9.97204C0.188869 10.252 0 10.5228 0 10.5228C0.116536 10.9247 0.350111 11.316 0.644967 11.683C0.552542 13.4208 1.11061 14.5683 1.11061 14.5683C1.23568 13.9599 1.48634 13.3666 1.80028 12.8123C2.718 13.5534 3.62668 14.0368 3.62668 14.0368C3.19821 13.2633 2.88326 12.3972 2.6517 11.559C3.68043 10.258 4.81465 9.37777 4.81465 9.37777Z" fill="white" fillOpacity="0.4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2.33131 5.18998C2.84266 4.69138 3.46603 4.1543 4.221 3.59951C4.221 3.59951 3.5248 3.59951 2.78891 3.78832C3.27565 2.60079 3.81564 1.94273 3.81564 1.94273C3.81564 1.94273 0.716882 4.16341 0.128174 8.15477C0.128174 8.15477 0.388873 7.43293 1.27194 6.34461C1.62255 7.13579 2.0254 7.63389 2.0254 7.63389C2.03093 6.72274 2.15349 5.90371 2.33131 5.18998Z" fill="white" fillOpacity="0.4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.6402 1.90746C11.8189 1.49137 11.0333 1.20132 10.3019 1.00187C11.2327 0.209172 12.0982 0.00821253 12.0982 0.00821253C10.6043 -0.0515186 9.3199 0.220308 8.24445 0.636908C6.63857 0.508334 5.61586 0.788766 5.61586 0.788766C5.85647 0.971503 6.0594 1.298 6.26736 1.68828C4.91012 2.62423 4.22095 3.59967 4.22095 3.59967C5.03871 3.19066 5.9067 2.88037 6.76214 2.64397C6.85557 2.81659 6.95352 2.9892 7.06101 3.15776C7.62762 4.04664 8.24948 4.66724 8.24948 4.66724C8.472 3.60119 8.85426 2.77153 9.29378 2.12765C11.2036 1.85988 12.6402 1.90746 12.6402 1.90746Z" fill="white" fillOpacity="0.4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M19.4801 6.55178C18.9733 5.40221 18.2419 4.53459 17.4452 3.88109C16.7651 2.44248 15.9825 1.77785 15.9825 1.77785C16.0488 2.1089 15.9991 2.48399 15.8765 2.87072C14.1837 2.02538 12.6401 1.90743 12.6401 1.90743C13.6237 2.55688 14.4962 3.36224 15.2461 4.1924C14.5559 5.32527 13.6473 6.28046 13.6473 6.28046C14.8508 6.00711 15.8203 5.89221 16.5953 5.87145C17.7853 7.52266 18.4263 8.90964 18.4263 8.90964C18.4494 7.79348 18.3383 6.81956 18.1535 5.97978C19.1606 6.17314 19.4801 6.55178 19.4801 6.55178Z" fill="white" fillOpacity="0.4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20 10.694C19.7052 11.1602 19.2531 11.4923 18.7247 11.7261C18.6528 10.2318 18.4263 8.90965 18.4263 8.90965C17.9747 10.1372 17.4955 11.2265 17.0339 12.167C15.4421 12.373 13.8799 12.1366 13.8799 12.1366C14.7368 13.1723 15.4024 13.7868 15.9409 14.2014C15.1352 15.5702 14.5515 16.3011 14.5515 16.3011C15.6782 16.0414 16.5733 15.6102 17.2855 15.0989C17.5774 15.3009 17.828 15.5261 18.0762 15.9068C18.0762 15.9068 18.5358 15.2503 18.6935 13.7291C19.8313 12.2288 20 10.694 20 10.694Z" fill="white" fillOpacity="0.4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.09 11.2481C9.49574 12.1603 8.89699 13.1347 8.35249 14.1142C8.35249 14.1142 7.92854 12.6477 7.77031 10.5349C6.79382 10.2033 5.78568 9.81859 4.81421 9.3777C4.81421 9.3777 5.93687 8.80468 7.73314 8.1031C7.78387 7.00465 7.93557 5.83483 8.24901 4.66653C8.24901 4.66653 8.83973 5.76194 9.91467 7.31849C11.0464 6.9434 12.3102 6.57894 13.6468 6.28029C13.6468 6.28029 12.6538 7.523 11.394 9.31898C12.1153 10.2322 12.9486 11.1975 13.8799 12.1365C13.8799 12.1365 12.261 11.852 10.09 11.2481Z" fill="white" fillOpacity="0.4" />
  </svg>
);

IconUcl.propTypes = {
  className: PropTypes.string,
};

IconUcl.defaultProps = {
  className: '',
};
