import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { convertStringifiedBoolean } from 'core/helpers';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { IconArrowRight } from 'components/icons/icon-arrow-right/icon-arrow-right';
import { GA } from 'helpers/ga';
import { Sports } from '../sports/sports';
import { Games } from '../games/games';

class EventNavigationUI extends Component {
  static propTypes = {
    isUserLoggedIn: PropTypes.bool.isRequired,
    pageName: PropTypes.string.isRequired,
    sports: PropTypes.arrayOf(PropTypes.shape()),
    topLeagues: PropTypes.arrayOf(PropTypes.shape()),
    isSportsInProgress: PropTypes.bool.isRequired,
    params: PropTypes.shape({
      sportId: PropTypes.string,
      leagueId: PropTypes.string,
    }),
    activeSportId: PropTypes.number,
    activeLeagueId: PropTypes.number,
    activeCountries: PropTypes.shape(),
    setActiveSportId: PropTypes.func,
    setActiveCountries: PropTypes.func,
    setActiveLeagueId: PropTypes.func,
    clearActiveLeagueId: PropTypes.func,
    clearActiveCountries: PropTypes.func,
    clearActiveCountriesBySport: PropTypes.func,
    clearActiveCountriesExceptSport: PropTypes.func,
    clearActiveCountriesExceptSportAndCountry: PropTypes.func,
    favoriteSports: PropTypes.arrayOf(PropTypes.shape()),
    favoriteLeagues: PropTypes.arrayOf(PropTypes.shape()),
    isFavoritesInProgress: PropTypes.bool.isRequired,
    onFavoriteLeagueClick: PropTypes.func,
    location: PropTypes.shape().isRequired,
    sendSportClickFeedback: PropTypes.func.isRequired,
    sendLeagueClickFeedback: PropTypes.func.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    sports: null,
    topLeagues: null,
    params: {},
    activeSportId: null,
    activeLeagueId: null,
    favoriteSports: null,
    favoriteLeagues: null,
    activeCountries: {},
    setActiveSportId: () => {},
    setActiveCountries: () => {},
    setActiveLeagueId: () => {},
    clearActiveLeagueId: () => {},
    clearActiveCountries: () => {},
    clearActiveCountriesBySport: () => {},
    clearActiveCountriesExceptSport: () => {},
    clearActiveCountriesExceptSportAndCountry: () => {},
    onFavoriteLeagueClick: () => {},
  };

  state = {
    isGamesShowed: true,
  };

  componentDidMount() {
    const { clearActiveCountries } = this.props;
    clearActiveCountries();
  }

  toggleNavigation = () => {
    const { isGamesShowed } = this.state;
    const { location: { search } } = this.props;
    const { isLive } = qs.parse(search, { ignoreQueryPrefix: true });
    const formattedIsLive = convertStringifiedBoolean(isLive);

    let label;

    if (formattedIsLive) {
      label = isGamesShowed ? 'show-categories-live-event' : 'go-to-events-list-live-event';
    } else {
      label = isGamesShowed ? 'show-categories-prematch-event' : 'go-to-events-list-prematch-event';
    }

    if (label) {
      GA.event({
        category: 'navigation',
        label,
      });
    }

    this.setState(prevState => ({ isGamesShowed: !prevState.isGamesShowed }));
  };

  render() {
    const { isGamesShowed } = this.state;
    const {
      isUserLoggedIn,
      params,
      pageName,
      sports,
      topLeagues,
      isSportsInProgress,
      activeSportId,
      activeLeagueId,
      activeCountries,
      setActiveSportId,
      setActiveCountries,
      setActiveLeagueId,
      clearActiveLeagueId,
      clearActiveCountries,
      clearActiveCountriesBySport,
      clearActiveCountriesExceptSport,
      clearActiveCountriesExceptSportAndCountry,
      favoriteSports,
      favoriteLeagues,
      isFavoritesInProgress,
      onFavoriteLeagueClick,
      sendSportClickFeedback,
      sendLeagueClickFeedback,
      sendEventClickFeedback,
    } = this.props;

    return (
      <div>
        {!isGamesShowed && (
          <div className="p-2 pl-2_5">
            <div
              role="button"
              tabIndex="0"
              onClick={this.toggleNavigation}
              onKeyPress={this.toggleNavigation}
              className="d-flex align-items-center justify-content-end"
            >
              <FormattedTag
                id="left-sidebar.forward"
                className="text-small mr-1"
              />
              <IconArrowRight />
            </div>
          </div>
        )}

        <div className={classNames({ 'd-none': !isGamesShowed })}>
          <Games
            params={params}
            toggleNavigation={this.toggleNavigation}
            setActiveCountries={setActiveCountries}
            setActiveLeagueId={setActiveLeagueId}
            sendEventClickFeedback={sendEventClickFeedback}
          />
        </div>

        <div className={classNames({ 'd-none': isGamesShowed })}>
          <Sports
            isUserLoggedIn={isUserLoggedIn}
            params={params}
            pageName={pageName}
            sports={sports}
            topLeagues={topLeagues}
            isSportsInProgress={isSportsInProgress}
            activeSportId={activeSportId}
            activeLeagueId={activeLeagueId}
            activeCountries={activeCountries}
            setActiveSportId={setActiveSportId}
            setActiveCountries={setActiveCountries}
            setActiveLeagueId={setActiveLeagueId}
            clearActiveLeagueId={clearActiveLeagueId}
            clearActiveCountries={clearActiveCountries}
            clearActiveCountriesBySport={clearActiveCountriesBySport}
            clearActiveCountriesExceptSport={clearActiveCountriesExceptSport}
            clearActiveCountriesExceptSportAndCountry={clearActiveCountriesExceptSportAndCountry}
            favoriteSports={favoriteSports}
            favoriteLeagues={favoriteLeagues}
            isFavoritesInProgress={isFavoritesInProgress}
            onFavoriteLeagueClick={onFavoriteLeagueClick}
            sendSportClickFeedback={sendSportClickFeedback}
            sendLeagueClickFeedback={sendLeagueClickFeedback}
          />
        </div>
      </div>
    );
  }
}

export const EventNavigation = withRouter(EventNavigationUI);
