import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportSkiRacing = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M14.945 5.743c.973 0 1.762-.789 1.762-1.762 0-.972-.789-1.76-1.762-1.76s-1.762.788-1.762 1.76c0 .973.79 1.762 1.762 1.762m2.475 2.855l-.059.022c-.146.071-.326.135-.463.19v6.686c0 .311-.217.554-.528.554h-.07c-.31 0-.564-.243-.564-.554v-6.41c-.206.014-.3.021-.45.021-1.016 0-2.024-.326-2.855-.95l-2.667 1.78c.223.558.316 1.168.254 1.785-.112 1.138-.722 2.155-1.674 2.79l-.555.37 6.328 4.55-.04.055c-.363.507-1.069.621-1.574.258L1.235 11.646c-.253-.182-.31-.535-.129-.788l.041-.055c.182-.254.534-.312.787-.129l4.173 3c.077-.138.186-.26.325-.354l.775-.515c.438-.293.72-.76.771-1.286.052-.524-.132-1.038-.504-1.41l-.012-.014c-.097-.086-.185-.186-.26-.298-.47-.707-.28-1.662.427-2.134l2.31-1.54c-.663-.223-1.403-.183-2.058.144-.507.254-1.124.05-1.376-.458-.249-.498-.055-1.1.433-1.362L3.978.974C3.776.737 3.804.382 4.042.18l.052-.045c.237-.203.593-.174.795.063l3.262 3.827c1.53-.307 3.143.155 4.284 1.296l.434.434.068.068.446.445c.814.815 2.056 1.02 3.09.511l.044-.022c.51-.25 1.123-.04 1.373.467.25.51.037 1.123-.47 1.374" />
  </svg>
);

IconSportSkiRacing.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportSkiRacing.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
