import React from 'react';
import PropTypes from 'prop-types';

export const IconLeague1 = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5702 10.5974C14.6694 12.3256 14.1983 13.4461 13 15C11.3223 15.1189 9.17769 15.0951 7.5 15L5.63636 18.7631C8.84297 19.0565 11.7521 19.1278 14.7355 18.6839C16.876 16.1628 18.5289 13.5703 20 10.5974C18.8595 8.03662 17.2975 5.69787 15.5702 3.50976L13 7C13.9008 8.22091 14.8595 9.2496 15.5702 10.5974ZM7 13C6.09917 11.7632 5.15702 10.7242 4.42975 9.38437C5.32231 7.65608 6.29339 6.55388 7.5 5C9.17769 4.88108 10.8223 4.90486 12.5 5L14.3636 1.20272C11.3388 0.909389 8.28926 0.933173 5.26446 1.282C3.15702 3.75552 1.38843 6.47481 0 9.38437C1.16529 11.9372 2.6281 14.1729 4.42975 16.4561L7 13Z" fill="white" fillOpacity="0.4" />
    <path d="M10 13C11.6555 13 13 11.6555 13 10C12.9909 8.34451 11.6555 7 10 7C8.34451 7 7 8.34451 7 10C7 11.6555 8.34451 13 10 13Z" fill="white" fillOpacity="0.4" />
  </svg>
);

IconLeague1.propTypes = {
  className: PropTypes.string,
};

IconLeague1.defaultProps = {
  className: '',
};
