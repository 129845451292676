import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportEfootball = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M17.881 15.527c-.332 0-.604-.272-.604-.605 0-.332.272-.605.604-.605.333 0 .604.273.604.605 0 .333-.27.605-.604.605zm-1.512-.907c-.332 0-.604-.273-.604-.605 0-.333.272-.605.604-.605.333 0 .605.272.605.605 0 .332-.272.605-.605.605zm0 1.813c-.332 0-.604-.27-.604-.604 0-.332.272-.605.604-.605.333 0 .605.273.605.605 0 .333-.272.604-.605.604zm-4.535-.756h-.453v.454c0 .25-.206.454-.454.454-.248 0-.453-.205-.453-.454v-.454h-.454c-.247 0-.453-.205-.453-.453 0-.248.206-.453.453-.453h.454v-.454c0-.248.205-.453.453-.453.248 0 .454.205.454.453v.454h.453c.248 0 .453.205.453.453 0 .248-.205.453-.453.453zm7.063-2.26c-1.596-2.625-3.997-.799-3.997-.799-.158.115-.442.212-.64.212h-.642c-.193.006-.484-.097-.634-.212 0 0-2.408-1.826-4.004.8-1.596 2.617-.95 5.47-.95 5.47.11.696.485 1.161 1.18 1.108.701-.06 2.22-1.88 2.22-1.88.12-.152.386-.279.58-.279h3.857c.194 0 .46.127.581.278 0 0 1.518 1.82 2.22 1.881.7.053 1.07-.412 1.185-1.108 0 0 .64-2.853-.956-5.47zM1.48 4.06C.6 5.36.08 6.91 0 8.59L1.93 6.7l-.45-2.64zM4.63 15l-2.67-.39c1 1.26 2.33 2.24 3.85 2.8L4.63 15zm.31-6.6l-2.11-.69L.1 10.37c.14.98.45 1.9.89 2.75l3.78.54 1.3-1.79L4.94 8.4zm0-7.44c-.87.44-1.66 1.02-2.34 1.7l.65 3.77 2.19.71L8.32 5.3V2.74L4.94.96zM8.99 0c-.82 0-1.62.11-2.38.32l2.38 1.25L11.37.32C10.61.11 9.81 0 8.99 0zm0 6.47l-2.7 1.72 1.03 3.15h1.51c.62-.5 1.33-.78 2.1-.82l.76-2.33-2.7-1.72zm-1.84 6.22l-1.29 1.76.68 1.37c.16-.98.49-2.07 1.13-3.13h-.52zM13.04.96L9.66 2.74V5.3l2.88 1.84 2.18-.71.65-3.77c-.67-.68-1.46-1.26-2.33-1.7zm2.11 6.75l-2.11.69-.74 2.27c.69.18 1.22.5 1.46.66h.36c.34-.23 1.34-.83 2.59-.83.38 0 .76.06 1.11.18.03-.1.04-.2.06-.31l-2.73-2.66zm1.35-3.65l-.45 2.64 1.93 1.89c-.08-1.68-.6-3.23-1.48-4.53z" />
  </svg>
);

IconSportEfootball.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportEfootball.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
