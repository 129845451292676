import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import { BET_SLIP_MAX_SYSTEM_STAKES } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { IconArrowBottom } from 'components/icons/icon-arrow-bottom/icon-arrow-bottom';
import { GA } from 'helpers/ga';

import './bet-type-dropdown.scss';

const EXPRESS_ITEM = 'EXPRESS_ITEM';

export class BetTypeDropdown extends Component {
  static propTypes = {
    stakesAmount: PropTypes.number.isRequired,
    selectedItem: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    onExpressClick: PropTypes.func.isRequired,
    onSystemClick: PropTypes.func.isRequired,
  };

  state = {
    isOpen: false,
  };

  toggle = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }));

  prepareSystemIndexes = () => {
    const { stakesAmount } = this.props;
    const systemIndexes = [];

    if (stakesAmount > BET_SLIP_MAX_SYSTEM_STAKES) {
      return systemIndexes;
    }

    for (let i = 2; i < stakesAmount; i += 1) {
      systemIndexes.push(i);
    }

    return systemIndexes;
  };

  onDropdownClick = () => {
    GA.event({
      category: 'bet-slip',
      label: 'bet-type-dropdown-click',
    });
  };

  render() {
    const {
      stakesAmount,
      selectedItem,
      onExpressClick,
      onSystemClick,
    } = this.props;
    const { isOpen } = this.state;

    return (
      <div className="bet-type-dropdown">
        <Dropdown isOpen={isOpen} toggle={this.toggle} onClick={this.onDropdownClick}>
          <DropdownToggle className="bet-type-dropdown-toggle caption btn-outline-secondary pl-1_5 pr-1">
            <FormattedMessage
              id={`bet-slip.type.${selectedItem === EXPRESS_ITEM ? 'express' : 'system'}`}
              values={selectedItem === EXPRESS_ITEM ? {} : { systemIndex: selectedItem, stakesAmount }}
            />
            <IconArrowBottom className="ml-1" color="white" opacity={0.65} backgroundColor="transparent" />
          </DropdownToggle>
          <DropdownMenu>
            <FormattedTag
              id="bet-slip.type.express"
              tag={DropdownItem}
              onClick={onExpressClick}
              className="text-small"
            />
            {this.prepareSystemIndexes().map(idx => (
              <FormattedTag
                key={idx}
                id="bet-slip.type.system"
                values={{ systemIndex: idx, stakesAmount }}
                tag={DropdownItem}
                onClick={onSystemClick}
                data-system-index={idx}
                className="text-small"
              />
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}
