import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportCycling = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M16 11.501c-1.379 0-2.5 1.121-2.5 2.5 0 1.38 1.121 2.5 2.5 2.5s2.5-1.12 2.5-2.5c0-1.379-1.121-2.5-2.5-2.5m0 6.5c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4m-12-6.5c-1.379 0-2.5 1.121-2.5 2.5 0 1.38 1.121 2.5 2.5 2.5s2.5-1.12 2.5-2.5c0-1.379-1.121-2.5-2.5-2.5m0 6.5c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4m9.177-12.629c.203.138.434.233.675.28h.002c.597.116 1.225-.077 1.656-.505.436-.437.628-1.08.501-1.684-.124-.589-.54-1.092-1.095-1.323-.568-.236-1.23-.17-1.74.173-.511.344-.82.933-.814 1.548.006.602.316 1.175.815 1.511m-2.765 3.703l-1.143-.887 2.255-1.155 1.904 1.588c.128.107.291.175.47.185l1.814.094c.431.024.819-.279.893-.714.081-.469-.235-.773-.704-.853l-1.854-.277s-1.353-1.865-1.658-2.185c-.913-.954-1.461-1.49-2.512-.972L6.671 5.483c-.922.465-1.525 1.696-1.007 2.746.255.517.656.778 1.242 1.158l1.856 1.132.22 3.546c.033.492.442.881.94.877.516-.003.932-.424.929-.94l-.027-4.13c-.004-.303-.146-.606-.412-.797" />
  </svg>
);

IconSportCycling.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportCycling.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
