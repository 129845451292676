import * as R from 'ramda';

export const prepareInplayGames = (inplayGames, watchlist) => {
  const preparedWatchlist = [];
  const preparedInplayGames = [];

  inplayGames.forEach((group) => {
    const foundWatchlistGroup = R.find(R.propEq('leagueId', group.leagueId), watchlist);

    if (foundWatchlistGroup) {
      const updatedWatchlistGames = [];
      const updatedInplayGames = [];

      group.games.forEach((event) => {
        const foundWatchlistEvent = R.find(R.propEq('eventId', event.eventId), foundWatchlistGroup.games);

        if (foundWatchlistEvent) {
          updatedWatchlistGames.push(event);
        } else {
          updatedInplayGames.push(event);
        }
      });

      if (updatedWatchlistGames.length) {
        preparedWatchlist.push(...updatedWatchlistGames);
      }

      if (updatedInplayGames.length) {
        preparedInplayGames.push({
          sportId: group.sportId,
          sportName: group.sportName,
          leagueId: group.leagueId,
          leagueName: group.leagueName,
          games: updatedInplayGames,
        });
      }
    } else {
      preparedInplayGames.push(group);
    }
  });

  return {
    preparedWatchlist,
    preparedInplayGames,
  };
};
