import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportPoker = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M15.23 5.754c.778.971 1.294 2.158 1.438 3.458h3.256c-.163-2.196-1.032-4.192-2.385-5.766L15.23 5.754zM9.213 16.668c-1.3-.144-2.487-.66-3.458-1.437l-2.307 2.307c1.573 1.354 3.569 2.223 5.765 2.386v-3.256zm4.958-1.437c-.97.777-2.158 1.293-3.458 1.437v3.256c2.197-.163 4.193-1.032 5.766-2.386l-2.308-2.307zm2.498-4.519c-.144 1.3-.66 2.487-1.437 3.458l2.308 2.308c1.353-1.574 2.222-3.57 2.385-5.766h-3.256zM4.694 14.17c-.777-.97-1.293-2.158-1.438-3.458H0c.163 2.196 1.032 4.192 2.385 5.766l2.308-2.308zM10.712 0v3.256c1.3.144 2.488.66 3.458 1.437l2.308-2.307C14.905 1.032 12.908.163 10.712 0zm-.75 4.712c-2.894 0-5.25 2.355-5.25 5.25s2.356 5.25 5.25 5.25c2.895 0 5.25-2.355 5.25-5.25s-2.355-5.25-5.25-5.25zm-6.706 4.5c.145-1.3.66-2.487 1.438-3.458L2.386 3.446C1.033 5.02.164 7.016 0 9.212h3.255zm.19-6.826C5.02 1.032 7.017.163 9.213 0v3.256c-1.3.144-2.487.66-3.458 1.437L3.447 2.386z" />
  </svg>
);

IconSportPoker.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportPoker.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
