import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocale, withCoreComponent } from 'core/hocs';
import { InplayGames as InplayGamesCore } from 'core/components';
import { getConfig } from 'core/helpers';
import { TOP_EVENTS_TYPES } from 'core/constants';

import { Tabs, Tab } from 'components/tabs/tabs';
import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { IconMenu } from 'components/icons/icon-menu/icon-menu';
import { IconLivestream } from 'components/icons/icon-livestream/icon-livestream';
import { AllGames } from './all-games/all-games';
import { TopGames } from './top-games/top-games';
import { GamesWithLiveStream } from './games-with-live-stream/games-with-live-stream';

const IS_LIVE_STREAMS_ENABLED = getConfig('IS_LIVE_STREAMS_ENABLED');

const TABS = {
  ALL: 'ALL',
  TOP: 'TOP',
  WITH_LIVE_STREAM: 'WITH_LIVE_STREAM',
};

class InplayGamesUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    params: PropTypes.shape({
      eventId: PropTypes.string,
    }).isRequired,
    toggleNavigation: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape()),
    isInProgress: PropTypes.bool.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    items: null,
  };

  state = {
    activeTab: TABS.ALL,
  };

  toggleTab = activeTab => this.setState({ activeTab });

  render() {
    const {
      locale,
      items,
      isInProgress,
      params,
      toggleNavigation,
      sendEventClickFeedback,
    } = this.props;
    const { activeTab } = this.state;
    const tabs = [
      <Tab tabId={TABS.ALL} label="left-sidebar.inplay.tabs.all">
        {activeTab === TABS.ALL && (
          <AllGames
            locale={locale}
            params={params}
            items={items}
            isInProgress={isInProgress}
            sendEventClickFeedback={sendEventClickFeedback}
          />
        )}
      </Tab>,
      <Tab tabId={TABS.TOP} label="left-sidebar.inplay.tabs.top">
        {activeTab === TABS.TOP && (
          <TopGames
            locale={locale}
            params={params}
            coreProps={{ type: TOP_EVENTS_TYPES.LIVE }}
            sendEventClickFeedback={sendEventClickFeedback}
          />
        )}
      </Tab>
    ];

    if (IS_LIVE_STREAMS_ENABLED) {
      tabs.push(
        <Tab tabId={TABS.WITH_LIVE_STREAM} external={<IconLivestream />}>
          {activeTab === TABS.WITH_LIVE_STREAM && (
            <GamesWithLiveStream
              locale={locale}
              params={params}
              items={items}
              isInProgress={isInProgress}
              sendEventClickFeedback={sendEventClickFeedback}
            />
          )}
        </Tab>
      );
    }

    return (
      <div className="list-of-games bg-main-2 rounded">
        <div className="p-2 pl-2_5">
          <div
            role="button"
            tabIndex="0"
            onClick={toggleNavigation}
            onKeyPress={toggleNavigation}
            className="d-flex align-items-center"
          >
            <IconMenu />
            <FormattedTag
              id="left-sidebar.back"
              className="text-small ml-2_5"
            />
          </div>
        </div>

        <Tabs activeTab={activeTab} toggleTab={this.toggleTab} className="pt-1_5">
          {tabs}
        </Tabs>
      </div>
    );
  }
}

export const InplayGames = withLocale(withCoreComponent(InplayGamesCore, InplayGamesUI));
