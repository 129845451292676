/* eslint-disable global-require */
module.exports = {
  MAX_EXPRESS_BET_FACTOR: 1000,
  IS_LIVE_STREAMS_ENABLED: true,
  SUPPORTED_CURRENCIES: [
    'EUR',
    'USD',
    'TRY',
    'DZD',
  ],
  IS_BALANCE_FETCHING_ENABLED: false,
  CURRENCY_PRESETS: {
    EUR: ['5', '10', '20', '50'],
    USD: ['5', '10', '20', '50'],
    TRY: ['10', '25', '50', '100'],
    DZD: ['500', '1000', '2500', '5000'],
  },
  BANNERS_MAPPING: {},
  BETS_FEED_CURRENCIES: [
    'EUR',
    'USD',
    'TRY',
    'DZD',
  ],
  IS_AI_ENABLED: false,
  IS_STAKE_AUTOFILL_ENABLED: true,
  IS_ONLY_LIVE_SERVICE: false,
};
/* eslint-enable global-require */
