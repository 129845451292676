import React from 'react';
import PropTypes from 'prop-types';

export const IconDatePicker = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path fill="#FFF" fillRule="evenodd" d="M17.5 5.545h-.75V4h-1.5v1.545h-6.5V4h-1.5v1.545H6.5c-.825 0-1.5.696-1.5 1.546v10.364C5 18.305 5.675 19 6.5 19h11c.825 0 1.5-.695 1.5-1.545V7.09c0-.85-.675-1.546-1.5-1.546zm0 11.91h-11V9.409h11v8.046z" />
  </svg>
);

IconDatePicker.propTypes = {
  className: PropTypes.string,
};

IconDatePicker.defaultProps = {
  className: null,
};
