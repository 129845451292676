import React from 'react';
import PropTypes from 'prop-types';

export const IconEuroleague = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#icon-leagues-a)">
      <path d="M4.73047 7.70741H4.73961C4.73961 7.70741 8.14509 9.37802 10.5351 9.91229C10.6728 9.93652 10.6819 9.96638 10.6819 10.005L10.5807 10.0834C8.32142 10.554 4.7375 12.2615 4.72836 12.2657C4.72591 12.2664 1.94988 13.4906 1.94672 15.0217C1.94426 15.9132 2.59621 16.6874 2.83577 16.9628L2.96784 17.1082C4.76876 18.8905 7.2441 19.9928 9.97657 19.9984C14.3196 20.0076 18.0222 17.2466 19.4115 13.3796C19.3788 13.217 19.07 13.186 18.7546 13.2201C13.6265 13.7762 12.1172 18.0573 12.0947 18.1027C7.78188 19.4185 8.09135 16.9748 8.09135 16.9748C8.08643 15.2272 11.4343 12.9907 14.1348 11.8695C14.4878 11.7234 16.7721 10.8551 19.7283 10.5147C19.918 10.4996 19.9837 10.337 19.9928 10.1792C20.0054 9.94882 20.0037 9.79531 19.9693 9.59334C19.925 9.39628 19.7546 9.34886 19.6236 9.33622C19.2604 9.29125 18.9165 9.24348 18.5909 9.1936C17.56 9.01586 15.9227 8.64599 14.1394 7.92133C12.1867 7.12924 8.15598 5.06944 8.12085 3.01807C8.12085 3.01807 7.82123 0.572581 12.1291 1.90703C12.1509 1.95128 13.6427 6.23984 18.7672 6.81767C19.0827 6.8535 19.3918 6.82364 19.4252 6.65995C18.0531 2.78694 14.3631 0.00915717 10.0201 2.43641e-05C7.28661 -0.00594709 4.80705 1.08613 2.99805 2.86035L2.86598 3.00577C2.62466 3.27976 1.97096 4.05183 1.96675 4.94263C1.96288 6.01293 3.20846 6.95536 4.73047 7.70741Z" fill="white" fillOpacity="0.4" />
      <path d="M0.797383 13.7509C0.839886 13.7509 0.925242 13.7382 0.925242 13.6497C0.927701 12.6304 2.06052 11.7322 3.48489 11.0047C4.2387 10.6422 5.03676 10.2874 5.77758 10.0728L5.88366 9.99448L5.75334 9.90175C5.11545 9.67553 4.20462 9.30741 3.43255 8.93578C2.03769 8.20796 0.939644 7.31541 0.941401 6.30939C0.942103 6.22087 0.856044 6.20753 0.814595 6.20753L0.69622 6.3252C0.249414 7.45556 0.00317996 8.68779 1.86063e-05 9.97762C-0.00244023 11.2668 0.238877 12.4997 0.680413 13.6325L0.797383 13.7509Z" fill="white" fillOpacity="0.4" />
    </g>
  </svg>
);

IconEuroleague.propTypes = {
  className: PropTypes.string,
};

IconEuroleague.defaultProps = {
  className: '',
};
