import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withLocale } from 'core/hocs';
import { LOCALE_KEYS } from 'core/constants';

import { RouteLink } from 'components/route-link/route-link';
import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { IconLive } from 'components/icons/icon-live/icon-live';
import { IconLiveTr } from 'components/icons/icon-live-tr/icon-live-tr';
import { IconHome } from 'components/icons/icon-home/icon-home';
import { IconStar } from 'components/icons/icon-star/icon-star';
import { IconCalendar } from 'components/icons/icon-calendar/icon-calendar';
import { IconBetsFeed } from 'components/icons/icon-bets-feed/icon-bets-feed';
import { InPlayAnimation } from 'components/in-play-animation/in-play-animation';
import { PAGE_NAMES } from '../../constants';

import './fast-navigation-item.scss';

const ICON_COMPONENT_MAPPING = {
  'left-sidebar.in-play': IconLive,
  'left-sidebar.home': IconHome,
  'left-sidebar.watchlist': IconStar,
  'left-sidebar.calendar': IconCalendar,
  'left-sidebar.bets-feed': IconBetsFeed,
};

const getIconComponent = (itemId, locale) => {
  if (itemId === 'left-sidebar.in-play' && locale === LOCALE_KEYS.TR) {
    return IconLiveTr;
  }

  return ICON_COMPONENT_MAPPING[itemId];
};

class FastNavigationItemUI extends Component {
  static propTypes = {
    itemId: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    total: PropTypes.number,
    handleClick: PropTypes.func,
    locale: PropTypes.string.isRequired,
    activePage: PropTypes.string.isRequired,
    page: PropTypes.string.isRequired,
    inProgress: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    total: null,
    handleClick: null,
    inProgress: false,
    className: '',
  };

  onClick = (...args) => {
    const { handleClick } = this.props;
    handleClick(...args);
  };

  render() {
    const {
      itemId,
      link,
      total,
      locale,
      page,
      activePage,
      inProgress,
      className,
    } = this.props;
    const IconComponent = getIconComponent(itemId, locale);
    const isNew = itemId === 'left-sidebar.bets-feed';

    return (
      <>
        <RouteLink
          className={classNames(
            'd-flex fast-navigation-item justify-content-between align-items-center pl-lg-2_5 pr-2 inert-element text-capitalize position-relative',
            { 'bg-main-4 active font-weight-bold': page === activePage }, className,
          )}
          to={link}
          locale={locale}
          onClick={this.onClick}
        >
          <div className={classNames('icon text-truncate', { 'active-icon': page === activePage })}>
            <IconComponent />
            <FormattedTag className="pl-2_5 align-text-top" id={itemId} />
            {inProgress
              ? <span className="dot-preloader" />
              : <span className="text-extra-3 pl-1 d-inline font-weight-normal align-text-top">{total}</span>}

            {page === PAGE_NAMES.LIVE && activePage !== PAGE_NAMES.LIVE && (
              <InPlayAnimation
                firstAnimationPositionY="first-animation-positionY"
                secondAnimationPositionY="second-animation-positionY"
                thridAnimationPositionY="third-animation-positionY"
                animationPositionX="animation-positionX"
              />
            )}

            {isNew && <FormattedTag id="general.new" className="fast-navigation-item-new font-weight-bolder text-uppercase" />}
          </div>
        </RouteLink>
      </>
    );
  }
}

export const FastNavigationItem = withLocale(FastNavigationItemUI);
