import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportPool = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M10 13.25c-.689 0-1.25-.561-1.25-1.25s.561-1.25 1.25-1.25 1.25.561 1.25 1.25-.561 1.25-1.25 1.25M8.75 8c0-.689.561-1.25 1.25-1.25s1.25.561 1.25 1.25-.561 1.25-1.25 1.25S8.75 8.689 8.75 8m3.125 2c.535-.502.875-1.21.875-2 0-1.517-1.233-2.75-2.75-2.75S7.25 6.483 7.25 8c0 .79.34 1.498.875 2-.535.502-.875 1.21-.875 2 0 1.517 1.233 2.75 2.75 2.75s2.75-1.233 2.75-2.75c0-.79-.34-1.498-.875-2M10 17c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7m0-17C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0" />
  </svg>
);

IconSportPool.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportPool.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
