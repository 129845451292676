import React from 'react';
import PropTypes from 'prop-types';

export const IconInfo = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
    <path fill="#FFF" fillOpacity=".45" fillRule="evenodd" d="M5.5.5c2.761 0 5 2.239 5 5s-2.239 5-5 5-5-2.239-5-5 2.239-5 5-5zm0 1c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zM6 5v3H5V5h1zm0-2v1H5V3h1z" />
  </svg>
);

IconInfo.propTypes = {
  className: PropTypes.string,
};

IconInfo.defaultProps = {
  className: null,
};
