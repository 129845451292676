import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withGlobalEvents, withModalActions } from 'core/hocs';
import { GLOBAL_EVENTS } from 'core/constants';

import { MODAL_IDS } from '../../constants';

export class GlobalEventsHandlerUI extends PureComponent {
  static propTypes = {
    globalEvents: PropTypes.shape().isRequired,
    openModal: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { globalEvents } = this.props;

    globalEvents
      .on(GLOBAL_EVENTS.ADD_ACCUMULATOR_OF_THE_DAY, this.openAddAccumulatorModal);
  }

  componentWillUnmount() {
    const { globalEvents } = this.props;
    globalEvents.removeAllListeners();
  }

  openAddAccumulatorModal = ({ payload }) => {
    const { openModal } = this.props;
    openModal({ id: MODAL_IDS.ADD_ACCUMULATOR, data: payload });
  };

  render() {
    return null;
  }
}

export const GlobalEventsHandler = withGlobalEvents(withModalActions(GlobalEventsHandlerUI));
