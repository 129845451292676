import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { BetSlip } from 'components/bet-slip/bet-slip';

export class BetSlipTab extends Component {
  static propTypes = {
    scrollTicketToBottom: PropTypes.func.isRequired,
  };

  static defaultProps = {
  };

  state = {
  };

  render() {
    const { scrollTicketToBottom } = this.props;

    return (
      <div>
        <BetSlip scrollTicketToBottom={scrollTicketToBottom} />
      </div>
    );
  }
}
