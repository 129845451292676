import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportTableFootball = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M14.5 9.75H20v-1.5h-5.5v1.5zM0 9.75h5.5v-1.5H0v1.5zM12 5h-1.016L9.966 7l-.977-2H8c-.553 0-1 .447-1 1v6c0 .553.447 1 1 1l.833 5H8c-.553 0-1 .447-1 1 0 .553.447 1 1 1h4c.553 0 1-.447 1-1 0-.553-.447-1-1-1h-.833L12 13c.553 0 1-.447 1-1V6c0-.553-.447-1-1-1zM8 2c0-1.104.896-2 2-2s2 .896 2 2-.896 2-2 2-2-.896-2-2z" />
  </svg>
);

IconSportTableFootball.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportTableFootball.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
