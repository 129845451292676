import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  withCoreComponent,
  withWatchlist,
  withWatchlistActions,
} from 'core/hocs';
import { TopEvents as TopEventsCore } from 'core/components';
import { WATCHLIST_STORE_FIELDS, AI_OUTCOME_SECTION } from 'core/constants';

import { IconStar } from 'components/icons/icon-star/icon-star';
import { IconStarEmpty } from 'components/icons/icon-star-empty/icon-star-empty';
import { SportTypeIcon } from 'components/sport-type-icon/sport-type-icon';
import { prepareInplayGames } from 'helpers/watchlist';
import { groupGamesByLeague } from 'helpers/games';
import { GA } from 'helpers/ga';
import { GamesPreloader } from '../../games-preloader/games-preloader';
import { EmptyState } from '../../empty-state/empty-state';
import { GameItem } from '../../game-item/game-item';
import colors from '../../../../../customizations/js/color-variables';

class TopGamesUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape()),
    isInProgress: PropTypes.bool.isRequired,
    params: PropTypes.shape({
      eventId: PropTypes.string,
    }).isRequired,
    watchlistInplay: PropTypes.arrayOf(PropTypes.shape()),
    updateWatchlist: PropTypes.func.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    items: null,
    watchlistInplay: null,
  };

  onEventClick = () => {
    GA.event({
      category: 'go-to-event-page',
      label: 'left-sidebar-live-event-top',
    });
  }

  render() {
    const {
      locale,
      items,
      isInProgress,
      params: { eventId },
      watchlistInplay,
      updateWatchlist,
      sendEventClickFeedback,
    } = this.props;

    const groupItems = groupGamesByLeague(items || []);
    const { preparedWatchlist, preparedInplayGames } = prepareInplayGames(groupItems, watchlistInplay || []);

    if (isInProgress) {
      return <GamesPreloader />;
    }

    if (!preparedWatchlist.length && !preparedInplayGames.length) {
      return <EmptyState />;
    }

    return (
      <Fragment>
        <div className="list-of-games label d-flex flex-column text-capitalize">
          <div className="game-title w-100 py-1 px-2 d-flex align-items-center text-extra-3">
            <IconStar className="icon-star mr-1" color={colors.cpSecondary3} />
            <FormattedMessage id="left-sidebar.watchlist" />
          </div>

          {preparedWatchlist.length
            ? (
              <div>
                {preparedWatchlist.map(game => (
                  <GameItem
                    key={game.eventId}
                    eventData={game}
                    locale={locale}
                    isActive={Number(eventId) === game.eventId}
                    withWathlistToggle
                    updateWatchlist={updateWatchlist}
                    isInWatchlist
                    sendEventClickFeedback={sendEventClickFeedback}
                    sectionBeforeEventPage={AI_OUTCOME_SECTION.LEFT_SIDEBAR_LIVE_TOP}
                  />
                ))}
              </div>
            )
            : (
              <div className="p-2_5 text-center text-small font-weight-normal text-extra-2 d-flex align-items-center flex-wrap">
                <FormattedMessage id="left-sidebar.watchlist.title.1" />
                <IconStarEmpty className="icon-star-empty mx-0_5 flex-shrink-0" />
                <FormattedMessage id="left-sidebar.watchlist.title.2" />
              </div>
            )}
        </div>

        {preparedInplayGames && preparedInplayGames.map(({
          sportId,
          leagueId,
          leagueName,
          games,
        }) => (
          <div key={leagueId} className="list-of-games label d-flex flex-column text-capitalize">
            <div className="game-title w-100 py-1 px-2 d-flex align-items-center text-extra-3">
              <SportTypeIcon sportId={sportId} width="12" height="12" isActive className="sport-type-icon mr-1" />
              <span>
                {leagueName}
              </span>
            </div>

            <div>
              {games.map(game => (
                <GameItem
                  key={game.eventId}
                  eventData={game}
                  locale={locale}
                  isActive={Number(eventId) === game.eventId}
                  withWathlistToggle
                  updateWatchlist={updateWatchlist}
                  onEventClick={this.onEventClick}
                  sendEventClickFeedback={sendEventClickFeedback}
                  sectionBeforeEventPage={AI_OUTCOME_SECTION.LEFT_SIDEBAR_LIVE_TOP}
                />
              ))}
            </div>
          </div>
        ))}
      </Fragment>
    );
  }
}

export const TopGames = withWatchlistActions(withCoreComponent(TopEventsCore, withWatchlist(
  TopGamesUI,
  [WATCHLIST_STORE_FIELDS.INPLAY]
)));
