import React from 'react';
import PropTypes from 'prop-types';

export const IconStar = ({ className, color }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path fill={color} d="M20 7.621L12.81 6.968 10 0 7.19 6.979 0 7.621 5.46 12.6 3.82 20 10 16.074 16.18 20 14.55 12.6z" />
  </svg>
);

IconStar.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconStar.defaultProps = {
  className: null,
  color: '#FFFFFF',
};
