import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportBaseball = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M17.51 3.41c-.41.41-.78.85-1.12 1.33l.62.36c.36.21.49.67.28 1.02-.14.25-.39.38-.65.38-.13 0-.26-.03-.38-.1l-.61-.35c-.26.51-.45 1.05-.6 1.61l.71.13c.41.07.68.46.61.86-.07.37-.38.62-.74.62-.04 0-.09 0-.13-.01l-.71-.12c-.03.28-.04.57-.04.86 0 .29.01.58.04.86l.71-.12c.41-.07.8.2.87.61.07.4-.2.79-.61.86l-.71.13c.15.56.34 1.1.6 1.61l.61-.35c.36-.21.82-.08 1.03.28.21.35.08.81-.28 1.02l-.62.36c.34.48.71.92 1.12 1.33C19.06 14.83 20 12.53 20 10c0-2.53-.94-4.83-2.49-6.59zM14.42 16.4c-.12.07-.25.1-.38.1-.26 0-.51-.13-.65-.38-.21-.35-.08-.81.28-1.02l.67-.39c-.33-.67-.59-1.37-.77-2.11l-.77.14c-.04 0-.08.01-.13.01-.35 0-.67-.26-.73-.62-.08-.41.2-.8.6-.87l.77-.14c-.04-.37-.06-.74-.06-1.12 0-.38.02-.75.06-1.12l-.77-.14c-.4-.07-.68-.46-.6-.87.07-.41.46-.68.86-.61l.77.14c.18-.74.44-1.44.77-2.11l-.67-.39c-.36-.21-.49-.67-.28-1.02.21-.36.67-.49 1.03-.28l.67.39c.4-.59.85-1.13 1.36-1.63C14.71.89 12.46 0 10 0S5.29.89 3.55 2.36c.51.5.96 1.04 1.36 1.63l.67-.39c.36-.21.82-.08 1.03.28.21.35.08.81-.28 1.02l-.67.39c.33.67.59 1.37.77 2.11l.77-.14c.41-.07.79.2.86.61.08.41-.2.8-.6.87l-.77.14c.04.37.06.74.06 1.12 0 .38-.02.75-.06 1.12l.77.14c.4.07.68.46.6.87-.06.36-.38.62-.73.62-.05 0-.09-.01-.13-.01l-.77-.14c-.18.74-.44 1.44-.77 2.11l.67.39c.36.21.49.67.28 1.02-.14.25-.39.38-.65.38-.13 0-.26-.03-.38-.1l-.67-.39c-.4.59-.85 1.13-1.36 1.63C5.29 19.11 7.54 20 10 20s4.71-.89 6.45-2.36c-.51-.5-.96-1.04-1.36-1.63l-.67.39zM4.5 10.74l.71.12c.03-.28.04-.57.04-.86 0-.29-.01-.58-.04-.86l-.71.12c-.04.01-.09.01-.13.01-.36 0-.67-.25-.74-.62-.07-.4.2-.79.61-.86l.71-.13c-.15-.56-.34-1.1-.6-1.61l-.61.35c-.12.07-.25.1-.38.1-.26 0-.51-.13-.65-.38-.21-.35-.08-.81.28-1.02l.62-.36c-.34-.48-.71-.92-1.12-1.33C.94 5.17 0 7.47 0 10c0 2.53.94 4.83 2.49 6.59.41-.41.78-.85 1.12-1.33l-.62-.36c-.36-.21-.49-.67-.28-1.02.21-.36.67-.49 1.03-.28l.61.35c.26-.51.45-1.05.6-1.61l-.71-.13c-.41-.07-.68-.46-.61-.86.07-.41.46-.68.87-.61z" />
  </svg>
);

IconSportBaseball.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportBaseball.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
