import React from 'react';
import PropTypes from 'prop-types';

export const IconRemove = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="29" height="20" viewBox="0 0 29 20">
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M.935 9.093L9.414.588C9.789.212 10.299 0 10.83 0h17.178c.548 0 .992.455.992.992v18.016c0 .548-.455.992-.992.992H10.856c-.547 0-1.07-.224-1.447-.619L.92 10.49c-.374-.392-.367-1.012.016-1.396z" />
      <path fill="#1A1A1E" d="M14.95 5.95c.39-.39 1.023-.39 1.414 0l2.534 2.535 2.537-2.535c.39-.39 1.024-.39 1.414 0 .39.39.39 1.023 0 1.414l-2.536 2.535 2.536 2.536c.39.39.39 1.024 0 1.414-.39.39-1.023.39-1.414 0l-2.537-2.535-2.534 2.535c-.39.39-1.024.39-1.414 0-.39-.39-.39-1.023 0-1.414l2.534-2.536-2.534-2.535c-.39-.39-.39-1.024 0-1.414z" />
    </g>
  </svg>

);

IconRemove.propTypes = {
  className: PropTypes.string,
};

IconRemove.defaultProps = {
  className: null,
};
