import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BET_SLIP_STATE, AI_OUTCOME_SECTION } from 'core/constants';
import { getMainLineMarkets, isEmptyOrNil } from 'core/helpers';

import { RouteLink } from 'components/route-link/route-link';
import { SportTypeIcon } from 'components/sport-type-icon/sport-type-icon';
import { IconArrowControl } from 'components/icons/icon-arrow-control/icon-arrow-control';
import { GameStatus } from 'components/betting-table/game-status/game-status';
import { toTimeIfNearOrDate } from 'helpers/date';
import { prepareHomeScore, prepareAwayScore } from 'helpers/score';
import { GA } from 'helpers/ga';

import { SearchedGamesOutcomeGroup } from '../../searched-games-outcome-group/searched-games-outcome-group';
import { SPORTS_WITH_3WAY_MAIN_MARKET, SPORTS_WITHOUT_LIVE_SCORE } from '../../../../constants';

import './search-group-item.scss';

const SCROLL_OFFSET = 100;

export class SearchGroupItem extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    betSlipStakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    toggleStake: PropTypes.func.isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    eventId: PropTypes.number.isRequired,
    eventNumber: PropTypes.number.isRequired,
    teamA: PropTypes.string.isRequired,
    teamB: PropTypes.string.isRequired,
    timestamp: PropTypes.number.isRequired,
    isBetAllowed: PropTypes.bool.isRequired,
    isLive: PropTypes.bool.isRequired,
    stakeTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    eventStatus: PropTypes.string.isRequired,
    passedTime: PropTypes.number.isRequired,
    homeScore: PropTypes.number.isRequired,
    awayScore: PropTypes.number.isRequired,
    leagueId: PropTypes.number.isRequired,
    leagueName: PropTypes.string.isRequired,
    setAltScore: PropTypes.string.isRequired,
    sportId: PropTypes.number.isRequired,
    isLogoShowed: PropTypes.bool.isRequired,
    isOutright: PropTypes.bool.isRequired,
    isResults: PropTypes.bool.isRequired,
    isWatchlist: PropTypes.bool.isRequired,
    isSchedule: PropTypes.bool.isRequired,
    recommendationId: PropTypes.number.isRequired,
    className: PropTypes.string,
    onLeagueClick: PropTypes.func.isRequired,
    onEventClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: null,
  };

  oddsRef = createRef();
  gameOutcomeFiltersRef = createRef();

  resizeWindowSubsription;

  timeout = null;

  state = {
    isPrevShowed: false,
    isNextShowed: false,
  };

  componentDidMount() {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      this.resizeWindowSubsription = fromEvent(window, 'resize')
        .pipe(debounceTime(200))
        .subscribe(this.onScroll);

      this.timeout = setTimeout(() => {
        const { scrollWidth, clientWidth } = odds;

        if (scrollWidth > clientWidth) {
          this.setIsNextShowed(true);
        }
      }, 50);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !R.equals(this.props, nextProps) || !R.equals(this.state, nextState);
  }

  componentDidUpdate(prevProps) {
    const { stakeTypes: prevStakeTypes } = prevProps;
    const { stakeTypes } = this.props;

    if (!R.equals(prevStakeTypes, stakeTypes)) {
      this.onScroll();
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    if (this.resizeWindowSubsription) {
      this.resizeWindowSubsription.unsubscribe();
    }
  }

  setIsNextShowed = isNextShowed => this.setState({ isNextShowed });

  setIsPrevShowed = isPrevShowed => this.setState({ isPrevShowed });

  onScroll = () => {
    const { isPrevShowed, isNextShowed } = this.state;

    if (this.oddsRef && this.oddsRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = this.oddsRef.current;
      const preparedWidth = Math.ceil(scrollLeft + clientWidth);

      if (isPrevShowed) {
        if (scrollLeft === 0) {
          this.setIsPrevShowed(false);
        }
      } else if (scrollLeft > 0) {
        this.setIsPrevShowed(true);
      }

      if (isNextShowed) {
        if (preparedWidth >= scrollWidth) {
          this.setIsNextShowed(false);
        }
      } else if (preparedWidth < scrollWidth) {
        this.setIsNextShowed(true);
      }
    }
  };

  onPrevClick = () => {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollLeft } = odds;
      odds.scrollTo({
        left: scrollLeft - SCROLL_OFFSET,
        behavior: 'smooth',
      });
    }
  };

  onNextClick = () => {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollLeft } = odds;
      odds.scrollTo({
        left: scrollLeft + SCROLL_OFFSET,
        behavior: 'smooth',
      });
    }
  };

  onEventClick = (e) => {
    const { onEventClick } = this.props;
    onEventClick(e);
    GA.event({
      category: 'search',
      label: 'event-click',
    });
  };

  render() {
    const {
      locale,
      toggleStake,
      betSlipStakes,
      betSlipState,
      eventId,
      eventNumber,
      teamA,
      teamB,
      timestamp,
      isBetAllowed,
      isLive,
      stakeTypes,
      eventStatus,
      passedTime,
      homeScore,
      awayScore,
      leagueId,
      leagueName,
      setAltScore,
      sportId,
      isLogoShowed,
      isOutright,
      isResults,
      isWatchlist,
      isSchedule,
      recommendationId,
      className,
      onLeagueClick,
    } = this.props;
    const { isPrevShowed, isNextShowed } = this.state;

    const isEventInLive = isLive || eventStatus !== null;
    const is3way = SPORTS_WITH_3WAY_MAIN_MARKET.includes(sportId) && !isOutright;
    const marketsList = getMainLineMarkets({
      stakeTypes, isLive, eventStatus, betSlipStakes, betSlipState
    });
    const isAnyMarketExists = !!marketsList.length
    && !!marketsList.filter(({ marketStake }) => marketStake && !!marketStake.length).length;
    const preparedHomeScore = isEventInLive && prepareHomeScore(homeScore, sportId, setAltScore);
    const preparedAwayScore = isEventInLive && prepareAwayScore(awayScore, sportId, setAltScore);

    const oddProps = {
      isBetAllowed,
      toggleStake,
      eventId,
      eventNumber,
      teamA,
      teamB,
      betSlipStakes,
      isEventInLive,
      betSlipState,
      isOutright,
      leagueId,
      isResults,
      isWatchlist,
      isSchedule,
      recommendationId,
      isSearch: true,
    };

    return (
      <div className={classNames('search-group-item position-relative mb-0_5 d-flex justify-content-between align-items-center', className)}>
        <div className="search-group-item-head pr-1 d-flex flex-column overflow-hidden">
          <div className="d-flex align-items-center mb-1">
            {isLogoShowed && <SportTypeIcon sportId={sportId} width="16" height="16" isActive className="sport-type-icon d-flex mr-1" />}
            <RouteLink
              to={`/league/${sportId}/${leagueId}`}
              locale={locale}
              className="text-extra-2 text-truncate"
              onClick={onLeagueClick}
              data-league-id={leagueId}
            >
              {leagueName}
            </RouteLink>
          </div>
          <div className="d-flex flex-column overflow-hidden">
            <RouteLink
              locale={locale}
              to={`/event/${eventId}?isLive=${isEventInLive}&sectionBeforeEventPage=${AI_OUTCOME_SECTION.SEARCH}`}
              className="search-group-item-link mb-0_25 text-small text-truncate"
              onClick={this.onEventClick}
              data-event-id={eventId}
              data-is-live={isEventInLive}
            >
              {isOutright ? teamA : `${teamA} - ${teamB}`}
            </RouteLink>
            {isEventInLive
              ? (
                <div className="d-flex text-extra-3 caption text-truncate">
                  <GameStatus
                    locale={locale}
                    sportId={sportId}
                    eventStatus={eventStatus}
                    passedTime={passedTime}
                    className="mr-0_5 caption"
                  />
                  &middot;
                  {(homeScore !== null && awayScore !== null && !SPORTS_WITHOUT_LIVE_SCORE.includes(sportId)) && (
                    <div className="mx-0_5 font-weight-bold text-success">
                      {isOutright ? (
                        homeScore - awayScore
                      ) : (
                        `${preparedHomeScore === '' ? 0 : preparedHomeScore} - ${preparedAwayScore === '' ? 0 : preparedAwayScore}`
                      )}
                    </div>
                  )}
                  &middot;
                  <RouteLink
                    locale={locale}
                    to={`/league/${sportId}/${leagueId}`}
                    className="ml-0_5 text-truncate text-extra-3"
                    onClick={onLeagueClick}
                    data-league-id={leagueId}
                  >
                    {leagueName}
                  </RouteLink>
                </div>
              )
              : (
                <div className="d-flex caption text-extra-3 text-truncate">
                  <span className="mr-0_5">
                    {toTimeIfNearOrDate(timestamp, locale)}
                  </span>
                  &middot;
                  <RouteLink
                    locale={locale}
                    to={`/league/${sportId}/${leagueId}`}
                    className="ml-0_5 text-truncate text-extra-3"
                    onClick={onLeagueClick}
                    data-league-id={leagueId}
                  >
                    {leagueName}
                  </RouteLink>
                </div>
              )}
          </div>
        </div>

        <div
          ref={this.gameOutcomeFiltersRef}
          className="search-group-item-odds-wrapper d-flex justify-content-between position-absolute"
        >
          <div
            ref={this.oddsRef}
            onScroll={this.onScroll}
            className="search-group-item-odds-scroll-bar w-100 d-flex align-items-end"
          >
            {isAnyMarketExists ? (
              marketsList.map(({
                stakeTypeId,
                stakeTypeName,
                marketStake,
                stakes,
                isTotal,
                isHandicap,
              }, index) =>
                !isEmptyOrNil(marketStake) && (
                  <SearchedGamesOutcomeGroup
                    key={stakeTypeId}
                    marketStake={marketStake}
                    stakeTypeName={stakeTypeName}
                    stakeTypeId={stakeTypeId}
                    isTotal={isTotal}
                    isHandicap={isHandicap}
                    stakes={stakes}
                    oddProps={oddProps}
                    gameOutcomeFiltersRef={this.gameOutcomeFiltersRef}
                    isLastMarket={marketsList.length === index + 1}
                    isFirstMarket={index === 0}
                  />
                ))
            ) : (
              <div className={classNames('searched-games-outcome-group', { 'with-draw': is3way })}>
                <div className="searched-games-outcome-group-odds-group">
                  <div className="odd-cell w-100 is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                    &#8212;
                  </div>
                  <div className="odd-cell w-100 is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                    &#8212;
                  </div>
                  {is3way && (
                    <div className="odd-cell w-100 is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                      &#8212;
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className={classNames('search-group-item-odds-prev position-absolute d-flex align-items-center', { invisible: !isPrevShowed })}>
            <div
              role="button"
              tabIndex="0"
              onClick={this.onPrevClick}
              onKeyPress={this.onPrevClick}
              className="search-group-item-odds-prev-btn d-flex justify-content-center align-items-center position-relative"
            >
              <IconArrowControl />
            </div>
          </div>

          <div className={classNames('search-group-item-odds-next position-absolute d-flex align-items-center justify-content-end', { invisible: !isNextShowed })}>
            <div
              role="button"
              tabIndex="0"
              onClick={this.onNextClick}
              onKeyPress={this.onNextClick}
              className="search-group-item-odds-next-btn d-flex justify-content-center align-items-center position-relative"
            >
              <IconArrowControl />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
