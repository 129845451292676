import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportEvolleyball = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M3 4.57c.08-.9.24-1.79.49-2.67C1.36 3.55 0 6.13 0 9.02c0 1.19.23 2.33.66 3.37.94-.12 1.87-.34 2.75-.64.21-.07.41-.17.61-.26C3.15 9.28 2.79 6.89 3 4.57m5.14-2.03c.171-.86.41-1.71.73-2.54-1.28.03-2.5.32-3.59.82-.51 1.25-.82 2.55-.94 3.87-.18 2.09.14 4.23.91 6.24 1.09-.57 2.1-1.29 3.03-2.16-.48-2.08-.53-4.18-.14-6.23m1.04 7.25c-1.57 1.45-3.36 2.55-5.33 3.24-.83.29-1.69.49-2.56.63.63 1.06 1.49 1.99 2.5 2.71.96-.14 1.899-.37 2.8-.7.17-.95.51-2.01 1.13-3.03.81-1.33 1.98-2.08 3.32-2.13-.601-.28-1.22-.52-1.86-.72m-3.55 7.59c.27.11.54.21.809.29-.019-.16-.019-.34 0-.53-.259.09-.539.17-.809.24M10.29.1c-.37.87-.64 1.78-.82 2.7-.04.21-.06.44-.09.66 2.36.35 4.6 1.22 6.51 2.57.75.52 1.44 1.12 2.06 1.77-.53-3.99-3.68-7.15-7.66-7.7m4.82 7.04C13.4 5.93 11.39 5.13 9.26 4.8c-.05 1.23.06 2.47.35 3.71 1.829.56 3.5 1.41 4.96 2.56.48-.26 1.26-.57 2.16-.57.4 0 .78.06 1.15.19.059-.26.09-.53.12-.8-.82-1.07-1.8-2-2.89-2.75m2.791 8.387c-.332 0-.604-.272-.604-.605 0-.332.272-.605.604-.605.333 0 .605.273.605.605 0 .333-.272.605-.605.605m-1.511-.907c-.332 0-.605-.273-.605-.605 0-.333.273-.605.605-.605.333 0 .604.272.604.605 0 .332-.271.605-.604.605m0 1.813c-.332 0-.605-.27-.605-.604 0-.332.273-.605.605-.605.333 0 .604.273.604.605 0 .333-.271.604-.604.604m-4.536-.756h-.453v.455c0 .248-.206.453-.454.453-.248 0-.453-.205-.453-.453v-.455h-.454c-.247 0-.453-.205-.453-.453 0-.248.206-.453.453-.453h.454v-.454c0-.248.205-.453.453-.453.248 0 .454.205.454.453v.454h.453c.249 0 .454.205.454.453 0 .248-.205.453-.454.453m7.063-2.26c-1.596-2.625-3.997-.799-3.997-.799-.157.115-.441.212-.641.212h-.64c-.194.006-.485-.097-.635-.212 0 0-2.407-1.826-4.004.8-1.596 2.617-.949 5.47-.949 5.47.109.696.484 1.161 1.179 1.108.702-.06 2.219-1.88 2.219-1.88.121-.152.387-.279.581-.279h3.858c.193 0 .46.127.581.278 0 0 1.517 1.82 2.219 1.881.701.053 1.07-.412 1.185-1.108 0 0 .641-2.853-.956-5.47" />
  </svg>
);

IconSportEvolleyball.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportEvolleyball.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
