import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import { getConfig } from 'core/helpers';

import { IconRemove } from 'components/icons/icon-remove/icon-remove';
import { IconKeyboard } from 'components/icons/icon-keyboard/icon-keyboard';
import { GA } from 'helpers/ga';

import './bet-slip-keyboard.scss';

const CURRENCY_PRESETS = getConfig('CURRENCY_PRESETS') || {};

export class BetSlipKeyboard extends Component {
  static propTypes = {
    currency: PropTypes.string.isRequired,
    isKeyboardShowed: PropTypes.bool.isRequired,
    setIsKeyboardShowed: PropTypes.func.isRequired,
    keyboardValue: PropTypes.string.isRequired,
    setKeyboardValue: PropTypes.func.isRequired,
    isKeyboardShadowShowed: PropTypes.bool.isRequired,
  };

  onPresetClick = (e) => {
    const { dataset: { preset, idx } } = e.currentTarget;
    const { setKeyboardValue } = this.props;
    let label;

    setKeyboardValue(preset);

    if (idx === '0') {
      label = 'preset1-click';
    } else if (idx === '1') {
      label = 'preset2-click';
    } else if (idx === '2') {
      label = 'preset3-click';
    } else if (idx === '3') {
      label = 'preset4-click';
    }

    if (label) {
      GA.event({
        category: 'bet-slip',
        label,
      });
    }
  };

  onKeyClick = (e) => {
    const { dataset: { key } } = e.currentTarget;
    const { keyboardValue, setKeyboardValue } = this.props;
    let formattedValue = `${keyboardValue}${key}`;

    if (key === '.' && !keyboardValue) {
      formattedValue = '0.';
    }

    setKeyboardValue(formattedValue);
  };

  onRemoveClick = () => {
    const { keyboardValue, setKeyboardValue } = this.props;

    setKeyboardValue(R.dropLast(1, String(keyboardValue)));
  };

  onKeyboardClick = () => {
    const { setIsKeyboardShowed } = this.props;

    setIsKeyboardShowed(false);
  };

  render() {
    const { isKeyboardShowed, isKeyboardShadowShowed, currency } = this.props;
    const presets = CURRENCY_PRESETS[currency];

    return (
      <div className={classNames('bet-slip-keyboard position-absolute', {
        'd-none': !isKeyboardShowed,
        'is-shadow-showed': isKeyboardShadowShowed,
      })}
      >
        {presets && presets.map((preset, idx) => (
          <div
            key={preset}
            role="button"
            tabIndex="0"
            onClick={this.onPresetClick}
            onKeyPress={this.onPresetClick}
            data-preset={preset}
            data-idx={idx}
            className="bet-slip-keyboard-preset d-flex align-items-center justify-content-center bg-main-4 font-weight-bold"
          >
            {preset}
          </div>
        ))}

        {['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'].map(key => (
          <div
            key={key}
            role="button"
            tabIndex="0"
            onClick={this.onKeyClick}
            onKeyPress={this.onKeyClick}
            data-key={key}
            className={classNames('bet-slip-keyboard-key d-flex align-items-center justify-content-center bg-main-2', {
              'bet-slip-keyboard-zero': key === '0',
            })}
          >
            {key}
          </div>
        ))}

        <div
          role="button"
          tabIndex="0"
          onClick={this.onRemoveClick}
          onKeyPress={this.onRemoveClick}
          className="bet-slip-keyboard-remove d-flex align-items-center justify-content-center flex-fill bg-main-4"
        >
          <IconRemove />
        </div>

        <div
          role="button"
          tabIndex="0"
          onClick={this.onKeyboardClick}
          onKeyPress={this.onKeyboardClick}
          className="bet-slip-keyboard-close d-flex align-items-center justify-content-center flex-fill bg-main-4"
        >
          <IconKeyboard />
        </div>
      </div>
    );
  }
}
