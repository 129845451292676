import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import { withCoreComponent } from 'core/hocs';
import { BetsHistory as BetsHistoryCore } from 'core/components';
import { BETS_HISTORY_TYPES } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { DatePicker } from 'components/date-picker/date-picker';
import { IconDatePicker } from 'components/icons/icon-date-picker/icon-date-picker';
import { toDatePickerFormat, prepareDateFrom, prepareDateTo } from 'helpers/date';
import { GA } from 'helpers/ga';
import { BetsTable } from './bets-table/bets-table';

import './bets-history.scss';

class BetsHistoryUI extends Component {
  static propTypes = {
    currency: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
      BETS_HISTORY_TYPES.ALL,
      BETS_HISTORY_TYPES.ACTIVE,
      BETS_HISTORY_TYPES.FINISHED,
      BETS_HISTORY_TYPES.CASHOUTED,
    ]).isRequired,
    setBetsHistoryType: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape),
    isInProgress: PropTypes.bool.isRequired,
    isLoadMoreInProgress: PropTypes.bool.isRequired,
    isLoadMoreAvailable: PropTypes.bool.isRequired,
    loadMore: PropTypes.func.isRequired,
    makeCashout: PropTypes.func.isRequired,
    setDateRangeFilter: PropTypes.func.isRequired,
    repeatBet: PropTypes.func.isRequired,
  };

  static defaultProps = {
    items: null,
  };

  state = {
    isDatePickerOpen: false,
    from: null,
    to: null,
  };

  componentDidUpdate(prevProps, prevState) {
    const { isDatePickerOpen } = this.state;
    const { isDatePickerOpen: prevIsDatePickerOpen } = prevState;

    if (prevIsDatePickerOpen !== isDatePickerOpen) {
      if (isDatePickerOpen) {
        document.addEventListener('click', this.checkOutsideClick);
      } else {
        document.removeEventListener('click', this.checkOutsideClick);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.checkOutsideClick);
  }

  checkOutsideClick = (e) => {
    const path = e.path || (e.composedPath && e.composedPath());

    const isClickOnDatePicker = R.compose(
      R.contains(true),
      R.map(DOMTokenList => DOMTokenList.contains('DayPicker')),
      R.reject(DOMTokenList => !DOMTokenList),
      R.pluck('classList')
    )(path);

    if (!isClickOnDatePicker) {
      this.setState({ isDatePickerOpen: false });
    }
  };

  onAllBetsClick = () => {
    const { type, setBetsHistoryType } = this.props;
    const { from, to } = this.state;
    GA.event({
      category: 'bets-history',
      label: 'all-filter-click',
    });

    if (type === BETS_HISTORY_TYPES.ALL && !from && !to) {
      return;
    }

    setBetsHistoryType(BETS_HISTORY_TYPES.ALL);
    this.resetDatePicker();
  };

  onActiveBetsClick = () => {
    const { type, setBetsHistoryType } = this.props;
    const { from, to } = this.state;
    GA.event({
      category: 'bets-history',
      label: 'unsettled-filter-click',
    });

    if (type === BETS_HISTORY_TYPES.ACTIVE && !from && !to) {
      return;
    }

    setBetsHistoryType(BETS_HISTORY_TYPES.ACTIVE);
    this.resetDatePicker();
  };

  onFinishedBetsClick = () => {
    const { type, setBetsHistoryType } = this.props;
    const { from, to } = this.state;
    GA.event({
      category: 'bets-history',
      label: 'settled-filter-click',
    });

    if (type === BETS_HISTORY_TYPES.FINISHED && !from && !to) {
      return;
    }

    setBetsHistoryType(BETS_HISTORY_TYPES.FINISHED);
    this.resetDatePicker();
  };

  openDatePicker = () => {
    this.setState(prevState => ({ isDatePickerOpen: !prevState.isDatePickerOpen }));
    GA.event({
      category: 'bets-history',
      label: 'date-filter-click',
    });
  };

  onDatePickerChange = ({ from, to }) => {
    const { setDateRangeFilter } = this.props;
    this.setState({
      from,
      to,
      isDatePickerOpen: !from || !to,
    });

    if ((from && to) || (!from && !to)) {
      setDateRangeFilter(prepareDateFrom(from), prepareDateTo(to));
    }
  }

  resetDatePicker = () => this.setState({
    from: null,
    to: null,
    isDatePickerOpen: false,
  });

  loadMore = () => {
    const { loadMore } = this.props;
    const { from, to } = this.state;

    if (loadMore) {
      loadMore(prepareDateFrom(from), prepareDateTo(to));
    }
  }

  onCashoutedBetsClick = () => {
    const { type, setBetsHistoryType } = this.props;
    const { from, to } = this.state;
    GA.event({
      category: 'bets-history',
      label: 'cashouted-filter-click',
    });

    if (type === BETS_HISTORY_TYPES.CASHOUTED && !from && !to) {
      return;
    }

    setBetsHistoryType(BETS_HISTORY_TYPES.CASHOUTED);
    this.resetDatePicker();
  };

  render() {
    const {
      currency,
      type,
      items,
      isInProgress,
      isLoadMoreInProgress,
      isLoadMoreAvailable,
      makeCashout,
      repeatBet,
    } = this.props;
    const { isDatePickerOpen, from, to } = this.state;
    const isAll = type === BETS_HISTORY_TYPES.ALL;
    const isActive = type === BETS_HISTORY_TYPES.ACTIVE;
    const isFinished = type === BETS_HISTORY_TYPES.FINISHED;
    const isCashouted = type === BETS_HISTORY_TYPES.CASHOUTED;

    return (
      <div className="bets-history d-flex flex-column position-relative">
        <div className="d-flex justify-content-between mb-3">
          <FormattedTag id="bets-history.title" className="h3 font-weight-bolder" />

          <div className="d-flex align-items-center">
            <div
              role="button"
              tabIndex="0"
              onClick={this.openDatePicker}
              onKeyPress={this.openDatePicker}
              className="d-flex align-items-center"
            >
              <IconDatePicker />
              {from && to
                ? <span className="ml-1 text-extra-2 text-small">{toDatePickerFormat(from, to)}</span>
                : <FormattedTag id="bets-history.date-range" className="ml-1 text-extra-2 text-small" />}
            </div>
            <DatePicker
              numberOfMonths={2}
              isOpen={isDatePickerOpen}
              from={from}
              to={to}
              onChange={this.onDatePickerChange}
            />

            <div className="d-flex align-items-center text-small ml-5">
              <FormattedTag
                id="my-bets.tab.all"
                role="button"
                onClick={this.onAllBetsClick}
                className={classNames({
                  'font-weight-bold': isAll,
                  'text-extra-2': !isAll,
                })}
              />
              <span className="bets-history-tabs-divider mx-2" />
              <FormattedTag
                id="my-bets.tab.finished"
                role="button"
                onClick={this.onFinishedBetsClick}
                className={classNames({
                  'font-weight-bold': isFinished,
                  'text-extra-2': !isFinished,
                })}
              />
              <span className="bets-history-tabs-divider mx-2" />
              <FormattedTag
                id="my-bets.tab.active"
                role="button"
                onClick={this.onActiveBetsClick}
                className={classNames({
                  'font-weight-bold': isActive,
                  'text-extra-2': !isActive,
                })}
              />
              <span className="bets-history-tabs-divider mx-2" />
              <FormattedTag
                id="my-bets.tab.cashout"
                role="button"
                onClick={this.onCashoutedBetsClick}
                className={classNames({
                  'font-weight-bold': isCashouted,
                  'text-extra-2': !isCashouted,
                })}
              />
            </div>
          </div>
        </div>

        <BetsTable
          type={type}
          currency={currency}
          bets={items}
          isInProgress={isInProgress}
          isLoadMoreInProgress={isLoadMoreInProgress}
          makeCashout={makeCashout}
          repeatBet={repeatBet}
          // withInfiniteScroll props
          isLoadMoreAvailable={isLoadMoreAvailable && !isInProgress && !isLoadMoreInProgress}
          loadMore={this.loadMore}
          isModal
        />
      </div>
    );
  }
}

export const BetsHistory = withCoreComponent(BetsHistoryCore, BetsHistoryUI);
