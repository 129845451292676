import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as R from 'ramda';
import { wrapRoute2Locale } from 'core/helpers';

class RouteLinkUI extends Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.objectOf(PropTypes.string),
    title: PropTypes.string,
    history: PropTypes.shape().isRequired,
    id: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    onClick: null,
    style: null,
    title: null,
    id: null,
  };

  onClick = (...args) => {
    const {
      onClick,
      history,
      to,
      locale,
    } = this.props;

    if (onClick) {
      onClick(...args);
    }

    history.push(wrapRoute2Locale(to, locale));
  };

  render() {
    const {
      className,
      children,
      style,
      title,
      id,
      ...restProps
    } = this.props;
    const dataProps = R.pickBy((val, key) => key.includes('data-'), restProps);

    return (
      <span
        role="button"
        tabIndex="0"
        onClick={this.onClick}
        onKeyPress={this.onClick}
        className={className}
        style={style}
        title={title}
        id={id}
        {...dataProps}
      >
        {children}
      </span>
    );
  }
}

export const RouteLink = withRouter(RouteLinkUI);
