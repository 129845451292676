import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BET_TYPE } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { GA } from 'helpers/ga';
import { BetTypeDropdown } from './bet-type-dropdown/bet-type-dropdown';
import { StickyHeader } from './sticky-header/sticky-header';

import './bet-slip-header.scss';

const EXPRESS_ITEM = 'EXPRESS_ITEM';

export class BetSlipHeader extends Component {
  static propTypes = {
    betType: PropTypes.oneOf([
      BET_TYPE.ORDINAR,
      BET_TYPE.EXPRESS,
      BET_TYPE.SYSTEM,
    ]).isRequired,
    clearBetSlip: PropTypes.func.isRequired,
    setBetType: PropTypes.func.isRequired,
    stakesAmount: PropTypes.number.isRequired,
    systemIndex: PropTypes.number,
    isMobile: PropTypes.bool,
    setIsOpened: PropTypes.func,
  };

  static defaultProps = {
    systemIndex: null,
    isMobile: false,
    setIsOpened: null,
  };

  timeoutId;

  state = {
    isHidden: false,
    selectedItem: this.props.systemIndex || EXPRESS_ITEM,
  };

  componentDidUpdate(prevProps) {
    const { systemIndex, betType } = this.props;
    const { systemIndex: prevSystemIndex, betType: prevBetType } = prevProps;

    if (betType !== prevBetType || systemIndex !== prevSystemIndex) {
      this.setSelectedItem(systemIndex || EXPRESS_ITEM);
    }
  }

  componentWillUnmount() {
    this.clearTimeoutId();
  }

  setSelectedItem = selectedItem => this.setState({ selectedItem });

  onExpressClick = (e) => {
    e.stopPropagation();
    const { setBetType } = this.props;
    const { selectedItem } = this.state;

    if (selectedItem !== EXPRESS_ITEM) {
      setBetType({ betType: BET_TYPE.EXPRESS });
      this.setSelectedItem(EXPRESS_ITEM);
    }
  };

  onSystemClick = (e) => {
    e.stopPropagation();
    const { dataset: { systemIndex } } = e.currentTarget;
    const { setBetType } = this.props;
    const { selectedItem } = this.state;

    if (selectedItem !== Number(systemIndex)) {
      setBetType({ betType: BET_TYPE.SYSTEM, systemIndex: Number(systemIndex) });
      this.setSelectedItem(Number(systemIndex));
    }
  };

  setIsHidden = isHidden => this.setState({ isHidden });

  onClearClick = () => {
    const { clearBetSlip, isMobile, setIsOpened } = this.props;

    if (isMobile && setIsOpened) {
      setIsOpened(false);
      this.clearTimeoutId();
      this.timeoutId = setTimeout(clearBetSlip, 300); // 300ms - close animation
    } else {
      clearBetSlip();
    }

    GA.event({
      category: 'bet-slip',
      label: 'clear-click',
    });
  };

  clearTimeoutId = () => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  };

  render() {
    const { betType, stakesAmount } = this.props;
    const { isHidden, selectedItem } = this.state;
    const isOrdinar = betType === BET_TYPE.ORDINAR;
    const isDropdownShowed = !isOrdinar && stakesAmount > 2;

    return (
      <Fragment>
        <div className={classNames('bet-slip-header d-flex align-items-center justify-content-between pr-1_5 bg-main-2', { 'is-hidden': isHidden })}>
          {isDropdownShowed
            ? (
              <BetTypeDropdown
                selectedItem={selectedItem}
                onExpressClick={this.onExpressClick}
                onSystemClick={this.onSystemClick}
                stakesAmount={stakesAmount}
              />
            )
            : <FormattedTag id={`bet-slip.type.${isOrdinar ? 'ordinar' : 'express'}`} className="caption font-weight-bold text-extra-2" />}

          <FormattedTag
            id="bet-slip.clear"
            role="button"
            tabIndex="0"
            onClick={this.onClearClick}
            onKeyPress={this.onClearClick}
            className="caption text-extra-2"
          />
        </div>
        <StickyHeader
          {...this.props}
          isOrdinar={isOrdinar}
          isDropdownShowed={isDropdownShowed}
          onClearClick={this.onClearClick}
          setIsHidden={this.setIsHidden}
          selectedItem={selectedItem}
          onExpressClick={this.onExpressClick}
          onSystemClick={this.onSystemClick}
        />
      </Fragment>
    );
  }
}
