import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { IconTeamPlaceholder } from 'components/icons/icon-team-placeholder/icon-team-placeholder';
import { IconCountryPlaceholder } from 'components/icons/icon-team-placeholder/icon-country-placeholder';
import { IconIndividualPlaceholder } from 'components/icons/icon-team-placeholder/icon-individual-placeholder';
import { TEAM_SPORTS, INDIVIDUAL_SPORTS } from '../../constants';

export class TeamLogoPlaceholder extends Component {
  static propTypes = {
    sportId: PropTypes.number.isRequired,
    imageUrl: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    imageUrl: null,
    className: null,
  };

  state = {
    error: false,
  };

  onError = () => this.setState({ error: true });

  renderPlaceholder = () => {
    const { sportId, className } = this.props;

    if (TEAM_SPORTS.includes(sportId)) {
      return <IconTeamPlaceholder className={className} />;
    }

    if (INDIVIDUAL_SPORTS.includes(sportId)) {
      return <IconIndividualPlaceholder className={className} />;
    }

    return <IconCountryPlaceholder className={className} />;
  }

  render() {
    const { imageUrl, className } = this.props;
    const { error } = this.state;

    return error || !imageUrl
      ? this.renderPlaceholder()
      : <img src={imageUrl} alt="" className={className} onError={this.onError} />;
  }
}
