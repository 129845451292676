import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withLocale } from 'core/hocs';
import { getEnv } from 'core/helpers';

import './live-stats.scss';

const pId = getEnv('USER_SYSTEM_RID');

class LiveStatsWidget extends Component {
  static propTypes = {
    activeItem: PropTypes.shape(),
    isShowed: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
  };

  static defaultProps = {
    activeItem: null,
  };

  async componentDidMount() {
    const { activeItem, isShowed, locale } = this.props;

    if (isShowed && activeItem && activeItem.hasLiveStats) {
      this.loadLiveInfoScript(activeItem.eventId, locale);
    }
  }

  componentDidUpdate(prevProps) {
    const { activeItem, isShowed, locale } = this.props;

    if (isShowed && activeItem && activeItem.hasLiveStats) {
      if (!prevProps.isShowed || !prevProps.activeItem
        || (prevProps.activeItem.eventId !== activeItem.eventId)) {
        this.loadLiveInfoScript(activeItem.eventId, locale);
      }
    }
  }

  initializeLiveInfo = (eventId, locale) => {
    const params = {
      containerSelector: '#LiveInfoWidget',
      globalSettings: {
        partner: { CdnDomainName: 'cdn-sp.kertn.net' },
        projects: { 'live-center': '5.5.6' },
      },
      translations: {},
      eventId,
      server: 'widgets.feeddigi.com',
      lightMode: false,
      liveScoreUrl: '',
      language: locale,
      liveCenterSocket: 'wss://sp-spc-ws.sportdigi.com',
      liveCenterApi: 'https://sp-spc-api.sportdigi.com',
      uuid: '35e94e45-6bc6-49d0-9fc5-161e844abd10',
      cssVariables: [{ name: 'lc_background', value: '' }, { name: 'lc_background2', value: '' }, { name: 'lc_text', value: '' }, { name: 'lc_text2', value: '' }, { name: 'lc_progress', value: '' }, { name: 'lc_accent', value: '' }, { name: 'lc_accent2', value: '' }, { name: 'lc_score_background', value: '' }, { name: 'lc_score_text', value: '' }],
      liveCenterInfo: {},
      pId,
    };

    if (window.Bootstrapper) {
      window.Bootstrapper.boot(params, { name: 'LiveCenter' });
    }
  };

  loadLiveInfoScript(eventId, locale) {
    const script = document.createElement('script');
    script.src = 'https://cdn-sp.kertn.net/boot/bootstrapper.min.js';
    script.async = true;
    script.onload = () => this.initializeLiveInfo(eventId, locale);
    document.body.appendChild(script);
  }

  render() {
    const { isShowed, activeItem } = this.props;

    return (
      <Fragment>
        {isShowed && activeItem && activeItem.hasLiveStats ? (
          <div
            id="LiveInfoWidget"
            className={classNames('live-center live-stats position-relative', {
              'd-none': !isShowed || (activeItem && !activeItem.hasLiveStats),
            })}
          />
        ) : null}
      </Fragment>
    );
  }
}

export const LiveStats = withLocale(LiveStatsWidget);
