import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportSpeedway = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M8.74 3.85c-2.199-.22-4.06-.05-5.569.5-.06.04-.3.42-.39 1.47-.03.39-.35.69-.74.69h-.06c-.42-.03-.72-.4-.69-.81.12-1.54.58-2.47 1.36-2.76 1.73-.63 3.8-.83 6.24-.59.41.05.72.41.67.82-.04.42-.4.72-.82.68zm11.07.46c-.018 0-1.648-.83-4.03-1.65C13.582 1.9 10.27 1 6.98 1c-1.76 0-3.37.26-4.769.77-3.57 1.34-1.8 8.72-1.57 9.59l-.27 1.22c-.03.15.05.29.19.35l14.84 5.3c.03.02.06.02.1.02.05 0 .11-.01.16-.04l1.69-1.04c.06-.04.1-.09.12-.14.18-.41.42-1.98.43-2.17.01-.05 0-.1-.03-.14l-.72-1.53-1.13.62c-.36.2-.76.3-1.16.3-.31 0-.61-.06-.9-.18l-3.42-1.36c-.8-.33-1.32-1.09-1.32-1.96V9.57c0-.95.64-1.79 1.56-2.03l4.68-1.26 4.3-1.41c.12-.04.2-.14.21-.26.01-.12-.06-.24-.16-.3zm-3.308 7.5s-.46-2.4-.781-4.04l-4.55 1.22c-.27.07-.45.31-.45.58v1.04c0 .25.15.47.38.56l3.42 1.37c.25.1.53.09.78-.05l1.21-.67-.01-.01z" />
  </svg>
);

IconSportSpeedway.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportSpeedway.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
