import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TeamLogoPlaceholder } from '../../team-logo-placeholder/team-logo-placeholder';

import './team-column.scss';

const TeamColumnUI = ({
  name,
  logo,
  className,
  sportId,
}) => (
  <div className={classNames('team-column font-weight-bold text-small d-flex align-items-center pr-sm-1', className)}>
    <TeamLogoPlaceholder
      sportId={sportId}
      imageUrl={logo}
      className="flex-shrink-0 mr-1 mr-sm-1_5 team-column-logo"
    />
    <span
      title={name}
      className="team-column-name text-truncate text-small font-weight-bold"
    >
      {name}
    </span>
  </div>
);

TeamColumnUI.propTypes = {
  sportId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  logo: PropTypes.string,
  className: PropTypes.string,
};

TeamColumnUI.defaultProps = {
  className: null,
  logo: null,
};

export const TeamColumn = React.memo(TeamColumnUI);
