import { IconSportOthers } from 'components/icons/icon-sports/icon-sport-others';
import { IconAo } from 'components/icons/icon-leagues/icon-ao';
import { IconAtp } from 'components/icons/icon-leagues/icon-atp';
import { IconEpl } from 'components/icons/icon-leagues/icon-epl';
import { IconEuroleague } from 'components/icons/icon-leagues/icon-euroleague';
import { IconLaliga } from 'components/icons/icon-leagues/icon-laliga';
import { IconLeague1 } from 'components/icons/icon-leagues/icon-league-1';
import { IconNba } from 'components/icons/icon-leagues/icon-nba';
import { IconNhl } from 'components/icons/icon-leagues/icon-nhl';
import { IconRg } from 'components/icons/icon-leagues/icon-rg';
import { IconSeriaA } from 'components/icons/icon-leagues/icon-seria-a';
import { IconTrSl } from 'components/icons/icon-leagues/icon-tr-sl';
import { IconUcl } from 'components/icons/icon-leagues/icon-ucl';
import { IconUel } from 'components/icons/icon-leagues/icon-uel';
import { IconUsOpen } from 'components/icons/icon-leagues/icon-us-open';
import { IconWc } from 'components/icons/icon-leagues/icon-wc';
import { IconWimbledon } from 'components/icons/icon-leagues/icon-wimbledon';
// import { IconWta } from 'components/icons/icon-leagues/icon-wta';
import { IconEuro } from 'components/icons/icon-leagues/icon-euro';

export const getTopLeagueIcon = (leagueId, withDefault = true) => {
  if ([5692, 22109, 5691, 15672, 15673, 15674, 15675,
    15676, 15677, 15678, 15679, 15680, 30294, 22187,
  ].includes(leagueId)) {
    return IconWc;
  }

  if ([4592, 17297, 24158, 24159, 24160, 24161, 24162, 24164, 27008].includes(leagueId)) {
    return IconEuro;
  }

  if ([
    4584, 4586, 6470, 6471, 6472, 6473, 6474, 6475,
    6476, 6477, 15007, 15998, 22627, 29324, 5054, 11508,
  ].includes(leagueId)) {
    return IconUcl;
  }

  if ([4230, 8282, 17753, 29364, 5055, 30867].includes(leagueId)) {
    return IconUel;
  }

  if ([4633, 14844, 18728, 4485].includes(leagueId)) {
    return IconEpl;
  }

  if ([4496, 15309, 29920, 4484].includes(leagueId)) {
    return IconSeriaA;
  }

  if ([4572, 11928, 29930, 4486].includes(leagueId)) {
    return IconLaliga;
  }

  if ([4631, 15308, 4610].includes(leagueId)) {
    return IconLeague1;
  }

  if ([21398, 4520].includes(leagueId)) {
    return IconTrSl;
  }

  if ([
    8915, 12150, 12944, 12945, 20219, 28172, 29179, 14912, 16654, 13257,
    998, 12150, 20024, 21422, 2849, 9122, 11431, 11432, 14027,
  ].includes(leagueId)) {
    return IconNba;
  }

  if ([1379, 11844, 21986].includes(leagueId)) {
    return IconEuroleague;
  }

  if ([2890, 2903, 29151].includes(leagueId)) {
    return IconNhl;
  }

  if ([1553, 1554, 1546, 13847, 13887, 17561, 17562, 27286].includes(leagueId)) {
    return IconAo;
  }

  if ([1819, 1822, 1823, 25277, 25282, 29708, 29705, 29707, 20404, 20419].includes(leagueId)) {
    return IconRg;
  }

  if ([1894, 1895].includes(leagueId)) {
    return IconWimbledon;
  }

  if ([21381, 21383, 21385, 21386, 21387, 26092, 29525, 29526, 26088, 26114, 21388, 26089, 26113].includes(leagueId)) {
    return IconUsOpen;
  }

  if ([1855, 10616].includes(leagueId)) {
    return IconAtp;
  }

  // if ([].includes(leagueId)) {
  //   return IconWta;
  // }

  if (withDefault) {
    return IconSportOthers;
  }

  return null;
};
