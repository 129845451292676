import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportTableTennis = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M9 2c0-1.104-.896-2-2-2S5 .896 5 2s.896 2 2 2 2-.896 2-2m8.99.01C15.87-.11 12.74-.38 10.11.4c.25.48.39 1.02.39 1.6 0 1.93-1.57 3.5-3.5 3.5-.87 0-1.68-.32-2.29-.86-.33.53-.6 1.07-.8 1.63l9.82 9.82c1.36-.49 2.65-1.41 3.84-2.6 2.55-2.55 3.69-8.21.42-11.48M3.51 7.99l8.5 8.5c-1.05.09-2.12-.09-3.21-.61-1.12-.53-2.46-.3-3.27.63l-2.84 3.28c-.23.26-.64.28-.89.03L.18 18.19c-.25-.24-.24-.65.03-.88l3.28-2.84c.93-.81 1.16-2.15.63-3.27-.52-1.09-.7-2.16-.61-3.21" />
  </svg>
);

IconSportTableTennis.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportTableTennis.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
