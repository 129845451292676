import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportDarts = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M20 3.536l-3.182-.354L16.465 0l-3.001 3 .247 2.228L8.47 10.47c-.293.293-.293.768 0 1.06.146.147.338.22.53.22s.384-.073.53-.22l5.241-5.241 2.228.247 3.001-3zM17.52 8.11l-2.2-.25-.24.24c.43.88.67 1.86.67 2.9 0 3.72-3.03 6.75-6.75 6.75S2.25 14.72 2.25 11 5.28 4.25 9 4.25c1.04 0 2.02.24 2.9.67l.25-.25-.25-2.18C10.99 2.17 10.01 2 9 2c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9c0-1.01-.17-1.98-.48-2.89zm-5.53 3.08l1.95-1.95c.2.55.31 1.14.31 1.76 0 2.9-2.36 5.25-5.25 5.25S3.75 13.9 3.75 11 6.11 5.75 9 5.75c.62 0 1.21.11 1.76.31L8.81 8.01C7.24 8.1 6 9.4 6 11c0 1.66 1.34 3 3 3 1.6 0 2.9-1.24 2.99-2.81z" />
  </svg>
);

IconSportDarts.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportDarts.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
