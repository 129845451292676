import React from 'react';
import PropTypes from 'prop-types';

import { SportSubitem } from '../sport-subitem/sport-subitem';

export const SportItemBody = ({
  locale,
  topLeagues,
  countries,
  sportId,
  activeLeagueId,
  activeCountries,
  setActiveCountries,
  setActiveLeagueId,
  clearActiveCountries,
  clearActiveCountriesExceptSportAndCountry,
  sendLeagueClickFeedback,
}) => (
  <div className="flex-column">
    {topLeagues && !!topLeagues.length && topLeagues.map(({
      leagueId,
      leagueName,
      eventsCount,
      inplayEventsCount,
    }) => (
      <SportSubitem
        key={leagueId}
        locale={locale}
        leagueId={leagueId}
        leagueName={leagueName}
        eventsCount={eventsCount}
        inplayEventsCount={inplayEventsCount}
        sportId={sportId}
        activeLeagueId={activeLeagueId}
        setActiveLeagueId={setActiveLeagueId}
        clearActiveCountries={clearActiveCountries}
        sendLeagueClickFeedback={sendLeagueClickFeedback}
      />
    ))}

    {topLeagues && topLeagues.length > 0 && <div className="sports-divider my-1" />}

    {countries.map(({
      countryId,
      countryName,
      eventsCount,
      inplayEventsCount,
      leagues,
    }) => (
      <SportSubitem
        key={countryId}
        locale={locale}
        countryId={countryId}
        countryName={countryName}
        eventsCount={eventsCount}
        inplayEventsCount={inplayEventsCount}
        sportId={sportId}
        leagues={leagues}
        isCountries
        activeLeagueId={activeLeagueId}
        isActive={activeCountries.includes(countryId)}
        setActiveCountries={setActiveCountries}
        setActiveLeagueId={setActiveLeagueId}
        clearActiveCountriesExceptSportAndCountry={clearActiveCountriesExceptSportAndCountry}
        sendLeagueClickFeedback={sendLeagueClickFeedback}
      />
    ))}
  </div>
);

SportItemBody.propTypes = {
  locale: PropTypes.string.isRequired,
  topLeagues: PropTypes.arrayOf(PropTypes.shape()),
  countries: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  sportId: PropTypes.number.isRequired,
  activeLeagueId: PropTypes.number,
  activeCountries: PropTypes.arrayOf(PropTypes.number).isRequired,
  setActiveCountries: PropTypes.func.isRequired,
  setActiveLeagueId: PropTypes.func.isRequired,
  clearActiveCountries: PropTypes.func,
  clearActiveCountriesExceptSportAndCountry: PropTypes.func.isRequired,
  sendLeagueClickFeedback: PropTypes.func.isRequired,
};

SportItemBody.defaultProps = {
  topLeagues: null,
  activeLeagueId: null,
  clearActiveCountries: () => {},
};
