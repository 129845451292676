import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportWeightlifting = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M15.5 4c-.828 0-1.5.672-1.5 1.5v3.75H6V5.5C6 4.672 5.328 4 4.5 4S3 4.672 3 5.5v9c0 .828.672 1.5 1.5 1.5S6 15.328 6 14.5v-3.75h8v3.75c0 .828.672 1.5 1.5 1.5s1.5-.672 1.5-1.5v-9c0-.828-.672-1.5-1.5-1.5m3.75 3.25c-.414 0-.75.336-.75.75v4c0 .414.336.75.75.75s.75-.336.75-.75V8c0-.414-.336-.75-.75-.75M1.5 8v4c0 .414-.336.75-.75.75S0 12.414 0 12V8c0-.414.336-.75.75-.75s.75.336.75.75" />
  </svg>
);

IconSportWeightlifting.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportWeightlifting.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
