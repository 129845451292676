import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { PAGE_NAMES } from '../../constants';

export class LocationTrackerUI extends PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string,
      state: PropTypes.shape(),
    }).isRequired,
    history: PropTypes.shape({
      action: PropTypes.string,
    }).isRequired,
    pageName: PropTypes.string.isRequired,
  };

  timeoutId;

  componentDidUpdate(prevProps) {
    const {
      location: { pathname },
      history: { action },
      pageName,
    } = this.props;
    const {
      location: { pathname: prevPathname, state },
      pageName: prevPageName,
    } = prevProps;

    if (pathname !== prevPathname && !(pageName === PAGE_NAMES.LIVE && prevPageName === PAGE_NAMES.LIVE)) {
      const isPopAction = action === 'POP';
      this.clearTimeoutId();
      this.timeoutId = setTimeout(() => {
        const centralPart = document.querySelector('.central-part');

        if (centralPart) {
          centralPart.scrollTop = isPopAction && state && state.scrollTop ? state.scrollTop : 0;
        }
      }, isPopAction ? 500 : 0);
    }
  }

  componentWillUnmount() {
    this.clearTimeoutId();
  }

  clearTimeoutId = () => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  };

  render() {
    return null;
  }
}

export const LocationTracker = withRouter(LocationTrackerUI);
