import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportFormula = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M13.738 3L4.525 16.168l1.228.86 6.348-9.071 5.03 3.525 2.868-4.096-5.032-3.526-.413-.288L13.738 3zM6.261 3L0 7.382l2.868 4.095 6.261-4.381L6.261 3zm4.658 9.268l3.339 4.76 1.231-.86-3.659-5.21-.911 1.31z" />
  </svg>
);

IconSportFormula.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportFormula.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
