import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportPolitics = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M7.5 4.731h-4c-1.933 0-3.5 1.566-3.5 3.5 0 1.933 1.567 3.5 3.5 3.5h4v-7zm10.5 1.5v-4.73c0-.363-.372-.605-.703-.458L9 4.731v7l8.297 3.688c.331.146.703-.096.703-.458v-4.73c1.104 0 2-.896 2-2s-.896-2-2-2zM7.935 17.064c.24.79-.201 1.63-1 1.87-.79.24-1.63-.2-1.871-.99L3.62 13.231h3.15l1.165 3.833z" />
  </svg>
);

IconSportPolitics.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportPolitics.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
