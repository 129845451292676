import React from 'react';
import PropTypes from 'prop-types';

export const IconFreebet = ({ className }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.4 0C6.42656 0 4.8 1.62656 4.8 3.6C4.8 4.06406 5.13281 4.3875 5.29687 4.8H0V12H1.2V20.5172C1.2 21.7594 2.24063 22.8 3.47813 22.8H20.5172C21.7594 22.8 22.8 21.7594 22.8 20.5172V12H24V4.8H18.6984C18.8672 4.3875 19.2 4.06406 19.2 3.6C19.2 1.62656 17.5734 0 15.6 0C14.5031 0 13.5234 0.515626 12.8625 1.30313L12 2.16094L11.1375 1.30313C10.4766 0.515626 9.49687 0 8.4 0ZM8.4 2.4C9.06563 2.4 9.6 2.93437 9.6 3.6C9.6 4.26563 9.06563 4.8 8.4 4.8C7.73437 4.8 7.2 4.26563 7.2 3.6C7.2 2.93437 7.73437 2.4 8.4 2.4ZM15.6 2.4C16.2656 2.4 16.8 2.93437 16.8 3.6C16.8 4.26563 16.2656 4.8 15.6 4.8C14.9344 4.8 14.4 4.26563 14.4 3.6C14.4 2.93437 14.9344 2.4 15.6 2.4ZM2.4 7.2H21.6V9.6H2.4V7.2ZM3.6 12H10.8V20.4H3.6V12ZM13.2 12H20.4V20.4H13.2V12Z" fill="#FFEB3B" />
  </svg>
);

IconFreebet.propTypes = {
  className: PropTypes.string,
};

IconFreebet.defaultProps = {
  className: null,
};
