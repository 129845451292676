import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';
import { withFavorites, withFavoritesActions } from 'core/hocs';
import { FAVORITES_STORE_FIELDS } from 'core/constants';

import { IconStar } from 'components/icons/icon-star/icon-star';
import { IconStarEmpty } from 'components/icons/icon-star-empty/icon-star-empty';
import { GA } from 'helpers/ga';

class FavoritesToggleButtonUI extends Component {
  static propTypes = {
    favoriteSports: PropTypes.arrayOf(PropTypes.shape()),
    favoriteLeagues: PropTypes.arrayOf(PropTypes.shape()),
    toggleFavoriteSport: PropTypes.func.isRequired,
    toggleFavoriteLeague: PropTypes.func.isRequired,
    isSport: PropTypes.bool,
    sportId: PropTypes.number,
    sportName: PropTypes.string,
    leagueId: PropTypes.number,
    leagueName: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    favoriteSports: null,
    favoriteLeagues: null,
    isSport: false,
    sportId: null,
    sportName: null,
    leagueId: null,
    leagueName: null,
    className: null,
  };

  toggle = (e) => {
    const {
      isSport,
      sportId,
      sportName,
      leagueId,
      leagueName,
      toggleFavoriteSport,
      toggleFavoriteLeague,
      favoriteSports,
      favoriteLeagues,
    } = this.props;

    e.stopPropagation();

    if (isSport) {
      toggleFavoriteSport({ sportId, sportName });
    } else if (leagueId) {
      toggleFavoriteLeague({ sportId, leagueId, leagueName });
    }

    const isFavorite = isSport
      ? !R.isNil(favoriteSports) && !!R.find(R.propEq('sportId', sportId), favoriteSports)
      : !R.isNil(favoriteLeagues) && !!R.find(R.propEq('leagueId', leagueId), favoriteLeagues);

    GA.event({
      category: 'favorites',
      label: isFavorite ? 'delete-sidebar' : 'add-sidebar',
    });
  };

  render() {
    const {
      isSport,
      sportId,
      leagueId,
      favoriteSports,
      favoriteLeagues,
      className,
    } = this.props;
    const isFavorite = isSport
      ? !R.isNil(favoriteSports) && !!R.find(R.propEq('sportId', sportId), favoriteSports)
      : !R.isNil(favoriteLeagues) && !!R.find(R.propEq('leagueId', leagueId), favoriteLeagues);

    return (
      <div
        role="button"
        tabIndex="0"
        onClick={this.toggle}
        onKeyPress={this.toggle}
        className={classNames('d-flex align-items-center pl-2', className)}
      >
        {isFavorite ? <IconStar /> : <IconStarEmpty />}
      </div>
    );
  }
}

export const FavoritesToggleButton = withFavoritesActions(withFavorites(
  FavoritesToggleButtonUI,
  [
    FAVORITES_STORE_FIELDS.SPORTS,
    FAVORITES_STORE_FIELDS.LEAGUES,
  ],
));
