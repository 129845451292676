import React from 'react';
import PropTypes from 'prop-types';

export const IconStarEmpty = ({ className, color }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path fill={color} fillRule="evenodd" d="M20 7.24l-7.19-.62L10 0 7.19 6.63 0 7.24l5.46 4.73L3.82 19 10 15.27 16.18 19l-1.63-7.03L20 7.24zM10 13.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L10 4.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L10 13.4z" />
  </svg>
);

IconStarEmpty.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconStarEmpty.defaultProps = {
  className: null,
  color: '#FFFFFF',
};
