import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { getConfig, convertStringifiedBoolean } from 'core/helpers';

import { RouteLink } from 'components/route-link/route-link';
import { IconStar } from 'components/icons/icon-star/icon-star';
import { IconStarEmpty } from 'components/icons/icon-star-empty/icon-star-empty';
import { IconLivestreamSmall } from 'components/icons/icon-livestream-small/icon-livestream-small';
import { GameStatus } from 'components/betting-table/game-status/game-status';
import { toTimeFormat } from 'helpers/date';
import { prepareHomeScore, prepareAwayScore } from 'helpers/score';
import { GA } from 'helpers/ga';
import { SPORTS_WITHOUT_LIVE_SCORE } from '../../../../constants';

const IS_LIVE_STREAMS_ENABLED = getConfig('IS_LIVE_STREAMS_ENABLED');

export class GameItem extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    eventData: PropTypes.shape().isRequired,
    updateWatchlist: PropTypes.func,
    withWathlistToggle: PropTypes.bool,
    isInWatchlist: PropTypes.bool,
    onEventClick: PropTypes.func,
    sendEventClickFeedback: PropTypes.func.isRequired,
    sectionBeforeEventPage: PropTypes.string.isRequired,
  };

  static defaultProps = {
    updateWatchlist: null,
    withWathlistToggle: false,
    isInWatchlist: false,
    onEventClick: () => null,
  };

  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props, nextProps);
  }

  updateWatchlist = (e) => {
    e.stopPropagation();
    const { updateWatchlist, eventData, isInWatchlist } = this.props;

    updateWatchlist(eventData);
    GA.event({
      category: 'watchlist',
      label: isInWatchlist ? 'delete-sidebar' : 'add-sidebar',
    });
  }

  onClick = (e) => {
    const { dataset: { eventId, isLive } } = e.currentTarget;
    const { sendEventClickFeedback, onEventClick } = this.props;

    sendEventClickFeedback(eventId, convertStringifiedBoolean(isLive));
    onEventClick(e);
  };

  render() {
    const {
      locale,
      isActive,
      eventData: {
        eventId,
        teamA,
        teamB,
        timestamp,
        isLive,
        sportId,
        passedTime,
        eventStatus,
        homeScore,
        awayScore,
        isOutright,
        hasLiveStream,
        setAltScore,
      },
      withWathlistToggle,
      isInWatchlist,
      sectionBeforeEventPage,
    } = this.props;
    const isEventInLive = isLive || eventStatus !== null;
    const preparedHomeScore = isEventInLive && prepareHomeScore(homeScore, sportId, setAltScore);
    const preparedAwayScore = isEventInLive && prepareAwayScore(awayScore, sportId, setAltScore);

    return (
      <div className={classNames('sport-item-link d-flex flex-column position-relative', { 'current-item': isActive })}>
        <RouteLink
          onClick={this.onClick}
          locale={locale}
          to={`/event/${eventId}?isLive=${isEventInLive}&sectionBeforeEventPage=${sectionBeforeEventPage}`}
          className="sport-item-link-teams pl-2 d-flex flex-column justify-content-center"
          data-event-id={eventId}
          data-is-live={isEventInLive}
        >
          <div className="team-title text-small font-weight-normal text-break">{teamA}</div>
          {!isOutright && <div className="team-title text-small font-weight-normal text-break mt-0_5">{teamB}</div>}
        </RouteLink>

        <div className="sport-item-link-status position-absolute">
          {isEventInLive
            ? (
              <div className="d-flex">
                <div className="d-flex flex-column align-items-end text-right text-extra-3 flex-shrink-1">
                  <div className="d-flex mb-1">
                    {withWathlistToggle && (
                      <div
                        role="button"
                        tabIndex="0"
                        onClick={this.updateWatchlist}
                        onKeyPress={this.updateWatchlist}
                        className="mr-1"
                      >
                        {isInWatchlist ? <IconStar className="icon-star" /> : <IconStarEmpty className="icon-star" />}
                      </div>
                    )}
                    {!SPORTS_WITHOUT_LIVE_SCORE.includes(sportId) && (
                      <div className="text-success label font-weight-bold">
                        {preparedHomeScore === '' ? 0 : preparedHomeScore}
                        {' - '}
                        {preparedAwayScore === '' ? 0 : preparedAwayScore}
                      </div>
                    )}
                  </div>

                  <div className="d-flex align-items-center">
                    {IS_LIVE_STREAMS_ENABLED && hasLiveStream && <IconLivestreamSmall className="mr-1 flex-shrink-0" />}
                    <GameStatus
                      locale={locale}
                      sportId={sportId}
                      eventStatus={eventStatus}
                      passedTime={passedTime}
                      className="label text-break"
                    />
                  </div>
                </div>
              </div>
            )
            : <span className="label text-extra-2">{toTimeFormat(timestamp, locale)}</span>}
        </div>
      </div>
    );
  }
}
