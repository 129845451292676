import React from 'react';
import PropTypes from 'prop-types';

export const IconAo = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.034 12.1505C13.8387 12.1505 12.8733 11.1848 12.8733 9.96625C12.8733 8.77067 13.8387 7.78202 15.034 7.78202C16.2293 7.78202 17.1947 8.74768 17.1947 9.96625C17.1947 11.1848 16.2293 12.1505 15.034 12.1505ZM15.057 5C12.3217 5 10.115 7.23021 10.115 9.98924C10.115 12.7483 12.3217 14.9785 15.057 14.9785C17.7924 14.9785 19.999 12.7483 19.999 9.98924C19.9761 7.23021 17.7694 5 15.057 5ZM5.74761 5.22992H4.16157L0.00106812 14.7716H3.08122L4.96608 9.8283L6.82796 14.7716H9.90811L5.74761 5.22992Z" fill="white" fillOpacity="0.4" />
  </svg>
);

IconAo.propTypes = {
  className: PropTypes.string,
};

IconAo.defaultProps = {
  className: '',
};
