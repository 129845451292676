import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { IconLiveStreamsStub } from 'components/icons/icon-live-streams-stub/icon-live-streams-stub';
import { IconLiveStatsStub } from 'components/icons/icon-live-stats-stub/icon-live-stats-stub';
import { LIVE_WIDGET_STUBS } from '../../../constants';

export const LiveWidgetStub = ({
  stubs,
  isLiveStreamsEnabled,
  isUserLoggedIn,
  isLiveStreamAvailable,
  isLiveStreams,
  isLiveStats,
}) => (
  <Fragment>
    {isLiveStreamsEnabled && isUserLoggedIn && isLiveStreamAvailable && isLiveStreams
    && stubs.includes(LIVE_WIDGET_STUBS.NO_STREAM) && (
      <div className="d-flex flex-column align-items-center justify-content-center text-center px-2 pb-2_5 pt-5">
        <IconLiveStreamsStub className="mb-2" />
        <FormattedTag id="live-streams.empty.event" className="text-extra-3" />
      </div>
    )}

    {isLiveStats && stubs.includes(LIVE_WIDGET_STUBS.NO_STATS) && (
      <div className="d-flex flex-column align-items-center justify-content-center text-center px-2 pb-2_5 pt-5">
        <IconLiveStatsStub className="mb-2" />
        <FormattedTag id="live-stats.empty.event" className="text-extra-3" />
      </div>
    )}
  </Fragment>
);

LiveWidgetStub.propTypes = {
  stubs: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLiveStreamsEnabled: PropTypes.bool.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  isLiveStreamAvailable: PropTypes.bool.isRequired,
  isLiveStreams: PropTypes.bool.isRequired,
  isLiveStats: PropTypes.bool.isRequired,
};
