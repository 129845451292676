import React from 'react';
import PropTypes from 'prop-types';

export const IconSearch = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd" opacity=".4">
      <path d="M0 0L20 0 20 20 0 20z" />
      <path fill="#FFF" fillRule="nonzero" d="M13.5 12h-.79l-.28-.27C13.41 10.59 14 9.11 14 7.5 14 3.91 11.09 1 7.5 1S1 3.91 1 7.5 3.91 14 7.5 14c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L18.49 17l-4.99-5zm-6 0C5.01 12 3 9.99 3 7.5S5.01 3 7.5 3 12 5.01 12 7.5 9.99 12 7.5 12z" />
    </g>
  </svg>
);

IconSearch.propTypes = {
  className: PropTypes.string,
};

IconSearch.defaultProps = {
  className: null,
};
