import React from 'react';
import PropTypes from 'prop-types';

import { MediaQuery } from 'components/media-query/media-query';
import { LiveWidget } from 'components/live-widget/live-widget';

import './right-sidebar.scss';

export const RightSidebar = ({ pageName, params }) => (
  <div className="right-sidebar d-none d-lg-flex flex-column flex-shrink-0">
    <div>
      <MediaQuery up="lg">
        <LiveWidget pageName={pageName} params={params} />
      </MediaQuery>
    </div>

    <div className="bg-main-2 flex-fill" />
  </div>
);

RightSidebar.propTypes = {
  pageName: PropTypes.string.isRequired,
  params: PropTypes.shape().isRequired,
};
