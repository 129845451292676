import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Collapse from 'reactstrap/lib/Collapse';
import { prepareBetsForRepeat, mathRound, convertStringifiedBoolean } from 'core/helpers';
import {
  BET_TYPE,
  BONUS_TYPES,
  BET_STAKE_STATUS,
  AI_OUTCOME_SECTION,
} from 'core/constants';
import { handicapStakeTypesIds, restOfTheMatchStakeTypeIds } from 'core/mapping-stake-types';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { RouteLink } from 'components/route-link/route-link';
import { IconArrowBottom } from 'components/icons/icon-arrow-bottom/icon-arrow-bottom';
import { IconBonus } from 'components/icons/icon-bonus/icon-bonus';
import { IconRepeat } from 'components/icons/icon-repeat/icon-repeat';
import { prepareStakeTypeName } from 'helpers/stake';
import {
  getBetStatusIcon,
  findLostStake,
  getMultipleBetStatusIcon,
  isBetWithRepeat,
} from 'helpers/bets';
import { formatAmountWithCurrencySymbol, prepareCurrency } from 'helpers/currency';
import { prepareBetsScore } from 'helpers/score';
import { GA } from 'helpers/ga';

export class BetItemMultiple extends Component {
  static propTypes = {
    stakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    locale: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    betAmount: PropTypes.number.isRequired,
    isCashouted: PropTypes.bool.isRequired,
    betFactor: PropTypes.number,
    bettingBonusType: PropTypes.oneOf([
      BONUS_TYPES.EXPRESS_PERCENT,
      BONUS_TYPES.EXPRESS_CASHBACK,
    ]),
    betType: PropTypes.oneOf([
      BET_TYPE.ORDINAR,
      BET_TYPE.EXPRESS,
      BET_TYPE.SYSTEM,
    ]).isRequired,
    systemIndex: PropTypes.number,
    order: PropTypes.shape().isRequired,
    bettingBonusValue: PropTypes.number,
    accumulatorId: PropTypes.number,
    repeatBet: PropTypes.func.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    betFactor: null,
    systemIndex: null,
    bettingBonusType: null,
    bettingBonusValue: null,
    accumulatorId: null,
  };

  state = {
    isOpen: false,
  };

  toggleIsOpen = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }));

  onCashoutClick = (e) => {
    e.stopPropagation();
  };

  getBetDescription = () => {
    const { stakes } = this.props;

    return stakes.reduce((prev, cur, idx) => {
      const {
        stakeCode,
        teamA,
        teamB,
        stakeArgument,
        stakeTypeId,
        stakeName,
        stakeTypeName,
        isOutright,
        periodName,
      } = cur;
      const formattedStakeTypeName = prepareStakeTypeName(periodName, stakeTypeName);
      let formattedStakeName = `${stakeName}${stakeArgument === null ? '' : ` ${stakeArgument}`}`;

      if (handicapStakeTypesIds.includes(stakeTypeId)) {
        formattedStakeName = `${stakeCode === 1 ? teamA : teamB} ${stakeArgument > 0 ? `+${stakeArgument}` : stakeArgument}`;
      } else if (restOfTheMatchStakeTypeIds.includes(stakeTypeId)) {
        formattedStakeName = `${stakeName} (${stakeArgument.toFixed(1).replace('.', ':')})`;
      }

      if (idx === 0) {
        return `${isOutright ? `${teamA} ` : ''}${formattedStakeTypeName} ${formattedStakeName}`;
      }

      return `${prev}, ${isOutright ? `${teamA} ` : ''}${formattedStakeTypeName} ${formattedStakeName}`;
    }, '');
  };

  onEventClick = (e) => {
    const { dataset: { eventId, isLive } } = e.currentTarget;
    const { sendEventClickFeedback } = this.props;
    e.stopPropagation();
    GA.event({
      category: 'go-to-event-page',
      label: 'my-bets-event-click',
    });
    sendEventClickFeedback(eventId, convertStringifiedBoolean(isLive));
  };

  repeatBet = (e) => {
    e.stopPropagation();
    const {
      repeatBet,
      betAmount,
      betType,
      stakes,
      systemIndex,
    } = this.props;
    repeatBet({
      betAmount,
      betType,
      stakes: prepareBetsForRepeat(stakes),
      systemIndex,
    });
    GA.event({
      category: 'bet-slip',
      label: 'repeat-last-bet-click',
    });
  };

  render() {
    const {
      locale,
      currency,
      betAmount,
      betFactor,
      betType,
      stakes,
      systemIndex,
      isCashouted,
      bettingBonusType,
      bettingBonusValue,
      accumulatorId,
      order: {
        winAmount,
        isWon,
      },
    } = this.props;
    const { isOpen } = this.state;
    const isExpress = betType === BET_TYPE.EXPRESS;
    const betDescription = this.getBetDescription();
    const StatusIcon = getMultipleBetStatusIcon(false, isWon, stakes, bettingBonusType);
    const isLostWithCashback = bettingBonusType === BONUS_TYPES.EXPRESS_CASHBACK && findLostStake(stakes);
    let intlId = 'bet-slip.type.system';

    if (accumulatorId) {
      intlId = 'bet-slip.type.boosted-express';
    } else if (isExpress) {
      intlId = 'bet-slip.type.express';
    }

    return (
      <div
        role="button"
        tabIndex="0"
        onClick={this.toggleIsOpen}
        onKeyPress={this.toggleIsOpen}
        className={classNames('bet-item-multiple py-2 bg-main-4', {
          'is-open': isOpen,
        })}
      >
        <div className="d-flex justify-content-between px-2">
          <div className="mb-0_25">
            <IconArrowBottom className="icon-arrow-bottom" backgroundColor="transparent" />
            <FormattedTag
              id={intlId}
              values={isExpress ? {} : { systemIndex, stakesAmount: stakes.length }}
              className="font-weight-bold ml-1"
            />
          </div>

          {isExpress && !!betFactor && <span className="font-weight-bold">{mathRound(betFactor)}</span>}
        </div>

        <div title={betDescription} className="d-flex justify-content-between mb-1 px-2">
          <span title={betDescription} className="bet-item-multiple-desc caption text-extra-2">{betDescription}</span>

          {isBetWithRepeat(stakes) && (
            <div
              role="button"
              tabIndex="0"
              onClick={this.repeatBet}
              onKeyPress={this.repeatBet}
              className="d-flex align-items-center align-self-end flex-shrink-0 ml-1"
            >
              <IconRepeat className="flex-shrink-0 mr-1" />
              <FormattedTag id="bet-slip.repeat-bet" className="caption font-weight-bold text-extra-2" />
            </div>
          )}
        </div>

        <Collapse isOpen={isOpen} className="px-2">
          {stakes.map((stake, idx) => {
            const StakeStatusIcon = getBetStatusIcon(stake.order.statusId);
            const score = prepareBetsScore(stake.order.currentScore, stake.sportId);
            const formattedStakeTypeName = prepareStakeTypeName(stake.periodName, stake.stakeTypeName);
            let formattedStakeName = `${stake.stakeName}${stake.stakeArgument === null ? '' : ` ${stake.stakeArgument}`}`;
            const isActive = stake.order.statusId === BET_STAKE_STATUS.NEW;
            const LinkComponent = isActive ? RouteLink : 'span';
            const linkProps = isActive
              ? {
                locale,
                to: `/event/${stake.eventNumber}?isLive=${!!score}&sectionBeforeEventPage=${AI_OUTCOME_SECTION.MY_BETS}`,
                onClick: this.onEventClick,
                'data-event-id': stake.eventNumber,
                'data-is-live': !!score,
              }
              : {};

            if (handicapStakeTypesIds.includes(stake.stakeTypeId)) {
              formattedStakeName = `${stake.stakeCode === 1 ? stake.teamA : stake.teamB} ${stake.stakeArgument > 0 ? `+${stake.stakeArgument}` : stake.stakeArgument}`;
            } else if (restOfTheMatchStakeTypeIds.includes(stake.stakeTypeId)) {
              formattedStakeName = `${stake.stakeName} (${stake.stakeArgument.toFixed(1).replace('.', ':')})`;
            }

            return (
              <div
                key={stake.orderId}
                className={classNames({
                  'mb-2_5': idx !== stakes.length - 1 || bettingBonusValue,
                  'mb-3': idx === stakes.length - 1 && !bettingBonusValue,
                })}
              >
                <div className="d-flex justify-content-between mb-0_5 text-small">
                  <div className="d-flex align-items-center">
                    <StakeStatusIcon className="bet-status-icon mr-1 flex-shrink-0" />
                    <span title={formattedStakeName} className="font-weight-bold text-truncate">{formattedStakeName}</span>
                  </div>
                  {!!stake.betFactor && <span className="text-extra-2">{mathRound(stake.betFactor)}</span>}
                </div>

                <div title={formattedStakeTypeName} className="text-extra-2 caption pl-2_5 mb-0_25">{formattedStakeTypeName}</div>

                <div className={classNames('d-flex caption pl-2_5', { 'mb-1': !bettingBonusValue })}>
                  <LinkComponent
                    {...linkProps}
                    title={stake.isOutright ? stake.teamA : `${stake.teamA} - ${stake.teamB}`}
                    className={classNames('text-truncate', { 'bet-item-event-link': isActive, 'text-extra-2': !isActive })}
                  >
                    {stake.isOutright ? stake.teamA : `${stake.teamA} - ${stake.teamB}`}
                  </LinkComponent>

                  {score && (
                    <>
                      <span className="mx-0_5 text-extra-3">&middot;</span>
                      <span className="text-nowrap font-weight-bold">{score[0]} - {score[1]}</span>
                    </>
                  )}
                </div>
              </div>
            );
          })}
          {!!bettingBonusValue && (
            <div className="d-flex align-items-center justify-content-between my-2_5 text-small">
              <div className="d-flex align-items-center ml-n0_5">
                <IconBonus />
                <FormattedTag id={`general.${bettingBonusType === BONUS_TYPES.EXPRESS_PERCENT ? 'bonus' : 'cashback'}`} className="font-weight-bold ml-1 mr-0_25" />
                {bettingBonusType === BONUS_TYPES.EXPRESS_CASHBACK && bettingBonusValue && `${mathRound(bettingBonusValue)} ${prepareCurrency(currency)}`}
              </div>
              <span className="text-extra-2">
                {bettingBonusType === BONUS_TYPES.EXPRESS_PERCENT && bettingBonusValue
                && mathRound(bettingBonusValue)}
              </span>
            </div>
          )}
        </Collapse>

        <div className="d-flex bet-item-info without-cashout px-2">
          <div className="bet-item-stake pr-0_25 mr-0_5">
            <FormattedTag id="bet-slip.stake" tag="div" className="caption text-extra-2" />
            {!!betAmount && <div className="text-small font-weight-bold text-break">{formatAmountWithCurrencySymbol(betAmount, currency)}</div>}
          </div>
          <div className="bet-item-return pl-0_25 ml-0_5">
            <FormattedTag id={isCashouted ? 'bets-history.cashed-out' : 'bet-slip.return'} tag="div" className="caption text-extra-2" />
            <div className="d-flex align-items-center">
              {!isCashouted && <StatusIcon className="bet-status-icon mr-1 flex-shrink-0" />}
              {typeof winAmount === 'number'
                ? (
                  <div className={classNames('bet-item-win-amount text-small font-weight-bold text-break', {
                    'text-success': isWon && !isCashouted && !isLostWithCashback,
                    'text-extra-3': !isWon || isLostWithCashback,
                  })}
                  >
                    {formatAmountWithCurrencySymbol(winAmount, currency)}
                  </div>
                )
                : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
