import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportBeachFootball = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M19.64 7.33l-4.35 1.74-1.53 4.69 2.61 3.92C18.59 15.84 20 13.07 20 9.97c0-.91-.12-1.8-.36-2.64zm-.51-1.42C17.68 2.63 14.5.28 10.75 0v4.64l4.1 2.99 4.28-1.72zM7.47 14.72l-2.78 3.72c1.54.97 3.36 1.53 5.31 1.53 1.88 0 3.65-.52 5.15-1.43l-2.55-3.82H7.47zm.17-1.5h4.72l1.46-4.49L10 5.95 6.18 8.73l1.46 4.49zm1.61-8.58V0C5.39.29 2.15 2.76.75 6.18l4.42 1.43 4.08-2.97zm-2.99 9.19l-2.77 3.72C1.35 15.72 0 13 0 9.97c0-.81.1-1.6.29-2.36L4.7 9.04l1.56 4.79z" />
  </svg>
);

IconSportBeachFootball.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportBeachFootball.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
