import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportEquestrian = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M5.59 13.83c-1.01-.79-1.33-1.88-1.43-2.56h2.65c.07 1.19.23 2.3.5 3.29-.51-.08-1.13-.28-1.72-.73m-2.4-5.21c-.04.39-.37.67-.74.67h-.09c-.41-.05-.71-.42-.66-.83.11-.95.45-2.36 1.48-3.69.55-.7 1.22-1.29 2-1.76.35-.21.81-.09 1.03.26.21.35.09.82-.26 1.03-.62.37-1.15.84-1.58 1.4-.82 1.05-1.1 2.17-1.18 2.92m16.34 3.57c-.51-.26-2.37-1.34-2.88-1.71.01-.02.01-.03.01-.05-.05-1.57-.44-3.12-1.13-4.54-.97-1.94-2.97-4.22-6.98-4.57V1c0-.55-.45-1-1-1H7.5c-.55 0-1 .45-1 1v.33c-2.59.32-4.52 1.9-5.61 4.63C.34 7.39.03 8.9 0 10.43c0 .46.37.84.83.84h1.82c.1.91.5 2.56 2.02 3.74 1.16.9 2.39 1.09 3.2 1.1.73 1.61 1.79 2.74 3.11 3.18.42.14.87.21 1.33.21.09 0 .19 0 .28-.01.41-.02.73-.38.7-.79-.02-.42-.4-.74-.79-.71-.38.03-.72-.02-1.05-.12-1.75-.59-2.94-3.16-3.13-6.6h3.64c1.1 1.06 4.37 2.74 7.22 2.04.5-.12.69-.26.79-.47.12-.25-.09-.48-.44-.65" />
  </svg>
);

IconSportEquestrian.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportEquestrian.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
