import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BET_SLIP_STATE, STAKE_TYPES_WITH_3WAY_MAIN_MARKET, AI_OUTCOME_SECTION } from 'core/constants';
import { getConfig, getMainLineMarkets, isEmptyOrNil } from 'core/helpers';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { RouteLink } from 'components/route-link/route-link';
import { SportTypeIcon } from 'components/sport-type-icon/sport-type-icon';
import { GameStatus } from 'components/betting-table/game-status/game-status';
import { TeamColumn } from 'components/betting-table/team-column/team-column';
import { IconLivestreamSmall } from 'components/icons/icon-livestream-small/icon-livestream-small';
import { OddsCell } from 'components/betting-table/odds-cell/odds-cell';
import { toTimeIfNearOrDate, toTodayOrTomorrow } from 'helpers/date';
import { prepareHomeScore, prepareAwayScore } from 'helpers/score';
import { setEmptyStakes } from 'helpers/stake';
import { GA } from 'helpers/ga';
import {
  SPORTS_WITH_3WAY_MAIN_MARKET,
  TODAY,
  TOMORROW,
  SPORTS_WITHOUT_LIVE_SCORE,
} from '../../../../constants';

import './search-group-item.scss';

const IS_LIVE_STREAMS_ENABLED = getConfig('IS_LIVE_STREAMS_ENABLED');

const SCROLL_OFFSET = 100;

export class SearchGroupItem extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    sportId: PropTypes.number.isRequired,
    betSlipStakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    toggleStake: PropTypes.func.isRequired,
    isLogoShowed: PropTypes.bool.isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    eventId: PropTypes.number.isRequired,
    eventNumber: PropTypes.number.isRequired,
    teamA: PropTypes.string.isRequired,
    teamB: PropTypes.string.isRequired,
    teamALogo: PropTypes.string.isRequired,
    teamBLogo: PropTypes.string.isRequired,
    timestamp: PropTypes.number.isRequired,
    isBetAllowed: PropTypes.bool.isRequired,
    isLive: PropTypes.bool.isRequired,
    stakeTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    eventStatus: PropTypes.string.isRequired,
    passedTime: PropTypes.number.isRequired,
    homeScore: PropTypes.number.isRequired,
    awayScore: PropTypes.number.isRequired,
    leagueId: PropTypes.number.isRequired,
    leagueName: PropTypes.string.isRequired,
    setAltScore: PropTypes.string.isRequired,
    hasLiveStream: PropTypes.bool.isRequired,
    oddsCount: PropTypes.number.isRequired,
    isOutright: PropTypes.bool.isRequired,
    isResults: PropTypes.bool.isRequired,
    isWatchlist: PropTypes.bool.isRequired,
    isSchedule: PropTypes.bool.isRequired,
    recommendationId: PropTypes.number.isRequired,
    onLeagueClick: PropTypes.func.isRequired,
    onEventClick: PropTypes.func.isRequired,
  };

  oddsRef = createRef();

  resizeWindowSubsription;

  state = {
    isPrevShowed: false,
    isNextShowed: false,
  };

  componentDidMount() {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollWidth, clientWidth } = odds;
      this.resizeWindowSubsription = fromEvent(window, 'resize')
        .pipe(debounceTime(200))
        .subscribe(this.onScroll);

      if (scrollWidth > clientWidth) {
        this.setIsNextShowed(true);
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !R.equals(this.props, nextProps) || !R.equals(this.state, nextState);
  }

  componentDidUpdate(prevProps) {
    const { stakeTypes: prevStakeTypes } = prevProps;
    const { stakeTypes } = this.props;

    if (!R.equals(prevStakeTypes, stakeTypes)) {
      this.onScroll();
    }
  }

  componentWillUnmount() {
    if (this.resizeWindowSubsription) {
      this.resizeWindowSubsription.unsubscribe();
    }
  }

  setIsNextShowed = isNextShowed => this.setState({ isNextShowed });

  setIsPrevShowed = isPrevShowed => this.setState({ isPrevShowed });

  onScroll = () => {
    const { isPrevShowed, isNextShowed } = this.state;

    if (this.oddsRef && this.oddsRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = this.oddsRef.current;
      const preparedWidth = Math.ceil(scrollLeft + clientWidth);

      if (isPrevShowed) {
        if (scrollLeft === 0) {
          this.setIsPrevShowed(false);
        }
      } else if (scrollLeft > 0) {
        this.setIsPrevShowed(true);
      }

      if (isNextShowed) {
        if (preparedWidth >= scrollWidth) {
          this.setIsNextShowed(false);
        }
      } else if (preparedWidth < scrollWidth) {
        this.setIsNextShowed(true);
      }
    }
  };

  onPrevClick = () => {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollLeft } = odds;
      odds.scrollTo({
        left: scrollLeft - SCROLL_OFFSET,
        behavior: 'smooth',
      });
    }
  };

  onNextClick = () => {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollLeft } = odds;
      odds.scrollTo({
        left: scrollLeft + SCROLL_OFFSET,
        behavior: 'smooth',
      });
    }
  };

  onEventClick = (e) => {
    const { onEventClick } = this.props;
    onEventClick(e);
    GA.event({
      category: 'search',
      label: 'event-click',
    });
  };

  render() {
    const {
      isLogoShowed,
      locale,
      sportId,
      toggleStake,
      betSlipStakes,
      betSlipState,
      eventId,
      eventNumber,
      teamA,
      teamB,
      teamALogo,
      teamBLogo,
      timestamp,
      isBetAllowed,
      isLive,
      stakeTypes,
      eventStatus,
      passedTime,
      homeScore,
      awayScore,
      leagueId,
      leagueName,
      oddsCount,
      hasLiveStream,
      setAltScore,
      isOutright,
      isResults,
      isWatchlist,
      isSchedule,
      recommendationId,
      onLeagueClick,
    } = this.props;
    const { isPrevShowed, isNextShowed } = this.state;
    let is3way = SPORTS_WITH_3WAY_MAIN_MARKET.includes(sportId) && !isOutright;
    const isEventInLive = isLive || eventStatus !== null;
    const marketsList = getMainLineMarkets({
      stakeTypes, isLive, eventStatus, betSlipStakes, betSlipState
    });
    const isAnyMarketExists = !!marketsList.length
    && !!marketsList.filter(({ marketStake }) => marketStake && !!marketStake.length).length;
    const isOneMarket = !!marketsList.length
    && marketsList.filter(({ marketStake }) => marketStake && !!marketStake.length).length < 2;
    const formattedDate = toTodayOrTomorrow(timestamp);
    const preparedHomeScore = isEventInLive && prepareHomeScore(homeScore, sportId, setAltScore);
    const preparedAwayScore = isEventInLive && prepareAwayScore(awayScore, sportId, setAltScore);

    const oddProps = {
      isBetAllowed,
      toggleStake,
      eventId,
      eventNumber,
      teamA,
      teamB,
      betSlipStakes,
      isEventInLive,
      betSlipState,
      isOutright,
      leagueId,
      isResults,
      isWatchlist,
      isSchedule,
      recommendationId,
      isSearch: true,
    };

    return (
      <div className="search-group-item-mobile">
        <div className="search-group-item-mobile-head d-flex align-items-center rounded-top bg-main-2 px-2">
          {isLogoShowed && <SportTypeIcon sportId={sportId} width="12" height="12" isActive className="sport-type-icon mr-1 d-flex" />}
          <RouteLink
            locale={locale}
            to={`/league/${sportId}/${leagueId}`}
            className="text-truncate text-extra-2 caption"
            onClick={onLeagueClick}
            data-league-id={leagueId}
          >
            {leagueName}
          </RouteLink>
        </div>

        <div className={classNames('search-group-item-mobile-body position-relative bg-main-2 px-2 pt-1_5 rounded-bottom', { 'is-markets': isAnyMarketExists })}>
          <RouteLink
            locale={locale}
            to={`/event/${eventId}?isLive=${isEventInLive}&sectionBeforeEventPage=${AI_OUTCOME_SECTION.SEARCH}`}
            className="d-flex pb-1_5"
            onClick={this.onEventClick}
            data-event-id={eventId}
            data-is-live={isEventInLive}
          >
            <div className="flex-fill overflow-hidden">
              <TeamColumn name={teamA} logo={teamALogo} sportId={sportId} className="mb-0_5" />
              {!isOutright && <TeamColumn name={teamB} logo={teamBLogo} sportId={sportId} />}
            </div>

            {isEventInLive
              ? (
                <div className="d-flex">
                  {IS_LIVE_STREAMS_ENABLED && hasLiveStream && <IconLivestreamSmall className="align-self-start mr-2 mt-0_25" />}

                  <div className="search-group-item-mobile-live-info d-flex flex-column align-items-end justify-content-between">
                    <div className="overflow-hidden">
                      <GameStatus
                        locale={locale}
                        sportId={sportId}
                        eventStatus={eventStatus}
                        passedTime={passedTime}
                        className="search-group-item-mobile-status text-center caption"
                      />
                    </div>
                    {!!oddsCount && (
                      <span className="caption text-extra-3">+{oddsCount}</span>
                    )}
                  </div>

                  <span className="search-group-item-mobile-score-divider mx-2" />

                  {!SPORTS_WITHOUT_LIVE_SCORE.includes(sportId) && (
                    <div className="text-success d-flex flex-column text-small font-weight-bold text-right">
                      {isOutright ? (
                        <Fragment>
                          <span>{homeScore}</span>
                          <span>{awayScore}</span>
                        </Fragment>
                      ) : (
                        <Fragment>
                          {preparedHomeScore === '' ? <span>&nbsp;</span> : <span>{preparedHomeScore}</span>}
                          {preparedAwayScore === '' ? <span>&nbsp;</span> : <span>{preparedAwayScore}</span>}
                        </Fragment>
                      )}
                    </div>
                  )}
                </div>
              )
              : (
                <div className="caption d-flex flex-column justify-content-center text-extra-3">
                  <div className="mb-1 text-nowrap">
                    {formattedDate === TODAY && <FormattedTag id="general.today" />}
                    {formattedDate === TOMORROW && <FormattedTag id="general.tomorrow" />}
                        &nbsp;
                    {toTimeIfNearOrDate(timestamp, locale)}
                  </div>
                  {!!oddsCount && (
                    <span className="caption text-extra-3 align-self-end">+{oddsCount}</span>
                  )}
                </div>
              )}
          </RouteLink>

          <div className="search-group-item-mobile-odds-wrapper d-flex justify-content-between position-absolute">
            <div
              ref={this.oddsRef}
              onScroll={this.onScroll}
              className="search-group-item-mobile-odds-scroll-bar w-100 d-flex"
            >
              {isAnyMarketExists ? (
                marketsList.map(({
                  stakeTypeId,
                  stakeTypeName,
                  marketStake,
                  isTotal,
                  isHandicap,
                }, index) => {
                  is3way = STAKE_TYPES_WITH_3WAY_MAIN_MARKET.includes(stakeTypeId);
                  const isLastMarket = marketsList.length === index + 1;
                  const marketStakeWithEmptyValue = setEmptyStakes(marketStake, is3way);

                  return !R.isNil(marketStakeWithEmptyValue) && (
                    <div key={stakeTypeId} className={classNames('search-group-item-mobile-odds-item d-flex flex-column flex-shrink-0 position-relative', { 'is-one-market': isOneMarket, 'with-draw': is3way, 'mr-1_5': !isLastMarket })}>
                      <div className="market-name overflow-hidden caption position-absolute mb-0_5 mt-1_5">
                        <span className="text-nowrap text-extra-3">
                          {stakeTypeName}
                        </span>
                      </div>
                      <div className="search-group-item-mobile-odds-group mt-4 flex-shrink-0">
                        {marketStakeWithEmptyValue.map(stake =>
                          !isEmptyOrNil(stake) ? (
                            <OddsCell
                              key={stake.stakeId}
                              stake={stake}
                              isHandicap={isHandicap}
                              isTotal={isTotal}
                              {...oddProps}
                            />
                          ) : (
                            <div className="odd-cell is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                              &#8212;
                            </div>
                          ))}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className={classNames('search-group-item-mobile-odds-item w-100 mt-1_5', { 'with-draw': is3way })}>
                  <div className="search-group-item-mobile-odds-group">
                    <div className="odd-cell is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                      &#8212;
                    </div>
                    <div className="odd-cell is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                      &#8212;
                    </div>
                    {is3way && (
                      <div className="odd-cell is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                        &#8212;
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div
              role="button"
              tabIndex="0"
              onClick={this.onPrevClick}
              onKeyPress={this.onPrevClick}
              className={classNames('search-group-item-mobile-odds-prev position-absolute', { invisible: !isPrevShowed })}
            />

            <div
              role="button"
              tabIndex="0"
              onClick={this.onNextClick}
              onKeyPress={this.onNextClick}
              className={classNames('search-group-item-mobile-odds-next position-absolute', { invisible: !isNextShowed })}
            />
          </div>
        </div>
      </div>
    );
  }
}
