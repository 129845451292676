import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportAthletics = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M11.121 3.907c.134.08.241.172.326.272.05.02.097.04.145.064.131.025.265.077.402.157 2 1.187 3.857.962 5.242-.982.908-1.268 3.024-.052 2.108 1.23-1.772 2.48-4.256 3.424-6.868 2.623-.823 1.408-1.6 2.843-2.349 4.292 1.105.566 2.13 1.269 3.081 2.097.042.035.077.074.113.11.207.107.39.285.518.553l1.92 3.966c.686 1.42-1.523 2.45-2.208 1.036l-1.825-3.77c-.082-.046-.164-.102-.244-.17-1.102-.962-2.367-1.74-3.75-2.222-.07-.024-.132-.053-.19-.084-1.695 2.152-4.06 3.336-6.893 1.68-1.493-.871-.143-3.19 1.354-2.315 2.074 1.213 3.317-.567 4.255-2.28.042-.166.101-.333.188-.502.726-1.412 1.483-2.808 2.276-4.185-1.549-.493-2.964-.041-4.077 1.517-.904 1.267-3.022.052-2.105-1.23 2.153-3.017 5.364-3.766 8.581-1.857zM13.208 0c1.092 0 1.979.886 1.979 1.978 0 1.094-.887 1.979-1.98 1.979-1.091 0-1.978-.885-1.978-1.979C11.229.886 12.116 0 13.208 0z" />
  </svg>
);

IconSportAthletics.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportAthletics.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
