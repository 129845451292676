import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportIceHockey = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport2Bg : colors.cpExtra4} d="M0 9.55v3.95C0 15.99 4.48 18 10 18s10-2.01 10-4.5V9.55c-1.94 1.79-5.6 2.95-10 2.95S1.94 11.34 0 9.55M10 11C4.478 11 0 8.986 0 6.5 0 4.015 4.478 2 10 2s10 2.015 10 4.5c0 2.486-4.478 4.5-10 4.5" />
  </svg>
);

IconSportIceHockey.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportIceHockey.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
