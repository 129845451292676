import React from 'react';
import PropTypes from 'prop-types';

export const IconColon = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="2" height="8" viewBox="0 0 2 8">
    <path fill="#FFF" fillRule="evenodd" d="M1 6c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm0-6c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z" />
  </svg>
);

IconColon.propTypes = {
  className: PropTypes.string,
};

IconColon.defaultProps = {
  className: null,
};
