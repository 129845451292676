import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import { FormattedMessage } from 'react-intl';
import Button from 'reactstrap/lib/Button';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import {
  subtractValues,
  isEmptyOrNil,
  getConfig,
  mathRound,
} from 'core/helpers';
import {
  BET_TYPE,
  BET_SLIP_STATE,
  IS_MULTIPLE_RESTRICTED_FIELD,
  BET_SLIP_ERRORS,
  BONUS_TYPES,
  BET_FACTOR_CHANGES,
} from 'core/constants';

import { Spinner } from 'components/spinner/spinner';
import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';
import { BetFactorChanges } from 'components/bet-slip/bet-factor-changes/bet-factor-changes';
import { Bonuses } from 'components/bonuses/bonuses';
import { IconInfo } from 'components/icons/icon-info/icon-info';
import { IconWarning } from 'components/icons/icon-warning/icon-warning';
import { prepareCurrency } from 'helpers/currency';
import { GA } from 'helpers/ga';
import { IconFreebet } from 'components/icons/icon-freebet/icon-freebet';
import { BetAmountInput } from '../bet-amount-input/bet-amount-input';

import './bet-slip-footer.scss';

const IS_STAKE_AUTOFILL_ENABLED = getConfig('IS_STAKE_AUTOFILL_ENABLED') || false;

const TOOLTIP_ID = 'system-variants-tooltip-id';
const FREEBET_TOOLTIP_ID = 'stake-item-mobile-freebet-tooltip-id';

export class BetSlipFooter extends Component {
  static propTypes = {
    currency: PropTypes.string.isRequired,
    stakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    betType: PropTypes.oneOf([
      BET_TYPE.ORDINAR,
      BET_TYPE.EXPRESS,
      BET_TYPE.SYSTEM,
    ]).isRequired,
    betFactor: PropTypes.number,
    totalReturn: PropTypes.number,
    systemVariants: PropTypes.number,
    betAmount: PropTypes.number,
    isSystemInfoInProgress: PropTypes.bool.isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    acceptChanges: PropTypes.func.isRequired,
    keyboardValue: PropTypes.string.isRequired,
    isKeyboardShowed: PropTypes.bool.isRequired,
    setIsKeyboardShowed: PropTypes.func.isRequired,
    placeBet: PropTypes.func.isRequired,
    isPlaceBetInProgress: PropTypes.bool.isRequired,
    error: PropTypes.shape(),
    bonuses: PropTypes.arrayOf(PropTypes.shape({})),
    bonusType: PropTypes.oneOf([
      BONUS_TYPES.EXPRESS_PERCENT,
      BONUS_TYPES.EXPRESS_CASHBACK,
    ]),
    isBonusConditionsReached: PropTypes.bool.isRequired,
    bonusAmount: PropTypes.number,
    setBonusType: PropTypes.func.isRequired,
    betFactorChanges: PropTypes.oneOf([
      BET_FACTOR_CHANGES.INCREASE,
      BET_FACTOR_CHANGES.ALWAYS,
      BET_FACTOR_CHANGES.NEVER,
    ]).isRequired,
    setBetFactorChanges: PropTypes.func.isRequired,
    onBetFactorChangesChange: PropTypes.func.isRequired,
    clearOutOfBonusBalanceError: PropTypes.func.isRequired,
    setTicketMaxHeight: PropTypes.func.isRequired,
    availableFreebetId: PropTypes.number,
    setUsedFreebet: PropTypes.func.isRequired,
    isFreebetUsed: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    betFactor: null,
    totalReturn: null,
    betAmount: null,
    systemVariants: null,
    error: null,
    bonuses: null,
    bonusType: null,
    bonusAmount: null,
    availableFreebetId: null,
  };

  onPlaceBetClick = () => {
    const { placeBet, stakes } = this.props;
    const isAccumulator1 = !!R.find(R.propEq('accumulatorIdx', 0), stakes);
    const isAccumulator2 = !!R.find(R.propEq('accumulatorIdx', 1), stakes);
    let label = 'place-bet-click';

    if (isAccumulator1) {
      label = 'place-bet-1';
    } else if (isAccumulator2) {
      label = 'place-bet-2';
    }

    placeBet();
    GA.event({
      category: 'bet-slip',
      label,
    });
  };

  onAcceptChangesClick = () => {
    const { acceptChanges } = this.props;
    acceptChanges();
    GA.event({
      category: 'bet-slip',
      label: 'accept-changes-click',
    });
  };

  onFreebetClick = () => {
    const { setUsedFreebet, availableFreebetId, isFreebetUsed } = this.props;
    setUsedFreebet(isFreebetUsed ? null : availableFreebetId);
  };

  render() {
    const {
      currency,
      betType,
      betFactor,
      totalReturn,
      systemVariants,
      betAmount,
      isSystemInfoInProgress,
      betSlipState,
      stakes,
      keyboardValue,
      isKeyboardShowed,
      setIsKeyboardShowed,
      isPlaceBetInProgress,
      error,
      bonuses,
      bonusType,
      isBonusConditionsReached,
      bonusAmount,
      setBonusType,
      betFactorChanges,
      setBetFactorChanges,
      onBetFactorChangesChange,
      clearOutOfBonusBalanceError,
      setTicketMaxHeight,
      availableFreebetId,
      isFreebetUsed,
    } = this.props;
    const isOrdinar = betType === BET_TYPE.ORDINAR;
    const isExpress = betType === BET_TYPE.EXPRESS;
    const isSystem = betType === BET_TYPE.SYSTEM;
    const isDefaultState = betSlipState === BET_SLIP_STATE.DEFAULT;
    const isSuspendedState = betSlipState === BET_SLIP_STATE.SUSPENDED;
    const isBetFactorDecreasedState = betSlipState === BET_SLIP_STATE.BET_FACTOR_DECREASED;
    const isErrorState = betSlipState === BET_SLIP_STATE.ERROR;
    const isMultipleRestrictedState = R.find(R.prop(IS_MULTIPLE_RESTRICTED_FIELD), stakes);
    const isExpressDeniedState = isExpress && R.find(R.prop('isExpressDenied'), stakes);
    const isOutOfBalance = isErrorState && error.errorId === BET_SLIP_ERRORS.BET_AMOUNT_OUT_OF_BALANCE;
    const isOutOfBonusBalance = isErrorState && error.errorId === BET_SLIP_ERRORS.BET_AMOUNT_OUT_OF_BONUS_BALANCE;
    const isDepositState = isOutOfBalance && !error.amount;
    const isStakesAmountExceed = isErrorState && error.errorId === BET_SLIP_ERRORS.STAKES_AMOUNT_EXCEED;
    const isReferenceToAnotherEvent = isErrorState && error.errorId === BET_SLIP_ERRORS.REFERENCE_TO_ANOTHER_EVENT;
    const totalReturnWithoutBonus = bonusAmount && bonusType === BONUS_TYPES.EXPRESS_PERCENT
      ? subtractValues([totalReturn, bonusAmount])
      : null;
    let errorIntlId;

    if (isErrorState) {
      if (error.errorId === BET_SLIP_ERRORS.BET_AMOUNT_LESS_THAN_LIMIT) {
        errorIntlId = 'bet-slip.stake-limit-error.less.title';
      } else if (error.errorId === BET_SLIP_ERRORS.BET_AMOUNT_MORE_THAN_LIMIT) {
        errorIntlId = 'bet-slip.stake-limit-error.more.title';
      } else if (isOutOfBalance) {
        errorIntlId = 'bet-slip.out-of-balance-error.title';
      } else if (isOutOfBonusBalance) {
        errorIntlId = 'bet-slip.out-of-bonus-balance-error.title';
      } else if (isReferenceToAnotherEvent) {
        errorIntlId = 'bet-slip.reference-to-event-error.title';
      }
    }

    return (
      <div className={classNames('bet-slip-footer-mobile px-1_5 pb-2 bg-main-4', {
        'is-ordinar': isOrdinar,
        'pt-2': !isOrdinar && !isMultipleRestrictedState && !isExpressDeniedState,
        'pt-1_5': !isOrdinar && (isMultipleRestrictedState || isExpressDeniedState),
      })}
      >
        {(isMultipleRestrictedState || isExpressDeniedState) && (
          <FormattedTag
            tag="div"
            id={`bet-slip.${isMultipleRestrictedState ? 'multiple-restricted' : 'express-denied'}`}
            className="text-warning label mb-1"
          />
        )}

        <div className={classNames('d-flex justify-content-between align-items-center', {
          'mb-2': isOrdinar,
        })}
        >
          <BetAmountInput
            currency={currency}
            keyboardValue={keyboardValue}
            isKeyboardShowed={isKeyboardShowed}
            setIsKeyboardShowed={setIsKeyboardShowed}
            betSlipState={betSlipState}
            error={error}
          />
          {isOrdinar && !!betAmount && (
            <div className="d-flex align-items-center">
              <FormattedTag id="bet-slip.return" className="caption text-extra-2 mx-1 text-nowrap" />
              {totalReturn
                ? (
                  <div className="d-flex align-items-center text-small">
                    {IS_STAKE_AUTOFILL_ENABLED && isErrorState && !isDepositState && !isStakesAmountExceed
                    && error && !!error.prevTotalReturn && (
                      <span className="bet-slip-footer-mobile-prev-total-return mr-1_5 text-extra-3 text-right text-break">
                        {mathRound(error.prevTotalReturn)} {prepareCurrency(currency)}
                      </span>
                    )}
                    <span className={classNames('bet-slip-footer-mobile-total-return font-weight-bold text-right text-break', {
                      'text-warning': IS_STAKE_AUTOFILL_ENABLED && isErrorState && !isDepositState && !isStakesAmountExceed && !isOutOfBonusBalance,
                    })}
                    >
                      {mathRound(totalReturn)} {prepareCurrency(currency)}
                    </span>
                  </div>
                )
                : <span className="text-extra-3">–</span>}
            </div>
          )}
          {isExpress && !!betFactor && <span className="font-weight-bold">{mathRound(betFactor)}</span>}
          {isSystem && !!betAmount && (
            <Fragment>
              {systemVariants
                ? (
                  <Fragment>
                    {isSystemInfoInProgress
                      ? <Spinner isSmall />
                      : (
                        <div className="d-flex align-items-center">
                          <div id={TOOLTIP_ID} className="icon-info d-flex justify-content-center align-items-center align-selft-stretch">
                            <IconInfo />
                          </div>
                          <FormattedTag
                            id="bet-slip.variants.description"
                            tag={UncontrolledTooltip}
                            placement="bottom-start"
                            target={TOOLTIP_ID}
                          />
                          <FormattedTag id="bet-slip.variants" className="text-small text-extra-2" values={{ amount: null }} />
                          <span className="text-small font-weight-bold ml-0_5">{systemVariants}</span>
                        </div>
                      )}
                  </Fragment>
                )
                : isSystemInfoInProgress && <Spinner isSmall />}
            </Fragment>
          )}

          {availableFreebetId && (
            <>
              <div
                id={FREEBET_TOOLTIP_ID}
                role="button"
                tabIndex="0"
                onClick={this.onFreebetClick}
                onKeyPress={this.onFreebetClick}
                className={classNames('bet-slip-footer-mobile-freebet d-flex align-items-center justify-content-center', { 'active bg-warning': isFreebetUsed })}
              >
                <IconFreebet className="bet-slip-footer-mobile-icon-freebet" />
              </div>
              {!isFreebetUsed && (
                <UncontrolledTooltip placement="top" target={FREEBET_TOOLTIP_ID}>
                  <FormattedTag id="freebet.tooltip" className="text-small font-weight-bold" />
                </UncontrolledTooltip>
              )}
            </>
          )}
        </div>

        {!isSystem && !isEmptyOrNil(bonuses) && (
          <Bonuses
            isMobile
            currency={currency}
            bonuses={bonuses}
            bonusType={bonusType}
            isBonusConditionsReached={isBonusConditionsReached}
            bonusAmount={bonusAmount}
            setBonusType={setBonusType}
            stakes={stakes}
            betAmount={betAmount}
            setTicketMaxHeight={setTicketMaxHeight}
          />
        )}

        {!isOrdinar && (
          <div className={classNames('d-flex justify-content-between align-items-center mt-1_5', {
            'mb-2': !isSuspendedState,
            'mb-1_5': isSuspendedState,
          })}
          >
            <FormattedTag id="bet-slip.return" className="bet-slip-footer-mobile-return-title text-small text-extra-2" />
            {totalReturn
              ? (
                <div className="d-flex align-items-center">
                  {(totalReturnWithoutBonus || (IS_STAKE_AUTOFILL_ENABLED && isErrorState && !isDepositState
                  && !isStakesAmountExceed && error && !!error.prevTotalReturn)) && (
                    <span className="bet-slip-footer-mobile-prev-total-return mr-1_5 text-small text-extra-3 text-right text-break">
                      {IS_STAKE_AUTOFILL_ENABLED && error && error.prevTotalReturn
                        ? mathRound(error.prevTotalReturn)
                        : mathRound(totalReturnWithoutBonus)} {prepareCurrency(currency)}
                    </span>
                  )}
                  <span className={classNames('bet-slip-footer-mobile-total-return font-weight-bold text-right text-break', {
                    'text-warning': IS_STAKE_AUTOFILL_ENABLED && isErrorState && !isDepositState && !isStakesAmountExceed && !isReferenceToAnotherEvent,
                  })}
                  >
                    {mathRound(totalReturn)} {prepareCurrency(currency)}
                  </span>
                </div>
              )
              : <Fragment>{isSystemInfoInProgress ? <Spinner isSmall /> : <span className="text-extra-3">–</span>}</Fragment>}
          </div>
        )}

        {(isSuspendedState || isBetFactorDecreasedState) && (
          <div className="bet-slip-footer-mobile-suspended text-small text-center position-relative pt-1_5">
            <FormattedTag tag="div" id={`bet-slip.${isSuspendedState ? 'suspended' : 'decreased'}.title`} />
            <FormattedTag tag="div" id="bet-slip.accept-changes.subtitle" className="mb-1_5 text-extra-2" />
            <FormattedTag
              id="bet-slip.accept-changes"
              tag={Button}
              size="sm"
              color="info"
              block
              onClick={this.onAcceptChangesClick}
            />
          </div>
        )}

        {isErrorState && !isDepositState && !isStakesAmountExceed && (
          <div className="bet-slip-footer-mobile-error text-small text-center position-relative pt-1_5 mb-1_5">
            <div className="d-flex align-items-center justify-content-center">
              <IconWarning className="mr-0_5 flex-shrink-0" />
              {error && (
                <FormattedTag
                  id={errorIntlId}
                  values={{ amount: error.prevBetAmount ? `${mathRound(error.prevBetAmount)} ${prepareCurrency(currency)}` : null }}
                  className="font-weight-bold"
                />
              )}
            </div>
            {!isOutOfBonusBalance && !isReferenceToAnotherEvent && (
              <div className="d-flex align-items-center justify-content-center">
                <FormattedTag id="bet-slip.stake-limit-error.subtitle" className="text-extra-2" />
                {error && (error.amount || error.limit) && (
                  <span className="ml-0_5 font-weight-bold">
                    {' '}
                    {isOutOfBalance ? mathRound(error.amount) : mathRound(error.limit)} {prepareCurrency(currency)}
                  </span>
                )}
              </div>
            )}
          </div>
        )}

        {(isDefaultState || isErrorState) && (
          <Fragment>
            {isOutOfBonusBalance
              ? (
                <FormattedTag
                  id="general.got-it"
                  tag={Button}
                  size="sm"
                  color="primary"
                  block
                  onClick={clearOutOfBonusBalanceError}
                />
              )
              : (
                <ButtonWithLoader
                  size="sm"
                  color="primary"
                  block
                  disabled={!betAmount || isMultipleRestrictedState || isExpressDeniedState}
                  onClick={this.onPlaceBetClick}
                  isLoading={isPlaceBetInProgress}
                >
                  <FormattedMessage
                    id={IS_STAKE_AUTOFILL_ENABLED && isErrorState && !isDepositState && !isStakesAmountExceed && !isReferenceToAnotherEvent ? 'bet-slip.place-amount' : `bet-slip.place-${isOrdinar ? 'bet' : 'bets'}`}
                    values={{ amount: IS_STAKE_AUTOFILL_ENABLED && isErrorState && !isDepositState && !isStakesAmountExceed && !isReferenceToAnotherEvent && betAmount ? `${mathRound(betAmount)} ${prepareCurrency(currency)}` : null }}
                  />
                </ButtonWithLoader>
              )}
          </Fragment>
        )}

        <BetFactorChanges
          isMobile
          betFactorChanges={betFactorChanges}
          setBetFactorChanges={setBetFactorChanges}
          onChange={onBetFactorChangesChange}
        />
      </div>
    );
  }
}
