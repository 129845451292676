import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportBiathlon = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M8.737 3.969c0-1.063.86-1.922 1.922-1.922 1.061 0 1.922.86 1.922 1.921 0 1.063-.86 1.923-1.922 1.923-1.062 0-1.922-.86-1.922-1.922zM3.94 8.142l2.642-6.836c.094-.246.37-.369.616-.274.245.095.368.371.272.617L5.984 5.495c.491.19.735.743.546 1.234l-.324.837c.463.177.692.697.513 1.16l-.98 2.537c-.186.482-.73.72-1.213.524-.472-.192-.685-.745-.502-1.218l.625-1.62-.427-.167c-.255-.098-.38-.383-.283-.64zm7.646 8.87l.031-.105c.388-1.268.24-2.602-.416-3.755-.4-.704-.957-1.28-1.622-1.691l.57-2.261c.53.13 1.072.197 1.615.197.504 0 1.008-.056 1.501-.168l4.143 7.784h-5.822zm-3.234-3.867c.435.236.796.588 1.05 1.032.37.652.454 1.405.234 2.122l-.124.409c-.03.1-.045.166-.045.305H4.641c1.442-1.106 2.687-2.39 3.71-3.869zm10.486 3.867c-.012-.069-.03-.1-.055-.146L14.498 8.82c.103-.046.206-.096.307-.148l.048-.023c.51-.26.713-.882.454-1.393-.258-.51-.882-.713-1.39-.454l-.049.023c-1.096.558-2.37.649-3.528.279-.186-.425-.559-.763-1.043-.885-.83-.21-1.676.295-1.886 1.127l-.943 3.744c-.137.365-.127.762.011 1.112-.88 1.223-1.936 2.296-3.15 3.204l-1.035.775c-.273.204-.414.486-.415.832H.592c-.314 0-.592.25-.592.564v.143c0 .314.278.604.592.604H18.85c.63 0 1.151-.544 1.151-1.172v-.139h-1.163z" />
  </svg>
);

IconSportBiathlon.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportBiathlon.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
