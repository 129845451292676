import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportGaelicFootball = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M6.85 13.5l3.25-4.77-1.41-.6-3.66 5.37h1.82zm3.71 0l2.43-3.56-1.41-.59-2.84 4.15h1.82zM1 5c.55 0 1 .45 1 1 0 1.21 1.09 2.18 2.34 1.97C5.33 7.81 6 6.88 6 5.88c0-.61.6-1.03 1.17-.82L18 10c3.03 1.28 2.23 4.5.07 4.5H18v.75c0 .41-.34.75-.75.75-.42 0-.75-.34-.75-.75v-.75h-1v.75c0 .41-.34.75-.75.75-.42 0-.75-.34-.75-.75v-.75h-1v.75c0 .41-.34.75-.75.75-.42 0-.75-.34-.75-.75v-.75H5v.75c0 .41-.34.75-.75.75-.42 0-.75-.34-.75-.75v-.75h-1v.75c0 .41-.34.75-.75.75-.42 0-.75-.34-.75-.75v-.75c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1z" />
  </svg>
);

IconSportGaelicFootball.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportGaelicFootball.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
