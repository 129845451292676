import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportMma = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M16.697 19v-2c0-.553-.447-1-1-1h-8c-.553 0-1 .447-1 1v2c0 .553.447 1 1 1h8c.553 0 1-.447 1-1m.75-12.83c-.02 0-.03 0-.05-.01-2.24-.74-4.57-1.07-6.9-.96-1.55.07-3.09.34-4.58.78-.08.46-.11.92-.08 1.38.03.76.21 1.51.52 2.22.16.38-.01.83-.39.99-.1.05-.2.06-.3.06-.29 0-.57-.16-.69-.45-.38-.88-.6-1.8-.64-2.75-.04-.66.02-1.31.16-1.96-.84.2-2.41.73-2.489 1.88-.111 1.58.939 4.1 2.659 5.46.98.77 1.88 1.38 2.469 1.75.181-.04.372-.06.561-.06h8c.28 0 .55.05.8.13.38-.76 1.17-2.65 1.17-4.94 0-1.87-.08-2.78-.22-3.52m-12.4-1.49l-.35.11V2.32c0-.65.53-1.18 1.18-1.18h.759c.211 0 .411.14.471.35l.66 2.05.08-1.34.1-1.73c.02-.26.24-.47.5-.47h1.45c.239 0 .44.17.489.4l.561 2.79.05-.26.38-1.99c.05-.24.25-.41.49-.41h1.44c.24 0 .44.17.49.4l.3 1.51.05.26.14.7.24-1.25c.05-.23.25-.4.49-.4h1.01c.67 0 1.22.55 1.22 1.22v1.6c-2.25-.58-4.52-.97-6.82-.87-1.82.09-3.63.42-5.38.98" />
  </svg>
);

IconSportMma.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportMma.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
