import { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

export class Portal extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.portalRoot = document.getElementById('portal-root');
    this.element = document.createElement('div');
  }

  componentDidMount() {
    this.portalRoot.appendChild(this.element);
  }

  componentWillUnmount() {
    this.portalRoot.removeChild(this.element);
  }

  render() {
    return createPortal(this.props.children, this.element);
  }
}

