import React from 'react';
import PropTypes from 'prop-types';

export const IconClock = ({ className, color }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path fill={color} fillRule="evenodd" d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm0 4c-.552 0-1 .448-1 1v5l.009.131c.028.217.128.42.284.576l3 3 .094.083c.392.305.96.278 1.32-.083l.083-.094c.305-.392.278-.96-.083-1.32L11 9.585V5l-.007-.117C10.936 4.386 10.513 4 10 4z" />
  </svg>
);

IconClock.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconClock.defaultProps = {
  className: null,
  color: '#FFFFFF',
};
