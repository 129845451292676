import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { withCoreComponent } from 'core/hocs';
import { LiveWidget as LiveWidgetCore } from 'core/components';
import { LIVE_WIDGET_TYPES, POST_MESSAGE_TYPES } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { Tabs, Tab } from 'components/tabs/tabs';
import { Spinner } from 'components/spinner/spinner';
import { GA } from 'helpers/ga';
import { LiveStreams } from './live-streams/live-streams';
import { LiveStats } from './live-stats/live-stats';
import { LiveWidgetHeader } from './live-widget-header/live-widget-header';
import { LiveWidgetStub } from './live-widget-stub/live-widget-stub';
import { PAGE_NAMES, LIVE_WIDGET_STUBS } from '../../constants';

import './live-widget.scss';

class LiveWidgetUI extends Component {
  static propTypes = {
    parent: PropTypes.string.isRequired,
    pageName: PropTypes.string.isRequired,
    params: PropTypes.shape().isRequired,
    isUserLoggedIn: PropTypes.bool.isRequired,
    isLiveStreamsEnabled: PropTypes.bool.isRequired,
    isPinned: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape()),
    isInProgress: PropTypes.bool.isRequired,
    setActiveLiveWidgetItem: PropTypes.func.isRequired,
    activeItem: PropTypes.number,
    setLiveWidgetType: PropTypes.func.isRequired,
    type: PropTypes.oneOf([
      LIVE_WIDGET_TYPES.LIVE_STREAMS,
      LIVE_WIDGET_TYPES.LIVE_STATS,
    ]).isRequired,
    setIsLiveWidgetPinned: PropTypes.func.isRequired,
    isBootstrapperLoaded: PropTypes.bool.isRequired,
    isCheckAvailbilityInProgress: PropTypes.bool.isRequired,
    isLiveStreamAvailable: PropTypes.bool.isRequired,
    eventData: PropTypes.shape(),
    isEventInProgress: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    items: null,
    activeItem: null,
    eventData: null,
  };

  state = {
    stubs: null,
  };

  componentDidMount() {
    const { items } = this.props;

    if (items) {
      this.setActiveItem();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !R.equals(this.props, nextProps) || !R.equals(this.state, nextState);
  }

  componentDidUpdate(prevProps) {
    const {
      pageName,
      params: { eventId },
      isPinned,
      items,
      activeItem,
    } = this.props;
    const {
      params: { eventId: prevEventId },
      items: prevItems,
      activeItem: prevActiveItem,
    } = prevProps;
    const isActiveItemExists = items && activeItem && !!R.find(R.propEq('eventId', activeItem), items);
    const isPrevActiveItemExists = prevItems && prevActiveItem && !!R.find(R.propEq('eventId', prevActiveItem), prevItems);

    if (!prevItems && items) {
      this.setActiveItem();
    }

    if (pageName === PAGE_NAMES.EVENT && eventId && eventId !== prevEventId && !isPinned) {
      this.setActiveItem();
    }

    if (pageName !== PAGE_NAMES.EVENT && !isActiveItemExists && isPrevActiveItemExists) {
      this.setActiveItem();
    }
  }

  setStubs = stubs => this.setState({ stubs });

  setActiveItem = () => {
    const {
      params: { eventId },
      items,
      setLiveWidgetType,
      setActiveLiveWidgetItem,
      pageName,
    } = this.props;
    let stubs = null;
    const isEvent = pageName === PAGE_NAMES.EVENT && eventId;

    const { hasLiveStream, hasLiveStats, eventId: activeEventId } = (items || []).find(item => isEvent
      ? item.eventId === Number(eventId)
      : item.hasLiveStats) || {};

    if (isEvent) {
      if (hasLiveStream) {
        setLiveWidgetType(LIVE_WIDGET_TYPES.LIVE_STREAMS);

        if (!hasLiveStats) {
          stubs = [LIVE_WIDGET_STUBS.NO_STATS];
        }
      } else if (hasLiveStats) {
        setLiveWidgetType(LIVE_WIDGET_TYPES.LIVE_STATS);
        stubs = [LIVE_WIDGET_STUBS.NO_STREAM];
      } else {
        stubs = [LIVE_WIDGET_STUBS.NO_STREAM, LIVE_WIDGET_STUBS.NO_STATS];
      }
    } else {
      const newStubs = [];
      setLiveWidgetType(LIVE_WIDGET_TYPES.LIVE_STATS);

      if (!hasLiveStats) {
        newStubs.push(LIVE_WIDGET_STUBS.NO_STATS);
      }

      if (!hasLiveStream) {
        newStubs.push(LIVE_WIDGET_STUBS.NO_STREAM);
      }

      if (newStubs.length) {
        stubs = newStubs;
      }
    }

    setActiveLiveWidgetItem(activeEventId ? Number(activeEventId) : null);
    this.setStubs(stubs);
  };

  setIsLiveWidgetPinned = (newIsPinned) => {
    const { setIsLiveWidgetPinned, isPinned, pageName } = this.props;

    if (!newIsPinned && isPinned && pageName === PAGE_NAMES.EVENT) {
      this.setActiveItem();
    }

    setIsLiveWidgetPinned(newIsPinned);
  };

  onLoginClick = () => {
    if (window && window.parent && window.parent.postMessage) {
      window.parent.postMessage({ type: POST_MESSAGE_TYPES.UNAUTHORIZED }, '*');
    }
  }

  onDepositClick = () => {
    if (window && window.parent && window.parent.postMessage) {
      window.parent.postMessage({ type: POST_MESSAGE_TYPES.DEPOSIT }, '*');
    }
  }

  onTabClick = (tab) => {
    let label;

    if (tab === LIVE_WIDGET_TYPES.LIVE_STREAMS) {
      label = 'live-stream';
    } else if (LIVE_WIDGET_TYPES.LIVE_STATS) {
      label = 'live-stats-widget';
    }

    if (label) {
      GA.event({
        category: 'event-page',
        label,
      });
    }
  };

  render() {
    const {
      parent,
      isUserLoggedIn,
      isLiveStreamsEnabled,
      type,
      items,
      isInProgress,
      setActiveLiveWidgetItem,
      activeItem: activeEventId,
      isPinned,
      isBootstrapperLoaded,
      isCheckAvailbilityInProgress,
      isLiveStreamAvailable,
      eventData,
      isEventInProgress,
      pageName,
      setLiveWidgetType,
    } = this.props;
    const { stubs } = this.state;
    const isWidgetLoaded = !isInProgress && !isCheckAvailbilityInProgress && !isEventInProgress && isBootstrapperLoaded;
    const isLiveStreams = type === LIVE_WIDGET_TYPES.LIVE_STREAMS;
    const isLiveStats = type === LIVE_WIDGET_TYPES.LIVE_STATS;
    const isHeaderShowed = (isLiveStreams && isUserLoggedIn) || isLiveStats;
    const filteredItems = R.filter(
      ({ hasLiveStream, hasLiveStats }) => isLiveStreams ? hasLiveStream : hasLiveStats,
      items || []
    );
    const activeItem = activeEventId ? R.find(R.propEq('eventId', activeEventId), items || []) : null;

    return (
      <div className={classNames('live-widget position-relative bg-main-2 mb-0_5', {
        'with-tabs': isLiveStreamsEnabled,
      })}
      >
        {isLiveStreamsEnabled && (
          <Fragment>
            <Tabs activeTab={type} toggleTab={setLiveWidgetType} className="">
              <Tab tabId={LIVE_WIDGET_TYPES.LIVE_STREAMS} label="live-widget.tab.1" onClick={this.onTabClick} />
              <Tab tabId={LIVE_WIDGET_TYPES.LIVE_STATS} label="live-widget.tab.2" onClick={this.onTabClick} />
            </Tabs>
          </Fragment>
        )}

        {isHeaderShowed && (
          <LiveWidgetHeader
            type={type}
            items={filteredItems}
            isPinned={isPinned}
            setIsLiveWidgetPinned={this.setIsLiveWidgetPinned}
            activeItem={activeItem}
            setActiveLiveWidgetItem={setActiveLiveWidgetItem}
            setStubs={this.setStubs}
            pageName={pageName}
            eventData={eventData}
            isEventInProgress={isEventInProgress}
            isWidgetLoaded={isWidgetLoaded}
          />
        )}

        <div className={classNames({ 'd-none': !isWidgetLoaded && !isPinned })}>
          {isLiveStreamsEnabled && isLiveStreams && (
            <Fragment>
              {isLiveStreamAvailable
                ? (
                  <Fragment>
                    {activeItem && activeItem.hasLiveStream && <LiveStreams activeItem={activeItem} parent={parent} />}
                  </Fragment>
                )
                : (
                  <Fragment>
                    {isUserLoggedIn && (
                      <div className="d-flex flex-column align-items-center justify-content-center py-8_5">
                        <FormattedTag id="live-streams.deposit.title" className="mt-0_5 mb-2 font-weight-bold" />
                        <FormattedTag
                          tag={Button}
                          id="live-streams.deposit.button"
                          color="primary"
                          className="live-widget-button text-small mb-0_5"
                          onClick={this.onDepositClick}
                        />
                      </div>
                    )}
                  </Fragment>
                )}

              {!isUserLoggedIn && (
                <div className="d-flex flex-column align-items-center justify-content-center py-8_5">
                  <FormattedTag id="live-streams.not-logged.title" className="mt-0_5 mb-2 font-weight-bold" />
                  <FormattedTag
                    tag={Button}
                    id="live-streams.not-logged.button"
                    color="primary"
                    className="live-widget-button text-small mb-0_5"
                    onClick={this.onLoginClick}
                  />
                </div>
              )}
            </Fragment>
          )}

          <LiveStats
            isShowed={isLiveStats && isBootstrapperLoaded}
            activeItem={activeItem}
          />

          {stubs && (
            <LiveWidgetStub
              stubs={stubs}
              isLiveStreamsEnabled={isLiveStreamsEnabled}
              isUserLoggedIn={isUserLoggedIn}
              isLiveStreamAvailable={isLiveStreamAvailable}
              isLiveStreams={isLiveStreams}
              isLiveStats={isLiveStats}
            />
          )}
        </div>

        {!isWidgetLoaded && !isPinned && (
          <div className="d-flex justify-content-center py-8_5 bg-main-2">
            <Spinner className="my-0_5" />
          </div>
        )}
      </div>
    );
  }
}

export const LiveWidget = withCoreComponent(LiveWidgetCore, LiveWidgetUI);
