import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportFieldHockey = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M9.998 16c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5M18.83.29c-.21-.187-.532-.17-.72.04-2.405 2.679-5.48 6.215-8.11 9.223C7.37 6.546 4.291 3.013 1.89.33c-.19-.21-.51-.23-.72-.04-.2.18-.22.48-.06.69 2.266 2.89 5.286 6.635 7.83 9.783-.202.23-.41.47-.604.69L3.988 16.4c-.219.25-.273.31-.392.39-.113.075-.24.126-.374.149-.27.052-.545-.026-.759-.18-.444-.306-.549-.994-.2-1.43.332-.416.284-1.03-.12-1.384-.418-.364-1.052-.321-1.416.096-.54.62-.805 1.483-.707 2.305.095.824.557 1.606 1.231 2.087.672.494 1.56.676 2.365.504.404-.083.792-.251 1.13-.49.34-.228.638-.596.768-.767l4.117-5.138c2.747-3.425 6.55-8.102 9.26-11.557.162-.21.138-.514-.062-.695m1.15 16.06c-.1.82-.56 1.6-1.23 2.08-.67.5-1.56.68-2.37.51-.4-.09-.79-.25-1.13-.49-.34-.23-.63-.6-.76-.77l-3.53-4.4c.35-.44.73-.9 1.1-1.37l3.95 4.49c.22.25.28.31.39.39.12.07.24.12.38.15.27.05.54-.03.76-.18.44-.31.55-1 .2-1.43-.34-.42-.29-1.03.12-1.39.42-.36 1.05-.32 1.41.1.54.62.81 1.48.71 2.31" />
  </svg>
);

IconSportFieldHockey.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportFieldHockey.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
