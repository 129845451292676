import React from 'react';
import PropTypes from 'prop-types';

export const IconLivestream = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path fill="#FFF" fillOpacity=".65" fillRule="evenodd" d="M17 2c1.657 0 3 1.343 3 3v10c0 1.657-1.343 3-3 3H3c-1.657 0-3-1.343-3-3V5c0-1.657 1.343-3 3-3zm0 2H3c-.552 0-1 .448-1 1v10c0 .552.448 1 1 1h14c.552 0 1-.448 1-1V5c0-.552-.448-1-1-1zM8 6.723c.174 0 .345.046.496.132l3.985 2.277c.48.274.646.885.372 1.364-.089.155-.217.284-.372.372l-3.985 2.277c-.48.274-1.09.107-1.364-.372-.087-.151-.132-.322-.132-.496V7.723c0-.552.448-1 1-1z" />
  </svg>
);

IconLivestream.propTypes = {
  className: PropTypes.string,
};

IconLivestream.defaultProps = {
  className: null,
};
