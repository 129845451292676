import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportEtennis = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M5.95 11.99C4.31 10.36 2.15 9.54 0 9.56c.13 2.1 1 4.16 2.61 5.77 1.1 1.1 2.4 1.85 3.79 2.25-.02-1.05.12-2.51.79-3.99-.34-.57-.75-1.11-1.24-1.6m5.09-5.09C9.14 5 8.19 2.49 8.21 0c-2.04.18-4.04 1.04-5.6 2.61C1.04 4.17.18 6.17 0 8.21c2.49-.02 5 .93 6.9 2.83.38.38.73.79 1.03 1.22.82-1.16 1.96-1.79 3.23-1.79 1.23 0 2.23.59 2.59.83h.36c.34-.23 1.34-.83 2.59-.83.38 0 .76.06 1.11.17.06-.3.1-.6.13-.91-2.49.02-5-.93-6.9-2.83m4.29-4.29C13.72 1 11.66.13 9.56 0c-.02 2.15.8 4.31 2.43 5.95 1.64 1.63 3.8 2.45 5.95 2.43-.13-2.1-1-4.16-2.61-5.77m2.541 12.888c-.332 0-.605-.273-.605-.606 0-.332.273-.604.605-.604.333 0 .604.271.604.604 0 .333-.27.606-.604.606m-1.512-.907c-.332 0-.604-.273-.604-.605 0-.334.272-.606.604-.606.333 0 .605.272.605.605 0 .333-.272.606-.605.606m0 1.813c-.332 0-.604-.271-.604-.604 0-.333.272-.606.604-.606.333 0 .605.273.605.605 0 .334-.272.605-.605.605m-4.535-.756h-.453v.454c0 .247-.206.453-.454.453-.248 0-.453-.206-.453-.453v-.454h-.454c-.247 0-.454-.205-.454-.454 0-.248.207-.453.454-.453h.454v-.454c0-.248.205-.453.453-.453.248 0 .454.206.454.454v.454h.453c.248 0 .453.204.453.452 0 .248-.205.454-.453.454m7.063-2.261c-1.596-2.626-3.998-.799-3.998-.799-.157.116-.44.213-.64.213h-.64c-.194.005-.485-.098-.636-.213 0 0-2.407-1.826-4.003.799-1.596 2.619-.95 5.471-.95 5.471.11.695.485 1.162 1.18 1.108.701-.06 2.22-1.88 2.22-1.88.12-.152.385-.28.58-.28h3.857c.194 0 .46.127.581.28 0 0 1.518 1.82 2.22 1.88.7.053 1.07-.413 1.185-1.108 0 0 .64-2.852-.956-5.472" />
  </svg>
);

IconSportEtennis.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportEtennis.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
