import React from 'react';
import PropTypes from 'prop-types';

export const IconPin = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H20V20H0z" />
      <path fill="#FFF" fillOpacity=".65" d="M6.267 12.172L1 19l6.828-5.267-1.56-1.56zm5.414-8.069l-5.823 3.68L4.35 6.275 2.862 7.763l9.375 9.375 1.488-1.488-1.508-1.508 3.68-5.824-4.216-4.215zM13.047 1l-1.495 1.496 5.952 5.952L19 6.953 13.047 1z" />
    </g>
  </svg>
);

IconPin.propTypes = {
  className: PropTypes.string,
};

IconPin.defaultProps = {
  className: null,
};
