import * as R from 'ramda';
import { BET_STAKE_STATUS, BONUS_TYPES } from 'core/constants';

import { IconWon } from 'components/icons/bet-status/icon-won';
import { IconPending } from 'components/icons/bet-status/icon-pending';
import { IconLost } from 'components/icons/bet-status/icon-lost';

export const getBetStatusIcon = R.cond([
  [R.equals(BET_STAKE_STATUS.NEW), R.always(IconPending)],
  [R.equals(BET_STAKE_STATUS.LOST), R.always(IconLost)],
  [R.T, R.always(IconWon)],
]);

export const findLostStake = R.find(({ order: { statusId } }) => statusId === BET_STAKE_STATUS.LOST);

export const getMultipleBetStatusIcon = (isActive, isWon, stakes, bettingBonusType) => {
  if (bettingBonusType === BONUS_TYPES.EXPRESS_CASHBACK && findLostStake(stakes)) {
    return IconLost;
  }

  if (isActive) {
    return IconPending;
  }

  if (isWon) {
    return IconWon;
  }

  return IconLost;
};

export const isBetWithRepeat = stakes =>
  !!R.find(({ order: { statusId } = {} } = {}) => statusId === BET_STAKE_STATUS.NEW, stakes);
