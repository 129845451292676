import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { fromEvent } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import * as R from 'ramda';
import {
  withLocale, withBetSlip, withBetSlipActions, withFreebets
} from 'core/hocs';
import { mathRound } from 'core/helpers';
import { BET_SLIP_STORE_FIELDS, BET_TYPE } from 'core/constants';

import { RouteLink } from 'components/route-link/route-link';
import { TicketMobile } from 'components/ticket-mobile/ticket-mobile';
import { IconHome } from 'components/icons/icon-home/icon-home';
import { IconStar } from 'components/icons/icon-star/icon-star';
import { IconClock } from 'components/icons/icon-clock/icon-clock';
import { IconGift } from 'components/icons/icon-gift/icon-gift';
import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { IconBetSlip } from 'components/icons/icon-bet-slip/icon-bet-slip';
import { IconCalendar } from 'components/icons/icon-calendar/icon-calendar';
import { GA } from 'helpers/ga';
import colors from '../../customizations/js/color-variables';
import { PAGE_NAMES } from '../../constants';

import './navigation-bar.scss';

class NavigationBarUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    pageName: PropTypes.string.isRequired,
    betSlipStakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    setIsOpened: PropTypes.func.isRequired,
    isBetSlipOpened: PropTypes.bool.isRequired,
    betFactor: PropTypes.number,
    betType: PropTypes.oneOf([
      BET_TYPE.ORDINAR,
      BET_TYPE.EXPRESS,
      BET_TYPE.SYSTEM,
    ]).isRequired,
    freebets: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    betFactor: null,
    freebets: null,
  };

  state = {
    isBackdropShowed: false,
    isPopoverShowed: false,
    isHidden: false,
  };

  timeout;

  scrollSubscription;

  prevScrollTop = 0;

  componentDidMount() {
    const centralPart = document.querySelector('.central-part');

    if (centralPart) {
      this.prevScrollTop = centralPart.scrollTop;

      this.scrollSubscription = fromEvent(centralPart, 'scroll').pipe(
        map(() => {
          const { isHidden } = this.state;
          const deltaY = centralPart.scrollTop - this.prevScrollTop;

          if (!isHidden && deltaY > 0) {
            this.setState({ isHidden: true });
          }

          this.prevScrollTop = centralPart.scrollTop;
        }),
        debounceTime(150)
      ).subscribe(() => {
        const { isHidden } = this.state;

        if (isHidden) {
          this.setState({ isHidden: false });
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { betSlipStakes, isBetSlipOpened, betType } = this.props;
    const { betSlipStakes: prevBetSlipStakes, isBetSlipOpened: prevIsBetSlipOpened } = prevProps;
    const { isBackdropShowed } = this.state;
    const { isBackdropShowed: prevIsBackdropShowed } = prevState;

    if ((prevBetSlipStakes.length && !betSlipStakes.length) || (prevIsBetSlipOpened && !isBetSlipOpened)) {
      this.setIsBackdropShowed(false);
    }

    if (isBackdropShowed !== prevIsBackdropShowed) {
      if (isBackdropShowed) {
        document.body.classList.add('scroll-disabled');
      } else {
        document.body.classList.remove('scroll-disabled');
      }
    }

    if (!isBetSlipOpened && betType !== BET_TYPE.SYSTEM && betSlipStakes.length && prevBetSlipStakes.length
    && prevBetSlipStakes.length !== betSlipStakes.length && !R.find(R.prop('isRepeatBet'), betSlipStakes)) {
      this.showPopover();
    }

    if (!isBackdropShowed && prevBetSlipStakes.length === 1 && betSlipStakes.length === 2
    && !R.find(R.prop('isRepeatBet'), betSlipStakes)) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(this.showPopover, 300);
    }

    if (prevBetSlipStakes.length && !betSlipStakes.length) {
      this.hidePopover();
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('scroll-disabled');

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  onClick = () => {
    const { setIsOpened } = this.props;

    this.setIsBackdropShowed(true);
    setIsOpened(true);
    GA.event({
      category: 'bet-slip',
      label: 'open',
    });
  };

  onBackdropClick = () => {
    const { setIsOpened } = this.props;

    this.setIsBackdropShowed(false);
    setIsOpened(false);
  };

  setIsBackdropShowed = isBackdropShowed => this.setState({ isBackdropShowed });

  showPopover = () => {
    this.setState({ isPopoverShowed: true });

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => this.setState({ isPopoverShowed: false }), 3000);
  };

  hidePopover = () => {
    this.setState({ isPopoverShowed: false });

    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  };

  onHomeClick = () => {
    GA.event({
      category: 'navigation',
      label: 'home-bottom-bar',
    });
  };

  onWatchlistClick = () => {
    GA.event({
      category: 'watchlist',
      label: 'open',
    });
  };

  onCalendarClick = () => {
    GA.event({
      category: 'calendar',
      label: 'click',
    });
  };

  onMyBetsClick = () => {
    GA.event({
      category: 'bet-slip',
      label: 'my-bets-click',
    });
  };

  render() {
    const {
      locale,
      pageName,
      betSlipStakes,
      betType,
      betFactor,
      freebets: { availableFreebetId },
    } = this.props;
    const { isBackdropShowed, isPopoverShowed, isHidden } = this.state;

    return (
      <Fragment>
        {isBackdropShowed && (
          <div
            role="button"
            tabIndex="0"
            onClick={this.onBackdropClick}
            onKeyPress={this.onBackdropClick}
            className="navigation-bar-backdrop position-fixed"
          />
        )}
        <div className={classNames('navigation-bar d-flex align-items-center justify-content-between d-lg-none position-fixed px-3 mx-1 bg-main-5', {
          'is-hidden': isHidden,
        })}
        >
          <RouteLink
            locale={locale}
            to="/"
            className="navigation-bar-item d-flex flex-column align-items-center"
            onClick={this.onHomeClick}
          >
            <IconHome color={pageName === PAGE_NAMES.HOME ? colors.cpExtra1 : colors.cpExtra2} className="mb-0_5" />
            <FormattedTag
              id="nav-bar.home"
              className={classNames('font-weight-bold', {
                'text-extra-2': pageName !== PAGE_NAMES.HOME,
              })}
            />
          </RouteLink>

          <RouteLink
            locale={locale}
            to="/watchlist"
            className="navigation-bar-item d-flex flex-column align-items-center"
            onClick={this.onWatchlistClick}
          >
            <IconStar color={pageName === PAGE_NAMES.WATCHLIST ? colors.cpExtra1 : colors.cpExtra2} className="mb-0_5" />
            <FormattedTag
              id="nav-bar.watchlist"
              className={classNames('font-weight-bold', {
                'text-extra-2': pageName !== PAGE_NAMES.WATCHLIST,
              })}
            />
          </RouteLink>

          <div
            role="button"
            tabIndex="0"
            onClick={this.onClick}
            onKeyPress={this.onClick}
            className="ticket-small rounded-circle position-relative d-flex align-items-center justify-content-center"
          >
            <IconBetSlip />
            <span className="ticket-small-count text-extra-2 font-weight-bold position-absolute">{betSlipStakes.length}</span>
          </div>

          <RouteLink
            locale={locale}
            to="/my-bets"
            className="navigation-bar-item d-flex flex-column align-items-center position-relative"
            onClick={this.onMyBetsClick}
          >
            <IconClock color={pageName === PAGE_NAMES.MY_BETS ? colors.cpExtra1 : colors.cpExtra2} className="mb-0_5" />

            {availableFreebetId && (
              <div
                className="freebet-icon-gift d-flex align-items-center justify-content-center bg-main-5 position-absolute"
              >
                <IconGift />
              </div>
            )}

            <FormattedTag
              id="nav-bar.my-bets"
              className={classNames('font-weight-bold', {
                'text-extra-2': pageName !== PAGE_NAMES.MY_BETS,
              })}
            />
          </RouteLink>

          <RouteLink
            locale={locale}
            to="/calendar"
            className="navigation-bar-item d-flex flex-column align-items-center"
            onClick={this.onCalendarClick}
          >
            <IconCalendar color={pageName === PAGE_NAMES.CALENDAR ? colors.cpExtra1 : colors.cpExtra2} className="mb-0_5" />
            <FormattedTag
              id="nav-bar.calendar"
              className={classNames('font-weight-bold', {
                'text-extra-2': pageName !== PAGE_NAMES.CALENDAR,
              })}
            />
          </RouteLink>

          <div className={classNames('navigation-bar-popover bg-success px-1 py-0_5 text-small text-extra-2 font-weight-bold position-absolute rounded', {
            'is-showed': isPopoverShowed,
          })}
          >
            <FormattedMessage id={`bet-slip.type.${betType === BET_TYPE.ORDINAR ? 'ordinar' : 'express'}`} />
            {' '}
            {!!betFactor && <span>{mathRound(betFactor)}</span>}

            <div className="arrow position-absolute" />
          </div>
        </div>

        <TicketMobile isBackdropShowed={isBackdropShowed} />
      </Fragment>
    );
  }
}

export const NavigationBar = withLocale(
  withBetSlipActions(withFreebets(withBetSlip(NavigationBarUI, [
    BET_SLIP_STORE_FIELDS.STAKES,
    BET_SLIP_STORE_FIELDS.IS_OPENED,
    BET_SLIP_STORE_FIELDS.BET_TYPE,
    BET_SLIP_STORE_FIELDS.BET_FACTOR,
  ])))
);
