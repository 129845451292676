import React from 'react';
import PropTypes from 'prop-types';

export const IconRg = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.40726 3L10.9172 0.042773C10.6121 0.0144687 10.3031 0 9.99088 0C4.49027 0 0 4.49027 0 10.0091C0 12.7211 1.09149 15.1875 2.85742 16.9912V14.4221L7.59981 10.2632L7.64239 8.79075L9.2502 12C9.92593 11.9479 10.5385 11.9312 11.1432 11.9499C11.3356 13.359 11.9837 15.0278 13.6035 15.0278C13.7874 15.0278 13.9502 15.0068 14.256 14.7829V12.8291C14.1762 12.7627 14.1137 12.6939 14.0543 12.6287C13.932 12.4943 13.8232 12.3746 13.6035 12.3201V11.9336H17.0067V12.2793C16.681 12.4828 16.5387 12.5848 16.5387 13.3579V15.1701C13.7743 16.4167 11.5667 16.198 10.2708 14.9211L5.60469 18.9815C6.93017 19.6335 8.41929 20 9.99088 20C15.4915 20 19.9811 15.5097 19.9811 10.0091C19.9811 6.88641 18.5437 4.093 16.2991 2.25601L10.3406 7.3472L10.2957 7.26149C10.8621 6.9794 11.317 6.47329 11.317 5.40898C11.317 4.99478 11.1671 4.48059 10.8014 4.03377L14.3101 0.986314C14.1062 0.887837 13.8985 0.7961 13.6872 0.711396L10.3396 3.59962C9.85117 3.24519 9.155 3 8.19759 3H7.40726ZM9.91723 8.5311L11.1135 10.6283C11.3277 9.3785 12.4568 8.00431 13.9093 8.00431C15.252 8.00431 15.5094 8.49156 15.5937 8.65112C15.6053 8.67322 15.6137 8.68903 15.6211 8.69639L15.9065 9.08293H16.192L16.2737 7.82125C13.8921 6.48299 11.2685 6.82278 9.91723 8.5311ZM7.18507 7.87794L7.18407 7.87594V7.87819L7.18507 7.87794ZM7.39844 7.49068C8.29528 7.49068 9.27032 7.29804 9.27032 5.94891C9.27032 4.41865 8.21486 4.44561 7.57106 4.46206C7.5093 4.46364 7.45133 4.46512 7.39844 4.46512V7.49068Z" fill="white" fillOpacity="0.4" />
    <path d="M3.05141 17.184L9.0158 11.8687C9.02192 11.957 9.02983 12.0465 9.03959 12.137C9.07018 12.409 9.11656 12.6688 9.17813 12.9156L5.46167 16.1517V18.9097C4.57379 18.4552 3.76201 17.8716 3.05141 17.184Z" fill="white" fillOpacity="0.4" />
  </svg>
);

IconRg.propTypes = {
  className: PropTypes.string,
};

IconRg.defaultProps = {
  className: '',
};
