import React from 'react';
import PropTypes from 'prop-types';

export const IconSuccess = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path fill="#FFF" fillRule="evenodd" d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zM8.82 14.848L5.13 11.16l1.41-1.414 2.242 2.234 5.754-6.082 1.453 1.375-7.168 7.575z" />
  </svg>
);

IconSuccess.propTypes = {
  className: PropTypes.string,
};

IconSuccess.defaultProps = {
  className: null,
};
