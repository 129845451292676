import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import { BET_SLIP_STATE, BET_SLIP_ERRORS } from 'core/constants';

import { prepareCurrency } from 'helpers/currency';

import './bet-amount-input.scss';

export class BetAmountInput extends Component {
  static propTypes = {
    currency: PropTypes.string.isRequired,
    setIsKeyboardShowed: PropTypes.func.isRequired,
    keyboardValue: PropTypes.string.isRequired,
    isKeyboardShowed: PropTypes.bool.isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    error: PropTypes.shape(),
  };

  static defaultProps = {
    error: null,
  };

  componentDidMount() {
    document.addEventListener('click', this.checkOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.checkOutsideClick);
  }

  onClick = () => {
    const { setIsKeyboardShowed } = this.props;

    setIsKeyboardShowed(true);
  };

  checkOutsideClick = (e) => {
    const { setIsKeyboardShowed } = this.props;
    const path = e.path || (e.composedPath && e.composedPath());

    const isClickOnInputOrKeyboard = R.compose(
      R.contains(true),
      R.map(DOMTokenList => DOMTokenList.contains('bet-amount-input-mobile') || DOMTokenList.contains('bet-slip-keyboard')),
      R.reject(DOMTokenList => !DOMTokenList),
      R.pluck('classList')
    )(path);

    if (!isClickOnInputOrKeyboard) {
      setIsKeyboardShowed(false);
    }
  };

  render() {
    const {
      currency,
      keyboardValue,
      isKeyboardShowed,
      betSlipState,
      error,
    } = this.props;
    const isErrorState = betSlipState === BET_SLIP_STATE.ERROR;
    const isOutOfBonusBalance = isErrorState && error.errorId === BET_SLIP_ERRORS.BET_AMOUNT_OUT_OF_BONUS_BALANCE;

    return (
      <div
        role="button"
        tabIndex="0"
        onClick={this.onClick}
        onKeyPress={this.onClick}
        className="bet-amount-input-mobile position-relative"
      >
        <div className={classNames('pl-6 text-right font-weight-bold w-100 form-control overflow-hidden', {
          'text-extra-3': !keyboardValue,
          'is-focused': isKeyboardShowed,
          'text-warning': isErrorState && (error.limit || error.amount || isOutOfBonusBalance),
        })}
        >
          {keyboardValue || 0}
        </div>
        <div className="bet-amount-input-mobile-currency position-absolute caption text-extra-2">
          {prepareCurrency(currency)}
        </div>
        {isKeyboardShowed && <div className="bet-amount-input-mobile-cursor position-absolute" />}
      </div>
    );
  }
}
