import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { RouteLink } from 'components/route-link/route-link';
import { SportTypeIcon } from 'components/sport-type-icon/sport-type-icon';
import { getTopLeagueIcon } from 'helpers/top-leagues';
import { GA } from 'helpers/ga';
import { FavoritesToggleButton } from '../favorites-toggle-button/favorites-toggle-button';
import { MODAL_IDS } from '../../../../constants';

import './favorite-league-item.scss';

export class FavoriteLeagueItem extends PureComponent {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    leagueId: PropTypes.number.isRequired,
    leagueName: PropTypes.string.isRequired,
    sportId: PropTypes.number.isRequired,
    eventsCount: PropTypes.number.isRequired,
    inplayEventsCount: PropTypes.number.isRequired,
    isActive: PropTypes.bool.isRequired,
    onFavoriteLeagueClick: PropTypes.func.isRequired,
    isAllSportsModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    sendLeagueClickFeedback: PropTypes.func.isRequired,
  };

  onClick = () => {
    const {
      leagueId,
      onFavoriteLeagueClick,
      isAllSportsModal,
      closeModal,
      sportId,
      sendLeagueClickFeedback,
    } = this.props;

    onFavoriteLeagueClick(leagueId, sportId);

    if (isAllSportsModal) {
      closeModal(MODAL_IDS.SPORTS);
    }

    GA.event({
      category: 'favorites',
      label: 'click',
    });
    sendLeagueClickFeedback(leagueId);
  };

  render() {
    const {
      locale,
      leagueId,
      leagueName,
      sportId,
      eventsCount,
      inplayEventsCount,
      isActive,
    } = this.props;
    const TopLeagueIcon = getTopLeagueIcon(leagueId, false);
    const IconComponent = TopLeagueIcon || SportTypeIcon;

    return (
      <div className={classNames('favorite-league-item position-relative', {
        'font-weight-bold bg-main-4 active': isActive,
        'with-live-status': inplayEventsCount > 0,
      })}
      >
        <RouteLink
          to={`/league/${sportId}/${leagueId}`}
          locale={locale}
          onClick={this.onClick}
          className="d-flex align-items-center h-100"
        >
          <IconComponent sportId={sportId} isActive={isActive} className={classNames('ml-2 ml-sm-2_5 mr-2_5', { 'icon-top-league': TopLeagueIcon })} />
          <div className="my-1 py-0_25 d-flex align-items-center overflow-hidden pr-8 pr-sm-8_5 pr-lg-5">
            <span title={leagueName} className="navigation-item-name">
              {leagueName}
            </span>
            <span className="text-extra-3 pl-1 font-weight-normal">{eventsCount}</span>
          </div>
        </RouteLink>

        <FavoritesToggleButton
          sportId={sportId}
          leagueId={leagueId}
          leagueName={leagueName}
          className="position-absolute favorites-button"
        />
      </div>
    );
  }
}
