import React from 'react';
import PropTypes from 'prop-types';

export const IconCloseRounded = ({ className }) => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM3.95958 5.37379L6.58582 8.00004L3.95958 10.6263L5.37379 12.0405L8.00004 9.41425L10.6262 12.0405L12.0405 10.6262L9.41425 8.00004L12.0405 5.37383L10.6262 3.95962L8.00004 6.58582L5.37379 3.95958L3.95958 5.37379Z" fill="white" fillOpacity="0.3" />
  </svg>
);

IconCloseRounded.propTypes = {
  className: PropTypes.string,
};

IconCloseRounded.defaultProps = {
  className: null,
};
