import React from 'react';

import './games-preloader.scss';

export const GamesPreloader = () => (
  <div className="games-preloader preloader">
    <div className="games-preloader-header d-flex align-items-center pl-2_5 position-relative">
      <div className="bg-main-4" />
    </div>

    <div className="games-preloader-item position-relative">
      <div className="games-preloader-subitem bg-main-4 position-absolute" />
      <div className="games-preloader-subitem bg-main-4 position-absolute" />
      <div className="games-preloader-subitem bg-main-4 position-absolute" />
    </div>

    <div className="games-preloader-item position-relative">
      <div className="games-preloader-subitem bg-main-4 position-absolute" />
      <div className="games-preloader-subitem bg-main-4 position-absolute" />
      <div className="games-preloader-subitem bg-main-4 position-absolute" />
    </div>

    <div className="games-preloader-item position-relative">
      <div className="games-preloader-subitem bg-main-4 position-absolute" />
      <div className="games-preloader-subitem bg-main-4 position-absolute" />
      <div className="games-preloader-subitem bg-main-4 position-absolute" />
    </div>

    <div className="games-preloader-item position-relative">
      <div className="games-preloader-subitem bg-main-4 position-absolute" />
      <div className="games-preloader-subitem bg-main-4 position-absolute" />
      <div className="games-preloader-subitem bg-main-4 position-absolute" />
    </div>
  </div>
);
