import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportBeachVolley = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M6.61 3.89c-.54.67-1.27 1.86-1.43 3.45 2.39-.23 10.53-.81 14.78 1.76-.11-1.19-.42-2.31-.91-3.34-3.5-3.17-10.97-2.12-12.44-1.87m-1.4 4.96c.29 1.99 1.49 3.38 2.08 3.95 1.63-.29 8.1-1.25 11.72 1.52.51-1.04.83-2.18.95-3.38C16.61 7.93 7.45 8.63 5.21 8.85m5.36 11.14c3.19-.19 5.99-1.87 7.68-4.35-1.07-.9-2.53-1.36-4.04-1.57-.34 1.02-1.45 4-3.64 5.92m-2.9-5.73c-.31 1.16-.95 2.62-2.28 3.78-.19.17-.4.33-.62.48 1 .62 2.13 1.07 3.33 1.3 2.64-1.15 4.07-4.51 4.57-5.9-2.14-.09-4.13.19-5 .34M6.58 2.37c1.61-.26 6.53-.87 10.47.54C15.24 1.11 12.75 0 10 0 8.36 0 6.81.39 5.45 1.1c.5.41.89.91 1.13 1.27m-.35 11.49c-.25.95-.77 2.13-1.83 3.05-.28.25-.6.47-.93.66C1.34 15.74 0 13.02 0 10c0-3.28 1.58-6.2 4.03-8.02.5.2.97.75 1.26 1.16-.69.92-1.56 2.47-1.64 4.52-.13 3.15 1.72 5.35 2.58 6.2" />
  </svg>
);

IconSportBeachVolley.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportBeachVolley.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
