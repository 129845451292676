import React from 'react';
import PropTypes from 'prop-types';

export const IconAllSports = ({ className, color }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M18.3401 4.51L17.8301 7.45L19.9801 9.54C19.8901 7.68 19.3101 5.96 18.3401 4.51ZM14.4901 9.33L13.2301 13.191L14.6801 15.18L18.8801 14.58C19.3701 13.64 19.7101 12.611 19.8701 11.52L16.8301 8.571L14.4901 9.33ZM13.5201 19.35C15.2101 18.72 16.6901 17.63 17.8001 16.24L14.8401 16.67L13.5201 19.35ZM10.7401 5.89L13.9401 7.93L16.3601 7.14L17.0801 2.96C16.3301 2.2 15.4601 1.56 14.4901 1.071L10.7401 3.05V5.89ZM7.94012 14.1L6.51012 16.06L8.39012 19.87C8.91012 19.96 9.44012 20 9.99012 20C10.5401 20 11.0701 19.96 11.5901 19.87L13.4701 16.06L12.0401 14.1H7.94012ZM9.99012 7.191L6.99012 9.1L8.13012 12.6H11.8501L12.9901 9.1L9.99012 7.191ZM12.6301 0.361C11.7901 0.12 10.9001 0 9.99012 0C9.08012 0 8.19012 0.12 7.35012 0.361L9.99012 1.75L12.6301 0.361ZM6.04012 7.93L9.24012 5.89V3.05L5.49012 1.071C4.52012 1.56 3.65012 2.2 2.89012 2.96L3.61012 7.14L6.04012 7.93ZM6.75012 13.191L5.49012 9.33L3.15012 8.571L0.110122 11.52C0.270122 12.611 0.610122 13.64 1.10012 14.58L5.30012 15.18L6.75012 13.191ZM2.18012 16.24C3.29012 17.63 4.77012 18.72 6.46012 19.35L5.14012 16.67L2.18012 16.24ZM2.15012 7.45L0.00012207 9.54C0.0901221 7.68 0.670122 5.96 1.64012 4.51L2.15012 7.45Z" fill={color} />
  </svg>
);

IconAllSports.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconAllSports.defaultProps = {
  className: null,
  color: '#FFFFFF',
};
