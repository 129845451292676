import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import store from 'store';
import { BET_TYPE, BET_SLIP_STATE, AI_OUTCOME_SECTION } from 'core/constants';
import { mathRound } from 'core/helpers';
import { handicapStakeTypesIds, restOfTheMatchStakeTypeIds } from 'core/mapping-stake-types';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { RouteLink } from 'components/route-link/route-link';
import { IconWarning } from 'components/icons/icon-warning/icon-warning';
import { prepareStakeTypeName } from 'helpers/stake';
import { GA } from 'helpers/ga';

import './stake-item.scss';

const STORAGE_DELETE_SWIPE = 'isDeleteSwipeShowed';

export class StakeItem extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    stake: PropTypes.shape().isRequired,
    toggleStake: PropTypes.func.isRequired,
    betType: PropTypes.oneOf([
      BET_TYPE.ORDINAR,
      BET_TYPE.EXPRESS,
      BET_TYPE.SYSTEM,
    ]).isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    isTouchXDisabled: PropTypes.bool.isRequired,
    setIsOpened: PropTypes.func.isRequired,
    stakesAmount: PropTypes.number.isRequired,
    stakeIdx: PropTypes.number.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  prevClientX = null;

  timeoutId;

  state = {
    isDeleteActive: false,
    isDeleteSwipeShowed: false,
  };

  componentDidMount() {
    const { stakeIdx } = this.props;

    if (stakeIdx === 0) {
      const isDeleteSwipeShowed = store.get(STORAGE_DELETE_SWIPE) || false;

      if (!isDeleteSwipeShowed) {
        store.set(STORAGE_DELETE_SWIPE, true);
        this.setState({ isDeleteSwipeShowed: true });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { betSlipState } = this.props;
    const { betSlipState: prevBetSlipState } = prevProps;
    const { isDeleteActive } = this.state;
    const { isDeleteActive: prevIsDeleteActive } = prevState;

    if (isDeleteActive && betSlipState !== prevBetSlipState) {
      this.setIsDeleteActive(false);
    }

    if (prevIsDeleteActive !== isDeleteActive) {
      if (isDeleteActive) {
        document.addEventListener('touchstart', this.checkOutsideTouch);
      } else {
        document.removeEventListener('touchstart', this.checkOutsideTouch);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('touchstart', this.checkOutsideTouch);
    this.clearTimeoutId();
  }

  setIsDeleteActive = isDeleteActive => this.setState({ isDeleteActive });

  onOnTouchStart = (e) => {
    if (e.targetTouches.length === 1) {
      this.prevClientX = e.targetTouches[0].clientX;
    }
  };

  onOnTouchMove = (e) => {
    const { isTouchXDisabled } = this.props;
    const { isDeleteActive } = this.state;

    if (isTouchXDisabled) {
      return;
    }

    if (e.targetTouches.length === 1) {
      const deltaX = e.targetTouches[0].clientX - this.prevClientX;

      if (!isDeleteActive && deltaX < -20) {
        this.setIsDeleteActive(true);
      } else if (isDeleteActive && deltaX > 20) {
        this.setIsDeleteActive(false);
      }
    }
  };

  checkOutsideTouch = (e) => {
    const { stake: { stakeId } } = this.props;
    const { isDeleteActive } = this.state;

    if (isDeleteActive) {
      const path = e.path || (e.composedPath && e.composedPath());
      const { touchedStakeId } = R.compose(
        R.find(R.prop('touchedStakeId')),
        R.pluck('dataset')
      )(path) || {};

      if (Number(touchedStakeId) !== stakeId) {
        this.setIsDeleteActive(false);
      }
    }
  };

  onRemoveClick = () => {
    const {
      toggleStake,
      stake,
      setIsOpened,
      stakesAmount,
    } = this.props;

    if (stakesAmount === 1) {
      setIsOpened(false);
      this.clearTimeoutId();
      this.timeoutId = setTimeout(() => toggleStake(stake), 300); // 300ms - close animation
    } else {
      toggleStake(stake);
    }

    GA.event({
      category: 'bet-slip',
      label: 'delete-outcome',
    });
  };

  clearTimeoutId = () => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  };

  onEventClick = () => {
    const { setIsOpened, sendEventClickFeedback, stake } = this.props;
    setIsOpened(false);
    GA.event({
      category: 'go-to-event-page',
      label: 'bet-slip-event-click',
    });
    sendEventClickFeedback(stake.eventNumber, stake.isEventInLive);
  };

  render() {
    const {
      locale,
      betType,
      stake: {
        stakeId,
        stakeTypeId,
        stakeTypeName,
        stakeCode,
        stakeArgument,
        stakeName,
        betFactor,
        teamA,
        teamB,
        isMultipleRestricted,
        isBetFactorDecreased,
        isBetFactorIncreased,
        isExpressDenied,
        isSuspended,
        isOutright,
        periodName,
        eventNumber,
        isEventInLive,
      },
    } = this.props;
    const { isDeleteActive, isDeleteSwipeShowed } = this.state;
    const isOrdinar = betType === BET_TYPE.ORDINAR;
    const teams = isOutright ? teamA : `${teamA} - ${teamB}`;
    const formattedStakeTypeName = prepareStakeTypeName(periodName, stakeTypeName);
    let formattedStakeName = `${stakeName}${stakeArgument === null ? '' : ` ${stakeArgument}`}`;

    if (handicapStakeTypesIds.includes(stakeTypeId)) {
      formattedStakeName = `${stakeCode === 1 ? teamA : teamB} ${stakeArgument > 0 ? `+${stakeArgument}` : stakeArgument}`;
    } else if (restOfTheMatchStakeTypeIds.includes(stakeTypeId)) {
      formattedStakeName = `${stakeName} (${stakeArgument.toFixed(1).replace('.', ':')})`;
    }

    return (
      <div
        onTouchStart={this.onOnTouchStart}
        onTouchMove={this.onOnTouchMove}
        className={classNames('stake-item-mobile-wrapper d-flex', {
          'is-delete-active': isDeleteActive,
          'is-delete-swipe-showed': isDeleteSwipeShowed,
        })}
        data-touched-stake-id={stakeId}
      >
        <div className={classNames('stake-item-mobile p-1_5 bg-main-4 position-relative flex-shrink-0', {
          'is-suspended': !isOrdinar && isSuspended,
        })}
        >
          <div className="d-flex justify-content-between font-weight-bold mb-0_25">
            <div className="d-flex align-items-center mr-1 overflow-hidden">
              <span title={formattedStakeName} className="text-truncate">
                {formattedStakeName}
              </span>
              {(isMultipleRestricted || isExpressDenied) && <IconWarning className="ml-0_5 flex-shrink-0" />}
            </div>

            {!!betFactor && (
              <span className={classNames('stake-item-mobile-bet-factor position-relative', {
                'is-decreased': isBetFactorDecreased && !isSuspended,
                'is-increased': isBetFactorIncreased && !isSuspended,
              })}
              >
                {mathRound(betFactor)}
              </span>
            )}
          </div>

          <div title={formattedStakeTypeName} className="text-extra-2 caption text-truncate mb-0_25">{formattedStakeTypeName}</div>
          <RouteLink
            locale={locale}
            to={`/event/${eventNumber}?isLive=${isEventInLive}&sectionBeforeEventPage=${AI_OUTCOME_SECTION.BET_SLIP}`}
            className="stake-item-event-link caption"
            onClick={this.onEventClick}
          >
            {teams}
          </RouteLink>

          {!isOrdinar && isSuspended && (
            <FormattedTag
              id="bet-slip.suspended"
              className="stake-item-mobile-suspended-label h6 d-flex align-items-center position-absolute bg-info"
            />
          )}
        </div>

        <div
          role="button"
          tabIndex="0"
          onClick={this.onRemoveClick}
          onKeyPress={this.onRemoveClick}
          className="stake-item-mobile-delete-button bg-danger d-flex align-items-center justify-content-center flex-shrink-0"
        >
          <FormattedTag id="general.delete" className="text-small font-weight-bold" />
        </div>
      </div>
    );
  }
}
