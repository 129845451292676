import * as R from 'ramda';

export const prepareFavoriteSports = ({
  isUserLoggedIn,
  favoriteSports,
  sports,
  isFavoritesInProgress,
}) => {
  let result = null;

  if (isUserLoggedIn) {
    if (!R.isNil(favoriteSports)) {
      result = favoriteSports;
    }
  } else if (!isFavoritesInProgress) {
    result = favoriteSports;
  }

  if (!result) {
    return result;
  }

  return R.map((sport) => {
    const foundSport = R.find(R.propEq('sportId', sport.sportId), sports);

    if (foundSport) {
      return {
        ...sport,
        eventsCount: foundSport.eventsCount,
        inplayEventsCount: foundSport.inplayEventsCount,
      };
    }

    return {
      ...sport,
      eventsCount: 0,
      inplayEventsCount: 0,
    };
  }, result);
};

export const prepareFavoriteLeagues = ({
  isUserLoggedIn,
  favoriteLeagues,
  sports,
  isFavoritesInProgress,
}) => {
  let leagues = null;

  if (isUserLoggedIn) {
    if (!R.isNil(favoriteLeagues)) {
      leagues = favoriteLeagues;
    }
  } else if (!isFavoritesInProgress) {
    leagues = favoriteLeagues;
  }

  if (!leagues) {
    return leagues;
  }

  return R.map((league) => {
    const foundSport = R.find(R.propEq('sportId', league.sportId), sports);

    if (foundSport) {
      const foundLeague = R.compose(
        R.find(R.propEq('leagueId', league.leagueId)),
        R.flatten,
        R.pluck('leagues')
      )(foundSport.countries);

      if (foundLeague) {
        return foundLeague;
      }
    }

    return {
      ...league,
      eventsCount: 0,
      inplayEventsCount: 0,
    };
  }, leagues);
};
