import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withCoreComponent, withLocale } from 'core/hocs';
import { ActiveBets as ActiveBetsCore } from 'core/components';
import { BET_TYPE } from 'core/constants';

import { Spinner } from 'components/spinner/spinner';
import { EmptyState } from 'components/ticket/empty-state/empty-state';
import { GA } from 'helpers/ga';
import { BetItemOrdinar } from './bet-item/bet-item-ordinar';
import { BetItemMultiple } from './bet-item/bet-item-multiple';

class ActiveBetsUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    isUserLoggedIn: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape()),
    isInProgress: PropTypes.bool.isRequired,
    currency: PropTypes.string.isRequired,
    makeCashout: PropTypes.func.isRequired,
    repeatBet: PropTypes.func.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    items: null,
  };

  state = {
    cashoutBetId: null,
    cashoutX: null,
    cashoutY: null,
  };

  componentWillUnmount() {
    const parrentWithScroll = document.querySelector('.ticket .tab-pane.active');

    if (parrentWithScroll) {
      parrentWithScroll.removeEventListener('scroll', this.onParrentScroll);
    }
  }

  onCashoutClick = (e, cashoutBetId) => {
    e.stopPropagation();
    const { right, bottom } = e.currentTarget.getBoundingClientRect();
    const { innerWidth, innerHeight } = window;
    const offsetX = right;
    const offsetY = bottom + 20;

    this.setState({
      cashoutBetId,
      cashoutX: offsetX > innerWidth ? innerWidth : offsetX,
      cashoutY: offsetY > innerHeight ? innerHeight : offsetY,
    });
    GA.event({
      category: 'my-bets',
      label: 'cashout-click',
    });
    const parrentWithScroll = document.querySelector('.ticket .tab-pane.active');

    if (parrentWithScroll) {
      parrentWithScroll.addEventListener('scroll', this.onParrentScroll);
    }
  };

  closeCashout = () => {
    const parrentWithScroll = document.querySelector('.ticket .tab-pane.active');
    this.setState({ cashoutBetId: null, cashoutX: null, cashoutY: null });

    if (parrentWithScroll) {
      parrentWithScroll.removeEventListener('scroll', this.onParrentScroll);
    }
  };

  onParrentScroll = () => {
    this.closeCashout();
  };

  render() {
    const {
      locale,
      isUserLoggedIn,
      currency,
      items,
      isInProgress,
      makeCashout,
      repeatBet,
      sendEventClickFeedback,
    } = this.props;
    const { cashoutBetId, cashoutX, cashoutY } = this.state;

    if (isInProgress) {
      return (
        <div className="my-bets-tab-loader d-flex align-items-center justify-content-center">
          <Spinner />
        </div>
      );
    }

    if (!isUserLoggedIn || (!isInProgress && items && !items.length)) {
      return <EmptyState />;
    }

    return (
      <div>
        {items && items.map(bet => bet.betType === BET_TYPE.ORDINAR
          ? (
            <BetItemOrdinar
              key={bet.orderId}
              {...bet}
              locale={locale}
              currency={currency}
              makeCashout={makeCashout}
              repeatBet={repeatBet}
              onCashoutClick={this.onCashoutClick}
              closeCashout={this.closeCashout}
              isCashoutOpen={cashoutBetId === bet.orderId}
              cashoutX={cashoutX}
              cashoutY={cashoutY}
              sendEventClickFeedback={sendEventClickFeedback}
            />
          )
          : (
            <BetItemMultiple
              key={bet.orderId}
              {...bet}
              locale={locale}
              currency={currency}
              makeCashout={makeCashout}
              repeatBet={repeatBet}
              onCashoutClick={this.onCashoutClick}
              closeCashout={this.closeCashout}
              isCashoutOpen={cashoutBetId === bet.orderId}
              cashoutX={cashoutX}
              cashoutY={cashoutY}
              sendEventClickFeedback={sendEventClickFeedback}
            />
          ))}
      </div>
    );
  }
}

export const ActiveBets = withLocale(withCoreComponent(ActiveBetsCore, ActiveBetsUI));
