import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportSkiJumping = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M17.961 8.302L6.402 14.744c.398-.753.962-1.713 1.724-2.758.368-.066.714-.263.958-.583.422-.552 1.78-2.153 2.991-2.928C13.66 7.461 14.646 7.003 14.653 7c.777-.354 1.12-1.27.764-2.047-.354-.775-1.27-1.118-2.047-.763-.024.011-.344.158-.898.46l-6.72 1.12c-.589.096-.986.653-.889 1.24.09.527.545.901 1.064.901.058 0 .118-.005.178-.014l2.317-.386c-1.017 1.003-1.747 1.956-1.795 2.02-.279.366-.368.818-.284 1.238C4.44 13.396 3.66 15.531 3.62 15.64c-.08.224-.08.455-.019.666L.29 18.152c-.272.151-.37.494-.219.765l.084.152c.152.272.495.37.767.217l4.05-2.256c.13-.043.251-.11.357-.2L17.61 9.986c.543-.303.74-.99.436-1.533l-.085-.15zm2.04-5.248c0 1.134-.92 2.053-2.055 2.053-1.134 0-2.053-.919-2.053-2.053 0-1.135.92-2.054 2.053-2.054C19.08 1 20 1.92 20 3.054z" />
  </svg>
);

IconSportSkiJumping.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportSkiJumping.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
