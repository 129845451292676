import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import { withLocale } from 'core/hocs';

import { RouteLink } from 'components/route-link/route-link';
import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { IconAllSports } from 'components/icons/icon-all-sports/icon-all-sports';
import { IconHome } from 'components/icons/icon-home/icon-home';
import { IconInplay } from 'components/icons/icon-inplay/icon-inplay';
import { IconBetsFeed } from 'components/icons/icon-bets-feed/icon-bets-feed';
import { IconSearchBold } from 'components/icons/icon-search-bold/icon-search-bold';
import { GA } from 'helpers/ga';
import { PAGE_NAMES } from '../../../constants';
import colors from '../../../customizations/js/color-variables';

import './sports-menu.scss';

class SportsMenuUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    pageName: PropTypes.string.isRequired,
    isHidden: PropTypes.bool.isRequired,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !R.equals(this.props, nextProps) || !R.equals(this.state, nextState);
  }

  onHomeClick = () => {
    GA.event({
      category: 'navigation',
      label: 'home-click',
    });
  };

  onInplayClick = () => {
    GA.event({
      category: 'navigation',
      label: 'in-play-click',
    });
  };

  onSportsClick = () => {
    GA.event({
      category: 'navigation',
      label: 'menu-sports-click',
    });
  };

  onBetsFeedClick = () => {
    GA.event({
      category: 'navigation',
      label: 'bets-feed-click',
    });
  };

  onSearchClick = () => {
    GA.event({
      category: 'search',
      label: 'start',
    });
  };

  render() {
    const {
      locale,
      pageName,
      isHidden,
    } = this.props;

    return (
      <div className={classNames('sports-menu d-flex justify-content-between align-items-center', { 'is-hidden': isHidden })}>
        <RouteLink
          locale={locale}
          to="/"
          className="d-flex align-items-center mr-2_5 pl-2 pl-sm-3 align-self-stretch"
          onClick={this.onHomeClick}
        >
          <IconHome color={pageName === PAGE_NAMES.HOME ? colors.cpSecondary1 : colors.cpExtra2} className="flex-shrink-0" />
          <FormattedTag
            id="left-sidebar.home"
            className={classNames('caption ml-1 pl-0_25 text-nowrap', {
              'text-extra-2': pageName !== PAGE_NAMES.HOME,
            })}
          />
        </RouteLink>

        <RouteLink
          locale={locale}
          to="/live"
          className="d-flex align-items-center mr-2_5 align-self-stretch"
          onClick={this.onInplayClick}
        >
          <IconInplay color={pageName === PAGE_NAMES.LIVE ? colors.cpSecondary1 : colors.cpExtra2} className="flex-shrink-0" />
          <FormattedTag
            id="left-sidebar.in-play"
            className={classNames('caption ml-1 pl-0_25 text-nowrap', {
              'text-extra-2': pageName !== PAGE_NAMES.LIVE,
            })}
          />
        </RouteLink>

        <RouteLink
          locale={locale}
          to="/sports"
          className="d-flex align-items-center mr-2_5 align-self-stretch"
          onClick={this.onSportsClick}
        >
          <IconAllSports color={pageName === PAGE_NAMES.SPORTS ? colors.cpSecondary1 : colors.cpExtra2} className="flex-shrink-0" />
          <FormattedTag
            id="left-sidebar.sports"
            className={classNames('caption ml-1 pl-0_25 text-nowrap', {
              'text-extra-2': pageName !== PAGE_NAMES.SPORTS,
            })}
          />
        </RouteLink>

        <div className="d-flex align-self-stretch pr-6 pr-sm-2_5">
          <RouteLink
            locale={locale}
            to="/bets-feed"
            className="d-flex align-items-center position-relative"
            onClick={this.onBetsFeedClick}
          >
            <IconBetsFeed color={pageName === PAGE_NAMES.BETS_FEED ? colors.cpSecondary1 : colors.cpExtra2} className="flex-shrink-0" />
            <FormattedTag
              id="left-sidebar.bets-feed"
              className={classNames('caption ml-1 pl-0_25 text-nowrap', {
                'text-extra-2': pageName !== PAGE_NAMES.BETS_FEED,
              })}
            />
            <FormattedTag id="general.new" className="sports-menu-new font-weight-bold text-uppercase position-absolute" />
          </RouteLink>
        </div>

        <RouteLink
          locale={locale}
          to="/search"
          className="sports-menu-search d-flex align-items-center pr-sm-3 align-self-stretch"
          onClick={this.onSearchClick}
        >
          <IconSearchBold color={pageName === PAGE_NAMES.SEARCH ? colors.cpSecondary1 : colors.cpExtra2} className="sports-menu-search-icon flex-shrink-0" />
          <FormattedTag
            id="left-sidebar.search"
            className={classNames('d-none d-sm-block caption ml-1 pl-0_25 text-nowrap', {
              'text-extra-2': pageName !== PAGE_NAMES.SEARCH,
            })}
          />
        </RouteLink>
      </div>
    );
  }
}

export const SportsMenu = withLocale(SportsMenuUI);
