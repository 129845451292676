import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportSpecials = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M10.13 7.5c-.1 0-.2 0-.3.02L12.567 0l4.7 1.71L14.58 9.1c-1.21-1-2.76-1.6-4.45-1.6zM5.68 9.1L3 1.71 7.7 0l1.63 4.49-1.2 3.3c-.91.27-1.74.72-2.45 1.31zm6.38 8.18l-1.93-1.01-1.93 1.01.37-2.15L7 13.61l2.16-.32.97-1.96.97 1.96 2.16.32-1.57 1.52.37 2.15zM10.13 9c-3.04 0-5.5 2.46-5.5 5.5s2.46 5.5 5.5 5.5 5.5-2.46 5.5-5.5S13.17 9 10.13 9z" />
  </svg>
);

IconSportSpecials.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportSpecials.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
