import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'reactstrap/lib/Button';
import { prepareStakesForRepeat, mathRound } from 'core/helpers';
import { BET_TYPE, BONUS_TYPES, AI_OUTCOME_SECTION } from 'core/constants';
import { handicapStakeTypesIds, restOfTheMatchStakeTypeIds } from 'core/mapping-stake-types';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { RouteLink } from 'components/route-link/route-link';
import { IconSuccess } from 'components/icons/icon-success/icon-success';
import { IconBonus } from 'components/icons/icon-bonus/icon-bonus';
import { prepareStakeTypeName } from 'helpers/stake';
import { formatAmountWithCurrencySymbol, prepareCurrency } from 'helpers/currency';
import { GA } from 'helpers/ga';

import './bet-slip-success.scss';

export class BetSlipSuccess extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    betType: PropTypes.oneOf([
      BET_TYPE.ORDINAR,
      BET_TYPE.EXPRESS,
      BET_TYPE.SYSTEM,
    ]).isRequired,
    betAmount: PropTypes.number.isRequired,
    betFactor: PropTypes.number.isRequired,
    systemIndex: PropTypes.number,
    systemVariants: PropTypes.number,
    totalReturn: PropTypes.number.isRequired,
    stakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    clearBetSlip: PropTypes.func.isRequired,
    bonusValue: PropTypes.number,
    bonusType: PropTypes.oneOf([
      BONUS_TYPES.EXPRESS_PERCENT,
      BONUS_TYPES.EXPRESS_CASHBACK,
    ]),
    accumulatorId: PropTypes.number,
    repeatBet: PropTypes.func.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    systemIndex: null,
    systemVariants: null,
    bonusValue: null,
    bonusType: null,
    accumulatorId: null,
  };

  onEventClick = (e) => {
    const { sendEventClickFeedback } = this.props;

    sendEventClickFeedback(e);
    GA.event({
      category: 'go-to-event-page',
      label: 'bet-slip-event-click',
    });
  };

  repeatBet = () => {
    const {
      repeatBet,
      betAmount,
      betType,
      stakes,
      systemIndex,
    } = this.props;
    repeatBet({
      betAmount,
      betType,
      stakes: prepareStakesForRepeat(stakes),
      systemIndex,
    });

    GA.event({
      category: 'bet-slip',
      label: 'repeat-last-bet-click',
    });
  };

  render() {
    const {
      locale,
      currency,
      betType,
      betAmount,
      betFactor,
      systemIndex,
      systemVariants,
      totalReturn,
      stakes,
      clearBetSlip,
      bonusValue,
      bonusType,
      accumulatorId,
    } = this.props;
    const isOrdinar = betType === BET_TYPE.ORDINAR;
    const isExpress = betType === BET_TYPE.EXPRESS;
    const isSystem = betType === BET_TYPE.SYSTEM;
    let ordinarFormattedStakeName;
    let ordinarTeams;
    let ordinarStakeTypeName;

    if (isOrdinar) {
      const stake = stakes[0];
      ordinarTeams = stake.isOutright ? stake.teamA : `${stake.teamA} - ${stake.teamB}`;
      ordinarStakeTypeName = prepareStakeTypeName(stake.periodName, stake.stakeTypeName);
      ordinarFormattedStakeName = `${stake.stakeName}${stake.stakeArgument === null ? '' : ` ${stake.stakeArgument}`}`;

      if (handicapStakeTypesIds.includes(stake.stakeTypeId)) {
        ordinarFormattedStakeName = `${stake.stakeCode === 1 ? stake.teamA : stake.teamB} ${stake.stakeArgument > 0 ? `+${stake.stakeArgument}` : stake.stakeArgument}`;
      } else if (restOfTheMatchStakeTypeIds.includes(stake.stakeTypeId)) {
        ordinarFormattedStakeName = `${stake.stakeName} (${stake.stakeArgument.toFixed(1).replace('.', ':')})`;
      }
    }

    return (
      <div className="bet-slip-success">
        <div className="bet-slip-success-header d-flex justify-content-between align-items-center pr-1">
          <div className="d-flex align-items-center">
            <IconSuccess />
            <FormattedTag id="bet-slip.success" className="ml-1_5 text-small font-weight-bold" />
          </div>

          <FormattedTag
            id="bet-slip.done"
            tag={Button}
            size="sm"
            color="primary"
            onClick={clearBetSlip}
          />
        </div>

        <div className="bet-slip-repeat-bet px-2 py-1_5 bg-main-4">
          <FormattedTag
            tag={Button}
            id="bet-slip.repeat-last-bet"
            block
            size="sm"
            outline
            onClick={this.repeatBet}
          />
        </div>

        <div className="bet-slip-success-bets pt-2 pb-2_5 bg-main-4 overflow-hidden">
          <div className="d-flex justify-content-between mb-0_25 font-weight-bold">
            {isOrdinar && ordinarFormattedStakeName}
            {isExpress && <FormattedMessage id={`bet-slip.type.${accumulatorId ? 'boosted-express' : 'express'}`} />}
            {isSystem && <FormattedMessage id="bet-slip.type.system" values={{ systemIndex, stakesAmount: stakes.length }} />}
            {!isSystem && !!betFactor && <span>{mathRound(betFactor)}</span>}
          </div>

          {isOrdinar && (
            <Fragment>
              <div title={ordinarStakeTypeName} className="caption text-extra-2 mb-0_25 text-truncate">
                {ordinarStakeTypeName}
              </div>

              <RouteLink
                locale={locale}
                to={`/event/${stakes[0].eventNumber}?isLive=${stakes[0].isEventInLive}&sectionBeforeEventPage=${AI_OUTCOME_SECTION.BET_SLIP}`}
                title={ordinarTeams}
                className="bet-slip-success-event-link caption"
                onClick={this.onEventClick}
                data-event-id={stakes[0].eventNumber}
                data-is-live={stakes[0].isEventInLive}
              >
                {ordinarTeams}
              </RouteLink>
            </Fragment>
          )}

          {isExpress && (
            <FormattedTag id="bet-slip.express-bets" className="caption text-extra-2" values={{ stakesAmount: stakes.length }} />
          )}

          {isSystem && (
            <FormattedTag id="bet-slip.variants" className="caption text-extra-2" values={{ amount: systemVariants }} />
          )}

          {!isOrdinar && stakes.map((stake) => {
            const teams = stake.isOutright ? stake.teamA : `${stake.teamA} - ${stake.teamB}`;
            const formattedStakeTypeName = prepareStakeTypeName(stake.periodName, stake.stakeTypeName);
            let formattedStakeName = `${stake.stakeName}${stake.stakeArgument === null ? '' : ` ${stake.stakeArgument}`}`;

            if (handicapStakeTypesIds.includes(stake.stakeTypeId)) {
              formattedStakeName = `${stake.stakeCode === 1 ? stake.teamA : stake.teamB} ${stake.stakeArgument > 0 ? `+${stake.stakeArgument}` : stake.stakeArgument}`;
            } else if (restOfTheMatchStakeTypeIds.includes(stake.stakeTypeId)) {
              formattedStakeName = `${stake.stakeName} (${stake.stakeArgument.toFixed(1).replace('.', ':')})`;
            }

            return (
              <div key={stake.stakeId} className="mt-2_5 pl-2_5">
                <div className="d-flex justify-content-between text-small overflow-hidden mb-0_5">
                  <span className="font-weight-bold text-truncate mr-1">{formattedStakeName}</span>
                  {!!stake.betFactor && <span className="text-extra-2">{mathRound(stake.betFactor)}</span>}
                </div>

                <div title={formattedStakeTypeName} className="caption text-extra-2 mb-0_25 text-truncate">
                  {formattedStakeTypeName}
                </div>

                <RouteLink
                  locale={locale}
                  to={`/event/${stake.eventNumber}?isLive=${stake.isEventInLive}&sectionBeforeEventPage=${AI_OUTCOME_SECTION.BET_SLIP}`}
                  title={teams}
                  className="bet-slip-success-event-link caption"
                  onClick={this.onEventClick}
                  data-event-id={stake.eventNumber}
                  data-is-live={stake.isEventInLive}
                >
                  {teams}
                </RouteLink>
              </div>
            );
          })}

          {!!bonusValue && (
            <div className="d-flex align-items-center justify-content-between text-small mt-2_5">
              <div className="d-flex align-items-center ml-n0_5">
                <IconBonus />
                <FormattedTag id={`general.${bonusType === BONUS_TYPES.EXPRESS_PERCENT ? 'bonus' : 'cashback'}`} className="font-weight-bold ml-1 mr-0_25" />
                {bonusType === BONUS_TYPES.EXPRESS_CASHBACK && bonusValue && `${mathRound(bonusValue)} ${prepareCurrency(currency)}`}
              </div>
              <span className="text-extra-2">
                {bonusType === BONUS_TYPES.EXPRESS_PERCENT && bonusValue && mathRound(bonusValue)}
              </span>
            </div>
          )}
        </div>

        <div className="bet-slip-success-footer py-2 bg-main-4">
          <div className="d-flex justify-content-between mb-0_5 text-small text-extra-2">
            <FormattedMessage id="bet-slip.stake" />
            {!!betAmount && <span>{formatAmountWithCurrencySymbol(betAmount, currency)}</span>}
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <FormattedTag id="bet-slip.return" className="text-small text-extra-2" />
            {!!totalReturn && <span className="font-weight-bold">{formatAmountWithCurrencySymbol(totalReturn, currency)}</span>}
          </div>
        </div>
      </div>
    );
  }
}
