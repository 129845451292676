import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportFutsal = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} fillRule="nonzero" d="M10 6.846l3 2.18-1.146 3.526H8.146L7 9.026l3-2.18zm6.653 8.429l-.512-1.502-3.137.387-1.339 2.865 1.265.944c-.915.338-1.899.53-2.93.53-1.056 0-2.063-.201-2.997-.554L8.263 17l-1.338-2.864-3.138-.388-.494 1.454C2.174 13.763 1.5 11.96 1.5 10c0-.124.013-.244.019-.367l1.239.874 2.31-2.158-.6-3.104-1.495-.02C4.081 3.6 5.74 2.384 7.677 1.832l-.444 1.434L10 4.794l2.767-1.53-.446-1.434c1.952.556 3.622 1.787 4.731 3.43l-1.547.023-.601 3.104 2.31 2.158 1.268-.896c.005.117.018.232.018.35 0 1.994-.694 3.826-1.847 5.276zM10 0C4.477 0 0 4.478 0 10c0 5.523 4.477 10 10 10 5.522 0 10-4.477 10-10 0-5.522-4.478-10-10-10z" mask="url(#icon-sports-fustal-b)" />
    </g>
  </svg>
);

IconSportFutsal.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportFutsal.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
