import * as R from 'ramda';

const getLogo = (games, teamName) => {
  const teamA = R.find(R.propEq('teamA', teamName), games);

  if (teamA) {
    return teamA.teamALogo;
  }

  const teamB = R.find(R.propEq('teamB', teamName), games);

  if (teamB) {
    return teamB.teamBLogo;
  }

  return '';
};

export const prepareSearchedGames = (searchedGames, searchValue) => R.compose(
  R.map(
    (([key, value]) => ({
      sportId: Number(key),
      sportName: value[0].sportName,
      teams: R.compose(
        R.map(name => ({
          name,
          logo: getLogo(value, name),
          games: R.filter(
            ({
              sportId,
              teamA,
              teamB,
              isOutright,
            }) => Number(key) === sportId && !isOutright && (name === teamA || name === teamB),
            searchedGames,
          ),
          outrights: R.filter(
            ({ sportId, teamA, isOutright }) => Number(key) === sportId && isOutright && name === teamA,
            searchedGames,
          ),
        })),
        R.filter(team => team.toLowerCase().includes(searchValue.toLowerCase().split(' - ')[0])),
        R.uniq,
        R.flatten,
        R.map(R.compose(R.values, R.pick(['teamA', 'teamB'])))
      )(value)
    })),
  ),
  games => Object.entries(R.groupBy(R.prop('sportId'), games)),
)(searchedGames);

export const filterBySport = (searchedGames, sportId) => R.filter(R.propEq('sportId', sportId), searchedGames);

export const filterLeaguesBySport = (leagues = [], sportId) => R.filter(R.propEq('sportId', sportId), leagues);
