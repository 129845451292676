import React from 'react';
import PropTypes from 'prop-types';

export const IconBetSlip = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 20 24">
    <g fill="none" stroke="#FFF" strokeWidth="2" transform="translate(1 1)">
      <rect width="18" height="19" rx="2" />
      <path d="M2 19h14v1c0 1.105-.895 2-2 2H4c-1.105 0-2-.895-2-2v-1h0z" />
    </g>
  </svg>

);

IconBetSlip.propTypes = {
  className: PropTypes.string,
};

IconBetSlip.defaultProps = {
  className: null,
};
