import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { RouteLink } from 'components/route-link/route-link';
import { getTopLeagueIcon } from 'helpers/top-leagues';
import { GA } from 'helpers/ga';
import { FavoritesToggleButton } from '../favorites-toggle-button/favorites-toggle-button';
import { MODAL_IDS } from '../../../../constants';

import './top-league-item.scss';

export class TopLeagueItem extends PureComponent {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    sportId: PropTypes.number.isRequired,
    eventsCount: PropTypes.number.isRequired,
    inplayEventsCount: PropTypes.number.isRequired,
    leagueId: PropTypes.number.isRequired,
    leagueName: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    isAllSportsModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    onFavoriteLeagueClick: PropTypes.func.isRequired,
    sendLeagueClickFeedback: PropTypes.func.isRequired,
  };

  onClick = () => {
    const {
      isAllSportsModal,
      closeModal,
      leagueId,
      onFavoriteLeagueClick,
      sportId,
      sendLeagueClickFeedback,
    } = this.props;

    onFavoriteLeagueClick(leagueId, sportId);

    if (isAllSportsModal) {
      closeModal(MODAL_IDS.SPORTS);
    }

    GA.event({
      category: 'navigation',
      label: 'top-leagues-click',
    });
    sendLeagueClickFeedback(leagueId);
  };

  render() {
    const {
      locale,
      sportId,
      eventsCount,
      inplayEventsCount,
      leagueId,
      leagueName,
      isActive,
    } = this.props;
    const TopLeagueIcon = getTopLeagueIcon(leagueId);

    return (
      <div className={classNames('top-league-item position-relative', {
        'font-weight-bold bg-extra-7 active': isActive,
        'with-live-status': inplayEventsCount > 0,
      })}
      >
        <RouteLink
          to={`/league/${sportId}/${leagueId}`}
          locale={locale}
          onClick={this.onClick}
          className="d-flex align-items-center h-100"
        >
          <TopLeagueIcon isActive={isActive} className="icon-sports-others ml-2 ml-sm-2_5 mr-2_5 flex-shrink-0" />
          <div className="my-1 py-0_25 d-flex align-items-center overflow-hidden pr-8_5 pr-lg-5">
            <span title={leagueName} className="navigation-item-name">
              {leagueName}
            </span>
            <span className="align-self-end text-extra-3 pl-1 font-weight-normal">{eventsCount}</span>
          </div>
        </RouteLink>

        <FavoritesToggleButton
          sportId={sportId}
          leagueId={leagueId}
          leagueName={leagueName}
          className="position-absolute favorites-button"
        />
      </div>
    );
  }
}
