import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportWaterSports = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M18.756 20c-.166 0-.333-.055-.472-.166l-1.817-1.47c-.157-.127-.376-.127-.533-.004l-1.135.896c-.715.563-1.706.555-2.41-.018l-1.07-.869c-.158-.127-.378-.127-.537-.002l-1.103.881c-.71.567-1.7.567-2.408-.002l-1.1-.881c-.158-.125-.377-.127-.535 0l-1.097.88c-.71.57-1.7.57-2.408.005L.28 17.776c-.322-.258-.376-.73-.117-1.053.258-.325.73-.377 1.054-.118l1.848 1.473c.158.125.377.125.534-.002l1.096-.88c.71-.57 1.703-.57 2.413-.003l1.1.883c.156.123.379.123.533 0l1.105-.88c.713-.57 1.706-.566 2.417.008l1.07.87c.158.13.377.13.536.004l1.134-.895c.71-.561 1.699-.556 2.406.015l1.818 1.47c.322.26.372.732.11 1.055-.147.183-.364.277-.582.277zm0-4c-.166 0-.333-.055-.472-.166l-1.817-1.47c-.157-.127-.376-.127-.533-.004l-1.135.897c-.715.562-1.706.555-2.41-.018l-1.07-.87c-.158-.127-.378-.127-.537-.002l-1.103.881c-.71.568-1.7.568-2.408-.002l-1.1-.882c-.158-.125-.377-.126-.535.002l-1.097.88c-.71.57-1.7.569-2.408.005L.28 13.774c-.322-.257-.376-.729-.117-1.053.258-.323.73-.375 1.054-.117l1.848 1.473c.158.125.377.125.534-.002l1.096-.88c.71-.57 1.703-.57 2.413-.002l1.1.882c.156.123.379.123.533 0l1.105-.88c.713-.57 1.706-.566 2.417.008l1.07.87c.158.13.377.13.536.005l1.134-.896c.71-.562 1.699-.556 2.406.014l1.818 1.47c.322.261.372.732.11 1.056-.147.182-.364.277-.582.277zM7.59 14.41l.01-.01c.15.07.17.08-.01.01zm0 0c.18.07.16.06.01-.01l-.01.01zM18.66 5.1c-.26-.6-.95-.88-1.56-.63-.56.23-1.13.44-1.71.61-.73.22-1.48.38-2.23.49-.74.1-1.48.15-2.22.14-.36-.01-.72-.05-1.08-.06-.41-.01-.81.01-1.22.03-.77.04-1.54.11-2.3.23-.78.11-1.56.27-2.33.46-.78.19-1.56.42-2.33.69l-.17.06c-.62.21-.95.88-.74 1.5.17.49.63.8 1.12.8.13 0 .26-.02.39-.06l.17-.06c1.52-.52 3.04-.88 4.53-1.09l-1.75 3.13c.22-.05.44-.07.67-.07.78 0 1.54.27 2.14.75l.44.35.43-.35c.01-.01.02-.01.03-.02l2.19-3.92h.02c2.29 0 4.64-.48 6.87-1.42.61-.26.89-.95.64-1.56zm-7.26-4c-1.03-.34-2.13.22-2.47 1.24-.34 1.03.22 2.14 1.25 2.48 1.02.33 2.13-.23 2.47-1.25.34-1.03-.22-2.13-1.25-2.47z" />
  </svg>
);

IconSportWaterSports.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportWaterSports.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
