import React from 'react';
import PropTypes from 'prop-types';

export const IconWon = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <path fill="#04D992" fillRule="evenodd" d="M6 0c3.314 0 6 2.686 6 6s-2.686 6-6 6-6-2.686-6-6 2.686-6 6-6zm2.47 3.47L5 6.94 3.53 5.47 2.47 6.53 5 9.06l4.53-4.53-1.06-1.06z" />
  </svg>
);

IconWon.propTypes = {
  className: PropTypes.string,
};

IconWon.defaultProps = {
  className: null,
};
