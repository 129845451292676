import React from 'react';
import PropTypes from 'prop-types';

export const IconGift = ({ className }) => (
  <svg className={className} width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.99781 3.28572C6.96841 2.90529 6.62927 2.62659 6.25618 2.62659H0.743819C0.370729 2.62659 0.0315872 2.90529 0.00218789 3.28572C-0.0198616 3.57445 0.126785 3.82699 0.350079 3.96025V7.2835C0.350079 7.67754 0.665071 7.99995 1.05006 7.99995H3V4.41771C3 4.21997 3.3068 4.05948 3.5 4.05948C3.6932 4.05948 4 4.21997 4 4.41771V7.99995H5.94994C6.33493 7.99995 6.64992 7.67754 6.64992 7.2835V3.96025C6.87322 3.82699 7.01986 3.57445 6.99781 3.28572Z" fill="#FFEB3B" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0.93934 0.439615C1.22064 0.158134 1.60218 0 2 0C2.39782 0 2.77936 0.158134 3.06066 0.439615C3.34196 0.721095 3.5 1.10286 3.5 1.50094C3.5 1.89901 3.34196 2.28078 3.06066 2.56226C2.77936 2.84374 2.39782 3.00188 2 3.00188C1.60218 3.00188 1.22064 2.84374 0.93934 2.56226C0.658035 2.28078 0.5 1.89901 0.5 1.50094C0.5 1.10286 0.658035 0.721095 0.93934 0.439615ZM2 1.00063C1.86739 1.00063 1.74021 1.05334 1.64645 1.14716C1.55268 1.24099 1.5 1.36825 1.5 1.50094C1.5 1.63363 1.55268 1.76089 1.64645 1.85471C1.74021 1.94854 1.86739 2.00125 2 2.00125C2.13261 2.00125 2.25979 1.94854 2.35355 1.85471C2.44732 1.76089 2.5 1.63363 2.5 1.50094C2.5 1.36825 2.44732 1.24099 2.35355 1.14716C2.25979 1.05334 2.13261 1.00063 2 1.00063ZM3.93934 0.439615C4.22064 0.158134 4.60218 0 5 0C5.39782 0 5.77936 0.158134 6.06066 0.439615C6.34196 0.721095 6.5 1.10286 6.5 1.50094C6.5 1.89901 6.34196 2.28078 6.06066 2.56226C5.77936 2.84374 5.39782 3.00188 5 3.00188C4.60218 3.00188 4.22064 2.84374 3.93934 2.56226C3.65804 2.28078 3.5 1.89901 3.5 1.50094C3.5 1.10286 3.65804 0.721095 3.93934 0.439615ZM5 1.00063C4.86739 1.00063 4.74022 1.05334 4.64645 1.14716C4.55268 1.24099 4.5 1.36825 4.5 1.50094C4.5 1.63363 4.55268 1.76089 4.64645 1.85471C4.74022 1.94854 4.86739 2.00125 5 2.00125C5.13261 2.00125 5.25979 1.94854 5.35355 1.85471C5.44732 1.76089 5.5 1.63363 5.5 1.50094C5.5 1.36825 5.44732 1.24099 5.35355 1.14716C5.25979 1.05334 5.13261 1.00063 5 1.00063Z" fill="#FFEB3B" />
    <path d="M4.3921 1.00061L3.52283 1.41409L2.65356 1.00061V2.7517H4.3921V1.00061Z" fill="#FFEB3B" />
  </svg>
);

IconGift.propTypes = {
  className: PropTypes.string,
};

IconGift.defaultProps = {
  className: null,
};
