import React from 'react';
import PropTypes from 'prop-types';

export const IconBetsFeed = ({ className, color }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="6" r="3" fill={color} />
    <path d="M16 14.2C15.0633 11.7416 12.7301 10 10 10C7.26991 10 4.93666 11.7416 4 14.2L4.00007 15.95C4.00007 16.5299 4.4587 17 5.02445 17H14.9756C15.5413 17 15.9999 16.5299 15.9999 15.95L16 14.2Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M18.9617 8.48085C18.9617 9.85099 17.851 10.9617 16.4809 10.9617C15.1107 10.9617 14 9.85099 14 8.48085C14 7.11072 15.1107 6 16.4809 6C17.851 6 18.9617 7.11072 18.9617 8.48085ZM20 14.8C19.4536 13.1611 18.0926 12 16.5 12C16.2325 12 15.9715 12.0328 15.7199 12.095C16.2578 12.8345 16.6932 13.6796 17 14.6L16.9999 15.6C16.9999 15.739 16.9826 15.8733 16.9504 16H19.4024C19.7324 16 20 15.6866 20 15.3L20 14.8Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.9617 8.48085C5.9617 9.85099 4.85099 10.9617 3.48085 10.9617C2.11072 10.9617 1 9.85099 1 8.48085C1 7.11072 2.11072 6 3.48085 6C4.85099 6 5.9617 7.11072 5.9617 8.48085ZM4.28015 12.095C4.02847 12.0328 3.7675 12 3.5 12C1.90744 12 0.546382 13.1611 0 14.8L4.07721e-05 15.3C4.07721e-05 15.6866 0.267575 16 0.597595 16H3.04957C3.01737 15.8733 3.00008 15.739 3.00008 15.6L3 14.6C3.30685 13.6796 3.74217 12.8345 4.28015 12.095Z" fill={color} />
  </svg>
);

IconBetsFeed.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconBetsFeed.defaultProps = {
  className: null,
  color: '#FFFFFF',
};
