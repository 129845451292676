import React from 'react';
import PropTypes from 'prop-types';

export const IconInplay = ({ className, color }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 17H14V19H6V17Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M2 2C0.895431 2 0 2.89543 0 4V12C0 13.1046 0.895431 14 2 14H18C19.1046 14 20 13.1046 20 12V4C20 2.89543 19.1046 2 18 2H2ZM7.75 11.4641L13 8L7.75 4.5359L7.75 11.4641Z" fill={color} />
  </svg>
);

IconInplay.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconInplay.defaultProps = {
  className: null,
  color: '#FFFFFF',
};
