import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Label from 'reactstrap/lib/Label';
import { BET_FACTOR_CHANGES } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { RadioButton } from 'components/radio-button/radio-button';
import { IconArrowBottom } from 'components/icons/icon-arrow-bottom/icon-arrow-bottom';
import { GA } from 'helpers/ga';

import './bet-factor-changes.scss';

export class BetFactorChanges extends Component {
  static propTypes = {
    betFactorChanges: PropTypes.oneOf([
      BET_FACTOR_CHANGES.INCREASE,
      BET_FACTOR_CHANGES.ALWAYS,
      BET_FACTOR_CHANGES.NEVER,
    ]).isRequired,
    setBetFactorChanges: PropTypes.func.isRequired,
    isMobile: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    isMobile: false,
    onChange: null,
  }

  state = {
    isOpen: false,
  };

  toggleIsOpen = () => {
    const { isOpen } = this.state;

    this.setState(prevState => ({ isOpen: !prevState.isOpen }), () => {
      const { onChange } = this.props;

      if (onChange) {
        onChange();
      }
    });

    if (!isOpen) {
      GA.event({
        category: 'bet-slip',
        label: 'auto-confirm-dropdown-click',
      });
    }
  }

  onIncreaseClick = () => {
    const { setBetFactorChanges } = this.props;

    setBetFactorChanges(BET_FACTOR_CHANGES.INCREASE);
    GA.event({
      category: 'bet-slip',
      label: 'when-odds-increase-click',
    });
  };

  onAlwaysClick = () => {
    const { setBetFactorChanges } = this.props;

    setBetFactorChanges(BET_FACTOR_CHANGES.ALWAYS);
    GA.event({
      category: 'bet-slip',
      label: 'always-click',
    });
  };

  onNeverClick = () => {
    const { setBetFactorChanges } = this.props;

    setBetFactorChanges(BET_FACTOR_CHANGES.NEVER);
    GA.event({
      category: 'bet-slip',
      label: 'never-click',
    });
  };

  render() {
    const { betFactorChanges, isMobile } = this.props;
    const { isOpen } = this.state;

    return (
      <div className={classNames('bet-factor-changes text-small mt-2 position-relative', {
        'is-open': isOpen,
      })}
      >
        <div
          role="button"
          tabIndex="0"
          onClick={this.toggleIsOpen}
          onKeyPress={this.toggleIsOpen}
          className="d-flex align-items-center justify-content-center"
        >
          <FormattedTag id="bet-slip.bet-factor-changes" className="text-extra-2 mr-0_5" />
          <IconArrowBottom className="icon-arrow-bottom flex-shrink-0" backgroundColor="transparent" />
        </div>

        {isOpen && (
          <div className="d-flex flex-wrap pt-0_5">
            <Label
              role="button"
              check
              className="d-flex align-items-center mr-4 mt-1_5"
            >
              <RadioButton
                id={`${isMobile ? 'm-' : ''}bet-factor-changes-${BET_FACTOR_CHANGES.INCREASE}`}
                type="radio"
                name={`${isMobile ? 'm-' : ''}bet-factor-changes-radio`}
                checked={betFactorChanges === BET_FACTOR_CHANGES.INCREASE}
                onChange={this.onIncreaseClick}
                className="mr-1"
              />
              <FormattedMessage id="bet-slip.bet-factor-changes.increase" />
            </Label>

            <Label
              role="button"
              check
              className="d-flex align-items-center mr-4 mt-1_5"
            >
              <RadioButton
                id={`${isMobile ? 'm-' : ''}bet-factor-changes-${BET_FACTOR_CHANGES.ALWAYS}`}
                type="radio"
                name={`${isMobile ? 'm-' : ''}bet-factor-changes-radio`}
                checked={betFactorChanges === BET_FACTOR_CHANGES.ALWAYS}
                onChange={this.onAlwaysClick}
                className="mr-1"
              />
              <FormattedMessage id="bet-slip.bet-factor-changes.always" />
            </Label>

            <Label
              role="button"
              check
              className="d-flex align-items-center mt-1_5"
            >
              <RadioButton
                id={`${isMobile ? 'm-' : ''}bet-factor-changes-${BET_FACTOR_CHANGES.NEVER}`}
                type="radio"
                name={`${isMobile ? 'm-' : ''}bet-factor-changes-radio`}
                checked={betFactorChanges === BET_FACTOR_CHANGES.NEVER}
                onChange={this.onNeverClick}
                className="mr-1"
              />
              <FormattedMessage id="bet-slip.bet-factor-changes.never" />
            </Label>
          </div>
        )}
      </div>
    );
  }
}
