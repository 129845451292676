import React from 'react';
import PropTypes from 'prop-types';

export const IconUel = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.9569 19C11.6552 18.957 12.3276 18.8495 12.9741 18.6989C13 18.6989 13 18.6774 13 18.6774C13 18.6774 13 18.6774 13 18.6559C12.1724 17.9032 10.8793 16.3763 10 15C10.4397 16.4839 10.8276 18.1183 10.9052 18.9785C10.931 19 10.931 19 10.9569 19Z" fill="white" fillOpacity="0.4" />
    <path d="M14 1.69048C14 1.66667 14 1.64286 13.9796 1.64286L11.5256 0C11.5256 0 11.5256 0 11.5052 0C11.4847 0 11.4847 0.0238095 11.4643 0.0238095C11.3416 0.642857 10.1964 6.7381 12.0368 14C11.3825 9.2619 12.9775 4.02381 14 1.69048Z" fill="white" fillOpacity="0.4" />
    <path d="M13 18.5C9.22045 11.8402 9.82428 2.91366 10.2492 0.0489691C10.2492 0.0244845 10.2268 0 10.2045 0C9.71246 0.220361 6.38019 1.68943 6.02236 1.83634C6 1.83634 6 1.86082 6 1.88531C6.67093 5.26418 8.39297 12.7706 13 18.5Z" fill="white" fillOpacity="0.4" />
    <path d="M8 18.6308C8 18.6554 8 18.68 8.02128 18.68C8.6383 18.8769 9.29787 18.9754 9.95745 19C9.97872 19 10 18.9754 10 18.9508C9.91489 17.3262 9.59574 13.9785 8.40425 11C8.78723 14.0031 8.3617 17.1538 8 18.6308Z" fill="white" fillOpacity="0.4" />
    <path d="M6.92373 19C6.94915 19 6.94915 19 6.94915 18.9753C6.97458 18.926 7 18.8028 7 18.7535C7 18.7288 6.97458 18.7288 6.97458 18.7042C3.89831 17.4222 2 13.9761 2 10.5C2 7.78814 3.0339 4.77504 5.09322 3.24653C5.09322 3.19723 5.04237 3.09861 5.04237 3.02465C5.04237 3 5.01695 3 4.99153 3H4.9661C2.55085 4.67643 1 7.38829 1 10.4946C1.02542 14.3159 3.4661 17.6194 6.92373 19Z" fill="white" fillOpacity="0.4" />
    <path d="M14.878 3C14.8537 3 14.8293 3 14.8293 3.02336L14.7317 3.21028C14.7317 3.21028 14.7317 3.21028 14.7317 3.23364C14.7317 3.25701 14.7317 3.25701 14.7561 3.25701C16.9512 4.68224 18 7.57944 18 10.3131C18 13.3738 16.6585 16.3879 14.0244 17.743L14 17.7664C14 17.7664 14 17.7664 14 17.7897L14.122 17.9766L14.1463 18H14.1707C17.0488 16.528 19 13.6308 19 10.3131C18.9756 7.25234 17.3415 4.56542 14.878 3Z" fill="white" fillOpacity="0.4" />
  </svg>
);

IconUel.propTypes = {
  className: PropTypes.string,
};

IconUel.defaultProps = {
  className: '',
};
