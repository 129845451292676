import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BET_STAKE_STATUS } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';

import './bet-status.scss';

const BetStatusUI = ({ status }) => {
  const isNew = status === BET_STAKE_STATUS.NEW;
  const isLost = status === BET_STAKE_STATUS.LOST;
  let intlId = 'bets-history.status.won';

  if (isNew) {
    intlId = 'bets-history.status.pending';
  } else if (isLost) {
    intlId = 'bets-history.status.lost';
  }

  return (
    <FormattedTag
      id={intlId}
      className={classNames('bet-status pl-1_5 position-relative', {
        'is-new': isNew,
        'is-lost': isLost,
      })}
    />
  );
};

BetStatusUI.propTypes = {
  status: PropTypes.number.isRequired,
};

export const BetStatus = React.memo(BetStatusUI);
