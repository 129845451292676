import React from 'react';
import PropTypes from 'prop-types';

export const IconClose = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <path fill="#FFF" fillRule="evenodd" d="M.95.95c.39-.39 1.023-.39 1.414 0l3.535 3.535L9.435.95c.39-.39 1.024-.39 1.414 0 .39.39.39 1.023 0 1.414L7.314 5.899l3.535 3.536c.39.39.39 1.024 0 1.414-.39.39-1.023.39-1.414 0L5.899 7.314l-3.535 3.535c-.39.39-1.024.39-1.414 0-.39-.39-.39-1.023 0-1.414l3.535-3.536L.95 2.364C.56 1.974.56 1.34.95.95z" opacity=".45" />
  </svg>
);

IconClose.propTypes = {
  className: PropTypes.string,
};

IconClose.defaultProps = {
  className: null,
};
