import React from 'react';
import PropTypes from 'prop-types';
import Countdown, { calcTimeDelta, formatTimeDelta } from 'react-countdown-now';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';

export const Renderer = ({ props: { date } }) => {
  const delta = calcTimeDelta(date);
  const { days, hours, minutes } = formatTimeDelta(delta);

  return (
    <div className="freebets-modal-countdown d-flex justify-content-center">
      <div>
        <div className="h3 m-0 font-weight-bolder text-extra-2">{days}</div>
        <FormattedTag id="freebet.days" className="text-extra-2 text-capitalize" />
      </div>
      <div className="h3 my-0 mx-1 text-extra-3">:</div>
      <div>
        <div className="h3 m-0 font-weight-bolder text-extra-2">{hours}</div>
        <FormattedTag id="freebet.hours" className="text-extra-2 text-capitalize" />
      </div>
      <div className="h3 my-0 mx-1 text-extra-3">:</div>
      <div>
        <div className="h3 m-0 font-weight-bolder text-extra-2">{minutes}</div>
        <FormattedTag id="freebet.minutes" className="text-extra-2 text-capitalize" />
      </div>
    </div>
  );
};

Renderer.propTypes = {
  props: PropTypes.shape(),
};

Renderer.defaultProps = {
  props: {},
};

export const CountDown = ({ date }) => (
  <Countdown date={date} renderer={Renderer} />
);

CountDown.propTypes = {
  date: PropTypes.shape({
    hours: PropTypes.number,
    minutes: PropTypes.number,
    seconds: PropTypes.number,
  }),
};

CountDown.defaultProps = {
  date: {},
};
