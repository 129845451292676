import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportMiniFootball = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} fillRule="nonzero" d="M18.91 5.51v3.24c.14.36.26.78.35 1.26.09.47.13.9.13 1.29l.47.2c.08-.49.12-.99.12-1.5 0-1.62-.38-3.14-1.07-4.49zm-.51 8.11c-.34.38-.68.66-.99.88l-1.77 3.74c1.83-1.25 3.21-3.1 3.89-5.26l-.51-.21c-.16.27-.36.56-.62.85zm-5.18-9.43c0 .26-.01.51-.03.75l3.56 3.19h.66V4.45c-.2-.26-.42-.54-.69-.81-.26-.27-.52-.5-.77-.69l-2.83.24c.05.3.09.63.1 1zm2 8.94l-4.45 1.18c-.15.36-.31.72-.52 1.11-.15.29-.31.55-.47.81l2.38 2.51c.28-.03.57-.07.89-.15.33-.08.62-.19.9-.3L16 13.96c-.26-.28-.52-.55-.78-.83zm-4.9-.25l4.62-1.23c.17-.39.34-.83.48-1.32.11-.41.19-.79.26-1.15l-3.79-3.4c-.34.06-.68.12-1.06.21-.45.12-.86.26-1.23.4-.39 1.1-1.03 3.03-1.28 4.62.28.31.59.62.93.94.36.34.72.65 1.07.93zm.5-11.55c.49.12.92.27 1.29.44l2.77-.24c-.07-.14-.15-.27-.23-.37 0-.01-.01-.01-.01-.01C13.25.42 11.66 0 9.98 0c-.4 0-.8.02-1.19.07l.6 1.05c.43.02.9.08 1.43.21zM6.6 16.78c-.46-.15-.85-.33-1.19-.51l-1.64 1c.1.29.22.56.35.82 1.45 1.06 3.19 1.73 5.08 1.88.18-.02.39-.07.61-.14.35-.13.63-.31.85-.49l-2.09-2.21c-.55-.02-1.23-.11-1.97-.35zM2.42 6.3c-.12.27-.24.57-.34.9-.11.37-.17.71-.21 1.02l2.92 3.29 2.07-.87c.28-1.6.85-3.41 1.25-4.56L6.76 4.65 2.42 6.3zm-.26-1.51l4.27-1.62c.21-.31.48-.65.82-.99.23-.23.46-.43.68-.6L7.24.39c-2.25.63-4.18 2.04-5.47 3.92.11.15.24.31.38.47 0 .01.01.01.01.01zm2.2 10.37l-1.64.99c-.28-.19-.71-.53-1.38-1.12C.59 13.74.11 12.27 0 10.7c.29-.28.64-.56 1.03-.84.06-.05.12-.08.18-.12l2.64 2.98c-.01.45.02.99.19 1.58.09.32.2.6.32.86z" mask="url(#icon-sports-mini-football-b)" />
    </g>
  </svg>
);

IconSportMiniFootball.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportMiniFootball.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
