import { PRODUCTION } from 'core/constants';
import { canUseDOM } from 'core/helpers';

export const GA = {
  event: ({ category = '', action = 'click', label = '' }) => {
    if (canUseDOM()) {
      if (window.ga) {
        window.ga('send', {
          hitType: 'event',
          eventCategory: category,
          eventAction: action,
          eventLabel: label,
        });
      }

      if (process.env.PROJECT_ENV !== PRODUCTION) {
        console.log('GA:', 'event', category, action, label);
      }
    }
  },
};
