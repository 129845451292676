import React from 'react';
import PropTypes from 'prop-types';

export const IconHome = ({ className, color }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path fill={color} fillRule="evenodd" d="M10.664.59l8 7.112c.214.19.336.461.336.747V18c0 .552-.448 1-1 1h-4.25v-5l-.005-.2c-.104-1.978-1.74-3.55-3.745-3.55-2.071 0-3.75 1.679-3.75 3.75v5H2c-.552 0-1-.448-1-1V8.45c0-.287.122-.559.336-.748l8-7.111c.379-.337.95-.337 1.328 0zM10 11.75c1.243 0 2.25 1.007 2.25 2.25v5h-4.5v-5l.005-.154c.08-1.17 1.054-2.096 2.245-2.096z" />
  </svg>
);

IconHome.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconHome.defaultProps = {
  className: null,
  color: '#FFFFFF',
};
