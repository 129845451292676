import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportCurling = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M17.416 7.656L15.388 3.6C14.895 2.613 13.903 2 12.8 2H4.97c-.553 0-1 .447-1 1 0 .552.447 1 1 1h7.83c.34 0 .646.19.8.494L14.851 7H4.97C2.38 7 .25 8.97 0 11.5h19.94c-.165-1.658-1.144-3.064-2.524-3.844M0 13.5h19.94c-.25 2.53-2.38 4.5-4.97 4.5h-10C2.38 18 .25 16.03 0 13.5" />
  </svg>
);

IconSportCurling.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportCurling.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
