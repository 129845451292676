import React from 'react';
import PropTypes from 'prop-types';

export const IconLost = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <path fill="#FFF" fillOpacity=".2" d="M6,0 C9.3137085,0 12,2.6862915 12,6 C12,9.3137085 9.3137085,12 6,12 C2.6862915,12 0,9.3137085 0,6 C0,2.6862915 2.6862915,0 6,0 Z M7.96966991,2.96966991 L5.99966991,4.93966991 L4.03033009,2.96966991 L2.96966991,4.03033009 L4.93966991,5.99966991 L2.96966991,7.96966991 L4.03033009,9.03033009 L5.99966991,7.05966991 L7.96966991,9.03033009 L9.03033009,7.96966991 L7.05966991,5.99966991 L9.03033009,4.03033009 L7.96966991,2.96966991 Z" />
  </svg>
);

IconLost.propTypes = {
  className: PropTypes.string,
};

IconLost.defaultProps = {
  className: null,
};
