import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './radio-button.scss';

export const RadioButton = ({
  name,
  onChange,
  disabled,
  checked,
  className,
  ...restProps
}) => (
  <div className={classNames('radio-button', className)}>
    <input
      type="radio"
      name={name}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      className="d-none"
      {...restProps}
    />
    <div />
  </div>
);

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

RadioButton.defaultProps = {
  className: null,
};
