import React from 'react';

export const IconsDefs = () => (
  <svg width="0" height="0">
    <defs>
      {/* IconNoBets start */}
      <linearGradient id="icon-no-bets-a" x1="86.018%" x2="44.145%" y1="40.619%" y2="50%">
        <stop offset="0%" stopColor="#1A1A1E" />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="icon-no-bets-c" x1="17.501%" x2="54.003%" y1="43.172%" y2="47.597%">
        <stop offset="0%" stopColor="#1A1A1E" />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="icon-no-bets-d" x1="26.415%" x2="78.955%" y1="30.393%" y2="74.066%">
        <stop offset="0%" stopColor="#292A2E" />
        <stop offset="100%" stopColor="#1A1A1E" />
      </linearGradient>
      <linearGradient id="icon-no-bets-e" x1="27.551%" x2="81.011%" y1="29.955%" y2="74.824%">
        <stop offset="0%" stopColor="#292A2E" />
        <stop offset="100%" stopColor="#1A1A1E" />
      </linearGradient>
      <linearGradient id="icon-no-bets-g" x1="24.353%" x2="81.49%" y1="38.372%" y2="64.277%">
        <stop offset="0%" stopColor="#292A2E" />
        <stop offset="100%" stopColor="#1A1A1E" />
      </linearGradient>
      <linearGradient id="icon-no-bets-h" x1="26.733%" x2="38.588%" y1="46.702%" y2="51.67%">
        <stop offset="0%" stopColor="#2979FF" />
        <stop offset="100%" stopColor="#1958C4" />
      </linearGradient>
      <linearGradient id="icon-no-bets-j" x1="0%" x2="110.577%" y1="2.548%" y2="133.649%">
        <stop offset="0%" stopColor="#1A1A1E" />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="icon-no-bets-k" x1="23.461%" x2="87.575%" y1="21.702%" y2="90.066%">
        <stop offset="0%" stopColor="#292A2E" />
        <stop offset="100%" stopColor="#1A1A1E" />
      </linearGradient>
      <radialGradient id="icon-no-bets-b" cx="58.304%" cy="91.129%" r="26.734%" fx="58.304%" fy="91.129%" gradientTransform="matrix(.75065 0 0 1 .145 0)">
        <stop offset="0%" />
        <stop offset="100%" stopOpacity="0" />
      </radialGradient>
      <radialGradient id="icon-no-bets-f" cx="24.377%" cy="11.965%" r="74.272%" fx="24.377%" fy="11.965%" gradientTransform="matrix(.99716 0 0 1 0 0)">
        <stop offset="0%" stopColor="#292A2E" />
        <stop offset="100%" stopColor="#1A1A1E" />
      </radialGradient>
      <radialGradient id="icon-no-bets-i" cx="33.533%" cy="22.114%" r="67.975%" fx="33.533%" fy="22.114%" gradientTransform="matrix(.99715 0 0 1 .001 0)">
        <stop offset="0%" stopColor="#323438" />
        <stop offset="100%" stopColor="#1A1A1E" />
      </radialGradient>
      <radialGradient id="icon-no-bets-l" cx="33.532%" cy="22.114%" r="67.975%" fx="33.532%" fy="22.114%" gradientTransform="matrix(.99715 0 0 1 .001 0)">
        <stop offset="0%" stopColor="#292A2E" />
        <stop offset="100%" stopColor="#1A1A1E" />
      </radialGradient>
      <radialGradient id="icon-no-bets-m" cx="33.532%" cy="22.115%" r="67.976%" fx="33.532%" fy="22.115%" gradientTransform="matrix(.99715 0 0 1 .001 0)">
        <stop offset="0%" stopColor="#292A2E" />
        <stop offset="100%" stopColor="#1A1A1E" />
      </radialGradient>
      {/* IconNoBets end */}

      {/* AllInplaySports start */}
      <linearGradient id="linear-gradient-all-sports" x1="0%" x2="100%" y1="8.434%" y2="91.566%">
        <stop offset="0%" stopColor="#5595ff" />
        <stop offset="100%" stopColor="#1c71ff" />
      </linearGradient>
      {/* AllInplaySports end */}

      {/* AllInplayBasketball start */}
      <linearGradient id="linear-gradient-basketball" x1="100%" x2="0%" y1="94.291%" y2="5.709%">
        <stop offset="0%" stopColor="#f5600f" />
        <stop offset="100%" stopColor="#ef8e00" />
      </linearGradient>
      {/* AllInplayBasketball end */}

      {/* AllInplayIceHockey start */}
      <linearGradient id="linear-gradient-icehockey" x1="0%" x2="100%" y1="13.624%" y2="86.376%">
        <stop offset="0%" stopColor="#5595ff" />
        <stop offset="100%" stopColor="#1c71ff" />
      </linearGradient>
      {/* AllInplayIceHockey end */}

      {/* AllInplaySoccer start */}
      <linearGradient id="linear-gradient-soccer" x1="0%" x2="100%" y1="5.709%" y2="94.291%">
        <stop offset="0%" stopColor="#04d992" />
        <stop offset="100%" stopColor="#00a970" />
      </linearGradient>
      {/* AllInplaySoccer end */}

      {/* AllInplayTennis start */}
      <linearGradient id="linear-gradient-tennis" x1="0%" x2="100%" y1="5.711%" y2="94.289%">
        <stop offset="0%" stopColor="#d3e21b" />
        <stop offset="100%" stopColor="#9bb60d" />
      </linearGradient>
      {/* AllInplayTennis end */}

      {/* AllInplayVolleyball start */}
      <linearGradient id="linear-gradient-volleyball" x1="0%" x2="100%" y1="5.709%" y2="94.291%">
        <stop offset=".435%" stopColor="#d33aed" />
        <stop offset="100%" stopColor="#aa24c1" />
      </linearGradient>
      {/* AllInplayVolleyball end */}

      {/* AllInplayHandball start */}
      <linearGradient id="linear-gradient-handball" x1="0%" x2="100%" y1="5.71%" y2="94.29%">
        <stop offset="0%" stopColor="#fedb83" />
        <stop offset="100%" stopColor="#dfbd68" />
      </linearGradient>
      {/* AllInplayHandball end */}

      {/* AllInplayOthers start */}
      <linearGradient id="linear-gradient-inplay-others" x1="0%" x2="100%" y1="5.709%" y2="94.291%">
        <stop offset="0%" stopColor="#d7e1ec" />
        <stop offset="100%" stopColor="#b4c4d8" />
      </linearGradient>
      {/* AllInplayOthers end */}

      {/* IconTicketEmpty start */}
      <linearGradient id="icon-ticket-empty-a" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#FFF" stopOpacity="0" />
        <stop offset="100%" stopColor="#FFF" stopOpacity=".04" />
      </linearGradient>
      <linearGradient id="icon-ticket-empty-b" x1="36.264%" x2="91.762%" y1="37.5%" y2="71.061%">
        <stop offset="0%" stopColor="#303236" />
        <stop offset="100%" stopColor="#222226" />
      </linearGradient>
      {/* IconTicketEmpty end */}

      {/* IconSportFutsal start */}
      <path id="icon-sports-fustal-a" d="M0 0L20 0 20 20 0 20z" />
      <mask id="icon-sports-fustal-b" fill="#fff">
        <use xlinkHref="#icon-sports-fustal-a" />
      </mask>
      {/* IconSportFutsal end */}

      {/* IconSportMiniFootball start */}
      <path id="icon-sports-mini-football-a" d="M0 0L19.98 0 19.98 19.97 0 19.97z" />
      <mask id="icon-sports-mini-football-b" fill="#fff">
        <use xlinkHref="#icon-sports-mini-football-a" />
      </mask>
      {/* IconSportMiniFootball end */}

      {/* IconStub start */}
      <linearGradient id="icon-stub-a" x1="32.704%" x2="91.156%" y1="28.571%" y2="74.17%">
        <stop offset="0%" stopColor="#303236" />
        <stop offset="100%" stopColor="#222226" />
        <stop offset="100%" stopColor="#222226" />
      </linearGradient>
      <linearGradient id="icon-stub-b" x1="25.867%" x2="52.524%" y1="47.817%" y2="64.488%">
        <stop offset="0%" stopColor="#303236" />
        <stop offset="100%" stopColor="#222226" />
        <stop offset="100%" stopColor="#222226" />
      </linearGradient>
      <linearGradient id="icon-stub-c" x1="43.967%" x2="50.631%" y1="0%" y2="381.809%">
        <stop offset="0%" stopColor="#303236" />
        <stop offset="100%" stopColor="#222226" />
        <stop offset="100%" stopColor="#222226" />
      </linearGradient>
      <linearGradient id="icon-stub-d" x1="49.349%" x2="94.083%" y1="21.889%" y2="181.495%">
        <stop offset="0%" stopColor="#FFF" stopOpacity=".1" />
        <stop offset="100%" stopColor="#FFF" stopOpacity=".04" />
        <stop offset="100%" stopColor="#FFF" stopOpacity=".02" />
      </linearGradient>
      <linearGradient id="icon-stub-e" x1="48.14%" x2="94.083%" y1="17.575%" y2="181.495%">
        <stop offset="2.631%" stopColor="#FFF" stopOpacity=".1" />
        <stop offset="100%" stopColor="#FFF" stopOpacity=".04" />
        <stop offset="100%" stopColor="#FFF" stopOpacity=".02" />
      </linearGradient>
      <linearGradient id="icon-stub-f" x1="42.559%" x2="226.332%" y1="17.575%" y2="181.495%">
        <stop offset="0%" stopColor="#FFF" stopOpacity=".12" />
        <stop offset="100%" stopColor="#FFF" stopOpacity=".04" />
        <stop offset="100%" stopColor="#FFF" stopOpacity=".02" />
      </linearGradient>
      <linearGradient id="icon-stub-g" x1="35.167%" x2="116.464%" y1="26.685%" y2="131.77%">
        <stop offset="0%" stopColor="#303236" />
        <stop offset="100%" stopColor="#222226" />
      </linearGradient>
      <linearGradient id="icon-stub-h" x1="50%" x2="100.982%" y1="30.307%" y2="220.597%">
        <stop offset="0%" stopColor="#FFF" stopOpacity=".12" />
        <stop offset="100%" stopColor="#FFF" stopOpacity=".04" />
        <stop offset="100%" stopColor="#FFF" stopOpacity=".02" />
      </linearGradient>
      {/* IconStub end */}

      {/* IconLiveStreamsStub start */}
      <linearGradient
        id="icon-live-stream-stub-a"
        x1="-856.79"
        y1="1249.86"
        x2="-855.79"
        y2="1249.49"
        gradientTransform="matrix(94 0 0 -58 80543 72514.99)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#303236" />
        <stop offset="1" stopColor="#222226" />
      </linearGradient>
      <linearGradient
        id="icon-live-stream-stub-b"
        x1="-849.46"
        y1="1201.31"
        x2="-849.46"
        y2="1198.43"
        gradientTransform="matrix(54 0 0 -17 45923.79 20481.41)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#222226" />
        <stop offset="1" stopColor="#303236" />
      </linearGradient>
      <linearGradient
        id="icon-live-stream-stub-c"
        x1="-857.67"
        y1="1252.89"
        x2="-856.67"
        y2="1252.47"
        gradientTransform="matrix(104 0 0 -68 89198 85216.37)"
        xlinkHref="#icon-live-stream-stub-a"
      />
      <linearGradient
        id="icon-live-stream-stub-d"
        x1="-811.51"
        y1="1231.07"
        x2="-811.24"
        y2="1230.07"
        gradientTransform="matrix(16 0 0 -30 13081 36970.13)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#303236" />
        <stop offset="1" stopColor="#2b2b30" />
      </linearGradient>
      <linearGradient
        id="icon-live-stream-stub-e"
        x1="-811.53"
        y1="1211.2"
        x2="-811.18"
        y2="1209.86"
        gradientTransform="matrix(16 0 0 -20 13081 24263.76)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" stopOpacity=".08" />
        <stop offset="1" stopColor="#fff" stopOpacity=".02" />
      </linearGradient>
      <linearGradient
        id="icon-live-stream-stub-f"
        x1="-829.63"
        y1="1228.3"
        x2="-828.89"
        y2="1226.97"
        gradientTransform="matrix(24 0 0 -28 20005 34446.86)"
        xlinkHref="#icon-live-stream-stub-a"
      />
      {/* IconLiveStreamsStub end */}

      {/* IconLiveStatsStub start */}
      <linearGradient id="icon-live-stats-stub-a" x1="0%" x2="82.878%" y1="34.673%" y2="61.861%">
        <stop offset="0%" stopColor="#303236" />
        <stop offset="100%" stopColor="#222226" />
      </linearGradient>
      <linearGradient id="icon-live-stats-stub-b" x1="0%" x2="93.609%" y1="49.926%" y2="50.789%">
        <stop offset="0%" stopColor="#303236" />
        <stop offset="100%" stopColor="#222226" />
      </linearGradient>
      <linearGradient id="icon-live-stats-stub-c" x1="0%" x2="82.878%" y1="47.752%" y2="51.74%">
        <stop offset="0%" stopColor="#303236" />
        <stop offset="100%" stopColor="#222226" />
      </linearGradient>
      <linearGradient id="icon-live-stats-stub-d" x1="42.559%" x2="60.766%" y1="43.307%" y2="67.203%">
        <stop offset="0%" stopColor="#FFF" stopOpacity=".08" />
        <stop offset="100%" stopColor="#FFF" stopOpacity=".04" />
        <stop offset="100%" stopColor="#FFF" stopOpacity=".02" />
      </linearGradient>
      <linearGradient id="icon-live-stats-stub-e" x1="12.92%" x2="100%" y1="12.905%" y2="109.652%">
        <stop offset="0%" stopColor="#303236" />
        <stop offset="100%" stopColor="#222226" />
      </linearGradient>
      <linearGradient id="icon-live-stats-stub-f" x1="19.402%" x2="74.735%" y1="9.967%" y2="143.706%">
        <stop offset="0%" stopColor="#FFF" stopOpacity=".08" />
        <stop offset="100%" stopColor="#FFF" stopOpacity=".04" />
        <stop offset="100%" stopColor="#FFF" stopOpacity=".02" />
      </linearGradient>
      {/* IconLiveStatsStub end */}

      {/* IconBonus start */}
      <linearGradient id="icon-bonus-a" x1="-9" y1="9" x2="9" y2="27" gradientUnits="userSpaceOnUse">
        <stop stopColor="#04D992" />
        <stop offset="1" stopColor="#00A970" />
      </linearGradient>
      <linearGradient id="icon-bonus-b" x1="19.2146" y1="9.73679" x2="9.05191" y2="0.889743" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0.599104" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      {/* IconBonus end */}

      {/* IconLeagues start */}
      <clipPath id="icon-leagues-a">
        <rect width="20" height="20" fill="white" />
      </clipPath>
      {/* IconLeagues end */}

      {/* IconTeamPlaceholder start */}
      <linearGradient id="icon-team-placeholder-a" x1="48" y1="8" x2="48" y2="88" gradientUnits="userSpaceOnUse">
        <stop stopColor="#B6B6B6" stopOpacity="0.82" />
        <stop offset="1" stopColor="#A8A8B8" stopOpacity="0.12" />
      </linearGradient>
      <linearGradient id="icon-team-placeholder-b" x1="59.4583" y1="11.2558" x2="14.3469" y2="55.7093" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.21" />
      </linearGradient>
      {/* IconTeamPlaceholder end */}

      {/* IconIndividualPlaceholder start */}
      <linearGradient id="icon-individual-placeholder-a" x1="48" y1="8" x2="48" y2="88" gradientUnits="userSpaceOnUse">
        <stop stopColor="#B6B6B6" stopOpacity="0.82" />
        <stop offset="1" stopColor="#A8A8B8" stopOpacity="0.27" />
      </linearGradient>
      <linearGradient id="icon-individual-placeholder-b" x1="48" y1="18" x2="48" y2="66.458" gradientUnits="userSpaceOnUse">
        <stop stopOpacity="0.36" />
        <stop offset="1" stopOpacity="0.82" />
      </linearGradient>
      {/* IconIndividualPlaceholder end */}

      {/* IconCountryPlaceholder start */}
      <linearGradient id="icon-country-placeholder-a" x1="48" y1="8" x2="48" y2="88" gradientUnits="userSpaceOnUse">
        <stop stopColor="#B6B6B6" stopOpacity="0.82" />
        <stop offset="1" stopColor="#A8A8B8" stopOpacity="0.27" />
      </linearGradient>
      <linearGradient id="icon-country-placeholder-b" x1="48" y1="18.1201" x2="48" y2="77.8801" gradientUnits="userSpaceOnUse">
        <stop stopOpacity="0.36" />
        <stop offset="1" stopOpacity="0.82" />
      </linearGradient>
      {/* IconCountryPlaceholder end */}
    </defs>
  </svg>
);
