import React from 'react';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { IconTicketEmpty } from 'components/icons/icon-ticket-empty/icon-ticket-empty';

import './empty-state.scss';

export const EmptyState = () => (
  <div className="empty-state d-flex flex-column justify-content-center align-items-center bg-main-2">
    <IconTicketEmpty className="ml-n1_5" />
    <FormattedTag id="ticket.empty" className="empty-state-title mt-3 text-center text-extra-3" />
  </div>
);
