import React from 'react';
import PropTypes from 'prop-types';
import Countdown, { calcTimeDelta, formatTimeDelta } from 'react-countdown-now';
import { FormattedMessage } from 'react-intl';

export const Renderer = ({ props: { date } }) => {
  const delta = calcTimeDelta(date);
  const { days, hours, minutes } = formatTimeDelta(delta);

  return (
    <span className="caption text-extra-2">
      {days}
      <FormattedMessage id="freebet.days.short" />
      :
      {hours}
      <FormattedMessage id="freebet.hours.short" />
      :
      {minutes}
      <FormattedMessage id="freebet.minutes.short" />
    </span>
  );
};

Renderer.propTypes = {
  props: PropTypes.shape(),
};

Renderer.defaultProps = {
  props: {},
};

export const CountDown = ({ date }) => (
  <Countdown date={date} renderer={Renderer} />
);

CountDown.propTypes = {
  date: PropTypes.shape({
    hours: PropTypes.number,
    minutes: PropTypes.number,
    seconds: PropTypes.number,
  }),
};

CountDown.defaultProps = {
  date: {},
};
