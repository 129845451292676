import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { fromEvent } from 'rxjs';

import { SportsMenu } from '../sports-menu/sports-menu';

import './sticky-header.scss';

export class StickyHeader extends Component {
  static propTypes = {
    pageName: PropTypes.string.isRequired,
    setIsHidden: PropTypes.func.isRequired,
  };

  scrollSubscription;

  prevScrollTop = 0;

  state = {
    isHidden: true,
  };

  componentDidMount() {
    const centralPart = document.querySelector('.central-part');

    if (centralPart) {
      this.prevScrollTop = centralPart.scrollTop;

      this.scrollSubscription = fromEvent(centralPart, 'scroll').subscribe(() => {
        const { setIsHidden } = this.props;
        const { isHidden } = this.state;
        const deltaY = centralPart.scrollTop - this.prevScrollTop;

        if (isHidden && deltaY < 0 && centralPart.scrollTop > 52) {
          setIsHidden(true);
          this.setState({ isHidden: false });
        }

        if ((!isHidden && deltaY > 0 && centralPart.scrollTop > 52) || (!isHidden && centralPart.scrollTop === 0)) {
          setIsHidden(false);
          this.setState({ isHidden: true });
        }

        this.prevScrollTop = centralPart.scrollTop;
      });
    }
  }

  componentWillUnmount() {
    const { setIsHidden } = this.props;
    setIsHidden(false);

    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  render() {
    const { isHidden } = this.state;

    return (
      <div
        className={classNames('header-main-sticky position-fixed bg-main-3 d-flex flex-column w-100', {
          'is-hidden': isHidden,
        })}
      >
        <SportsMenu {...this.props} />
      </div>
    );
  }
}
