import * as R from 'ramda';

export const findSport = (sports, sportId) => R.find(R.propEq('sportId', Number(sportId)), sports);

export const findTopLeague = (topLeagues, leagueId) => R.find(R.propEq('leagueId', Number(leagueId)), topLeagues);

export const findCountryByLeague = (countries, leagueId) => R.find(
  ({ leagues }) => R.find(R.propEq('leagueId', Number(leagueId)), leagues),
  countries,
);

export const findCountry = (countries, countryId) => R.find(R.propEq('countryId', Number(countryId)), countries);
