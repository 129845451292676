import React from 'react';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { IconStub } from 'components/icons/icon-stub/icon-stub';

import './empty-state.scss';

const EmptyStateUI = () => (
  <div className="left-sidebar-empty-state d-flex flex-column align-items-center text-center pt-4">
    <IconStub className="mb-1" />
    <FormattedTag id="left-sidebar.empty" className="text-extra-3" />
  </div>
);

export const EmptyState = React.memo(EmptyStateUI);
