import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import loadable from '@loadable/component';
import { fromEvent } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { matchRoutes } from 'react-router-config';
import Container from 'reactstrap/lib/Container';
import { withCoreComponent } from 'core/hocs';
import { App as AppCore } from 'core/app';

import { SkipServerRender } from 'components/skip-server-render/skip-server-render';
import { GlobalEventsHandler } from 'components/global-events-handler/global-events-handler';
import { GlobalNotificationsStack } from 'components/global-notifications-stack/global-notifications-stack';
import { MediaQuery } from 'components/media-query/media-query';
import { IconsDefs } from 'components/icons/icons-defs';
import { Header } from 'components/header/header';
import { LeftSidebar } from 'components/left-sidebar/left-sidebar';
import { RightSidebar } from 'components/right-sidebar/right-sidebar';
import { LocationTracker } from 'components/location-tracker/location-tracker';
import { SearchedGamesResultDesktop } from 'components/searched-games/searched-games-result-desktop/searched-games-result-desktop';
import { NavigationBar } from 'components/navigation-bar/navigation-bar';
import { Ticket } from 'components/ticket/ticket';
import { AddAccumulatorModal } from 'components/modals/add-accumulator-modal/add-accumulator-modal';
import { Freebets } from 'components/freebets/freebets';
import './locales';

import './app.scss';

const PageNotFound = loadable(() => import(/* webpackChunkName: "page-not-found" */ './pages/page-not-found/page-not-found'));

export class AppUI extends Component {
  static propTypes = {
    renderRoutes: PropTypes.func.isRequired,
    route: PropTypes.shape({
      routes: PropTypes.arrayOf(
        PropTypes.shape({
          path: PropTypes.string,
          exact: PropTypes.bool,
        }),
      ),
    }).isRequired,
    is404: PropTypes.bool.isRequired,
    history: PropTypes.shape().isRequired,
  };

  resizeWindowSubsription;

  state = {
    isSearchOpen: false,
  };

  componentDidMount() {
    this.resizeWindowSubsription = fromEvent(window, 'resize')
      .pipe(startWith('init'))
      .subscribe(this.setVhProperty);
  }

  componentWillUnmount() {
    if (this.resizeWindowSubsription) {
      this.resizeWindowSubsription.unsubscribe();
    }
  }

  setVhProperty = () => {
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  }

  setSearchOpen = isSearchOpen => this.setState({ isSearchOpen });

  render() {
    const {
      renderRoutes,
      route: { routes },
      is404,
      history,
    } = this.props;
    const { isSearchOpen } = this.state;
    const [routeInfo] = matchRoutes(routes, history.location.pathname);
    const currentRoute = R.propOr({}, 'route', routeInfo);
    const params = R.path(['match', 'params'], routeInfo);
    const {
      page: pageName,
    } = currentRoute;

    return is404
      ? <PageNotFound />
      : (
        <Fragment>
          <GlobalNotificationsStack />

          <div className="d-flex main-content">
            <LeftSidebar
              pageName={pageName}
              params={params}
              isSearchOpen={isSearchOpen}
              setSearchOpen={this.setSearchOpen}
            />

            <Container
              fluid
              className="d-flex flex-column central-part position-relative px-0 px-sm-2"
            >
              <Header pageName={pageName} />

              {renderRoutes(routes)}

              <MediaQuery up="lg">
                <SearchedGamesResultDesktop
                  isSearchOpen={isSearchOpen}
                  setSearchOpen={this.setSearchOpen}
                />
              </MediaQuery>

              <div className="bottom-stub flex-shrink-0" />
            </Container>
            <RightSidebar pageName={pageName} params={params} />
          </div>

          <MediaQuery down="md">
            <NavigationBar pageName={pageName} />
          </MediaQuery>

          <MediaQuery up="lg">
            <Ticket />
          </MediaQuery>

          <Freebets />

          <LocationTracker pageName={pageName} />
          <IconsDefs />
          <GlobalEventsHandler />

          <SkipServerRender skip>
            <AddAccumulatorModal />
          </SkipServerRender>
        </Fragment>
      );
  }
}

export const App = withCoreComponent(AppCore, AppUI);
