import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportSoccer = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport1Bg : colors.cpExtra4} d="M18.34 4.51l-.51 2.94 2.15 2.09c-.09-1.86-.67-3.58-1.64-5.03zm-3.85 4.82l-1.26 3.861 1.45 1.989 4.2-.6c.49-.94.83-1.969.99-3.06l-3.04-2.949-2.34.759zm-.97 10.02c1.69-.63 3.17-1.72 4.28-3.11l-2.96.43-1.32 2.68zM10.74 5.89l3.2 2.04 2.42-.79.72-4.18c-.75-.76-1.62-1.4-2.59-1.889L10.74 3.05v2.84zm-2.8 8.21l-1.43 1.96 1.88 3.81c.52.09 1.05.13 1.6.13s1.08-.04 1.6-.13l1.88-3.81-1.43-1.96h-4.1zm2.05-6.909l-3 1.909 1.14 3.5h3.72l1.14-3.5-3-1.909zm2.64-6.83C11.79.12 10.9 0 9.99 0c-.91 0-1.8.12-2.64.361L9.99 1.75 12.63.361zM6.04 7.93l3.2-2.04V3.05L5.49 1.071C4.52 1.56 3.65 2.2 2.89 2.96l.72 4.18 2.43.79zm.71 5.261L5.49 9.33l-2.34-.759L.11 11.52c.16 1.091.5 2.12.99 3.06l4.2.6 1.45-1.989zM2.18 16.24c1.11 1.39 2.59 2.48 4.28 3.11l-1.32-2.68-2.96-.43zm-.03-8.79L0 9.54c.09-1.86.67-3.58 1.64-5.03l.51 2.94z" />
  </svg>
);

IconSportSoccer.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportSoccer.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
