import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Collapse from 'reactstrap/lib/Collapse';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';
import { IconFreebet } from 'components/icons/icon-freebet/icon-freebet';
import { IconSuccess } from 'components/icons/icon-success/icon-success';
import { IconArrowBottom } from 'components/icons/icon-arrow-bottom/icon-arrow-bottom';
import { prepareCurrency } from 'helpers/currency';
import { CountDown } from './countdown';

export class FreebetsItem extends Component {
  static propTypes = {
    freebet: PropTypes.shape().isRequired,
    currency: PropTypes.string.isRequired,
    activateBonus: PropTypes.func.isRequired,
    sendSportClickFeedback: PropTypes.func.isRequired,
    sendLeagueClickFeedback: PropTypes.func.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
  };

  state = {
    isLearnMoreOpen: false,
  };

  onActivateClick = () => {
    const { activateBonus, freebet: { bonusId, activated } } = this.props;

    if (!activated) {
      activateBonus(bonusId);
    }
  };

  toggleLearnMore = () => this.setState(prevState => ({ isLearnMoreOpen: !prevState.isLearnMoreOpen }));

  onSportClick = (e) => {
    const { dataset: { sportId } } = e.currentTarget;
    const { sendSportClickFeedback } = this.props;
    sendSportClickFeedback(sportId);
  };

  onLeagueClick = (e) => {
    const { dataset: { leagueId } } = e.currentTarget;
    const { sendLeagueClickFeedback } = this.props;
    sendLeagueClickFeedback(leagueId);
  };

  onEventClick = (e) => {
    const { dataset: { eventId } } = e.currentTarget;
    const { sendEventClickFeedback } = this.props;
    sendEventClickFeedback(eventId, false);
  };

  render() {
    const { freebet, currency } = this.props;
    const { isLearnMoreOpen } = this.state;
    const {
      activated,
      bonusAmount: amount,
      endDate,
      period,
      isActivateBonusInProgress,
      minWageringOdd: minOdd,
    } = freebet;

    const timestamp = new Date(endDate).getTime();

    return (
      <div className="freebets-item-wrapper p-2 p-sm-0 p-lg-1_5">
        <div className="freebets-item mb-sm-1 mb-lg-0 p-2 px-lg-2_5 rounded">
          <div className="d-flex justify-content-between mb-2_5">
            <div className="d-flex align-items-center flex-wrap pr-1">
              <IconFreebet className="freebets-item-icon flex-shrink-0" />
              <FormattedTag id="freebet.title" tag="div" className="font-weight-bold mx-1" />
              <CountDown date={timestamp} />
            </div>

            <div className="font-weight-bold flex-shrink-0">
              {amount} {prepareCurrency(currency)}
            </div>
          </div>
          <ButtonWithLoader
            size="sm"
            color="primary"
            block
            className={classNames('freebets-item-btn mb-2', { activated })}
            onClick={this.onActivateClick}
            isLoading={isActivateBonusInProgress}
          >
            {activated && <IconSuccess className="flex-shrink-0 mr-1" />}
            <FormattedMessage id={`${activated ? 'freebet.activated' : 'freebet.activate'}`} />
          </ButtonWithLoader>

          <div
            role="button"
            tabIndex="0"
            onClick={this.toggleLearnMore}
            onKeyPress={this.toggleLearnMore}
            className="d-flex align-items-center justify-content-center"
          >
            <FormattedTag id="freebet.learn-more" className="text-small text-extra-2 mr-0_5" />
            <IconArrowBottom className={classNames('icon-arrow-bottom', { 'is-open': isLearnMoreOpen })} color="white" opacity={0.65} backgroundColor="transparent" />
          </div>

          <Collapse isOpen={isLearnMoreOpen}>
            <div className="pt-2 pb-0_5">
              <FormattedTag
                id="freebet.learn-more.description"
                tag="div"
                isHtml
                values={{
                  amount, minOdd, period, currency: prepareCurrency(currency)
                }}
                className="freebets-item-description caption"
              />
            </div>
          </Collapse>
        </div>
      </div>
    );
  }
}
