import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportHurling = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M10 17c-.828 0-1.5.672-1.5 1.5S9.172 20 10 20s1.5-.672 1.5-1.5S10.828 17 10 17zm7.51-2.13c-1.95-.74-3.82-2.84-4.83-4.27l-.85-1.21-.92 1.31.5.71c1.54 2.21 2.61 5.21 2.95 6.53.33 1.31.94 1.75 2.21 2 1.28.25 2.97-.31 3.34-2.34.38-2.02-.44-1.98-2.4-2.73zm-8.92-3.465c-1.538 2.217-2.613 5.214-2.945 6.53-.333 1.317-.943 1.76-2.217 2.009-1.276.249-2.966-.318-3.34-2.341-.374-2.023.443-1.982 2.397-2.73 1.951-.747 3.824-2.845 4.835-4.271l1.764-2.52L4.84 2.02c-.16-.23-.4-.4-.67-.46 0 0-.44-.11-.61-.15-.21-.05-.28-.32-.12-.44C3.65.83 4.69.09 4.69.09c.24-.16.56-.1.72.13l4.588 6.557 4.59-6.555c.164-.235.486-.291.72-.127 0 0 1.046.733 1.25.88.167.119.088.38-.116.432-.169.043-.61.15-.61.15-.27.068-.51.23-.67.46L8.59 11.406z" />
  </svg>
);

IconSportHurling.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportHurling.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
