import React from 'react';
import PropTypes from 'prop-types';

export const IconUsOpen = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.0288 5C11.3783 5 6.65051 6.57722 3 8C8.00975 6.98373 14.2621 6.67479 19 7C18.2621 6.06503 17.087 5 15.0288 5ZM14.5 8C9.01025 8.08272 4.79615 8.88607 0 11C7.22336 11.4472 12.8252 11.6179 19 13C19.7379 12.1057 20 11.2606 20 10.0005C19.9612 9.10615 19.8883 8.69107 19.5 8C18.2111 7.90091 15.7669 7.98091 14.5 8ZM9 13C7.81673 13.0133 6.57768 12.9289 5.5 13C12.6069 14.0569 13.1266 15 14.8742 15C16.2334 15 17.1456 14.7317 18 14C15.9903 13.4512 12.5499 12.96 9 13Z" fill="white" fillOpacity="0.4" />
  </svg>
);

IconUsOpen.propTypes = {
  className: PropTypes.string,
};

IconUsOpen.defaultProps = {
  className: '',
};
