import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import * as R from 'ramda';
import {
  withLocale,
  withSearchedGames,
  withBetSlipActions,
  withBetSlip,
  withFeedbackActions,
} from 'core/hocs';
import { convertStringifiedBoolean } from 'core/helpers';
import { BET_SLIP_STORE_FIELDS, BET_SLIP_STATE } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { Spinner } from 'components/spinner/spinner';
import { Tabs, Tab } from 'components/tabs/tabs';
import { filterLeaguesBySport, filterBySport } from 'helpers/searched-games';
import { SearchGroupItem } from '../searched-games-result-desktop/search-group-item/search-group-item';
import { SearchedLeagues } from '../searched-leagues/searched-leagues';

import './searched-games-result-tablet.scss';

class SearchedGamesResultTabletUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    searchValue: PropTypes.string.isRequired,
    searchedGames: PropTypes.arrayOf(PropTypes.shape()),
    isSearchedGamesInProgress: PropTypes.bool.isRequired,
    betSlipStakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    toggleStake: PropTypes.func.isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    isWatchlist: PropTypes.bool,
    isResults: PropTypes.bool,
    isSchedule: PropTypes.bool,
    sendLeagueClickFeedback: PropTypes.func.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    searchedGames: null,
    isResults: false,
    isSchedule: false,
    isWatchlist: false,
  };

  state = {
    isInProgress: false,
    activeTab: 0,
  };

  componentDidUpdate(prevProps) {
    const {
      searchValue: prevSearchValue,
      isSearchedGamesInProgress: prevIsSearchedGamesInProgress,
    } = prevProps;
    const {
      searchValue,
      isSearchedGamesInProgress,
    } = this.props;

    if (prevSearchValue !== searchValue && searchValue) {
      this.setIsInProgress(true);
    }

    if (prevIsSearchedGamesInProgress && !isSearchedGamesInProgress) {
      this.setActiveTab(0);
      this.setIsInProgress(false);
    }
  }

  setActiveTab = activeTab => this.setState({ activeTab });

  setIsInProgress = isInProgress => this.setState({ isInProgress });

  onLeagueClick = (e) => {
    const { dataset: { leagueId } } = e.currentTarget;
    const { sendLeagueClickFeedback } = this.props;
    sendLeagueClickFeedback(leagueId);
  };

  onEventClick = (e) => {
    const { dataset: { eventId, isLive } } = e.currentTarget;
    const { sendEventClickFeedback } = this.props;
    sendEventClickFeedback(eventId, convertStringifiedBoolean(isLive));
  };

  render() {
    const {
      locale,
      searchedGames,
      isSearchedGamesInProgress,
      toggleStake,
      betSlipStakes,
      betSlipState,
      isWatchlist,
      isResults,
      isSchedule,
    } = this.props;
    const { isInProgress, activeTab } = this.state;
    let events = R.isNil(searchedGames) ? [] : searchedGames.events;
    let leagues = R.isNil(searchedGames) ? [] : searchedGames.leagues;
    const sports = R.uniq(R.map(R.pick(['sportId', 'sportName']), [...events, ...leagues]));

    const tabs = [];

    if (sports.length) {
      if (sports.length > 1) {
        tabs.push(
          <Tab
            label="general.all"
            tabId={0}
            key={0}
          >
            {null}
          </Tab>
        );

        if (activeTab) {
          events = filterBySport(events, activeTab);
          leagues = filterLeaguesBySport(leagues, activeTab);
        }
      }

      sports.forEach(({ sportId, sportName }) => {
        tabs.push(
          <Tab
            label={sportName}
            tabId={sportId}
            key={sportId}
          >
            {null}
          </Tab>
        );
      });
    }

    return (
      <div className="searched-games-result-tablet d-none d-sm-block pt-3 px-2">
        {isInProgress || isSearchedGamesInProgress
          ? <Spinner className="searched-games-result-tablet-loader position-absolute" />
          : (
            <Fragment>
              {!!sports.length && (
                <Tabs
                  activeTab={sports.length > 1 ? activeTab : sports[0].sportId}
                  toggleTab={this.setActiveTab}
                  classNameLabel={classNames({ 'mb-4': leagues.length, 'mb-2_5': !leagues.length })}
                >
                  {tabs}
                </Tabs>
              )}

              {(!!leagues.length || !!events.length) && (
                <div>
                  {!!leagues.length && (
                    <SearchedLeagues
                      leagues={leagues}
                      locale={locale}
                      onLeagueClick={this.onLeagueClick}
                    />
                  )}

                  {!!leagues.length && !!events.length && <div className="searched-games-result-tablet-divider mt-4 mb-2_5" />}

                  {!!events.length && (
                    <div>
                      {events.map(event => (
                        <SearchGroupItem
                          {...event}
                          key={event.eventId}
                          locale={locale}
                          toggleStake={toggleStake}
                          betSlipStakes={betSlipStakes}
                          betSlipState={betSlipState}
                          isLogoShowed={!activeTab}
                          isWatchlist={isWatchlist}
                          isResults={isResults}
                          isSchedule={isSchedule}
                          className="bg-main-3 mb-1_5 px-1 rounded"
                          onLeagueClick={this.onLeagueClick}
                          onEventClick={this.onEventClick}
                        />
                      ))}
                    </div>
                  )}
                </div>
              )}

              {!R.isNil(searchedGames) && !leagues.length && !events.length && (
                <FormattedTag
                  id="search-results.not-found"
                  className="text-extra-3 text-small"
                />
              )}
            </Fragment>
          )}
      </div>
    );
  }
}

export const SearchedGamesResultTablet = withFeedbackActions(withLocale(withRouter(withSearchedGames(withBetSlipActions(
  withBetSlip(SearchedGamesResultTabletUI, [
    BET_SLIP_STORE_FIELDS.STAKES,
    BET_SLIP_STORE_FIELDS.BET_SLIP_STATE,
  ])
)))));
