import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromEvent } from 'rxjs';
import { filter, map, debounceTime } from 'rxjs/operators';

/**
 * Using that to ensure user hits the end of document
 */
const SAFE_TRIGGER_OFFSET = 150;
const INFINITE_SCROLL_DEBOUNCE = 100;

const isEndOfTarget = target =>
  (target.clientHeight + target.scrollTop) >= target.scrollHeight - SAFE_TRIGGER_OFFSET;

export const withInfiniteScroll = WrappedComponent =>
  class WithInfiniteScroll extends Component {
    static propTypes = {
      loadMore: PropTypes.func.isRequired,
      isLoadMoreAvailable: PropTypes.bool.isRequired,
      isModal: PropTypes.bool,
    };

    static defaultProps = {
      isModal: false,
    };

    scrollSubscription;

    componentDidMount() {
      const { isModal } = this.props;
      let target;

      if (isModal) {
        target = document.querySelector('.modal');
      } else {
        target = document.querySelector('.central-part');
      }

      if (target) {
        this.scrollSubscription = fromEvent(target, 'scroll')
          .pipe(
            debounceTime(INFINITE_SCROLL_DEBOUNCE),
            filter(() => isEndOfTarget(target)),
            map(() => window.pageYOffset)
          ).subscribe(() => {
            const { loadMore, isLoadMoreAvailable } = this.props;

            if (isLoadMoreAvailable) {
              loadMore();
            }
          });
      }
    }

    componentWillUnmount() {
      if (this.scrollSubscription) {
        this.scrollSubscription.unsubscribe();
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
