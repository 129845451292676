import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportKabaddi = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M10.663 12.932L7.927 11.23l.965-1.693.398.586c.058.086.137.153.22.21.176-.234.424-.42.723-.523l1.54-.528-.066.014c-.158.034-.315.014-.452-.048l-1.75-.786-1.362-.612 3.261.734c.124.028.25.027.366 0l2.656-.571c.46-.1.752-.552.653-1.012-.099-.46-.55-.753-1.012-.653l-2.472.533-3.078-.693.99.02c-.06-.05-.124-.098-.196-.14l-.502-.285c-.312-.178-.682-.225-1.028-.13-.347.094-.64.323-.819.634L2.89 13.779.28 16.542c-.388.41-.37 1.057.04 1.445.41.387 1.058.369 1.445-.041l2.716-2.876c.071-.075.13-.16.174-.251l1.211-2.464c.003 0 .005.004.008.006l3.237 2.012.047 2.887c.01.559.465 1.006 1.022 1.006h.016c.565-.01 1.015-.475 1.006-1.04l-.057-3.443c-.004-.347-.186-.667-.482-.851zM8.707 4.938c.696.398 1.563.198 2.032-.427.054.293.192.574.414.806.605.623 1.6.638 2.224.035.624-.605.64-1.6.036-2.224-.604-.623-1.6-.64-2.224-.036-.062.062-.114.128-.165.196-.08-.44-.34-.842-.759-1.081-.755-.43-1.715-.168-2.145.587-.43.754-.168 1.714.587 2.144zm4.915 3.945l-.005.486-3.163 1.085c-.444.153-.682.638-.529 1.082.121.354.452.577.805.577.092 0 .185-.016.277-.047l3.733-1.28c.341-.118.571-.437.575-.798l.016-1.728c-.2.203-.46.354-.762.419l-.947.204zm6.285 7.936l-1.604-3.511s.674-2.946.674-2.99c.004-.252-.09-.494-.266-.675l-3.17-3.275c.092.147.165.309.204.49.09.42-.002.836-.222 1.168l.473 1.968c.135.56-.18 1.14-.727 1.33l-1.9.652c-.314.17-.518.49-.534.85l-.196 4.373c-.025.563.412 1.04.975 1.066h.047c.543 0 .995-.428 1.02-.976l.17-3.781 1.486-.767-.105.43c-.053.223-.03.457.065.665l1.751 3.832c.234.512.84.74 1.354.505.514-.234.74-.841.505-1.354z" />
  </svg>
);

IconSportKabaddi.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportKabaddi.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
