import React from 'react';
import PropTypes from 'prop-types';

export const IconLive = ({ color, className }) => (
  <svg className={className} width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={color} fillRule="nonzero">
        <path d="M9,17 L9,14 L11,14 L11,17 L14,17 L14,19 L6,19 L6,17 L9,17 Z M0,3.99201702 C0,2.8918564 0.898212381,2 1.99079514,2 L18.0092049,2 C19.1086907,2 20,2.90017617 20,3.99201702 L20,11.007983 C20,12.1081436 19.1017876,13 18.0092049,13 L1.99079514,13 C0.891309342,13 0,12.0998238 0,11.007983 L0,3.99201702 Z M4.201514,9.05981652 L4.201514,5 L3,5 L3,9.98405806 L6.29303836,9.98405806 L6.29303836,9.05981652 L4.201514,9.05981652 Z M8.08428692,9.98405806 L8.08428692,5 L6.88619604,5 L6.88619604,9.98405806 L8.08428692,9.98405806 Z M10.85797,8.6285038 L9.86868924,5 L8.53709681,5 L10.2007316,9.98405806 L11.5152084,9.98405806 L13.1925356,5 L11.8506739,5 L10.85797,8.6285038 Z M16.6508421,7.87199499 L16.6508421,6.97856151 L14.7612816,6.97856151 L14.7612816,5.92766465 L17,5.92766465 L17,5 L13.5597676,5 L13.5597676,9.98405806 L16.9931538,9.98405806 L16.9931538,9.05981652 L14.7612816,9.05981652 L14.7612816,7.87199499 L16.6508421,7.87199499 Z" id="Combined-Shape" />
      </g>
    </g>
  </svg>
);

IconLive.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconLive.defaultProps = {
  className: null,
  color: '#FFFFFF',
};
