import React from 'react';
import PropTypes from 'prop-types';

export const IconSeriaA = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2 15L10 20L7 15H2Z" fill="white" fillOpacity="0.4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13 15L10 20L18 15H13Z" fill="white" fillOpacity="0.4" />
    <path d="M18 15L12 0C10.539 0 9.49508 0 8 0L2 15H7L9.99957 6.23241L11.5 10H10L9 13H12.5L13 15H18Z" fill="white" fillOpacity="0.4" />
  </svg>
);

IconSeriaA.propTypes = {
  className: PropTypes.string,
};

IconSeriaA.defaultProps = {
  className: '',
};
