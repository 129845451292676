import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './sports-preloader.scss';

const SportsPreloaderRow = ({ size }) => (
  <div className="sports-list-preloader-row preloader">
    <div className="d-flex  justify-content-start align-items-center pl-2_5 h-100">
      <div className="rounded-circle preloader-icon bg-main-4" />
      <div className={classNames('preloader-item bg-main-4 ml-2_5', `preloader-item-${size}`)} />
    </div>
  </div>
);

export const SportsPreloader = () => (
  <div className="sports-list-preloader preloader py-0_5">
    <div className="preloader-item bg-main-4 mt-2_5 mb-1_5 ml-2_5" />
    {/* eslint-disable-next-line react/no-array-index-key */}
    {['small', 'large', 'medium', 'xsmall', 'medium', 'xlarge', 'medium'].map((row, idx) => <SportsPreloaderRow key={idx} size={row} />)}
  </div>
);

SportsPreloaderRow.propTypes = {
  size: PropTypes.string.isRequired,
};
