import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './checkbox.scss';

export const Checkbox = ({
  name,
  onChange,
  disabled,
  checked,
  className,
  ...restProps
}) => (
  <div className={classNames('checkbox', className)}>
    <input
      type="checkbox"
      name={name}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      className="d-none"
      {...restProps}
    />
    <div />
  </div>
);

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  className: null,
};
