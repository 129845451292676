import React from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { IconDeposit } from 'components/icons/icon-deposit/icon-deposit';

import './bet-slip-deposit.scss';

export const BetSlipDeposit = ({
  clearOutOfBalanceError,
  sendDepositMessage,
}) => (
  <div className="bet-slip-deposit position-absolute">
    <div className="bet-slip-deposit-content bg-main-3 px-2_5 pb-2_5 pt-4_5 text-center">
      <IconDeposit className="mt-1 mb-3" />
      <FormattedTag id="bet-slip.deposit.title" tag="div" className="font-weight-bold mb-0_5" />
      <FormattedTag id="bet-slip.deposit.subtitle" tag="div" className="text-extra-3 mb-6" />

      <div className="d-flex">
        <FormattedTag
          tag={Button}
          id="general.cancel"
          outline
          size="sm"
          className="mr-0_5"
          onClick={clearOutOfBalanceError}
        />
        <FormattedTag
          tag={Button}
          id="general.deposit"
          color="primary"
          size="sm"
          className="ml-0_5"
          onClick={sendDepositMessage}
        />
      </div>
    </div>
  </div>
);

BetSlipDeposit.propTypes = {
  clearOutOfBalanceError: PropTypes.func.isRequired,
  sendDepositMessage: PropTypes.func.isRequired,
};
