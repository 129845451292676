import React from 'react';
import PropTypes from 'prop-types';

export const IconLiveStreamsStub = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 111 84"
    width="106"
    height="84"
    className={className}
  >
    <g>
      <g>
        <g>
          <g>
            <g>
              <path
                d="M10.13 5h83.74a5.87 5.87 0 013.13.53 3.6 3.6 0 011.52 1.52 5.87 5.87 0 01.48 3.08v47.74a5.87 5.87 0 01-.53 3.13A3.6 3.6 0 0197 62.47a5.87 5.87 0 01-3.08.53H10.13a5.87 5.87 0 01-3.08-.53A3.6 3.6 0 015.53 61 5.87 5.87 0 015 57.87V10.13a5.87 5.87 0 01.53-3.08 3.6 3.6 0 011.52-1.52A5.87 5.87 0 0110.13 5z"
                fill="url(#icon-live-stream-stub-a)"
              />
              <path
                d="M62.32 35.69l-13.25 8.37A2 2 0 0146 42.37V25.63a2 2 0 012-2 2 2 0 011.07.31l13.25 8.37a2 2 0 01.68 2.76 2.07 2.07 0 01-.68.62z"
                fill="#fff"
                fillOpacity=".08"
              />
              <path
                d="M57 67v10.12a109.34 109.34 0 0120.47 2.94 2 2 0 11-.94 3.88 103.8 103.8 0 00-49.06 0 2 2 0 11-.94-3.88A109.34 109.34 0 0147 77.12V67z"
                fill="url(#icon-live-stream-stub-b)"
              />
              <path
                d="M93.68 0h-83c-2.89 0-4.43.3-6 1.16a8.39 8.39 0 00-3.52 3.49C.3 6.26 0 7.8 0 10.69v46.62c0 2.89.3 4.43 1.16 6a8.39 8.39 0 003.49 3.49c1.61.86 3.15 1.16 6 1.16h82.66c2.89 0 4.43-.3 6-1.16a8.39 8.39 0 003.49-3.49c.86-1.61 1.16-3.15 1.16-6V10.69c0-2.89-.3-4.43-1.16-6a8.39 8.39 0 00-3.49-3.49A11.13 11.13 0 0093.68 0zm-83 3h82.63c2.67 0 3.64.28 4.62.8a5.4 5.4 0 012.27 2.27c.52 1 .8 1.95.8 4.62v47.23a7.85 7.85 0 01-.79 4 5.4 5.4 0 01-2.27 2.27c-1 .52-2 .8-4.62.8H10.08a7.85 7.85 0 01-4-.79 5.4 5.4 0 01-2.28-2.27C3.3 61 3 60.06 3 57.62V10.69c0-2.67.28-3.64.8-4.62A5.4 5.4 0 016.07 3.8C7.05 3.28 8 3 10.69 3z"
                fill="url(#icon-live-stream-stub-c)"
              />
            </g>
            <g>
              <path
                d="M99 38a8 8 0 018 8v14a8 8 0 01-8 8 8 8 0 01-8-8V46a8 8 0 018-8z"
                fill="url(#icon-live-stream-stub-d)"
              />
              <path
                d="M99 38a8 8 0 018 8v12H91V46a8 8 0 018-8z"
                fill="url(#icon-live-stream-stub-e)"
              />
              <path
                fill="#222226"
                d="M91 46h4a1 1 0 010 2h-4z"
              />
              <path
                fill="#222226"
                d="M107 46h-4a1 1 0 000 2h4z"
              />
              <path
                fill="#222226"
                d="M91 51h4a1 1 0 010 2h-4z"
              />
              <path
                fill="#222226"
                d="M107 51h-4a1 1 0 000 2h4z"
              />
              <path
                d="M91 56v2h-2v2a10 10 0 009.72 10H99a10 10 0 0010-9.72V58h-2v-2h4v4a12 12 0 01-10 11.83V81h4.5a1.5 1.5 0 010 3h-13a1.5 1.5 0 010-3H97v-9.17a12 12 0 01-10-11.52V56z"
                fill="url(#icon-live-stream-stub-f)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconLiveStreamsStub.propTypes = {
  className: PropTypes.string,
};

IconLiveStreamsStub.defaultProps = {
  className: null,
};
