import React from 'react';
import PropTypes from 'prop-types';

export const IconMenu = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path fill="#FFF" fillOpacity=".65" fillRule="evenodd" d="M4 14v2H2v-2h2zm14 0v2H6v-2h12zM4 9v2H2V9h2zm14 0v2H6V9h12zM4 4v2H2V4h2zm14 0v2H6V4h12z" />
  </svg>
);

IconMenu.propTypes = {
  className: PropTypes.string,
};

IconMenu.defaultProps = {
  className: null,
};
