import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TabContent from 'reactstrap/lib/TabContent';
import TabPane from 'reactstrap/lib/TabPane';
import Nav from 'reactstrap/lib/Nav';

import { TabLabel } from './tab-label';

import './tabs.scss';

export const Tabs = ({
  children,
  activeTab,
  toggleTab,
  className,
  classNameLabel,
  underNavContent,
}) => (
  <div className={classNames('tabs', className)}>
    <Nav tabs className={classNames('d-flex flex-nowrap mb-1_5 border-bottom', classNameLabel)}>
      {children.map(({
        props: {
          tabId,
          label,
          values = {},
          external,
          className: classname,
          onClick,
        },
      }, idx) => (
        <TabLabel
          key={tabId}
          tabId={tabId}
          label={label}
          values={values}
          external={external}
          isActive={tabId === activeTab}
          toggleTab={toggleTab}
          className={classNames(classname, {
            'mr-2_5': idx !== children.length - 1,
            'pr-2 pr-sm-0': idx === children.length - 1,
          })}
          onClick={onClick}
        />
      ))}
    </Nav>

    {underNavContent}

    <TabContent activeTab={activeTab}>
      {children}
    </TabContent>
  </div>
);

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  activeTab: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  toggleTab: PropTypes.func.isRequired,
  className: PropTypes.string,
  classNameLabel: PropTypes.string,
  underNavContent: PropTypes.node,
};

Tabs.defaultProps = {
  className: null,
  classNameLabel: '',
  underNavContent: null,
};

export const Tab = ({ children, tabId, className }) => (
  <TabPane tabId={tabId} className={className}>
    {children}
  </TabPane>
);

Tab.propTypes = {
  children: PropTypes.node,
  tabId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  className: PropTypes.string,
};

Tab.defaultProps = {
  children: null,
  className: null,
};
