import React from 'react';
import PropTypes from 'prop-types';

export const IconWimbledon = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#icon-leagues-a)">
      <path d="M11.6261 16.8405C11.2103 17.2642 10.6375 17.5288 9.99901 17.5288C9.36052 17.5288 8.78966 17.2642 8.37594 16.8405C8.13924 17.1687 8 17.5646 8 17.9982C8 18.4338 8.13924 18.8276 8.37594 19.1558C8.78568 18.7341 9.36052 18.4676 9.99901 18.4676C10.6375 18.4676 11.2143 18.7341 11.6261 19.1578C11.8608 18.8276 12 18.4338 12 17.9982C12 17.5646 11.8608 17.1687 11.6261 16.8405Z" fill="white" fillOpacity="0.4" />
      <path d="M9.9994 16.9766C10.4867 16.9746 10.9283 16.7698 11.2426 16.4396C10.9004 16.1691 10.4728 16 9.9994 16C9.52999 16 9.09837 16.1691 8.75824 16.4396C9.07251 16.7698 9.51407 16.9746 9.9994 16.9766Z" fill="white" fillOpacity="0.4" />
      <path d="M9.9994 19.02C9.5101 19.022 9.07052 19.2268 8.75824 19.555C9.09837 19.8295 9.52999 20.0006 9.9994 20.0006C10.4728 20.0006 10.9004 19.8295 11.2426 19.555C10.9303 19.2268 10.4907 19.022 9.9994 19.02Z" fill="white" fillOpacity="0.4" />
      <path d="M15.2214 16.2033V14.2106C14.9626 13.9531 14.7064 13.6996 14.449 13.4489V15.451C14.7091 15.7084 14.9679 15.9605 15.2214 16.2033Z" fill="white" fillOpacity="0.4" />
      <path d="M14.0475 15.0508V13.0621C13.7807 12.8033 13.5232 12.5553 13.2778 12.3219V14.265C13.5299 14.5251 13.7874 14.7893 14.0475 15.0508Z" fill="white" fillOpacity="0.4" />
      <path d="M10.2848 11.0436L11.3616 12.1472L12.1474 11.3587L11.0934 10.3261C10.8668 10.4911 10.6375 10.6896 10.3854 10.9417L10.2848 11.0436Z" fill="white" fillOpacity="0.4" />
      <path d="M15.7011 16.655C15.917 16.8562 16.0753 16.9755 16.5285 16.5223C16.9791 16.0704 16.8597 15.9135 16.6599 15.6949C16.5862 15.6171 16.5124 15.538 16.436 15.4589C16.1799 15.1826 15.9023 14.8984 15.6207 14.6127V16.5799C15.6462 16.6054 15.6743 16.6295 15.7011 16.655Z" fill="white" fillOpacity="0.4" />
      <path d="M12.8235 11.8909L12.7886 11.8574C12.7162 11.7904 12.7068 11.7877 12.6385 11.7193C12.5593 11.6496 12.5097 11.612 12.4561 11.612L11.6153 12.4528C11.614 12.5064 11.6515 12.5547 11.7253 12.6352C11.7923 12.7076 11.8017 12.721 11.8728 12.7974L11.9063 12.8336C12.2013 13.1447 12.5298 13.4894 12.8785 13.8501V11.9405C12.8597 11.9231 12.8409 11.907 12.8235 11.8909Z" fill="white" fillOpacity="0.4" />
      <path d="M8.08708 5.94833C8.04283 6.47131 7.80685 6.97643 7.33817 7.41871C6.83066 7.89764 6.3748 8.01223 5.68101 8.08706C4.4031 8.22489 3.8052 7.9331 2.67341 6.75035C0.710224 4.70536 0.783287 3.18003 2.20557 1.93821C3.53746 0.775306 4.63452 0.643369 6.68354 2.60656C7.86628 3.74237 8.20643 4.56444 8.08708 5.94833ZM9.28125 7.02721C9.27052 6.76438 9.27991 6.47608 9.30539 6.1529C9.42876 4.5853 9.10424 3.09414 7.33817 1.50642C4.89491 -0.699487 2.49054 -0.184551 1.15359 1.15106C-0.186049 2.48936 -0.698301 4.89373 1.50492 7.33565C3.09666 9.10306 4.58783 9.42891 6.15275 9.30286C6.47459 9.27738 6.76558 9.26934 7.02975 9.28006C7.77399 9.33504 8.3305 9.47987 8.87896 9.8178L9.10156 9.59788C9.38585 9.31493 9.59907 9.0588 9.77339 8.80535C9.46765 8.27701 9.33355 7.74061 9.28125 7.02721Z" fill="white" fillOpacity="0.4" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.5777 7.8366C4.68987 7.86009 4.80612 7.87487 4.92873 7.88183V6.96499H6.59079V7.61308C6.70799 7.55546 6.82186 7.48397 6.93649 7.39395V6.96499H7.36673C7.4573 6.85034 7.53349 6.73223 7.59601 6.61144H6.93649V4.95265H7.80634C7.79136 4.83213 7.76987 4.71554 7.74137 4.60174H6.93649V3.18643C6.81127 3.04498 6.67124 2.89994 6.51538 2.74895C6.45852 2.694 6.40246 2.64081 6.34716 2.58935H4.92873V1.55811C4.80824 1.50136 4.69145 1.45603 4.5777 1.42154V2.58671H2.92096V1.70621C2.80304 1.7828 2.68461 1.87028 2.5646 1.96769V2.58671H1.93346C1.84069 2.70277 1.75975 2.82144 1.69119 2.9429H2.5646V4.60174H1.51215C1.54531 4.71653 1.58728 4.83347 1.63832 4.95265H2.5646V6.34339C2.64223 6.43162 2.72402 6.52091 2.81003 6.6113C2.93245 6.74035 3.04811 6.85802 3.1588 6.96499H4.5777V7.8366ZM6.59079 2.9429H4.92873V4.60174H6.59079V2.9429ZM6.59079 4.95265H4.92873V6.61144H6.59079V4.95265ZM4.5777 2.9429H2.92096V4.60174H4.5777V2.9429ZM4.5777 4.95265H2.92096V6.61144H4.5777V4.95265Z" fill="white" fillOpacity="0.4" />
      <path d="M3.80753 15.216H5.80022C6.05501 14.9612 6.31113 14.701 6.5619 14.4462H4.55982C4.30101 14.7064 4.04756 14.9639 3.80753 15.216Z" fill="white" fillOpacity="0.4" />
      <path d="M4.95783 14.0438H6.94918C7.20799 13.7783 7.45473 13.5181 7.68806 13.2741H5.74498C5.48483 13.5248 5.21932 13.785 4.95783 14.0438Z" fill="white" fillOpacity="0.4" />
      <path d="M3.35493 15.6952C3.1511 15.9138 3.03578 16.0707 3.48635 16.5226C3.93825 16.9758 4.0938 16.8565 4.31238 16.6553C4.3915 16.5816 4.47196 16.5078 4.55108 16.4341C4.82598 16.1753 5.11027 15.9003 5.3959 15.6147H3.43002C3.40455 15.6429 3.37907 15.6697 3.35493 15.6952Z" fill="white" fillOpacity="0.4" />
      <path d="M8.11824 12.8207L8.15042 12.7844C8.21881 12.712 8.22418 12.7026 8.2872 12.6316C8.35962 12.5551 8.39851 12.5069 8.39851 12.4532L7.55771 11.6124C7.50273 11.6124 7.45445 11.65 7.37802 11.7197C7.30292 11.7894 7.28683 11.7988 7.21308 11.8699L7.17553 11.9034C6.86711 12.1958 6.52113 12.5243 6.15773 12.8729L8.06728 12.8716C8.08471 12.8555 8.10215 12.8367 8.11824 12.8207Z" fill="white" fillOpacity="0.4" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.9292 0.488983C16.5498 -0.264127 14.6133 -0.24516 12.6528 1.52497C10.888 3.11403 10.5622 4.60385 10.6869 6.17145C10.7137 6.49329 10.7217 6.78429 10.711 7.04578C10.6225 8.22852 10.3235 8.92449 9.35663 9.89268L7.86412 11.3543L8.6553 12.1482L10.1156 10.6517C10.4982 10.2707 10.8395 9.99236 11.1862 9.78992C11.1869 9.79041 11.1877 9.7909 11.1884 9.79139C11.717 9.48621 12.2536 9.35269 12.967 9.30115C13.2299 9.29071 13.5182 9.3004 13.8413 9.32622C15.4088 9.45127 16.9003 9.12836 18.4899 7.36399C20.6984 4.9231 20.1861 2.51817 18.8519 1.17979C18.5843 0.91137 18.274 0.676053 17.9292 0.488983ZM12.5776 7.35766C13.0194 7.8268 13.5243 8.06332 14.0472 8.10814C15.431 8.22897 16.2534 7.8897 17.3905 6.70818C19.3558 4.66127 19.2251 3.56406 18.0636 2.23092C16.8233 0.807314 15.2981 0.732617 13.251 2.69362C12.067 3.82413 11.7746 4.42172 11.911 5.69977C11.9851 6.39365 12.0992 6.84963 12.5776 7.35766Z" fill="white" fillOpacity="0.4" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.1635 4.58963C12.1396 4.70171 12.1244 4.81791 12.117 4.94049L13.034 4.94373L13.0281 6.60578L12.3799 6.60349C12.4371 6.7209 12.5082 6.83502 12.5978 6.94996L13.0269 6.95148L13.0253 7.3818C13.1397 7.47276 13.2575 7.54937 13.3781 7.6123L13.3804 6.95273L15.0392 6.9586L15.0361 7.82845C15.1567 7.81389 15.2734 7.79281 15.3873 7.76471L15.3901 6.95984L16.8053 6.96485C16.9472 6.84013 17.0927 6.70063 17.2443 6.5453C17.2995 6.48861 17.3529 6.4327 17.4046 6.37755L17.4096 4.95921L18.4407 4.96286C18.4979 4.84258 18.5436 4.72594 18.5785 4.61232L17.4135 4.6082L17.4193 2.95146L18.2997 2.95458C18.2235 2.83639 18.1365 2.71765 18.0395 2.5973L17.4206 2.59511L17.4228 1.96406C17.3071 1.87086 17.1887 1.7895 17.0675 1.72051L17.0644 2.59385L15.4056 2.58799L15.4093 1.53553C15.2944 1.56828 15.1773 1.60983 15.0579 1.66044L15.0547 2.58674L13.6639 2.58182C13.5753 2.65914 13.4858 2.74062 13.3951 2.82632C13.2656 2.94825 13.1476 3.06346 13.0402 3.17375L13.0352 4.59271L12.1635 4.58963ZM17.0501 6.62001L17.056 4.95796L15.3972 4.95209L15.3913 6.61414L17.0501 6.62001ZM15.0404 6.6129L15.0463 4.95085L13.3875 4.94499L13.3816 6.60703L15.0404 6.6129ZM17.0573 4.60694L17.0631 2.9502L15.4043 2.94434L15.3984 4.60107L17.0573 4.60694ZM15.0475 4.59983L15.0534 2.94309L13.3946 2.93723L13.3887 4.59396L15.0475 4.59983Z" fill="white" fillOpacity="0.4" />
    </g>
  </svg>
);

IconWimbledon.propTypes = {
  className: PropTypes.string,
};

IconWimbledon.defaultProps = {
  className: '',
};
