import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import * as R from 'ramda';
import { mathRound, getIsHighlighted } from 'core/helpers';
import { BET_SLIP_STATE, AI_OUTCOME_SECTION } from 'core/constants';

import { IconLock } from 'components/icons/icon-lock/icon-lock';
import { OddsWithRank } from 'components/odds-with-rank/odds-with-rank';
import { getXsLabel } from 'helpers/stake-types';
import { GA } from 'helpers/ga';
import { PAGE_NAMES } from '../../../constants';

import './odds-cell.scss';

export class OddsCell extends Component {
  static propTypes = {
    className: PropTypes.string,
    stake: PropTypes.shape().isRequired,
    toggleStake: PropTypes.func.isRequired,
    eventId: PropTypes.number.isRequired,
    eventNumber: PropTypes.number.isRequired,
    leagueId: PropTypes.number.isRequired,
    teamA: PropTypes.string.isRequired,
    teamB: PropTypes.string.isRequired,
    isEventInLive: PropTypes.bool.isRequired,
    isBetAllowed: PropTypes.bool.isRequired,
    isOutright: PropTypes.bool.isRequired,
    betSlipStakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    isResults: PropTypes.bool,
    pageName: PropTypes.string,
    isWatchlist: PropTypes.bool,
    isSchedule: PropTypes.bool,
    isSearch: PropTypes.bool,
    recommendationId: PropTypes.string,
    isTotal: PropTypes.bool.isRequired,
    isHandicap: PropTypes.bool.isRequired,
    oddsType: PropTypes.oneOf([
      'primary',
      'secondary',
    ]),
    liveSportId: PropTypes.number,
  };

  static defaultProps = {
    className: null,
    recommendationId: null,
    oddsType: 'primary',
    isResults: false,
    pageName: null,
    isWatchlist: false,
    isSchedule: false,
    isSearch: false,
    liveSportId: null,
  };

  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props, nextProps);
  }

  onClick = () => {
    const {
      stake: {
        stakeId,
        stakeTypeId,
        betFactor,
        stakeCode,
        stakeArgument,
        stakeName,
        isActive,
        stakeTypeName,
        isExpressDenied,
      },
      eventId,
      eventNumber,
      teamA,
      teamB,
      isBetAllowed,
      isEventInLive,
      toggleStake,
      isOutright,
      leagueId,
      isResults,
      pageName,
      betSlipState,
      betSlipStakes,
      isWatchlist,
      isSchedule,
      recommendationId,
      isSearch,
      liveSportId,
    } = this.props;
    const stake = {
      stakeId,
      stakeTypeId,
      betFactor,
      initialBetFactor: betFactor,
      stakeCode,
      stakeArgument,
      stakeName,
      isActive,
      isBetAllowed,
      eventId,
      eventNumber,
      teamA,
      teamB,
      stakeTypeName,
      isExpressDenied,
      isEventInLive,
      isOutright,
      leagueId,
    };
    const isAllowed = isEventInLive ? isBetAllowed && isActive : true;
    const isHighlighted = getIsHighlighted(betSlipState, betSlipStakes, stakeId, isEventInLive);
    let label;
    let section;

    if (!isAllowed || isResults) {
      return;
    }

    if (!isHighlighted) {
      if (recommendationId) {
        stake.recommendationId = recommendationId;
        stake.isAddedFromInplay = true;
      }

      if (isWatchlist) {
        label = 'watchlist-page';
        section = AI_OUTCOME_SECTION.WATCHLIST;
      } else if (isSchedule) {
        label = 'schedule-page';
        section = AI_OUTCOME_SECTION.SCHEDULE;
      } else if (isSearch) {
        label = 'search-page';
        section = AI_OUTCOME_SECTION.SEARCH;
      } else if (pageName === PAGE_NAMES.HOME) {
        if (isEventInLive) {
          label = 'inplay-section-main';
          section = AI_OUTCOME_SECTION.MAIN_INPLAY;
        } else {
          label = 'upcoming-section-main';
          section = AI_OUTCOME_SECTION.MAIN_UPCOMING;
        }
      } else if (pageName === PAGE_NAMES.SPORT) {
        if (isEventInLive) {
          label = 'inplay-section-sports';
          section = AI_OUTCOME_SECTION.SPORT_INPLAY;
        } else {
          label = 'upcoming-section-sports';
          section = AI_OUTCOME_SECTION.SPORT_UPCOMING;
        }
      } else if (pageName === PAGE_NAMES.LEAGUE) {
        if (isEventInLive) {
          label = 'inplay-section-league';
          section = AI_OUTCOME_SECTION.LEAGUE_INPLAY;
        } else {
          label = 'upcoming-section-league';
          section = AI_OUTCOME_SECTION.LEAGUE_UPCOMING;
        }
      } else if (pageName === PAGE_NAMES.LIVE) {
        label = 'main-inplay';

        if (liveSportId) {
          section = AI_OUTCOME_SECTION.INPLAY;
        } else {
          section = AI_OUTCOME_SECTION.ALL_SPORTS_INPLAY;
        }
      }

      if (label) {
        GA.event({
          category: 'added-outcome',
          label,
        });
      }

      if (section) {
        stake.section = section;
      }
    }

    toggleStake(stake);
  };

  render() {
    const {
      stake: {
        stakeId,
        stakeTypeId,
        stakeCode,
        stakeName,
        betFactor,
        isActive,
        stakeArgument,
      },
      isBetAllowed,
      className,
      betSlipStakes,
      betSlipState,
      isEventInLive,
      isTotal,
      isHandicap,
      oddsType
    } = this.props;
    const isHighlighted = getIsHighlighted(betSlipState, betSlipStakes, stakeId, isEventInLive);
    const isAllowed = isEventInLive ? isBetAllowed && isActive : true;
    const prepearedName = (!isHandicap && !isTotal) ? getXsLabel(stakeTypeId, stakeCode) || stakeName : null;

    return (
      <div
        role="button"
        tabIndex="0"
        onClick={this.onClick}
        onKeyPress={this.onClick}
        className={classNames(className, 'outcome-wrapper odd-cell d-flex flex-sm-column justify-content-between align-items-center text-small px-1_5 py-0_25', {
          'is-highlighted': isHighlighted && isAllowed,
          'is-not-allowed': !isAllowed,
        })}
      >
        <span className="odd-cell-label text-nowrap text-center pr-1 pr-sm-0">
          {isHandicap && (
            <FormattedMessage
              id={`line.handicap.${stakeCode}.short`}
              values={{ amount: stakeArgument > 0 ? `+${stakeArgument}` : stakeArgument }}
            />
          )}
          {isTotal && <FormattedMessage id={`line.total.${stakeCode}.short`} values={{ amount: stakeArgument }} />}
          {prepearedName}
        </span>
        <div className="odd-cell-value d-flex align-items-center justify-content-center">
          {isAllowed && betFactor
            ? (
              <OddsWithRank odds={betFactor} type={oddsType} className="rank-arrow font-weight-bold position-relative">
                {mathRound(betFactor)}
              </OddsWithRank>
            )
            : <IconLock />}
        </div>
      </div>
    );
  }
}
