import React from 'react';

import { MediaQuery } from 'components/media-query/media-query';
import { SearchedGamesInput } from 'components/searched-games/searched-games-input/searched-games-input';
import { SearchedGamesResultMobile } from 'components/searched-games/searched-games-result-mobile/searched-games-result-mobile';
import { SearchedGamesResultTablet } from 'components/searched-games/searched-games-result-tablet/searched-games-result-tablet';

export const Search = () => (
  <div className="mx-sm-n2 mx-lg-n0">
    <MediaQuery down="md">
      <SearchedGamesInput isMobile />
    </MediaQuery>

    <MediaQuery down="xs">
      <SearchedGamesResultMobile />
    </MediaQuery>

    <MediaQuery between={['sm', 'md']}>
      <SearchedGamesResultTablet />
    </MediaQuery>
  </div>
);
