import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconSportWaterPolo = ({
  className, isActive, width, height,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path fill={isActive ? colors.cpSport0Bg : colors.cpExtra4} d="M5.774 10.47c-.14.061-.26.12-.4.16-.75.26-1.52.441-2.31.53-.32-.83-.49-1.73-.49-2.669 0-2.331 1.06-4.411 2.73-5.791-.19.69-.31 1.38-.37 2.081-.17 1.91.13 3.859.84 5.689m3.55-2.229c-.71.649-1.48 1.2-2.31 1.66-.45-1.241-.87-2.971-.69-4.991.11-1.14.38-2.259.82-3.33.82-.349 1.73-.549 2.67-.58-.26.68-.46 1.37-.59 2.071-.33 1.71-.28 3.439.1 5.17m3.49 2.209c-1.09 1.32-2.44 2.4-3.95 3.09-.75.35-1.52.62-2.33.781-.2-.04-.42-.061-.63-.061-.19 0-.38.021-.56.05-.64-.51-1.18-1.13-1.62-1.829.72-.12 1.42-.29 2.1-.531 1.64-.56 3.12-1.469 4.42-2.669.92.29 1.78.679 2.57 1.169m3.1 2.791c-.68.819-1.52 1.5-2.49 1.969l-.22-.179c-.61-.491-1.38-.761-2.16-.761-.24 0-.47.02-.7.08 1.37-.79 2.6-1.85 3.62-3.1.11.09.23.16.34.26.59.51 1.14 1.09 1.61 1.731m1.58-5.991c-.49-.5-1.03-.96-1.61-1.36-1.57-1.1-3.41-1.819-5.35-2.13.02-.14.03-.279.06-.42.16-.78.39-1.53.7-2.26 3.17.51 5.68 3.01 6.2 6.17m.05 2.151c-.11.929-.39 1.819-.82 2.619-.45-.57-.96-1.09-1.51-1.57-1.31-1.129-2.84-1.959-4.52-2.49-.21-.94-.31-1.889-.28-2.82 1.29.21 3.01.73 4.66 1.891.93.66 1.77 1.45 2.47 2.37m1.211 9.595c-.166 0-.333-.054-.471-.167l-1.818-1.469c-.156-.126-.375-.126-.533-.003l-1.135.895c-.714.564-1.706.554-2.409-.017l-1.07-.869c-.158-.127-.379-.127-.537-.002l-1.104.88c-.709.568-1.699.568-2.408-.002l-1.1-.881c-.158-.125-.376-.126-.535.001l-1.096.879c-.709.57-1.7.57-2.409.005L.282 16.772c-.323-.258-.377-.73-.118-1.054.257-.323.729-.376 1.053-.118l1.848 1.474c.157.124.377.125.534-.002l1.097-.88c.711-.571 1.703-.569 2.412-.002l1.101.883c.156.123.378.123.533 0l1.104-.881c.713-.571 1.706-.566 2.417.008l1.072.871c.158.128.376.128.535.003l1.133-.895c.711-.562 1.7-.557 2.407.015l1.817 1.469c.322.261.372.733.111 1.055-.148.184-.365.278-.583.278" />
  </svg>
);

IconSportWaterPolo.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconSportWaterPolo.defaultProps = {
  className: '',
  isActive: false,
  width: '20',
  height: '20',
};
