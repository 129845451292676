import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DayPicker, { DateUtils } from 'react-day-picker';

import 'react-day-picker/lib/style.css';
import './date-picker.scss';

const renderDay = day => <span className="position-relative">{day.getDate()}</span>;

export class DatePicker extends Component {
  static propTypes = {
    numberOfMonths: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    from: PropTypes.string,
    to: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    numberOfMonths: 1,
    from: null,
    to: null,
    className: null,
  };

  handleDayClick = (day) => {
    const { onChange, from, to } = this.props;
    const range = DateUtils.addDayToRange(day, { from, to });
    onChange(range);
  };

  render() {
    const {
      numberOfMonths,
      isOpen,
      from,
      to,
      className,
    } = this.props;
    const modifiers = { start: from, end: to };

    return (
      <DayPicker
        className={classNames('date-picker position-absolute rounded bg-main-5', className, {
          'is-set': from && to,
          'd-none': !isOpen
        })}
        numberOfMonths={numberOfMonths}
        selectedDays={[from, { from, to }]}
        modifiers={modifiers}
        onDayClick={this.handleDayClick}
        renderDay={renderDay}
      />
    );
  }
}
