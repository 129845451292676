import { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

const SHOW_TIMEOUT = 50;

/**
 A component for configurable skip loading.
 @examples
 ```jsx
 <SkipServerRender skip={true}>
 <Footer />
 </SkipServerRender>
 ```
 @component SkipServerRender
 @import {SkipServerRender}
 @playground
 SkipServerRender
 ```
 <SkipServerRender skip={true}>
 <Footer />
 </SkipServerRender>
 ```
 @returns {ReactElement} The rendered component
 */

export class SkipServerRender extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    contextKey: PropTypes.string,
    skip: PropTypes.bool,
  };

  static defaultProps = {
    skip: false,
    contextKey: '',
  };

  constructor(props, context) {
    super(props, context);

    if (props.skip) {
      this.state = { visible: false };
    } else {
      this.state = { visible: !get(context, props.contextKey, false) };
    }
  }

  componentDidMount() {
    const { visible } = this.state;

    if (!visible) {
      this.timeout = setTimeout(this.onShow, SHOW_TIMEOUT);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  }

  onShow = () => {
    this.setState({ visible: true });
  };

  render() {
    const { visible } = this.state;
    const { children } = this.props;

    if (visible) {
      return children;
    }

    return null;
  }
}
