import React from 'react';
import PropTypes from 'prop-types';
import { isOneOfValues } from 'core/helpers';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { GAME_STATUSES_WITHOUT_TIME, SPORTS_WITH_TIME_STATUS } from '../../../constants';

const GameStatusUI = ({
  locale,
  sportId,
  passedTime,
  eventStatus,
  className,
}) => {
  if (isOneOfValues(GAME_STATUSES_WITHOUT_TIME[locale])(eventStatus)) {
    return <span className={className}>{eventStatus}</span>;
  }

  if (isOneOfValues(SPORTS_WITH_TIME_STATUS)(sportId)) {
    return passedTime
      ? (
        <span className={className}>
          {passedTime}
          <span className="animate-minute-live">&rsquo;</span>
        </span>
      )
      : (
        <span className={className}>{eventStatus}</span>
      );
  }

  return eventStatus
    ? <span className={className}>{eventStatus}</span>
    : <FormattedTag id="general.live" className={className} />;
};

GameStatusUI.propTypes = {
  locale: PropTypes.string.isRequired,
  sportId: PropTypes.number.isRequired,
  eventStatus: PropTypes.string.isRequired,
  passedTime: PropTypes.number,
  className: PropTypes.string,
};

GameStatusUI.defaultProps = {
  passedTime: null,
  className: null,
};

export const GameStatus = React.memo(GameStatusUI);
