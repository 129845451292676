import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { fromEvent } from 'rxjs';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { BetTypeDropdown } from '../bet-type-dropdown/bet-type-dropdown';

import './sticky-header.scss';

export class StickyHeader extends Component {
  static propTypes = {
    stakesAmount: PropTypes.number.isRequired,
    isOrdinar: PropTypes.bool.isRequired,
    isDropdownShowed: PropTypes.bool.isRequired,
    setIsHidden: PropTypes.func.isRequired,
    onClearClick: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    selectedItem: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    onExpressClick: PropTypes.func.isRequired,
    onSystemClick: PropTypes.func.isRequired,
  };

  scrollSubscription;

  prevScrollTop = 0;

  state = {
    isHidden: true,
  };

  componentDidMount() {
    const { isMobile } = this.props;
    const scrollableArea = document.querySelector(isMobile ? '.ticket-mobile-scrollable-area' : '.ticket .tab-pane.active');

    if (scrollableArea) {
      this.prevScrollTop = scrollableArea.scrollTop;

      this.scrollSubscription = fromEvent(scrollableArea, 'scroll').subscribe(() => {
        const { setIsHidden } = this.props;
        const { isHidden } = this.state;
        const deltaY = scrollableArea.scrollTop - this.prevScrollTop;

        if (isHidden && deltaY < 0 && scrollableArea.scrollTop > 44) {
          setIsHidden(true);
          this.setState({ isHidden: false });
        }

        if ((!isHidden && deltaY > 0 && scrollableArea.scrollTop > 44)
        || (!isHidden && scrollableArea.scrollTop === 0)) {
          setIsHidden(false);
          this.setState({ isHidden: true });
        }

        this.prevScrollTop = scrollableArea.scrollTop;
      });
    }
  }

  componentWillUnmount() {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  render() {
    const {
      isDropdownShowed,
      stakesAmount,
      isOrdinar,
      onClearClick,
      selectedItem,
      onExpressClick,
      onSystemClick,
    } = this.props;
    const { isHidden } = this.state;

    return (
      <div
        className={classNames('bet-slip-sticky-header position-fixed d-flex align-items-center justify-content-between pr-1_5 bg-main-2', {
          'is-hidden': isHidden,
        })}
      >
        {isDropdownShowed
          ? (
            <BetTypeDropdown
              selectedItem={selectedItem}
              onExpressClick={onExpressClick}
              onSystemClick={onSystemClick}
              stakesAmount={stakesAmount}
            />
          )
          : <FormattedTag id={`bet-slip.type.${isOrdinar ? 'ordinar' : 'express'}`} className="caption font-weight-bold text-extra-2" />}

        <FormattedTag
          id="bet-slip.clear"
          role="button"
          tabIndex="0"
          onClick={onClearClick}
          onKeyPress={onClearClick}
          className="caption text-extra-2"
        />
      </div>
    );
  }
}
